import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getConstructionElements } from "store/entities/projects/componentCatalogue/componentCatalogue.slice";
import { IElement, IIfcConElementAssignment, IStakeholderPermission } from "types";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { getSelectedModel } from "store/ui/projects/ifcViewer/ifcViewer.slice";
import { createIfcConElementAssignment, deleteIfcConElementAssignment, updateIfcConElementAssignment } from "store/entities/projects/ifcViewer/ifcConElementAssignments/ifcConElementAssignments.actions";
import { getIfcConElementAssignments } from "store/entities/projects/ifcViewer/ifcConElementAssignments/ifcConElementAssignments.slice";
import { getIfcElementThicknessByExpressId } from "../../helpers/helpers";
import { properties } from "../../../properties/properties";
import { IIfcConElementAssignmentError } from "../../ifcConElementAssignment";
import { getProjectPermissions } from "store/entities/projects/project.slice";
import { projectPermissions } from "common/utils/constants/auth.constants";
import { hasProjectPerm } from "services/authService";

interface IAssignmentSelect {
    tableSelection: GridRowSelectionModel;
    setTableSelection: Dispatch<SetStateAction<GridRowSelectionModel>>;
    getModelPropertiesByExpressId: (expressId: number) => properties[] | null;
    project_id: number;
    setIfcConElementAssignmentErrors: Dispatch<SetStateAction<IIfcConElementAssignmentError[]>>;
    ifcConElementAssignmentErrors: IIfcConElementAssignmentError[];
}

const AssignmentSelect: React.FC<IAssignmentSelect> = ({
    tableSelection,
    setTableSelection,
    getModelPropertiesByExpressId,
    project_id,
    setIfcConElementAssignmentErrors,
    ifcConElementAssignmentErrors,
}) => {
    const dispatch = useAppDispatch();

    const userPermissions: IStakeholderPermission[] = useAppSelector(
        getProjectPermissions
    );

    const elements = useAppSelector(getConstructionElements);
    const selectedModel = useAppSelector(getSelectedModel);
    const elementAssignmentList: IIfcConElementAssignment[] = useAppSelector(getIfcConElementAssignments);
    const menuItems = elements.map((element: any, index: number) => <MenuItem key={index} value={element.id}>{element.name}</MenuItem>)

    const handleSelectAssignmentChange = (event: SelectChangeEvent) => {
        setIfcConElementAssignmentErrors([]);
        const element: IElement | undefined = elements.find((element: IElement) => element.id === Number(event.target.value));
        if (selectedModel !== null) {
            if (element !== undefined) {
                for (const selection of tableSelection) {
                    const ifcElementThickness = getIfcElementThicknessByExpressId(getModelPropertiesByExpressId, Number(selection))
                    const constructionElementThickness = element.performance.total_width;

                    // Search if assignment got error
                    let assignmentError: String | null = null;
                    if (ifcElementThickness === 0) {
                        assignmentError = `Error retrieving ifc-Element thickness`;
                    } else if (constructionElementThickness === 0) {
                        assignmentError = `Error retrieving Construction-Element thickness`;
                    } else if (ifcElementThickness !== constructionElementThickness) {
                        assignmentError = `Thickness from ifc Element (${ifcElementThickness}) != Construction-Element thickness (${constructionElementThickness})`;
                    }

                    // Set assignment error to see error in table
                    if (assignmentError !== null) {
                        const index = ifcConElementAssignmentErrors.findIndex(error => error.ifc_element_express_id === Number(selection));
                        if (index !== -1) {
                            const updatedItems = [...ifcConElementAssignmentErrors];
                            updatedItems[index] = { ...updatedItems[index], assignmentError: assignmentError };
                            setIfcConElementAssignmentErrors(updatedItems);
                        } else {
                            const newError: IIfcConElementAssignmentError = { ifc_element_express_id: Number(selection), assignmentError: assignmentError };
                            setIfcConElementAssignmentErrors(prevItems => [...prevItems, newError]);
                        }
                    } else {
                        // Create new assigment or update assignment if no error
                        const existingAssignment: IIfcConElementAssignment | undefined = elementAssignmentList.find((assignment) => assignment.model_file.uuid === selectedModel.uuid && assignment.ifc_element_express_id === Number(selection));
                        if (existingAssignment === undefined) {
                            dispatch(createIfcConElementAssignment(
                                project_id,
                                selectedModel.uuid,
                                element.id,
                                Number(selection),
                                -1
                            ));
                        } else {
                            if (existingAssignment.construction_element.id !== element.id) {
                                dispatch(updateIfcConElementAssignment(
                                    project_id,
                                    existingAssignment.id,
                                    existingAssignment.model_file.uuid,
                                    existingAssignment.construction_element.id,
                                    existingAssignment.ifc_element_express_id,
                                    existingAssignment.minimal_bracing_force
                                ));
                            }
                        }
                    }
                }
            } else if (Number(event.target.value) === -1) {
                // Delete assignment of "No assignment" is selected
                for (const selection of tableSelection) {
                    elementAssignmentList.map((assignment) => {
                        if (assignment.model_file.uuid === selectedModel.uuid && assignment.ifc_element_express_id === Number(selection)) {
                            dispatch(deleteIfcConElementAssignment(project_id, assignment.id));
                        }
                    });
                }
            }
        }
        setTableSelection([]);
    };

    return (
        <FormControl disabled={!(tableSelection.length !== 0)} variant="filled" sx={{ mt: 1, minWidth: 250 }}>
            <InputLabel id="demo-multiple-checkbox-label">Assign to Construction-Element</InputLabel>
            <Select
                labelId="assignmentSelect"
                id="assignmentSelect"
                onChange={handleSelectAssignmentChange}
                value={""}
                disabled={!hasProjectPerm(userPermissions, [projectPermissions.ifc_con_element_assignments.maintainer,])}
            >
                <MenuItem key={"assignmentSelect"} value={-1}><em>No assignment</em></MenuItem>
                {menuItems}
            </Select>
        </FormControl>
    );
}

export default AssignmentSelect;