import { apiRequest } from "common/utils/constants/api.constants";
import * as ifcModelCreationsSlice from "./ifcModelCreations.slice";
import * as ifcModelCreationsEndpoints from "assets/endpoints/api/projects/ifcModelCreationsEndpoints";

export const fetchIfcModelCreations = (projectId: number) =>
  apiRequest({
    url: ifcModelCreationsEndpoints.getIfcModelCreationsListUrl(projectId),
    onStart: ifcModelCreationsSlice.ifcModelCreationsRequested.type,
    onSuccess: ifcModelCreationsSlice.ifcModelCreationsReceived.type,
    onError: ifcModelCreationsSlice.ifcModelCreationsRequestFailed.type,
  });

export const fetchIfcModelCreationsStatusStates = (projectId: number) =>
  apiRequest({
    url: ifcModelCreationsEndpoints.getIfcModelCreationsStatusStatesUrl(projectId),
    onStart: ifcModelCreationsSlice.ifcModelCreationsStatesRequested.type,
    onSuccess: ifcModelCreationsSlice.ifcModelCreationsStatesReceived.type,
    onError: ifcModelCreationsSlice.ifcModelCreationsStatesRequestFailed.type,
  });

export const fetchIfcModelCreationsLevelChoiches = (projectId: number) =>
  apiRequest({
    url: ifcModelCreationsEndpoints.getIfcModelCreationsLevelChoicesUrl(projectId),
    onStart: ifcModelCreationsSlice.ifcModelCreationsLevelsRequested.type,
    onSuccess: ifcModelCreationsSlice.ifcModelCreationsLevelsReceived.type,
    onError: ifcModelCreationsSlice.ifcModelCreationsLevelsRequestFailed.type,
  });

export const fetchIfcModelCreationsOptimizationPriorityChoices = (projectId: number) =>
  apiRequest({
    url: ifcModelCreationsEndpoints.getIfcModelCreationsOptimizationPriorityChoicesUrl(projectId),
    onStart: ifcModelCreationsSlice.ifcModelCreationsOptimizationPriorityChoicesRequested.type,
    onSuccess: ifcModelCreationsSlice.ifcModelCreationsOptimizationPriorityChoicesReceived.type,
    onError: ifcModelCreationsSlice.ifcModelCreationsOptimizationPriorityChoicesRequestFailed.type,
  });

export const createIfcModelCreation = (
  projectId: number,
  base_ifc_model_file_uuid: string,
  creation_level: string,
  creation_optimization_priority_hex: string,
) =>
  apiRequest({
    url: ifcModelCreationsEndpoints.getIfcModelCreationsListUrl(projectId),
    method: "post",
    headers: {
      'content-type': 'multipart/form-data'
    },
    data: {
      base_ifc_model_file_uuid: base_ifc_model_file_uuid,
      creation_level: creation_level,
      creation_optimization_priority_hex: creation_optimization_priority_hex,
    },
    onStart: ifcModelCreationsSlice.ifcModelCreationsCreateRequested.type,
    onSuccess: ifcModelCreationsSlice.ifcModelCreationsCreateFulfilled.type,
    onError: ifcModelCreationsSlice.ifcModelCreationsCreateFailed.type,
  });

export const deleteIfcModelCreation = (projectId: number, ifcModelCreationId: number) =>
  apiRequest({
    url: ifcModelCreationsEndpoints.getIfcModelCreationsItemUrl(projectId, ifcModelCreationId),
    method: "delete",
    onStart: ifcModelCreationsSlice.ifcModelCreationsDeleteRequested.type,
    onSuccess: ifcModelCreationsSlice.ifcModelCreationsDeleteFulfilled.type,
    onError: ifcModelCreationsSlice.ifcModelCreationsDeleteFailed.type,
  });
