import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  getDesign,
  selectDesign,
} from "store/entities/settings/settings.slice";
import { colorThemes } from "assets/colorThemes";

const Display = () => {
  const { t } = useTranslation("settings", { keyPrefix: "app" });
  const dispatch = useDispatch();
  const design = useSelector(getDesign);

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(selectDesign(event.target.value as string));
  };

  return (
    <FormControlLabel
      sx={{
        width: "100%",
        m: 0,
        direction: "row",
        justifyContent: "space-between",
        alignItems: "top",
      }}
      labelPlacement="start"
      label={t<string>("choose_preferred_color_theme")}
      control={
        <FormControl sx={{ width: "50%" }}>
          <Select
            displayEmpty={true}
            inputProps={{ "aria-label": "Without label" }}
            value={design.colorTheme}
            onChange={handleChange}
          >
            {colorThemes.map(
              (colorTheme: { value: string; name: string }, index: number) => (
                <MenuItem key={index} value={colorTheme.value}>
                  {colorTheme.name}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      }
    />
  );
};

export default Display;
