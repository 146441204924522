import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { getLocales } from "store/entities/settings/settings.slice";
import { useAppSelector } from "store";

interface IProps {
  elementForm: any;
  isMaintainer: boolean;
}

const ElementUpdateForm: React.FC<IProps> = ({ elementForm, isMaintainer }) => {
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "selected_element.dialogs.element.update",
  });
  const locales = useAppSelector(getLocales);

  return (
    <form id="element-update-form" onSubmit={elementForm.handleSubmit}>
      <Grid container spacing={2} sx={{ pt: 2 }}>
        <Grid item xs={12}>
          <TextField
            id="name"
            name="name"
            label={t("name")}
            required
            fullWidth
            variant="outlined"
            value={elementForm.values.name}
            onBlur={elementForm.handleBlur}
            error={elementForm.touched.name && Boolean(elementForm.errors.name)}
            helperText={elementForm.touched.name && elementForm.errors.name}
            onChange={elementForm.handleChange}
            InputProps={{
              readOnly: !isMaintainer,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="identifier"
            name="identifier"
            label={t("identifier")}
            required
            fullWidth
            variant="outlined"
            value={elementForm.values.identifier}
            onBlur={elementForm.handleBlur}
            error={
              elementForm.touched.identifier &&
              Boolean(elementForm.errors.identifier)
            }
            helperText={
              elementForm.touched.identifier && elementForm.errors.identifier
            }
            onChange={elementForm.handleChange}
            InputProps={{
              readOnly: !isMaintainer,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="description"
            name="description"
            label={t("description")}
            fullWidth
            variant="outlined"
            value={elementForm.values.description}
            onBlur={elementForm.handleBlur}
            error={
              elementForm.touched.description &&
              Boolean(elementForm.errors.description)
            }
            helperText={
              elementForm.touched.description && elementForm.errors.description
            }
            onChange={elementForm.handleChange}
            InputProps={{
              readOnly: !isMaintainer,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="performance.fire_protection"
            name="performance.fire_protection"
            label={t("fire_protection")}
            fullWidth
            variant="outlined"
            value={elementForm.values.performance.fire_protection}
            onBlur={elementForm.handleBlur}
            error={
              elementForm.touched.performance &&
              elementForm.touched.performance.fire_protection &&
              Boolean(elementForm.errors.performance) &&
              Boolean(elementForm.errors.performance.fire_protection)
            }
            helperText={
              elementForm.touched.performance &&
              elementForm.touched.performance.fire_protection &&
              elementForm.errors.performance &&
              elementForm.errors.performance.fire_protection
            }
            onChange={elementForm.handleChange}
            InputProps={{
              readOnly: !isMaintainer,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <NumericFormat
            id="performance.direct_sound_reduction_index"
            name="performance.direct_sound_reduction_index"
            label={t("direct_sound_reduction_index")}
            fullWidth
            variant="outlined"
            customInput={TextField}
            decimalSeparator={locales.code === "de-DE" ? "," : "."}
            suffix={" dB"}
            decimalScale={1}
            allowNegative={false}
            value={elementForm.values.performance.direct_sound_reduction_index}
            onBlur={elementForm.handleBlur}
            error={
              elementForm.touched.performance &&
              elementForm.touched.performance.direct_sound_reduction_index &&
              Boolean(elementForm.errors.performance) &&
              Boolean(
                elementForm.errors.performance.direct_sound_reduction_index
              )
            }
            helperText={
              elementForm.touched.performance &&
              elementForm.touched.performance.direct_sound_reduction_index &&
              elementForm.errors.performance &&
              elementForm.errors.performance.direct_sound_reduction_index
            }
            onValueChange={(values, sourceInfo) => {
              if (values.floatValue !== undefined) {
                const formattedValue = parseFloat(values.floatValue.toFixed(1));
                elementForm.setFieldValue(
                  "performance.direct_sound_reduction_index",
                  formattedValue
                );
              }
            }}
            InputProps={{
              readOnly: !isMaintainer,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <NumericFormat
            id="performance.sound_reduction_index"
            name="performance.sound_reduction_index"
            label={t("sound_reduction_index")}
            fullWidth
            variant="outlined"
            customInput={TextField}
            decimalSeparator={locales.code === "de-DE" ? "," : "."}
            suffix={" dB"}
            decimalScale={3}
            allowNegative={false}
            value={elementForm.values.performance.sound_reduction_index}
            onBlur={elementForm.handleBlur}
            error={
              elementForm.touched.performance &&
              elementForm.touched.performance.sound_reduction_index &&
              Boolean(elementForm.errors.performance) &&
              Boolean(elementForm.errors.performance.sound_reduction_index)
            }
            helperText={
              elementForm.touched.performance &&
              elementForm.touched.performance.sound_reduction_index &&
              elementForm.errors.performance &&
              elementForm.errors.performance.sound_reduction_index
            }
            onValueChange={(values, sourceInfo) => {
              if (values.floatValue !== undefined) {
                const formattedValue = parseFloat(values.floatValue.toFixed(1));
                elementForm.setFieldValue(
                  "performance.sound_reduction_index",
                  formattedValue
                );
              }
            }}
            InputProps={{
              readOnly: !isMaintainer,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <NumericFormat
            id="performance.weighted_normalized_impact_sound_pressure_level"
            name="performance.weighted_normalized_impact_sound_pressure_level"
            label={t("weighted_normalized_impact_sound_pressure_level")}
            fullWidth
            variant="outlined"
            customInput={TextField}
            decimalSeparator={locales.code === "de-DE" ? "," : "."}
            suffix={" dB"}
            decimalScale={3}
            allowNegative={false}
            value={
              elementForm.values.performance
                .weighted_normalized_impact_sound_pressure_level
            }
            onBlur={elementForm.handleBlur}
            error={
              elementForm.touched.performance &&
              elementForm.touched.performance
                .weighted_normalized_impact_sound_pressure_level &&
              Boolean(elementForm.errors.performance) &&
              Boolean(
                elementForm.errors.performance
                  .weighted_normalized_impact_sound_pressure_level
              )
            }
            helperText={
              elementForm.touched.performance &&
              elementForm.touched.performance
                .weighted_normalized_impact_sound_pressure_level &&
              elementForm.errors.performance &&
              elementForm.errors.performance
                .weighted_normalized_impact_sound_pressure_level
            }
            onValueChange={(values, sourceInfo) => {
              if (values.floatValue !== undefined) {
                const formattedValue = parseFloat(values.floatValue.toFixed(1));
                elementForm.setFieldValue(
                  "performance.weighted_normalized_impact_sound_pressure_level",
                  formattedValue
                );
              }
            }}
            InputProps={{
              readOnly: !isMaintainer,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <NumericFormat
            id="performance.normalized_impact_sound_pressure_level"
            name="performance.normalized_impact_sound_pressure_level"
            label={t("normalized_impact_sound_pressure_level")}
            fullWidth
            variant="outlined"
            customInput={TextField}
            decimalSeparator={locales.code === "de-DE" ? "," : "."}
            suffix={" dB"}
            decimalScale={3}
            allowNegative={false}
            value={
              elementForm.values.performance
                .normalized_impact_sound_pressure_level
            }
            onBlur={elementForm.handleBlur}
            error={
              elementForm.touched.performance &&
              elementForm.touched.performance
                .normalized_impact_sound_pressure_level &&
              Boolean(elementForm.errors.performance) &&
              Boolean(
                elementForm.errors.performance
                  .normalized_impact_sound_pressure_level
              )
            }
            helperText={
              elementForm.touched.performance &&
              elementForm.touched.performance
                .normalized_impact_sound_pressure_level &&
              elementForm.errors.performance &&
              elementForm.errors.performance
                .normalized_impact_sound_pressure_level
            }
            onValueChange={(values, sourceInfo) => {
              if (values.floatValue !== undefined) {
                const formattedValue = parseFloat(values.floatValue.toFixed(1));
                elementForm.setFieldValue(
                  "performance.normalized_impact_sound_pressure_level",
                  formattedValue
                );
              }
            }}
            InputProps={{
              readOnly: !isMaintainer,
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default ElementUpdateForm;
