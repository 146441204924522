export const getStakeholderListUrl = (projectId: number) =>
  `projects/${projectId}/stakeholders/`

export const getStakeholderRoleChoicesUrl = (projectId: number) =>
  `projects/${projectId}/stakeholders/role-choices/`

export const getStakeholderInvitationStatusChoicesUrl = (projectId: number) =>
  `projects/${projectId}/stakeholders/invitation-status-choices/`

export const getStakeholderItemUrl = (
  projectId: number,
  stakeholderId: number
) => `projects/${projectId}/stakeholders/${stakeholderId}/`

export const getInvitationStatusUrl = (
  projectId: number,
  stakeholderId: number
) => `projects/${projectId}/stakeholders/${stakeholderId}/invitation-status/`
