import { Avatar, ListItemAvatar, ListItemText, Stack, useMediaQuery } from "@mui/material";
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridRowSelectionModel, GridValueGetterParams } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles'
import { useAppSelector } from "store";
import { useEffect } from "react";
import { getLoading, getUploadedFiles } from "store/entities/projects/uploadedFiles/uploadedFiles.slice";
import { IIfcCreationLevelChoice, IUploadedFile, IUploadedFileTableRowData } from "types";
import { useTranslation } from "react-i18next";
import { getReadablyFileSize } from "common/utils/helpers/fileSize.helpers";
import { stringToColor } from "common/utils/helpers/stringToColor.helpers";
import { useState } from "react";
import { LoadingSkeletonOverly, NoRowsOverlay } from "./overlays";
import { getIfcModelCreationsDialogs } from "store/ui/projects/ifcModelCreations/ifcModelCreations.slice";
import { getIfcModelCreationsLevels } from "store/entities/projects/ifcModelCreations/ifcModelCreations.slice";

interface IProps {
    newIfcModelCreationForm: any;
    rowSelModelBaseFile: GridRowSelectionModel;
    setRowSelModelBaseFile: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
}

const Table: React.FC<IProps> = ({
    newIfcModelCreationForm,
    rowSelModelBaseFile,
    setRowSelModelBaseFile
}) => {
    const theme = useTheme()
    const { t } = useTranslation("ifc_model_creations", {
        keyPrefix: "dialogs.create_dialog.base_file",
    });

    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});

    const uploadedFiles = useAppSelector(getUploadedFiles);
    const ifcModelCreationsLevels = useAppSelector(getIfcModelCreationsLevels);
    const loading = useAppSelector(getLoading);
    const isDialogOpen = useAppSelector(getIfcModelCreationsDialogs).createIfcModelCreation;

    const translatedColumnNames = {
        id: t("table.column_headers.id"),
        name: t("table.column_headers.name"),
        created_at: t("table.column_headers.created_at"),
        uploaded_by: t("table.column_headers.uploaded_by"),
        size: t("table.column_headers.size"),
        creation_level: t("table.column_headers.creation_level"),
    }

    const tableColumns: GridColDef[] = [
        {
            field: 'id',
            headerName: translatedColumnNames.id,
            type: 'number',
            width: 70,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'name',
            headerName: translatedColumnNames.name,
            type: 'string',
            minWidth: 200,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'creation_level',
            headerName: translatedColumnNames.creation_level,
            type: 'string',
            minWidth: 100,
            flex: 0.5,
            align: 'left',
            headerAlign: 'left',
            valueGetter: (params: GridValueGetterParams) =>
                `${ifcModelCreationsLevels.find((state: IIfcCreationLevelChoice) => state.value === params.row["creation_level"]).label}`,
        },
        {
            field: 'created_at',
            headerName: translatedColumnNames.created_at,
            type: 'string',
            minWidth: 150,
            flex: 0.5,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'uploaded_by',
            headerName: translatedColumnNames.uploaded_by,
            type: 'string',
            minWidth: 150,
            flex: 0.5,
            align: 'left',
            headerAlign: 'left',
            renderCell: ({ row }) => (
                <Stack direction="row" spacing={-2}>
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                                bgcolor: stringToColor(
                                    `${row["uploaded_by"]["first_name"]} ${row["uploaded_by"]["last_name"]}`
                                ),
                                width: 24,
                                height: 24,
                                fontSize: 17
                            }}
                        >
                            {`${row["uploaded_by"]["first_name"].slice(0, 1)}${row["uploaded_by"]["last_name"].slice(
                                0,
                                1
                            )}`}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${row["uploaded_by"]["first_name"]} ${row["uploaded_by"]["last_name"]}`}
                    />
                </Stack>
            )
        },
        {
            field: 'size',
            headerName: translatedColumnNames.size,
            type: 'string',
            minWidth: 100,
            flex: 0.2,
            align: 'left',
            headerAlign: 'left',
        },
    ];

    const tableRows = uploadedFiles.filter(function (file: IUploadedFile) {
        if (file.extension !== "ifc") {
            return false;
        }
        return true;
    }).map((file: IUploadedFile, index: number): IUploadedFileTableRowData | undefined => {
        const created_at_format = new Date(file.created_at);
        const filzeSize = getReadablyFileSize(file.size);

        return ({
            id: index + 1,
            file_id: file.id,
            uuid: file.uuid,
            name: file.name,
            created_at: created_at_format.toLocaleString(),
            uploaded_by: file.uploaded_by,
            size: filzeSize,
            creation_level: file.creation_level,
        })
    }
    )

    useEffect(() => {
        if (!matches) {
            setColumnVisibilityModel(
                {
                    id: false,
                    created_at: false,
                    uploaded_by: false,
                    size: false,
                }
            )
        } else {
            setColumnVisibilityModel(
                {
                    id: true,
                    created_at: true,
                    uploaded_by: true,
                    size: true,
                }
            )
        }
    }, [matches]);

    useEffect(() => {
        if (!isDialogOpen) {
            setRowSelModelBaseFile([]);
        }
    }, [isDialogOpen]);

    return (
        <DataGrid
            rows={tableRows}
            columns={tableColumns}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                },
            }}
            onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = tableRows.filter((row: IUploadedFileTableRowData) =>
                    selectedIDs.has(row.id)
                );
                if (selectedRows.length === 1) {
                    if (selectedRows[0].hasOwnProperty('name')
                        && selectedRows[0].hasOwnProperty('uuid')
                        && selectedRows[0].hasOwnProperty('creation_level')) {
                        newIfcModelCreationForm.setFieldValue('base_model_file.uuid', selectedRows[0].uuid)
                        newIfcModelCreationForm.setFieldValue('base_model_file.name', selectedRows[0].name)
                        newIfcModelCreationForm.setFieldValue('base_model_file.creation_level', selectedRows[0].creation_level)
                    }
                }
                setRowSelModelBaseFile(ids);
            }}
            rowSelectionModel={rowSelModelBaseFile}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            disableColumnMenu
            columnVisibilityModel={columnVisibilityModel}
            slots={{
                loadingOverlay: LoadingSkeletonOverly,
                noRowsOverlay: NoRowsOverlay,
            }}
            loading={loading.isLoading && loading.type == "fetch"}
            autoHeight
            sx={{
                '--DataGrid-overlayHeight': '300px',
                width: "100%",
            }}
        />
    );
};

export default Table;
