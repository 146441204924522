import React from "react";


export default function LandingPage() {
    return (
        <div style={{ "width": "100%", "height": "100vh", "display": "flex", "backgroundColor": "white", "justifyContent": "space-evenly", "alignItems": "center", "textAlign": "center" }}>
            <div style={{}}>
                <img src="/img/brand/logos/hbcLogo.svg" alt="1logo" width={'600px'} />
                <p style={{ "paddingTop": "100px", "fontSize": "2em", "color": "black" }}>
                    coming soon
                </p>
                <p style={{ "paddingTop": "100px", "fontSize": "2em", "color": "black" }}>
                    nicolas@holzbau.cloud
                </p>
            </div>
        </div>
    );
}