import { Box } from '@mui/system'

interface Navigation {
    children: any
}

const BottomNavigation = (props: Navigation) => {
    const { children } = props

    return (
        <>
            <Box
                sx={{
                    position: "fixed",
                    bottom: { xs: 16, md: 72 },
                    right: { xs: 16, md: 72 },
                    float: "right",
                    zIndex: 1200,
                }}
            >
                {children}
            </Box>
        </>
    )
}

export default BottomNavigation;