import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "store";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { updateMyAccount } from "store/entities/authentication/authentication.actions";

interface Address {
  street: string;
  zip: string;
  city: string;
  country: string;
}

interface Account {
  phone: string;
  mailing_address: Address;
  billing_address: Address;
}

export default function Contact() {
  const { t } = useTranslation("settings", { keyPrefix: "account" });

  const dispatch = useAppDispatch();

  const [initialValues, setInitialValues] = useState({
    phone: "",
    mailing_address: {
      street: "",
      zip: "",
      city: "",
      country: "",
    },
    billing_address: {
      street: "",
      zip: "",
      city: "",
      country: "",
    },
  });

  const validationSchema = yup.object().shape({
    phone: yup
      .string()
      .min(8, t<string>("must_contain_at_least", { min_number: 8 }))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t<string>("no_valid_phone_number")
      ),
    mailing_address: yup.object().shape(
      {
        street: yup.string().when(["zip", "city", "country"], {
          is: (zip: string, city: string, country: string) =>
            zip || city || country,
          then: () => yup.string().required(t<string>("required")),
        }),
        zip: yup.string().when(["street", "city", "country"], {
          is: (street: string, city: string, country: string) =>
            street || city || country,
          then: () => yup.string().required(t<string>("required")),
        }),
        city: yup.string().when(["street", "zip", "country"], {
          is: (street: string, zip: string, country: string) =>
            street || zip || country,
          then: () => yup.string().required(t<string>("required")),
        }),
        country: yup.string().when(["street", "zip", "city"], {
          is: (street: string, zip: string, city: string) =>
            street || zip || city,
          then: () => yup.string().required(t<string>("required")),
        }),
      },
      [
        ["street", "zip"],
        ["street", "city"],
        ["street", "country"],
        ["zip", "city"],
        ["zip", "country"],
        ["city", "country"],
      ]
    ),
    billing_address: yup.object({
      street: yup.string(),
      zip: yup.string(),
      city: yup.string(),
      country: yup.string(),
    }),
  });

  const accountForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: Account) => {
      dispatch(updateMyAccount(values));
      setInitialValues({
        phone: "",
        mailing_address: {
          street: "",
          zip: "",
          city: "",
          country: "",
        },
        billing_address: {
          street: "",
          zip: "",
          city: "",
          country: "",
        },
      });
    },
  });

  return (
    <Grid
      container
      spacing={2}
      component="form"
      id="account-form"
      noValidate
      onSubmit={accountForm.handleSubmit}
    >
      <Grid item xs={12}>
        <Typography
          color="text.primary"
          align="left"
          sx={{ fontWeight: "bold" }}
        >
          {t("your_account_details")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="phone"
          name="phone"
          label={t("phone")}
          value={accountForm.values.phone}
          onBlur={accountForm.handleBlur}
          error={accountForm.touched.phone && Boolean(accountForm.errors.phone)}
          helperText={accountForm.touched.phone && accountForm.errors.phone}
          onChange={accountForm.handleChange}
          fullWidth
          autoComplete="phone"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          color="text.secondary"
          align="left"
          sx={{ fontWeight: "bold" }}
        >
          {t("mailing_address")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="mailing-address-street"
          name="mailing_address.street"
          label={t("street")}
          value={accountForm.values.mailing_address.street}
          onBlur={accountForm.handleBlur}
          error={
            accountForm.touched.mailing_address?.street &&
            Boolean(accountForm.errors.mailing_address?.street)
          }
          helperText={
            accountForm.touched.mailing_address?.street &&
            accountForm.errors.mailing_address?.street
          }
          onChange={accountForm.handleChange}
          fullWidth
          autoComplete="street"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="mailing-address-zip"
          name="mailing_address.zip"
          label={t("zip")}
          value={accountForm.values.mailing_address.zip}
          onBlur={accountForm.handleBlur}
          error={
            accountForm.touched.mailing_address?.zip &&
            Boolean(accountForm.errors.mailing_address?.zip)
          }
          helperText={
            accountForm.touched.mailing_address?.zip &&
            accountForm.errors.mailing_address?.zip
          }
          onChange={accountForm.handleChange}
          fullWidth
          autoComplete="zip"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="mailing-address-city"
          name="mailing_address.city"
          label={t("city")}
          value={accountForm.values.mailing_address.city}
          onBlur={accountForm.handleBlur}
          error={
            accountForm.touched.mailing_address?.city &&
            Boolean(accountForm.errors.mailing_address?.city)
          }
          helperText={
            accountForm.touched.mailing_address?.city &&
            accountForm.errors.mailing_address?.city
          }
          onChange={accountForm.handleChange}
          fullWidth
          autoComplete="city"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="mailing-address-country"
          name="mailing_address.country"
          label={t("country")}
          value={accountForm.values.mailing_address.country}
          onBlur={accountForm.handleBlur}
          error={
            accountForm.touched.mailing_address?.country &&
            Boolean(accountForm.errors.mailing_address?.country)
          }
          helperText={
            accountForm.touched.mailing_address?.country &&
            accountForm.errors.mailing_address?.country
          }
          onChange={accountForm.handleChange}
          fullWidth
          autoComplete="country"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          color="text.secondary"
          align="left"
          sx={{ fontWeight: "bold" }}
        >
          {t("billing_address")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="billing-address-street"
          name="billing_address.street"
          label={t("street")}
          value={accountForm.values.billing_address.street}
          onBlur={accountForm.handleBlur}
          error={
            accountForm.touched.billing_address?.street &&
            Boolean(accountForm.errors.billing_address?.street)
          }
          helperText={
            accountForm.touched.billing_address?.street &&
            accountForm.errors.billing_address?.street
          }
          onChange={accountForm.handleChange}
          fullWidth
          autoComplete="street"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="billing-address-zip"
          name="billing_address.zip"
          label={t("zip")}
          value={accountForm.values.billing_address.zip}
          onBlur={accountForm.handleBlur}
          error={
            accountForm.touched.billing_address?.zip &&
            Boolean(accountForm.errors.billing_address?.zip)
          }
          helperText={
            accountForm.touched.billing_address?.zip &&
            accountForm.errors.billing_address?.zip
          }
          onChange={accountForm.handleChange}
          fullWidth
          autoComplete="zip"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="billing-address-city"
          name="billing_address.city"
          label={t("city")}
          value={accountForm.values.billing_address.city}
          onBlur={accountForm.handleBlur}
          error={
            accountForm.touched.billing_address?.city &&
            Boolean(accountForm.errors.billing_address?.city)
          }
          helperText={
            accountForm.touched.billing_address?.city &&
            accountForm.errors.billing_address?.city
          }
          onChange={accountForm.handleChange}
          fullWidth
          autoComplete="city"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="billing-address-country"
          name="billing_address.country"
          label={t("country")}
          value={accountForm.values.billing_address.country}
          onBlur={accountForm.handleBlur}
          error={
            accountForm.touched.billing_address?.country &&
            Boolean(accountForm.errors.billing_address?.country)
          }
          helperText={
            accountForm.touched.billing_address?.country &&
            accountForm.errors.billing_address?.country
          }
          onChange={accountForm.handleChange}
          fullWidth
          autoComplete="country"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }}>
        <Button variant="text" sx={{ mr: 3 }}>
          {t("cancel")}
        </Button>
        <Button variant="contained" type="submit" form="account-form">
          {t("submit")}
        </Button>
      </Grid>
    </Grid>
  );
}
