import type { IPermissionName, IStakeholderPermission } from "types";

export const hasPerm = () => {
  // Placeholder for global permissions based on roles
};

export const hasProjectPerm = (
  currentPerm: IStakeholderPermission[],
  requiredPerm: IPermissionName[]
) => {
  let hasPermission = false;

  if (requiredPerm.length !== 0) {
    hasPermission = currentPerm.some(
    (permission: IStakeholderPermission) =>
      requiredPerm.includes(permission.name) && permission.is_member
  );
  } else {
    hasPermission = true;
  }
  

  return hasPermission;
};
