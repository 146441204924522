import { Fab, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

interface resetFilterButtonProps {
    sxProps?: { [key: string]: string | number | { [key: string]: string | number } }
    handleResetButtonClicked: () => void
}

const ResetButton: React.FC<resetFilterButtonProps> = ({
    sxProps,
    handleResetButtonClicked
}) => {
    const { t } = useTranslation("component_catalogue", {
        keyPrefix: "library.filter.actions",
    });

    return (
        <Tooltip title={t("reset_descr")}>
            <Fab
                onClick={handleResetButtonClicked}
                sx={{ ...sxProps }}
                color="secondary"
                size="large"
                variant="extended">
                {t("reset")}
            </Fab>
        </Tooltip>
    );
};

export default ResetButton;