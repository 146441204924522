import { IUploadedFile, IUploadedFileTableRowData } from "types";
import { getReadablyFileSize } from "./fileSize.helpers";

export const getUploadedFileTableRowDataFromUploadedFile = (uploadedFile: IUploadedFile, index: number): IUploadedFileTableRowData => {
  const created_at_format = new Date(uploadedFile.created_at);
  const fileSize = getReadablyFileSize(uploadedFile.size);

  return {
    id: index,
    file_id: uploadedFile.id,
    uuid: uploadedFile.uuid,
    name: uploadedFile.name,
    created_at: created_at_format.toLocaleString(),
    uploaded_by: uploadedFile.uploaded_by,
    size: fileSize,
    creation_level: uploadedFile.creation_level,
  };
};