const supportedLanguages = [
  {
    code: 'en-US',
    language: 'english',
    direction: 'ltr',
  },
  {
    code: 'de-DE',
    language: 'deutsch',
    direction: 'ltr',
  },
]
export default supportedLanguages
