export const getConstructionElementListUrl = (projectId: number) =>
  `projects/${projectId}/component-catalogues/${projectId}/construction-elements/`

export const getConstructionElementItemUrl = (
  projectId: number,
  elementId: number
) =>
  `projects/${projectId}/component-catalogues/${projectId}/construction-elements/${elementId}/`

export const getConstructionElementLayerListUrl = (
  projectId: number,
  elementId: number
) =>
  `projects/${projectId}/component-catalogues/${projectId}/construction-elements/${elementId}/layers/`

export const getConstructionElementLayerItemUrl = (
  projectId: number,
  elementId: number,
  layerId: number
) =>
  `projects/${projectId}/component-catalogues/${projectId}/construction-elements/${elementId}/layers/${layerId}/`

export const getConstructionElementLayerLayerFillingListUrl = (
  projectId: number,
  elementId: number,
  layerId: number
) =>
  `projects/${projectId}/component-catalogues/${projectId}/construction-elements/${elementId}/layers/${layerId}/layer-fillings/`

export const getConstructionElementLayerLayerFillingItemUrl = (
  projectId: number,
  elementId: number,
  layerId: number,
  layerFillingId: number,
) =>
  `projects/${projectId}/component-catalogues/${projectId}/construction-elements/${elementId}/layers/${layerId}/layer-fillings/${layerFillingId}/`

export const getConstructionMaterialListUrl = () =>
  `construction-materials/`

export const getConstructionMaterialItemUrl = (constructionMaterialId: number) =>
  `construction-materials/${constructionMaterialId}/`

export const getCategoryChoicesUrl = (projectId: number) =>
  `projects/${projectId}/component-catalogues/${projectId}/construction-elements/category-choices/`

export const getMaterialChoicesUrl = (projectId: number) =>
  `projects/${projectId}/component-catalogues/${projectId}/construction-elements/material-choices/`

export const getConstructionSectionChoicesUrl = (projectId: number) =>
  `projects/${projectId}/component-catalogues/${projectId}/construction-elements/construction-section-choices/`
