export const getIfcModelCreationsListUrl = (projectId: number) =>
  `projects/${projectId}/ifc-model-creations/`

export const getIfcModelCreationsItemUrl = (
  projectId: number,
  ifcModelCreationId: number
) => `projects/${projectId}/ifc-model-creations/${ifcModelCreationId}/`

export const getIfcModelCreationsStatusStatesUrl = (
  projectId: number,
) => `projects/${projectId}/ifc-model-creations/creation-status-states/`

export const getIfcModelCreationsLevelChoicesUrl = (
  projectId: number,
) => `projects/${projectId}/ifc-model-creations/creation-level-choices/`

export const getIfcModelCreationsOptimizationPriorityChoicesUrl = (
  projectId: number,
) => `projects/${projectId}/ifc-model-creations/creation-optimization-priority-choices/`