import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  getApi,
  getMyUserDetails,
} from "store/entities/authentication/authentication.slice";
import { setEmail } from "store/entities/authentication/authentication.actions";
import { useAppDispatch, useAppSelector } from "store";
import {
  closeEmailChangeDialog,
  getDialogs,
  openEmailChangeDialog,
} from "store/ui/settings/settings.slice";

interface Email {
  current_password: string;
  new_email: string;
}

export default function Email() {
  const { t } = useTranslation("settings", {
    keyPrefix: "security.set_email",
  });
  const dispatch = useAppDispatch();

  const authApi = useAppSelector(getApi);
  const me = useAppSelector(getMyUserDetails);
  const dialog = useAppSelector(getDialogs).confirmEmailChange;

  const emailValidationSchema = yup.object({
    current_password: yup.string().required(t("required") as string),
    new_email: yup
      .string()
      .email()
      .required(t("required") as string),
  });

  const [initialEmailValues, setInitialEmailValues] = useState<Email>({
    current_password: "",
    new_email: "",
  });

  const emailForm = useFormik({
    initialValues: initialEmailValues,
    validationSchema: emailValidationSchema,
    enableReinitialize: true,
    onSubmit: (values: Email) => {
      dispatch(setEmail(values));
      dispatch(closeEmailChangeDialog());
      emailForm.resetForm();
    },
  });

  const handleCancel = () => {
    dispatch(closeEmailChangeDialog());
    emailForm.resetForm();
  };

  const handleOk = () => {
    dispatch(openEmailChangeDialog());
  };

  return (
    <Box
      sx={{ m: 0, p: 0 }}
      component="form"
      id="email-form"
      noValidate
      onSubmit={emailForm.handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            color="text.primary"
            align="left"
            sx={{ fontWeight: "bold" }}
          >
            {t("change_email")}
          </Typography>
        </Grid>
        <Grid item xs={12} direction="row" sx={{ display: "flex" }}>
          <TextField
            id="new_email"
            name="new_email"
            label={t("new_email")}
            value={emailForm.values.new_email}
            onBlur={emailForm.handleBlur}
            error={
              emailForm.touched.new_email && Boolean(emailForm.errors.new_email)
            }
            onChange={emailForm.handleChange}
            autoComplete="new_email"
            variant="outlined"
            sx={{ flexGrow: 1, mr: 2 }}
          />
          <Button
            disabled={authApi.submitting}
            variant="contained"
            onClick={handleOk}
          >
            {t("change")}
          </Button>
        </Grid>
      </Grid>
      <Dialog maxWidth="xs" open={dialog.open}>
        <DialogTitle fontWeight="bold">{t("confirm_password")}</DialogTitle>
        <DialogContent>
          <TextField
            id="current_password"
            name="current_password"
            label={t("current_password")}
            value={emailForm.values.current_password}
            onBlur={emailForm.handleBlur}
            error={
              emailForm.touched.current_password &&
              Boolean(emailForm.errors.current_password)
            }
            helperText={
              emailForm.touched.current_password &&
              emailForm.errors.current_password
            }
            onChange={emailForm.handleChange}
            fullWidth
            type="password"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            {t("cancel")}
          </Button>
          <Button type="submit" form="email-form" disabled={authApi.submitting}>
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
