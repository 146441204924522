import { TFunction } from 'i18next'
import React from 'react'

export default async function notes(
  element: any,
  doc: any,
  layout: any,
  fontSizes: any,
  lineSpacing: number,
  startY: number,
  t: TFunction<'component_catalogue', 'pdf_export'>
) {
  let startX = layout.StartX
  let endX = layout.EndX
  startY += lineSpacing

  doc.text(t('pdf_export.notes'), startX, startY)

  if (element.description !== null && element.description !== '') {
    startY += lineSpacing * 0.65
    doc.text(element.description, startX, startY)
  }

  return startY
}
