import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Slide from "@mui/material/Slide";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useLocation, matchPath } from "react-router";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Link as RouterLink } from "react-router-dom";

import Sidebar from "./sidebar/sidebar";
import AccountMenu from "./submenus/accountMenu";
import LanguageMenu from "./submenus/languageMenu";
import Notifications from "./submenus/notifications";
import {
  closeSidebar,
  getMenus,
  getSelectedFeature,
  getSidebar,
  openSidebar,
} from "store/ui/navigation/navigation.slice";
import { getUser } from "store/entities/authentication/authentication.slice";
import SettingsTabs from "./tabs/settingsTabs";
import { useAppDispatch, useAppSelector } from "store";
import ProjectTabs from "./tabs/projectTabs";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

interface TopNavigationProps {
  matches: boolean;
}

export const TopNavigation = (props: TopNavigationProps) => {
  const { matches } = props;
  const { t } = useTranslation("navigation", {
    keyPrefix: "appbar.main",
  });
  const user = useAppSelector(getUser);
  const selectedFeature = useAppSelector(getSelectedFeature);
  const sidebar = useAppSelector(getSidebar);
  const { pathname } = useLocation();
  const isProjectsPath = matchPath("/projects/:projectId/*", pathname);
  const isSettingsPath = matchPath("/settings/*", pathname);

  const dispatch = useAppDispatch();
  const trigger = useScrollTrigger();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <React.Fragment>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar
          sx={{
            flexGrow: 1,
            width: "100%",
          }}
          position="fixed"
          color="inherit"
        >
          <Toolbar disableGutters={matches ? false : true}>
            {matches && (
              <>
                {isProjectsPath ? (
                  <IconButton
                    component={RouterLink}
                    to={"/projects/"}
                    edge="start"
                    color="inherit"
                    aria-label="back-to-menu"
                  >
                    <ArrowBackIcon />
                  </IconButton>
                ) : (
                  <>
                    <IconButton
                      onClick={() => dispatch(openSidebar())}
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                    >
                      <MenuIcon />
                    </IconButton>
                    <SwipeableDrawer
                      anchor="left"
                      open={sidebar.open}
                      onClose={() => dispatch(closeSidebar())}
                      onOpen={() => dispatch(openSidebar())}
                      elevation={0}
                      sx={{ display: "flex" }}
                    >
                      <Sidebar />
                    </SwipeableDrawer>
                  </>
                )}
              </>
            )}
            <Typography
              variant="h6"
              sx={{
                flexGrow: 1,
                pr: 2,
                pl: 2,
                fontWeight: 600,
              }}
            >
              {t(selectedFeature.title)}
            </Typography>
            {matches && (
              <LanguageMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
            )}
            {matches && user.loggedIn && (
              <Notifications anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
            )}
            <AccountMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
          </Toolbar>
          {isProjectsPath && <ProjectTabs isProjectsPath={isProjectsPath} />}
          {isSettingsPath && <SettingsTabs />}
        </AppBar>
      </Slide>
      <Offset />
      {(isSettingsPath || isProjectsPath) && <div style={{ height: "48px" }} />}
    </React.Fragment>
  );
};
