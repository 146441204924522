import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import { getLocales } from "store/entities/settings/settings.slice";
import { getMyUserDetails } from "store/entities/authentication/authentication.slice";
import { deleteNotification } from "store/entities/authentication/authentication.actions";
import { useAppDispatch, useAppSelector } from "store";

export default function Notifications() {
  const { t } = useTranslation("settings", { keyPrefix: "profile" });
  const me = useAppSelector(getMyUserDetails);
  const locales = useAppSelector(getLocales).code;

  const dispatch = useAppDispatch();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          color="text.primary"
          align="left"
          sx={{ fontWeight: "bold" }}
        >
          {t("your_notifications")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <List dense>
          {me.profile.notifications.length > 0 ? (
            me.profile.notifications.map(
              (notification: {
                id: number;
                message: string;
                notified_on: string;
                expiry_date: string;
                marked_as_read: boolean;
              }) => {
                const date = new Date(notification.notified_on);
                const sent_on = date.toLocaleDateString(locales, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                });
                return (
                  <ListItemButton
                    disableGutters
                    // onClick={() =>
                    //   dispatch(markNotificationAsRead(notification.id))
                    // }
                  >
                    <ListItem>
                      <ListItemText
                        primary={notification.message}
                        secondary={t("sent_on", {
                          date: sent_on,
                        })}
                        primaryTypographyProps={{
                          color: notification.marked_as_read
                            ? "text.secondary"
                            : "text.primary",
                          noWrap: true,
                        }}
                        secondaryTypographyProps={{
                          color: notification.marked_as_read
                            ? "text.secondary"
                            : "text.primary",
                          noWrap: true,
                        }}
                      />
                      <IconButton
                        edge="end"
                        aria-label="delete-notification"
                        onClick={() =>
                          dispatch(deleteNotification(notification.id))
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  </ListItemButton>
                );
              }
            )
          ) : (
            <ListItem disableGutters>
              <ListItemText primary={t("no_notifications")} />
            </ListItem>
          )}
        </List>
      </Grid>
    </Grid>
  );
}
