import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { IIfcModelCreation } from 'types';
import GeneralResultTab from './generalResultTab';
import ModelResultTab from './modelResultTab';
import { Divider } from '@mui/material';
import ModelComponentsResultTab from './modelComponentsResultTab/modelComponentsResultTab';
import { useEffect } from 'react';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

interface IDetailTabsProps {
    ifcModelCreation: IIfcModelCreation;
}

const DetailTabs: React.FC<IDetailTabsProps> = ({ ifcModelCreation }) => {
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTabIndex(newValue);
    };

    useEffect(() => {
        setSelectedTabIndex(0);
    }, []);

    if (ifcModelCreation.response_optimized === null) {
        return (
            <Box>{"No result generated yet"}</Box>
        );
    }
    else if (ifcModelCreation.response_optimized.status === "error") {
        return (
            <Box sx={{ mb: 2 }}>
                {/* <Typography variant="h6" >Error</Typography> */}
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                        <Typography variant="body1" color="GrayText">error message</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="body1">{ifcModelCreation.response_optimized?.error_message}</Typography>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Typography variant="h4">Result</Typography>
            <Divider sx={{ width: "100%", mb: 5 }} />
            <Box
                sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
                <Tabs
                    value={selectedTabIndex}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    textColor="secondary"
                    indicatorColor="secondary"
                >
                    <Tab label="General results" {...a11yProps(0)} />
                    <Tab label="Model results" {...a11yProps(1)} />
                    <Tab label="Model components results" {...a11yProps(1)} />
                </Tabs>

            </Box>
            <TabPanel value={selectedTabIndex} index={0}>
                <GeneralResultTab ifcModelCreation={ifcModelCreation} />
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={1}>
                <ModelResultTab response_optimized={ifcModelCreation.response_optimized} />
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={2}>
                <ModelComponentsResultTab response_optimized={ifcModelCreation.response_optimized} />
            </TabPanel>
        </Box>
    );
}

export default DetailTabs;