import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, List, ListItem, ListItemText } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useAppDispatch, useAppSelector } from 'store'
import { getReadablyFileSize } from 'common/utils/helpers/fileSize.helpers'
import { IFileValidationResponse } from 'types'
import { validateFile } from './validation'

interface IFileUploadFormProps {
    setFiles: React.Dispatch<React.SetStateAction<File[]>>;
    files: File[];
}

const FileUploadForm: React.FC<IFileUploadFormProps> = ({ setFiles, files }) => {
    const { t } = useTranslation('uploaded_files', {
        keyPrefix: 'dialogs.add_dialog',
    })
    const dispatch = useAppDispatch()
    const inputRef = React.useRef<HTMLInputElement>(null);

    const [isOver, setIsOver] = useState(false);

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsOver(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsOver(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsOver(false);

        const droppedFiles = Array.from(event.dataTransfer.files);
        setFiles(droppedFiles)
    };

    const onChangeSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const selectedFiles = Array.from(event.target.files);
            setFiles(selectedFiles);
        }
    }

    const selectedFilesInfo = files.map((file, fileIndex) => {
        const validation: IFileValidationResponse = validateFile(file, fileIndex);
        validation.errors.forEach(function (part, index, theArray) {
            theArray[index] = t(validation.errors[index]);
        });
        return (
            <ListItem
                key={fileIndex}
            >
                <ListItemText
                    primary={file.name}
                    secondary={
                        validation.valid ? getReadablyFileSize(file.size)
                            : <Typography style={{ color: '#f24444' }}>{validation.errors.join(" | ")}</Typography>
                    }
                />
            </ListItem>
        );
    })

    const onClick = () => {
        if (inputRef.current) {
            inputRef.current.click()
        }
    }

    return (
        <Grid container spacing={2} sx={{ pt: 2 }} maxWidth='xs'>
            <Grid item xs={12}>
                <Box component="div" sx={{ p: 2, border: '2px dashed grey' }}>
                    <Box className="dropzone"
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        sx={{
                            bgcolor: isOver ? 'primary.main' : 'inherit',
                        }}
                    >
                        <Typography sx={{ ml: 2, flex: 1, textAlign: "center" }} variant='h6' component='div'>
                            {t('drag_drop_file_instruction')}
                        </Typography>
                        <Typography sx={{ ml: 2, flex: 1, textAlign: "center" }} variant='subtitle2' component='div'>
                            {t('drag_drop_file_accepted')}
                        </Typography>
                        <input
                            type="file"
                            onChange={onChangeSelectFile}
                            multiple
                            hidden
                            ref={inputRef}
                        />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button variant="contained" onClick={onClick}>
                    {t('drag_drop_file_select')}
                </Button>
            </Grid>
            {
                <Grid item xs={12}>
                    {files.length !== 0 &&
                        <Box>
                            <Typography sx={{ ml: 2, flex: 1, textAlign: "left" }} variant='h6' component='div'>
                                {t('drag_drop_file_uploaded_info')}
                            </Typography>
                            <List sx={{ width: '100%' }}>
                                {selectedFilesInfo}
                            </List>
                        </Box>
                    }
                </Grid>
            }
        </Grid>
    )
}

export default FileUploadForm