import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { OutlinedInput } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import {
  getProjectStatusList,
  getProjects,
} from "store/entities/projects/projects.slice";
import { useAppSelector } from "store";
import type { IProjectStatus } from "types";
// import { filterProjects } from './listUiSlice'

const filterProjectsArray = async (
  filter: {
    text: string;
    status: string;
    bookmark: boolean;
  },
  projects: any[]
) => {
  let newProjects: any[];

  if (filter.status === "") {
    newProjects = projects;
  } else {
    newProjects = projects.filter(
      (project: any) => project.status === filter.status
    );
  }

  if (filter.bookmark === false) {
    newProjects = newProjects;
  } else {
    newProjects = newProjects.filter(
      (project: any) => project.bookmark === filter.bookmark
    );
  }

  if (filter.text === "") {
    newProjects = newProjects;
  } else {
    const regExp = new RegExp(filter.text, "gi");
    const check: any = (obj: string) => {
      if (obj !== null && typeof obj === "object") {
        return Object.values(obj).some(check);
      }
      if (Array.isArray(obj)) {
        return obj.some(check);
      }
      return (
        (typeof obj === "string" || typeof obj === "number") && regExp.test(obj)
      );
    };
    newProjects = await newProjects.filter(check);
  }
  return newProjects;
};

export default function Toolbar() {
  const { t } = useTranslation("projects", {
    keyPrefix: "list.header",
  });
  const projects = useAppSelector(getProjects);
  const statusFilterOptions = useAppSelector(getProjectStatusList);

  const [filter, setFilter] = useState({
    text: "",
    status: "",
    bookmark: false,
  });

  const handleBookmarkFilterChange = () => {
    setFilter((filter) => {
      return { ...filter, bookmark: !filter.bookmark };
    });
  };

  const handleTextFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilter((filter) => {
      return { ...filter, text: event.target.value };
    });
  };

  const handleStatusFilterChange = (event: SelectChangeEvent) => {
    setFilter((filter) => {
      return { ...filter, status: event.target.value as string };
    });
  };

  useEffect(() => {
    filterProjectsArray(filter, projects).then((filteredProjects) => {
      // dispatch(filterProjects(filteredProjects))
    });
  }, [filter]);

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={2}
      sx={{ my: 4 }}
    >
      <Tooltip
        title={
          filter.bookmark
            ? t<string>("show_bookmarks")
            : t<string>("reset_bookmarks")
        }
      >
        <ToggleButton
          color="primary"
          value="check"
          selected={filter.bookmark}
          onChange={handleBookmarkFilterChange}
          size="small"
        >
          {filter.bookmark ? <BookmarkIcon /> : <BookmarkBorderIcon />}
        </ToggleButton>
      </Tooltip>
      <TextField
        sx={{ width: 200 }}
        size="small"
        id="outlined-search-field"
        label={t("search")}
        value={filter.text}
        onChange={handleTextFilterChange}
      />
      <FormControl fullWidth sx={{ width: 200 }} size="small">
        <InputLabel
          id="demo-simple-select-label"
          shrink
          disableAnimation={true}
        >
          {t("status_filter")}
        </InputLabel>
        <Select
          input={<OutlinedInput notched label={t("status_filter")} />}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filter.status}
          label={t("status_filter")}
          onChange={handleStatusFilterChange}
          displayEmpty
        >
          <MenuItem value="">{t("all")}</MenuItem>
          {statusFilterOptions.map((status: IProjectStatus, index: number) => (
            <MenuItem key={index} value={status.value}>
              {status.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
