import React, { useEffect, useRef, useState } from "react";
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { IWallOptimized, ILayerOptimized, IResponseOptimized, IConstructionMaterial } from "types";
import LayerVisualizer from "./layerVisualizer/layerVisualizer";
import { useAppSelector } from "store";
import { getConstructionMaterials } from "store/entities/projects/componentCatalogue/componentCatalogue.slice";

interface IModelComponentsResultTabProps {
    response_optimized: IResponseOptimized;
}

const ModelComponentsResultTab: React.FC<IModelComponentsResultTabProps> = ({ response_optimized }) => {
    const [selectedWall, setSelectedWall] = useState<IWallOptimized | null>(null);
    const [selectedLayer, setSelectedLayer] = useState<ILayerOptimized | null>(null);
    const [colorPreference, setColorPreference] = useState<'random' | 'monocolor' | 'bincolor'>('bincolor');

    const plotRef = useRef<HTMLDivElement | null>(null);

    const constructionMaterials: IConstructionMaterial[] = useAppSelector(getConstructionMaterials);

    const handleWallChange = (event: SelectChangeEvent<string>) => {
        const wallId = event.target.value;
        const wall = response_optimized.walls.find(w => w.ifc_element_express_id === wallId) || null;
        setSelectedWall(wall);
        if (wall && wall.layers.length > 0) {
            setSelectedLayer(wall.layers[0])
        } else {
            setSelectedLayer(null);
        }
    };

    const handleLayerChange = (event: React.MouseEvent<HTMLElement>, newLayerId: string | null) => {
        if (newLayerId !== null && selectedWall) {
            const layer = selectedWall.layers.find(layer => layer.id === Number(newLayerId)) || null;
            setSelectedLayer(layer);
        }
    };

    const handleColorPreferenceChange = (event: React.MouseEvent<HTMLElement>, newPreference: 'random' | 'monocolor' | null) => {
        if (newPreference) {
            setColorPreference(newPreference);
        }
    };

    useEffect(() => {
        if (selectedWall === null && selectedLayer === null) {
            const walls = response_optimized.walls;
            if (walls.length > 0) {
                const firstWall = walls[0];
                setSelectedWall(firstWall);

                if (firstWall.layers.length > 0) {
                    setSelectedLayer(firstWall.layers[0])
                }
            }
        }
    }, [response_optimized]);

    const handleResize = () => {
        if (plotRef.current) {
            const resetAxesButtons = plotRef.current.querySelectorAll('[data-title="Reset axes"]');
            if (resetAxesButtons) {
                resetAxesButtons.forEach((button) => { button.dispatchEvent(new MouseEvent('click', { bubbles: true })); })
            }
        }
    };

    useEffect(() => {
        handleResize();
    }, [selectedWall, selectedLayer, colorPreference]);

    return (
        <Box ref={(el: HTMLDivElement | null) => (plotRef.current = el)}>
            <Box sx={{ mb: 2 }}>
                <Typography variant="h5" component="div">Select Wall</Typography>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <Select
                        labelId="wall-select-label"
                        value={selectedWall?.ifc_element_express_id || ''}
                        onChange={handleWallChange}
                        variant="filled"
                    >
                        {response_optimized.walls.map((wall) => (
                            <MenuItem key={wall.ifc_element_express_id} value={wall.ifc_element_express_id}>
                                {wall.ifc_element_express_id}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selectedWall && selectedWall.layers.length > 0 && (
                    <Box>
                        <Typography variant="h5" component="div">Select Layer</Typography>
                        <ToggleButtonGroup
                            value={selectedLayer?.id || ''}
                            exclusive
                            onChange={handleLayerChange}
                            aria-label="layer selection"
                            sx={{ flexWrap: 'wrap', mb: 2 }}
                        >
                            {selectedWall.layers.map(layer => {
                                const layerMaterial = constructionMaterials.find((material) => material.id === layer.construction_material);
                                return (
                                    <ToggleButton key={layer.id} value={layer.id} sx={{ minWidth: "150px" }}>
                                        {layerMaterial ? layerMaterial.name : `Layer ${layer.id}`}
                                    </ToggleButton>
                                );
                            })}
                        </ToggleButtonGroup>
                    </Box>
                )}
                <Box sx={{ mb: 2 }}>
                    <Typography variant="h5" component="div">Color Preference</Typography>
                    <ToggleButtonGroup
                        value={colorPreference}
                        exclusive
                        onChange={handleColorPreferenceChange}
                        aria-label="color preference"
                    >
                        <ToggleButton value="bincolor" aria-label="bincolor">
                            Colored Panels
                        </ToggleButton>
                        <ToggleButton value="random" aria-label="random color">
                            Random Color
                        </ToggleButton>
                        <ToggleButton value="monocolor" aria-label="monocolor">
                            Monocolor
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>
            {selectedLayer && (
                <LayerVisualizer
                    layer={selectedLayer}
                    colorPreference={colorPreference}
                />
            )}
        </Box>
    );
}

export default ModelComponentsResultTab;
