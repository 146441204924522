import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { commonThemeOptions } from './commonThemeOptions'

interface PaletteColor {
  light?: string
  main: string
  dark?: string
  contrastText?: string
}

const minimalTheme = createTheme({
  ...commonThemeOptions,
  palette: {
    mode: 'light',
    primary: {
      main: '#14213d',
    },
    secondary: {
      main: '#fca311',
    },
    background: {
      default: '#e5e5e5',
    },
    error: {
      main: '#95190c',
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: [
      '"Barlow Semi Condensed"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
})

const minimal = responsiveFontSizes(minimalTheme)

export default minimal
