import { AlertColor } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";
import {
  uploadedFileAddFulfilled,
  uploadedFileDeleteFulfilled,
  uploadedFileDownloadReceived,
  uploadedFileDownloadRequestFailed,
  uploadedFileDownloadRequested,
  uploadedFilesAddFailed,
  uploadedFilesAddRequested,
  uploadedFilesDeleteFailed,
  uploadedFilesDeleteRequested,
  uploadedFilesRequestFailed,
  uploadedFilesRequested
} from "store/entities/projects/uploadedFiles/uploadedFiles.slice";
import { RootState } from "store/rootReducer";
import { IUploadedFile } from "types";

interface DialogsState {
  addUploadedFile: boolean;
  deleteUploadedFile: boolean;
}

interface SliceState {
  dialogs: DialogsState;
  alertSnackbar: { open: boolean; message: string; severity: AlertColor };
  loadingSnackbar: { open: boolean; message: string; };
  selectedFile: IUploadedFile;
}

const initialState: SliceState = {
  dialogs: {
    addUploadedFile: false,
    deleteUploadedFile: false,
  },
  alertSnackbar: { open: false, message: "", severity: "success" },
  loadingSnackbar: { open: false, message: "" },
  selectedFile: {
    id: 0,
    uuid: "",
    name: "",
    file_sha256_hex_hash: "",
    created_at: "",
    uploaded_by: {
      email: "",
      first_name: "",
      last_name: "",
    },
    size: 0,
    extension: "",
    creation_level: "",
  }
};

const uploadedFilesSlice = createSlice({
  name: "uploadedFiles",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(uploadedFilesRequested, (uploadedFiles) => {
        uploadedFiles.alertSnackbar = {
          open: true,
          message: "uploaded_files_success",
          severity: "success",
        };
      })
      .addCase(uploadedFilesRequestFailed, (uploadedFiles) => {
        uploadedFiles.alertSnackbar = {
          open: true,
          message: "uploaded_files_failed",
          severity: "error",
        };
      })
      .addCase(uploadedFilesAddRequested, (uploadedFiles) => {
        uploadedFiles.alertSnackbar = {
          open: true,
          message: "uploaded_files_add_requested",
          severity: "info",
        };
        uploadedFiles.loadingSnackbar = {
          open: true,
          message: "uploaded_files_add_element_requested",
        };
      })
      .addCase(uploadedFileAddFulfilled, (uploadedFiles) => {
        uploadedFiles.alertSnackbar = {
          open: true,
          message: "uploaded_files_add_fullfilled",
          severity: "success",
        };
        uploadedFiles.loadingSnackbar = {
          open: false,
          message: "",
        };
      })
      .addCase(uploadedFilesAddFailed, (uploadedFiles) => {
        uploadedFiles.alertSnackbar = {
          open: true,
          message: "uploaded_files_add_failed",
          severity: "error",
        };
        uploadedFiles.loadingSnackbar = {
          open: false,
          message: "",
        };
      })
      .addCase(uploadedFileDownloadRequested, (uploadedFiles) => {
        uploadedFiles.alertSnackbar = {
          open: true,
          message: "uploaded_files_download_requested",
          severity: "info",
        };
      })
      .addCase(uploadedFileDownloadReceived, (uploadedFiles) => {
        uploadedFiles.alertSnackbar = {
          open: true,
          message: "uploaded_files_download_received",
          severity: "success",
        };
      })
      .addCase(uploadedFileDownloadRequestFailed, (uploadedFiles) => {
        uploadedFiles.alertSnackbar = {
          open: true,
          message: "uploaded_files_download_failed",
          severity: "error",
        };
      })
      .addCase(uploadedFilesDeleteRequested, (uploadedFiles) => {
        uploadedFiles.alertSnackbar = {
          open: true,
          message: "uploaded_files_delete_requested",
          severity: "info",
        };
        uploadedFiles.loadingSnackbar = {
          open: true,
          message: "uploaded_files_delete_element_requested",
        };
      })
      .addCase(uploadedFileDeleteFulfilled, (uploadedFiles) => {
        uploadedFiles.alertSnackbar = {
          open: true,
          message: "uploaded_files_delete_fullfilled",
          severity: "success",
        };
        uploadedFiles.loadingSnackbar = {
          open: false,
          message: "",
        };
      })
      .addCase(uploadedFilesDeleteFailed, (uploadedFiles) => {
        uploadedFiles.alertSnackbar = {
          open: true,
          message: "uploaded_files_delete_failed",
          severity: "error",
        };
        uploadedFiles.loadingSnackbar = {
          open: false,
          message: "",
        };
      })
  },
  reducers: {
    alertSnackbarOpened: (uploadedFiles, action) => {
      uploadedFiles.alertSnackbar = {
        open: true,
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    alertSnackbarClosed: (uploadedFiles) => {
      uploadedFiles.alertSnackbar = {
        ...uploadedFiles.alertSnackbar,
        open: false,
      };
    },
    loadingSnackbarOpened: (uploadedFiles, action) => {
      uploadedFiles.loadingSnackbar = {
        open: true,
        message: action.payload.message,
      };
    },
    loadingSnackbarClosed: (uploadedFiles) => {
      uploadedFiles.loadingSnackbar = {
        ...uploadedFiles.loadingSnackbar,
        open: false,
      };
    },
    uploadedFileSelected: (uploadedFiles, action) => {
      uploadedFiles.selectedFile = action.payload;
    },
    addUploadedFilesDialogOpened: (uploadedFiles) => {
      uploadedFiles.dialogs.addUploadedFile = true;
    },
    addUploadedFilesDialogClosed: (uploadedFiles) => {
      uploadedFiles.dialogs.addUploadedFile = false;
    },
    deleteUploadedFilesDialogOpened: (uploadedFiles) => {
      uploadedFiles.dialogs.deleteUploadedFile = true;
    },
    deleteUploadedFilesDialogClosed: (uploadedFiles) => {
      uploadedFiles.dialogs.deleteUploadedFile = false;
    },
  },
});

export const selectUploadedFile = (payload: IUploadedFile) => ({
  type: uploadedFileSelected.type,
  payload,
});

export const handleAddUploadedFilesDialogOpen = () =>
  ({ type: addUploadedFilesDialogOpened.type })

export const handleAddUploadedFilesDialogClose = () =>
  ({ type: addUploadedFilesDialogClosed.type })

export const handleDeleteUploadedFilesDialogOpen = () =>
  ({ type: deleteUploadedFilesDialogOpened.type })

export const handleDeleteUploadedFilesDialogClose = () =>
  ({ type: deleteUploadedFilesDialogClosed.type })

export const openAlertSnackbar = (payload: {
  message: string;
  severity: string;
}) => ({
  type: alertSnackbarOpened.type,
  payload,
});

export const closeAlertSnackbar = () => ({
  type: alertSnackbarClosed.type,
});

export const openLoadingSnackbar = (payload: {
  message: string;
}) => ({
  type: loadingSnackbarOpened.type,
  payload,
});

export const closeLoadingSnackbar = () => ({
  type: loadingSnackbarClosed.type,
});

export const {
  alertSnackbarOpened,
  alertSnackbarClosed,
  loadingSnackbarOpened,
  loadingSnackbarClosed,
  uploadedFileSelected,
  addUploadedFilesDialogOpened,
  addUploadedFilesDialogClosed,
  deleteUploadedFilesDialogOpened,
  deleteUploadedFilesDialogClosed,
} = uploadedFilesSlice.actions;

export default uploadedFilesSlice.reducer;

export const getUploadedFilesDialogs = (state: RootState) =>
  state.ui.projects.selectedProject.uploadedFiles.dialogs;

export const getSelectedUploadedFile = (state: any) =>
  state.ui.projects.selectedProject.uploadedFiles.selectedFile;

export const getAlertSnackbar = (state: any) =>
  state.ui.projects.selectedProject.uploadedFiles.alertSnackbar;

export const getLoadingSnackbar = (state: any) =>
  state.ui.projects.selectedProject.uploadedFiles.loadingSnackbar;