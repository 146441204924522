import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ["en-US", "de-DE"],
    defaultNS: "navigation",
    ns: ["navigation", "login", "settings", "blog"],
    fallbackLng: "en-US",
    load: "currentOnly",
    debug: false,
    detection: {
      order: ["localStorage", "cookie", "path", "htmlTag", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
      addPath: `${process.env.PUBLIC_URL}/locales/add/{{lng}}/{{ns}}`,
    },
    react: { useSuspense: true },
  });
export default i18n;
