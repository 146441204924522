import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";
import React, { useEffect, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import Zoom from "@mui/material/Zoom";
import { useAppDispatch, useAppSelector } from "store";
import {
  closeLayerUpdateDialog,
  getLayerDialogs,
  getSelectedLayer,
} from "store/ui/projects/componentCatalogue/componentCatalogue.slice";
import { ILayer, ILayerForm } from "types";
import {
  deleteLayer,
  updateLayer,
} from "store/entities/projects/componentCatalogue/componentCatalogue.actions";
import { useParams } from "react-router-dom";
import LayerUpdateForm from "./form";
import { Box, Stack } from "@mui/material";

import { getProjectPermissions } from "store/entities/projects/project.slice";
import { hasProjectPerm } from "services/authService";
import { projectPermissions } from "common/utils/constants/auth.constants";
import type { IStakeholderPermission } from "types";
import LayerFillingsRetrieveDialog from "../../layerFillings/retrieveDialog/retrieveDialog";

const ZoomTransition: any = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

const LayerUpdateDialog: React.FC = () => {
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "selected_element.dialogs.layers.update",
  });
  const { projectId, elementId } = useParams();

  const open = useAppSelector(getLayerDialogs).update;
  const selectedLayer: ILayer = useAppSelector(getSelectedLayer);
  const dispatch = useAppDispatch();

  const userPermissions: IStakeholderPermission[] = useAppSelector(
    getProjectPermissions
  );

  const isMaintainer = hasProjectPerm(userPermissions, [
    projectPermissions.component_catalogue.maintainer,
  ]);

  const handleDialogClose = () => {
    dispatch(closeLayerUpdateDialog());
  };

  useEffect(() => {
    setInitialValues({
      position: selectedLayer.position,
      name: selectedLayer.name,
      construction_section: selectedLayer.construction_section,
      layer_fillings: selectedLayer.layer_fillings,
    });
  }, [selectedLayer]);

  const [initialValues, setInitialValues] = useState<ILayerForm>({
    position: 0,
    name: "",
    construction_section: "wall_construction",
    layer_fillings: [],
  });

  const validationSchema = yup.object({
    name: yup.string().required(t("required") as string),
  });

  const layerForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      if (projectId !== undefined && elementId !== undefined) {
        dispatch(updateLayer(+projectId, +elementId, selectedLayer.id, values));
        dispatch(closeLayerUpdateDialog());
        layerForm.resetForm();
      }
    },
  });

  const handleDeleteLayer = () => {
    if (projectId !== undefined && elementId !== undefined) {
      dispatch(deleteLayer(+projectId, +elementId, selectedLayer.id));
      dispatch(closeLayerUpdateDialog());
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      TransitionComponent={ZoomTransition}
      aria-labelledby="layer-update-dialog"
      hideBackdrop
      maxWidth="md"
    >
      <DialogTitle style={{ cursor: "move" }} id="layer-update-dialog">
        {t("edit_layer_title", { layer_name: selectedLayer.name })}
      </DialogTitle>
      <DialogContent>
        <LayerUpdateForm layerForm={layerForm} isMaintainer={isMaintainer} />
        <LayerFillingsRetrieveDialog />
      </DialogContent>
      <DialogActions>
        <Stack
          display="flex"
          direction="row"
          justifyContent="space-between"
          width="100%"
        >
          {isMaintainer ? (
            <Button autoFocus onClick={handleDeleteLayer}>
              {t("delete")}
            </Button>
          ) : (
            <Box sx={{ flexGrow: 1 }} />
          )}
          <Stack direction="row">
            <Button autoFocus onClick={handleDialogClose}>
              {t("cancel")}
            </Button>
            {isMaintainer && (
              <Button type="submit" form="layer-update-form">
                {t("save")}
              </Button>
            )}
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default LayerUpdateDialog;

const PaperComponent: React.FC = (props: PaperProps) => {
  return (
    <Draggable
      handle="#layer-update-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};
