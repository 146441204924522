import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import { LatLngTuple } from "leaflet";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Paper from "@mui/material/Paper";
import React, { useState, useEffect, createContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { TileLayer, Marker, MapContainer } from "react-leaflet";

import http from "services/httpService";
import type { IProject } from "types";
import { useAppSelector } from "store";
import { getSelectedProject } from "store/entities/projects/project.slice";

const Map: React.FC = () => {
  const project: IProject = useAppSelector(getSelectedProject);
  const [location, setLocation] = useState("");
  const [position, setPosition] = useState<LatLngTuple>([0, 0]);
  const LayerContext: any = createContext({});

  useEffect(() => {
    setLocation(
      `${project.construction_site.street}, ${project.construction_site.postal_code} ${project.construction_site.city}, ${project.construction_site.country}`
    );
  }, [project]);

  useEffect(() => {
    getPosition(location);
  }, [location]);

  // const getPosition = async (location: string) => {
  //   const response = await http.get(
  //     `https://geocode.maps.co/search?q={${location}}`
  //   );
  //   const coordinates = response.data[0];
  //   if (coordinates) {
  //     setPosition([parseFloat(coordinates.lat), parseFloat(coordinates.lon)]);
  //   }
  // };
  const getPosition = async (location: string) => {
    const response = await http.get(
      `https://nominatim.openstreetmap.org/search?q={${location}}&format=json&addressdetails=1&limit=1&polygon_svg=1`
    );
    const coordinates = response.data[0];
    if (coordinates) {
      setPosition([parseFloat(coordinates.lat), parseFloat(coordinates.lon)]);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocation(event.target.value);
  };

  return (
    <>
      <LayerContext.Provider value={position}>
        <Container
          disableGutters
          component={MapContainer}
          center={position}
          zoom={13}
          scrollWheelZoom={true}
          sx={{
            width: "100%",
            height: "50vh",
          }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={position} />
        </Container>
      </LayerContext.Provider>
      <Paper
        component="form"
        sx={{
          zIndex: 1000,
          p: "2px 4px",
          mx: { xs: 1, md: 2 },
          mt: { xs: -1, md: -3, lg: -3 },
          mb: 2,
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
        variant="outlined"
      >
        <IconButton sx={{ p: "10px" }} aria-label="menu">
          <LocationOnIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Location"
          inputProps={{ "aria-label": "search location" }}
          value={location}
          onChange={handleChange}
        />
        <IconButton
          sx={{ p: "10px" }}
          aria-label="search"
          onClick={() => {
            getPosition(location);
          }}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </>
  );
};

export default Map;
