import { Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getUploadedFileTableRowDataFromUploadedFile } from "common/utils/helpers/getUploadedFileTableRowDataFromUploadedFile";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getUploadedFiles } from "store/entities/projects/uploadedFiles/uploadedFiles.slice";
import { selectToBeLoadedIfcModelFile } from "store/ui/projects/ifcViewer/ifcViewer.slice";
import { IIfcModelCreation, IUploadedFile } from "types";
import PreviewIcon from '@mui/icons-material/Preview';
import { handleShowInfosIfcModelCreationsDialogClose } from "store/ui/projects/ifcModelCreations/ifcModelCreations.slice";

interface IRequestDetailsTab {
    ifcModelCreation: IIfcModelCreation
}

const RequestDetailsTab: React.FC<IRequestDetailsTab> = ({ ifcModelCreation }) => {
    const dispatch = useAppDispatch();
    const { projectId } = useParams();
    const navigate = useNavigate();

    const uploadedFiles: IUploadedFile[] = useAppSelector(getUploadedFiles);

    const handleLoadIfcFileClick = () => {
        if (projectId !== undefined) {
            const uploadedFile = uploadedFiles.find((uploadedFile) => uploadedFile.uuid === ifcModelCreation.base_model_file.uuid);
            if (uploadedFile !== undefined) {
                dispatch(handleShowInfosIfcModelCreationsDialogClose())
                dispatch(selectToBeLoadedIfcModelFile(getUploadedFileTableRowDataFromUploadedFile(uploadedFile, uploadedFile.id)));
                navigate("/projects/" + projectId + "/ifc-viewer/");
            }
        }
    }

    return (
        <Box>
            <Typography variant="h4">Request details</Typography>
            <Divider sx={{ width: "100%", mb: 5 }} />
            <Box sx={{ mb: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6">Base model file</Typography>
                    <Tooltip sx={{ ml: 1 }} title={"Load model in IFC Viewer"}>
                        <IconButton onClick={handleLoadIfcFileClick} aria-label="loadIfcInViewer" size="medium">
                            <PreviewIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                </Box>
                {/* <Divider sx={{ width: "100%" }} /> */}
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                        <Typography variant="body1" color="GrayText">name</Typography>
                        <Typography variant="body1" color="GrayText">creation level</Typography>
                        <Typography variant="body1" color="GrayText">uuid</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="body1">{ifcModelCreation.base_model_file.name}</Typography>
                        <Typography variant="body1">{ifcModelCreation.base_model_file.creation_level ? ifcModelCreation.base_model_file.creation_level : "none"}</Typography>
                        <Typography variant="body1">{ifcModelCreation.base_model_file.uuid}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Typography variant="h6" >Creation optimization priority</Typography>
                {/* <Divider sx={{ width: "100%" }} /> */}
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {ifcModelCreation.creation_optimization_priority.map((priority, index) => {
                        if (priority.priority !== 0) {
                            return (
                                <Box key={index} sx={{ display: "flex", flexDirection: "row" }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                                        <Typography variant="body1" color="GrayText">topic</Typography>
                                        <Typography variant="body1" color="GrayText">priority</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Typography variant="body1">{priority.topic}</Typography>
                                        <Typography variant="body1">{priority.priority}</Typography>
                                    </Box>
                                </Box>
                            );
                        } return null;
                    })}
                </Box>
            </Box>
        </Box>
    );
}

export default RequestDetailsTab;