import { IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getUploadedFileTableRowDataFromUploadedFile } from "common/utils/helpers/getUploadedFileTableRowDataFromUploadedFile";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getUploadedFiles } from "store/entities/projects/uploadedFiles/uploadedFiles.slice";
import { handleShowInfosIfcModelCreationsDialogClose } from "store/ui/projects/ifcModelCreations/ifcModelCreations.slice";
import { selectToBeLoadedIfcModelFile } from "store/ui/projects/ifcViewer/ifcViewer.slice";
import { IIfcModelCreation, IUploadedFile } from "types";
import PreviewIcon from '@mui/icons-material/Preview';

interface IGeneralResultTabProps {
    ifcModelCreation: IIfcModelCreation;
}

const GeneralResultTab: React.FC<IGeneralResultTabProps> = ({ ifcModelCreation }) => {
    const dispatch = useAppDispatch();
    const { projectId } = useParams();
    const navigate = useNavigate();

    const uploadedFiles: IUploadedFile[] = useAppSelector(getUploadedFiles);

    const handleLoadIfcFileClick = () => {
        const created_model_file = ifcModelCreation.created_model_file;
        if (projectId !== undefined && created_model_file !== null) {
            const uploadedFile = uploadedFiles.find((uploadedFile) => uploadedFile.uuid === created_model_file.uuid);
            if (uploadedFile !== undefined) {
                dispatch(handleShowInfosIfcModelCreationsDialogClose())
                dispatch(selectToBeLoadedIfcModelFile(getUploadedFileTableRowDataFromUploadedFile(uploadedFile, uploadedFile.id)));
                navigate("/projects/" + projectId + "/ifc-viewer/");
            }
        }
    }

    return (
        <Box sx={{ mb: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6">Created model file</Typography>
                {ifcModelCreation.created_model_file && (
                    <Tooltip sx={{ ml: 1 }} title={"Load model in IFC Viewer"}>
                        <IconButton onClick={handleLoadIfcFileClick} aria-label="loadIfcInViewer" size="medium">
                            <PreviewIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            {/* <Divider sx={{ width: "100%" }} /> */}
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                    <Typography variant="body1" color="GrayText">name</Typography>
                    <Typography variant="body1" color="GrayText">creation level</Typography>
                    <Typography variant="body1" color="GrayText">uuid</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="body1">{ifcModelCreation.created_model_file?.name}</Typography>
                    <Typography variant="body1">{ifcModelCreation.created_model_file?.creation_level ? ifcModelCreation.created_model_file.creation_level : "none"}</Typography>
                    <Typography variant="body1">{ifcModelCreation.created_model_file?.uuid}</Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default GeneralResultTab;