const BUILD_VERSION_STORAGE_KEY = 'APP_BUILD_VERSION';

const localStorageBuildVersionUpdate = async (): Promise<void> => {
    const currentBuildTime = process.env.REACT_APP_BUILD_TIME;
    const currentBuildCommitString = process.env.REACT_APP_BUILD_COMMIT;

    const currentBuildVersion =
        (currentBuildTime !== undefined && currentBuildCommitString !== undefined)
            ? `${currentBuildTime}.${currentBuildCommitString.slice(0, 7)}`
            : 'unknown';

    const buildVersionStorageValue = localStorage.getItem(BUILD_VERSION_STORAGE_KEY);

    if (buildVersionStorageValue === null) {
        localStorage.setItem(BUILD_VERSION_STORAGE_KEY, currentBuildVersion);
        return;
    }
    else if (buildVersionStorageValue !== currentBuildVersion) {
        localStorage.clear();
        localStorage.setItem(BUILD_VERSION_STORAGE_KEY, currentBuildVersion);
        await new Promise(resolve => setTimeout(resolve, 100));
        window.location.reload();
    }
};

export default localStorageBuildVersionUpdate;