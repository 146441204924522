import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  getLocales,
  selectLanguage,
} from "store/entities/settings/settings.slice";
import supportedLanguages from "assets/languages";

const Language = () => {
  const { t } = useTranslation("settings", { keyPrefix: "app" });
  const dispatch = useDispatch();
  const locales = useSelector(getLocales);

  const handleChange = (event: any) => {
    dispatch(selectLanguage(event.target.value));
  };

  return (
    <FormControlLabel
      sx={{
        width: "100%",
        m: 0,
        direction: "row",
        justifyContent: "space-between",
        alignItems: "top",
      }}
      labelPlacement="start"
      label={t<string>("choose_preferred_language")}
      control={
        <FormControl sx={{ width: "50%" }}>
          <Select
            displayEmpty={true}
            inputProps={{ "aria-label": "Without label" }}
            value={locales}
            onChange={handleChange}
            renderValue={() => locales.language}
          >
            {supportedLanguages.map((language: any) => (
              <MenuItem key={language.code} value={language}>
                {language.language}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
    />
  );
};

export default Language;
