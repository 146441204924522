import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { NumericFormat } from "react-number-format";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { addProject } from "store/entities/projects/projects.slice";
import Address from "./address";
import Name from "./name";
import Title from "./title";
import { useAppDispatch, useAppSelector } from "store";
import {
  closeAddProjectDialog,
  getDialogs,
} from "store/ui/projects/projects.slice";
import type { IProjectCreateForm } from "types";

const steps = ["create_project", "create_address"];

const AddProjectDialog: React.FC = () => {
  const { t } = useTranslation("projects", {
    keyPrefix: "list.dialog",
  });
  const dialog = useAppSelector(getDialogs).addProject;
  const dispatch = useAppDispatch();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [initialValues, setInitialValues] = useState<IProjectCreateForm>({
    name: "",
    construction_site: { street: "", city: "", postal_code: "", country: "" },
  });

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t("required") as string)
      .max(
        55,
        t("string_larger_than", {
          max_number: 55,
        }) as string
      ),
    construction_site: yup.object().shape({
      street: yup
        .string()
        .required(t("required") as string)
        .max(
          55,
          t("string_larger_than", {
            max_number: 55,
          }) as string
        ),
      postal_code: yup
        .string()
        .required(t("required") as string)
        .max(
          55,
          t("string_larger_than", {
            max_number: 55,
          }) as string
        ),
      city: yup
        .string()
        .required(t("required") as string)
        .max(
          55,
          t("string_larger_than", {
            max_number: 55,
          }) as string
        ),
      country: yup
        .string()
        .required(t("required") as string)
        .max(
          55,
          t("string_larger_than", {
            max_number: 55,
          }) as string
        ),
    }),
  });

  const newProjectForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      setActiveStep(0);
      dispatch(addProject(values));
      dispatch(closeAddProjectDialog());
      newProjectForm.resetForm();
    },
  });

  const isStepOptional = (step: number) => {
    return step === 2;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleCancel = () => {
    setActiveStep(0);
    dispatch(closeAddProjectDialog());
    newProjectForm.resetForm();
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <Name newProjectForm={newProjectForm} />;
      case 1:
        return <Address newProjectForm={newProjectForm} />;
      default:
        return null;
    }
  }

  return (
    <Dialog
      open={dialog.open}
      onClose={() => (
        dispatch(closeAddProjectDialog()),
        newProjectForm.resetForm(),
        setActiveStep(0)
      )}
    >
      <Title steps={steps} activeStep={activeStep} skipped={skipped} />
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <DialogContent>{t("steps_completed")}</DialogContent>
          </Typography>
          <DialogActions>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={() => handleCancel()}>{t("reset")}</Button>
            </Box>
          </DialogActions>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <DialogContent>
            <form id="new-project-form" onSubmit={newProjectForm.handleSubmit}>
              {getStepContent(activeStep)}
            </form>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
              }}
            >
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t("back")}
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  {t("skip")}
                </Button>
              )}
              {activeStep === steps.length - 1 ? (
                <Button type="submit" form="new-project-form">
                  {t("finish")}
                </Button>
              ) : (
                <Button onClick={handleNext}>{t("next")}</Button>
              )}
            </Box>
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
};
export default AddProjectDialog;
