import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Store } from "redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./app";
import "./services/i18n";
import { store, persistor } from "./store";
import http from "./services/httpService";
import { IndexedDBConfig } from "services/indexeddb/indexeddbConfig";
import { initIndexedDB } from "services/indexeddb/indexed-hooks";
import localStorageBuildVersionUpdate from "services/localStorageBuildVersionUpdateService";

const container = document.getElementById("root");
const root = createRoot(container!);

const AppWrapper = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

const initApp = async () => {
  try {
    await localStorageBuildVersionUpdate();
    root.render(<AppWrapper />);
    initIndexedDB(IndexedDBConfig);
    http.apiSetup(store as Store);
  } catch (error) {
    console.error("Error during app initialization:", error);
  }
};

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", () => {
    initApp();
  });
} else {
  initApp();
}