import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, List, ListItem, Grid, Paper, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useAppSelector } from 'store';
import { getIfcModelOptimizationPriorityChoices } from 'store/entities/projects/ifcModelCreations/ifcModelCreations.slice';
import { IIfcModelCreationsOptimizationPriorityChoice } from 'types';
import { createTopicPositionMapping, encodeOptimizationPriorityToHex } from './optimizationPriorityFormat';
import { useTranslation } from 'react-i18next';

interface IOptimizationPriorityProps {
    newIfcModelCreationForm: any
}

const OptimizationPriority: React.FC<IOptimizationPriorityProps> = (props) => {
    const { t } = useTranslation("ifc_model_creations", {
        keyPrefix: "dialogs.create_dialog.optimization_priority",
    });
    const { newIfcModelCreationForm } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const ifcModelCreationsOptimizationPriorityChoices: IIfcModelCreationsOptimizationPriorityChoice[] = useAppSelector(getIfcModelOptimizationPriorityChoices);
    const topicPositionMapping = createTopicPositionMapping(ifcModelCreationsOptimizationPriorityChoices);

    const [left, setLeft] = useState<IIfcModelCreationsOptimizationPriorityChoice[]>(
        [ifcModelCreationsOptimizationPriorityChoices[0]]
    );
    const [right, setRight] = useState<IIfcModelCreationsOptimizationPriorityChoice[]>(
        ifcModelCreationsOptimizationPriorityChoices.slice(1).map((choice: IIfcModelCreationsOptimizationPriorityChoice) => {
            return choice
        })
    );

    useEffect(() => {
        const optimizationPriority = left.map((choice, idx) => {
            return { topic: choice.value, priority: idx + 1 }
        });
        const optimizationPriorityHex = encodeOptimizationPriorityToHex(optimizationPriority, topicPositionMapping);
        newIfcModelCreationForm.setFieldValue('creation_optimization_priority_hex', optimizationPriorityHex)
    }, [left])

    const handleMoveRight = (item: IIfcModelCreationsOptimizationPriorityChoice) => {
        setLeft(left.filter((i) => i !== item));
        setRight([...right, item]);
    };

    const handleMoveLeft = (item: IIfcModelCreationsOptimizationPriorityChoice) => {
        setRight(right.filter((i) => i !== item));
        setLeft([...left, item]);
    };

    const moveItemUp = (index: number) => {
        if (index === 0) return;
        const newList = [...left];
        [newList[index - 1], newList[index]] = [newList[index], newList[index - 1]];
        setLeft(newList);
    };

    const moveItemDown = (index: number) => {
        if (index === left.length - 1) return;
        const newList = [...left];
        [newList[index + 1], newList[index]] = [newList[index], newList[index + 1]];
        setLeft(newList);
    };

    const OptimizationModesTransferList = (items: IIfcModelCreationsOptimizationPriorityChoice[], direction: 'left' | 'right') => (
        <Paper variant="outlined" style={{ width: '100%', height: 230, overflow: 'auto', margin: 'auto' }}>
            <List dense component="div" role="list">
                <ListItem key={`${direction}-header`}>
                    <Grid container spacing={2} alignItems="center">
                        {direction === 'left' && (
                            <Grid item xs={3}>
                                <Typography variant="subtitle2">{t("priority")}</Typography>
                            </Grid>
                        )}
                        <Grid item xs={direction === 'left' ? 9 : 12}>
                            <Typography variant="subtitle2">{t("topic")}</Typography>
                        </Grid>
                    </Grid>
                </ListItem>
                {items.map((item, index) => (
                    <ListItem key={item.value} role="listitem">
                        <Grid container spacing={2} alignItems="center">
                            {direction === 'left' && (
                                <Grid item xs={3}>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <Typography variant="body1">{index + 1}</Typography>
                                        {index === 0 ? (
                                            <Typography variant="body2">&nbsp;&nbsp;{`(${t("highest_priority")})`}</Typography>
                                        ) : null}
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={5}>
                                <Typography variant="body1">{item.label}</Typography>
                            </Grid>
                            <Grid item xs={direction === 'left' ? 4 : 7}>
                                {direction === 'left' && (
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                        <Tooltip title={t("move_up_topic")}>
                                            <IconButton onClick={() => moveItemUp(index)} disabled={index === 0}>
                                                <KeyboardArrowUpIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t("move_down_topic")}>
                                            <IconButton onClick={() => moveItemDown(index)} disabled={index === items.length - 1}>
                                                <KeyboardArrowDownIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t("move_right_topic")}>
                                            <IconButton onClick={() => handleMoveRight(item)}>
                                                <KeyboardDoubleArrowRightIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                                {direction === 'right' && (
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                        <Tooltip title={t("move_left_topic")}>
                                            <IconButton onClick={() => handleMoveLeft(item)}>
                                                <KeyboardDoubleArrowLeftIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
        </Paper >
    );

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="flex-end">
            <Grid item xs={12} md={5}>
                <Typography sx={{ pb: 1 }} variant="h6">{t("selected_list_title")}</Typography>
                {OptimizationModesTransferList(left, 'left')}
            </Grid>
            <Grid item xs={12} md={5}>
                <Typography sx={{ pb: 1 }} variant="h6">{t("available_list_title")}</Typography>
                {OptimizationModesTransferList(right, 'right')}
            </Grid>
        </Grid>
    );
};

export default OptimizationPriority;
