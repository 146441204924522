import { createSlice } from '@reduxjs/toolkit'

type SliceState = {
  dialogs: {
    project: { update: boolean; delete: boolean }
  }
}

const initialState: SliceState = {
  dialogs: {
    project: { update: false, delete: false },
  },
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    ProjectUpdateDialogOpened: (dashboard) => {
      dashboard.dialogs.project.update = true
    },
    ProjectUpdateDialogClosed: (dashboard) => {
      dashboard.dialogs.project.update = false
    },
    ProjectDeleteUpdateOpened: (dashboard) => {
      dashboard.dialogs.project.delete = true
    },
    ProjectDeleteDialogClosed: (dashboard) => {
      dashboard.dialogs.project.delete = false
    },
  },
})

export const openProjectUpdateDialog = () => ({
  type: ProjectUpdateDialogOpened.type,
})

export const closeProjectUpdateDialog = () => ({
  type: ProjectUpdateDialogClosed.type,
})

export const openProjectDeleteDialog = () => ({
  type: ProjectDeleteUpdateOpened.type,
})

export const closeProjectDeleteDialog = () => ({
  type: ProjectDeleteDialogClosed.type,
})

const {
  ProjectUpdateDialogOpened,
  ProjectUpdateDialogClosed,
  ProjectDeleteUpdateOpened,
  ProjectDeleteDialogClosed,
} = dashboardSlice.actions
export default dashboardSlice.reducer

export const getProjectDialogs = (state: any) =>
  state.ui.projects.selectedProject.dashboard.dialogs.project
