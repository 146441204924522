import { TFunction } from 'i18next'
import React from 'react'

export default async function layers(
  element: any,
  doc: any,
  layout: any,
  fontSizes: any,
  lineSpacing: number,
  startY: number,
  t: TFunction<'component_catalogue', 'pdf_export'>
) {
  let startX = layout.StartX
  let endX = layout.EndX

  const tablecol1X = 32
  const tablecol2X = startX + (layout.ContentWidth / 4) * 0.5
  const tablecol3X = startX + (layout.ContentWidth / 4) * 2

  doc.line(startX, startY, endX, startY)

  startY += lineSpacing * 0.5

  doc.text(t('pdf_export.position'), startX, startY, { align: 'left' })
  doc.text(t('pdf_export.name'), tablecol2X, startY, { align: 'left' })
  doc.text(t('pdf_export.material'), tablecol3X, startY, { align: 'left' })
  doc.text(t('pdf_export.thickness'), endX, startY, { align: 'right' })

  startY += lineSpacing * 0.3
  doc.line(startX, startY, endX, startY)
  startY += lineSpacing * 0.5

  element.layers.map((layer: any) => {
    doc.text(`${layer.position}`, tablecol1X, startY, { align: 'right' })
    doc.text(layer.name, tablecol2X, startY, { align: 'left' })
    doc.text(layer.material, tablecol3X, startY, { align: 'left' })
    doc.text(`${layer.thickness.toString()} mm`, endX, startY, { align: 'right' })
    startY += lineSpacing * 0.65
  })

  doc.setFont('BarlowSemiCondensed-Bold', 'normal')
  doc.text(`${element.performance.total_width} mm`, endX, startY, {
    align: 'right',
  })
  doc.setFont('BarlowSemiCondensed-Regular', 'normal')

  startY += lineSpacing

  return startY
}
