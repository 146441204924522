import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { settingsTabs as tabs } from "assets/menu";
import { getUser } from "store/entities/authentication/authentication.slice";
import { useAppSelector } from "store";

export default function SettingsTabs() {
  const { t } = useTranslation("navigation", { keyPrefix: "tabs" });
  const user = useAppSelector(getUser);
  const [openTab, setOpenTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setOpenTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={openTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
      >
        {tabs.map((tab: any) => (
          <Tab
            key={tab.id}
            label={t(tab.name)}
            component={RouterLink}
            to={tab.url}
            disabled={user.loggedIn ? false : true}
          />
        ))}
      </Tabs>
    </Box>
  );
}
