import { AlertColor } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";
import {
  ifcModelCreationsReceived,
  ifcModelCreationsRequestFailed,
  ifcModelCreationsCreateRequested,
  ifcModelCreationsCreateFulfilled,
  ifcModelCreationsCreateFailed,
  ifcModelCreationsDeleteRequested,
  ifcModelCreationsDeleteFulfilled,
  ifcModelCreationsDeleteFailed,
} from "store/entities/projects/ifcModelCreations/ifcModelCreations.slice";
import { RootState } from "store/rootReducer";
import { IIfcModelCreation } from "types";

interface DialogsState {
  deleteIfcModelCreation: boolean;
  createIfcModelCreation: boolean;
  showDetailsIfcModelCreation: boolean;
}

interface SliceState {
  dialogs: DialogsState;
  alertSnackbar: { open: boolean; message: string; severity: AlertColor };
  loadingSnackbar: { open: boolean; message: string; };
  selectedIfcModelCreation: IIfcModelCreation;
}

const initialState: SliceState = {
  dialogs: {
    deleteIfcModelCreation: false,
    createIfcModelCreation: false,
    showDetailsIfcModelCreation: false,
  },
  alertSnackbar: { open: false, message: "", severity: "success" },
  loadingSnackbar: { open: false, message: "" },
  selectedIfcModelCreation: {
    id: 0,
    creation_level: "",
    creation_status: "",
    base_model_file: {
      uuid: "",
      name: "",
      creation_level: null,
    },
    created_model_file: null,
    created_at: "",
    created_by: {
      email: "string",
      first_name: "string",
      last_name: "string",
    },
    creation_optimization_priority_hex: "",
    creation_optimization_priority: [],
    response_optimized: null
  }
};

const ifcModelCreationsSlice = createSlice({
  name: "ifcModelCreations",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(ifcModelCreationsReceived, (ifcModelCreations) => {
        ifcModelCreations.alertSnackbar = {
          open: true,
          message: "ifc_model_creations_success",
          severity: "success",
        };
      })
      .addCase(ifcModelCreationsRequestFailed, (ifcModelCreations) => {
        ifcModelCreations.alertSnackbar = {
          open: true,
          message: "ifc_model_creations_failed",
          severity: "error",
        };
      })
      .addCase(ifcModelCreationsCreateRequested, (ifcModelCreations) => {
        ifcModelCreations.alertSnackbar = {
          open: true,
          message: "ifc_model_creations_create_requested",
          severity: "info",
        };
        ifcModelCreations.loadingSnackbar = {
          open: true,
          message: "ifc_model_creations_create_element_requested",
        };
      })
      .addCase(ifcModelCreationsCreateFulfilled, (ifcModelCreations) => {
        ifcModelCreations.alertSnackbar = {
          open: true,
          message: "ifc_model_creations_create_fullfilled",
          severity: "success",
        };
        ifcModelCreations.loadingSnackbar = {
          open: false,
          message: "",
        };
      })
      .addCase(ifcModelCreationsCreateFailed, (ifcModelCreations) => {
        ifcModelCreations.alertSnackbar = {
          open: true,
          message: "ifc_model_creations_create_failed",
          severity: "error",
        };
        ifcModelCreations.loadingSnackbar = {
          open: false,
          message: "",
        };
      })
      .addCase(ifcModelCreationsDeleteRequested, (ifcModelCreations) => {
        ifcModelCreations.alertSnackbar = {
          open: true,
          message: "ifc_model_creations_delete_requested",
          severity: "info",
        };
        ifcModelCreations.loadingSnackbar = {
          open: true,
          message: "ifc_model_creations_delete_element_requested",
        };
      })
      .addCase(ifcModelCreationsDeleteFulfilled, (ifcModelCreations) => {
        ifcModelCreations.alertSnackbar = {
          open: true,
          message: "ifc_model_creations_delete_fullfilled",
          severity: "success",
        };
        ifcModelCreations.loadingSnackbar = {
          open: false,
          message: "",
        };
      })
      .addCase(ifcModelCreationsDeleteFailed, (ifcModelCreations) => {
        ifcModelCreations.alertSnackbar = {
          open: true,
          message: "ifc_model_creations_delete_failed",
          severity: "error",
        };
        ifcModelCreations.loadingSnackbar = {
          open: false,
          message: "",
        };
      })
  },
  reducers: {
    alertSnackbarOpened: (ifcModelCreations, action) => {
      ifcModelCreations.alertSnackbar = {
        open: true,
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    alertSnackbarClosed: (ifcModelCreations) => {
      ifcModelCreations.alertSnackbar = {
        ...ifcModelCreations.alertSnackbar,
        open: false,
      };
    },
    loadingSnackbarOpened: (ifcModelCreations, action) => {
      ifcModelCreations.loadingSnackbar = {
        open: true,
        message: action.payload.message,
      };
    },
    loadingSnackbarClosed: (ifcModelCreations) => {
      ifcModelCreations.loadingSnackbar = {
        ...ifcModelCreations.loadingSnackbar,
        open: false,
      };
    },
    ifcModelCreationSelected: (ifcModelCreations, action) => {
      ifcModelCreations.selectedIfcModelCreation = action.payload;
    },
    createIfcModelCreationsDialogOpened: (ifcModelCreations) => {
      ifcModelCreations.dialogs.createIfcModelCreation = true;
    },
    createIfcModelCreationsDialogClosed: (ifcModelCreations) => {
      ifcModelCreations.dialogs.createIfcModelCreation = false;
    },
    deleteIfcModelCreationsDialogOpened: (ifcModelCreations) => {
      ifcModelCreations.dialogs.deleteIfcModelCreation = true;
    },
    deleteIfcModelCreationsDialogClosed: (ifcModelCreations) => {
      ifcModelCreations.dialogs.deleteIfcModelCreation = false;
    },
    showInfosIfcModelCreationsDialogOpened: (ifcModelCreations) => {
      ifcModelCreations.dialogs.showDetailsIfcModelCreation = true;
    },
    showInfosIfcModelCreationsDialogClosed: (ifcModelCreations) => {
      ifcModelCreations.dialogs.showDetailsIfcModelCreation = false;
    },
  },
});

export const selectIfcModelCreation = (payload: IIfcModelCreation) => ({
  type: ifcModelCreationSelected.type,
  payload,
});

export const handleCreateIfcModelCreationsDialogOpen = () =>
  ({ type: createIfcModelCreationsDialogOpened.type })

export const handleCreateIfcModelCreationsDialogClose = () =>
  ({ type: createIfcModelCreationsDialogClosed.type })

export const handleDeleteIfcModelCreationsDialogOpen = () =>
  ({ type: deleteIfcModelCreationsDialogOpened.type })

export const handleDeleteIfcModelCreationsDialogClose = () =>
  ({ type: deleteIfcModelCreationsDialogClosed.type })

export const handleShowInfosIfcModelCreationsDialogOpen = () =>
  ({ type: showInfosIfcModelCreationsDialogOpened.type })

export const handleShowInfosIfcModelCreationsDialogClose = () =>
  ({ type: showInfosIfcModelCreationsDialogClosed.type })

export const openAlertSnackbar = (payload: {
  message: string;
  severity: string;
}) => ({
  type: alertSnackbarOpened.type,
  payload,
});

export const closeAlertSnackbar = () => ({
  type: alertSnackbarClosed.type,
});

export const openLoadingSnackbar = (payload: {
  message: string;
}) => ({
  type: loadingSnackbarOpened.type,
  payload,
});

export const closeLoadingSnackbar = () => ({
  type: loadingSnackbarClosed.type,
});

export const {
  alertSnackbarOpened,
  alertSnackbarClosed,
  loadingSnackbarOpened,
  loadingSnackbarClosed,
  ifcModelCreationSelected,
  createIfcModelCreationsDialogOpened,
  createIfcModelCreationsDialogClosed,
  deleteIfcModelCreationsDialogOpened,
  deleteIfcModelCreationsDialogClosed,
  showInfosIfcModelCreationsDialogOpened,
  showInfosIfcModelCreationsDialogClosed
} = ifcModelCreationsSlice.actions;

export default ifcModelCreationsSlice.reducer;

export const getIfcModelCreationsDialogs = (state: RootState) =>
  state.ui.projects.selectedProject.ifcModelCreations.dialogs;

export const getIfcModelCreation = (state: any) =>
  state.ui.projects.selectedProject.ifcModelCreations.selectedIfcModelCreation;

export const getAlertSnackbar = (state: any) =>
  state.ui.projects.selectedProject.ifcModelCreations.alertSnackbar;

export const getLoadingSnackbar = (state: any) =>
  state.ui.projects.selectedProject.ifcModelCreations.loadingSnackbar;