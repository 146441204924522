import { Box, Typography } from "@mui/material";

const BuildVersionInfo = () => {
    return(
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary" align='center'>
            {process.env.REACT_APP_BUILD_TIME !== undefined 
              ? `build-version: ${process.env.REACT_APP_BUILD_TIME}.${process.env.REACT_APP_BUILD_COMMIT?.slice(0, 7)}`
              : `build-version: development`
            }
          </Typography>
        </Box>
    );
};

export default BuildVersionInfo;