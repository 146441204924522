import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { commonThemeOptions } from "./commonThemeOptions";

const darkTheme = createTheme({
  ...commonThemeOptions,
  palette: {
    mode: "dark",
    primary: {
      main: '#7997A1',
    },
    secondary: {
      main: '#768669',
    },
    background: {
      default: '#252627',
      paper: '#131b23',
    },
    error: {
      main: '#612940',
    },
  },
  typography: {
    fontFamily: [
      '"Barlow Semi Condensed"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  shape: {
    borderRadius: 0,
  },
});

const dark = responsiveFontSizes(darkTheme);

export default dark;
