import { apiRequest } from "common/utils/constants/api.constants";
import * as ifcConElementAssignmentsSlice from "./ifcConElementAssignments.slice";
import * as ifcConElementAssignmentsEndpoints from "assets/endpoints/api/projects/ifcViewerEndpoints";

export const fetchIfcConElementAssignments = (projectId: number) =>
  apiRequest({
    url: ifcConElementAssignmentsEndpoints.getIfcConElementAssigmentsListUrl(projectId),
    onStart: ifcConElementAssignmentsSlice.ifcConElementAssignmentsRequested.type,
    onSuccess: ifcConElementAssignmentsSlice.ifcConElementAssignmentsReceived.type,
    onError: ifcConElementAssignmentsSlice.ifcConElementAssignmentsRequestFailed.type,
  });

export const createIfcConElementAssignment = (
  projectId: number,
  ifc_model_file_uuid: string,
  construction_element_id: number,
  ifc_element_express_id: number,
  minimal_bracing_force: number = -1,
) =>
  apiRequest({
    url: ifcConElementAssignmentsEndpoints.getIfcConElementAssigmentsListUrl(projectId),
    method: "post",
    headers: {
      'content-type': 'multipart/form-data'
    },
    data: {
      ifc_model_file_uuid: ifc_model_file_uuid,
      construction_element_id: construction_element_id,
      ifc_element_express_id: ifc_element_express_id,
      minimal_bracing_force: minimal_bracing_force,
    },
    onStart: ifcConElementAssignmentsSlice.ifcConElementAssignmentsCreateRequested.type,
    onSuccess: ifcConElementAssignmentsSlice.ifcConElementAssignmentsCreateFulfilled.type,
    onError: ifcConElementAssignmentsSlice.ifcConElementAssignmentsCreateFailed.type,
  });

export const deleteIfcConElementAssignment = (projectId: number, ifcConElementAssignmentId: number) =>
  apiRequest({
    url: ifcConElementAssignmentsEndpoints.getIfcConElementAssigmentsItemUrl(projectId, ifcConElementAssignmentId),
    method: "delete",
    onStart: ifcConElementAssignmentsSlice.ifcConElementAssignmentsDeleteRequested.type,
    onSuccess: ifcConElementAssignmentsSlice.ifcConElementAssignmentsDeleteFulfilled.type,
    onError: ifcConElementAssignmentsSlice.ifcConElementAssignmentsDeleteFailed.type,
  });

export const updateIfcConElementAssignment = (
  projectId: number,
  ifcConElementAssignmentId: number,
  ifc_model_file_uuid: string,
  construction_element_id: number,
  ifc_element_express_id: number,
  minimal_bracing_force: number,
) =>
  apiRequest({
    url: ifcConElementAssignmentsEndpoints.getIfcConElementAssigmentsItemUrl(projectId, ifcConElementAssignmentId),
    method: "put",
    headers: {
      'content-type': 'multipart/form-data'
    },
    data: {
      ifc_model_file_uuid: ifc_model_file_uuid,
      construction_element_id: construction_element_id,
      ifc_element_express_id: ifc_element_express_id,
      minimal_bracing_force: minimal_bracing_force,
    },
    onStart: ifcConElementAssignmentsSlice.ifcConElementAssignmentsUpdateRequested.type,
    onSuccess: ifcConElementAssignmentsSlice.ifcConElementAssignmentsUpdateFulfilled.type,
    onError: ifcConElementAssignmentsSlice.ifcConElementAssignmentsUpdateFailed.type,
  });
