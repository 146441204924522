import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { apiRequest } from "common/utils/constants/api.constants";
import {
  getProjectListUrl,
  getProjectItemUrl,
  getProjectStatusUrl,
  getProjectBookmarkUrl,
} from "assets/endpoints/api/projects/projectEndpoints";
import {
  getInvitationStatusUrl,
  getStakeholderItemUrl,
} from "assets/endpoints/api/projects/stakeholderEndpoints";
import type { IProject, IProjectStatus } from "types";

type SliceState = {
  api: {
    submitting: boolean;
    loading: boolean;
    lastFetch: number | null;
  };
  data: IProject[];
  options: {
    projectStatus: IProjectStatus[];
  };
  filter: string;
};

const initialState: SliceState = {
  api: {
    submitting: false,
    loading: false,
    lastFetch: null,
  },
  data: [],
  options: {
    projectStatus: [],
  },
  filter: "",
};

const listSlice = createSlice({
  name: "list",
  initialState,
  reducers: {
    projectsPending: (list) => {
      list.api.loading = true;
    },
    projectsFulfilled: (list, action) => {
      list.data = action.payload;
      list.api.lastFetch = Date.now();
      list.api.loading = false;
    },
    projectsRejected: (list) => {
      list.api.loading = false;
    },
    projectCreated: (list, action: any) => {
      list.data.unshift(action.payload);
    },
    projectJoined: (list, action) => {
      const index = list.data.findIndex(
        (project) => project.id === action.payload
      );
      list.data[index].me.invitation_status = "accepted";
    },
    projectUpdated: (list, action) => {
      const index = list.data.findIndex(
        (project) => project.id === action.payload.id
      );
      list.data[index] = action.payload;
    },
    projectBookmarkUpdated: (list, action) => {
      const index = list.data.findIndex(
        (project) => project.id === action.payload.id
      );
      list.data[index].bookmark = action.payload.bookmark;
    },
    projectDeleted: (list, action) => {
      const index = list.data.findIndex(
        (project) => project.id === action.payload
      );
      list.data.splice(index, 1);
    },
    projectStatusFulfilled: (stakeholders, action) => {
      stakeholders.options.projectStatus = action.payload;
    },
    filterChanged: (list, action: PayloadAction<string>) => {
      list.filter = action.payload;
    },
  },
});

export const addProject = (project: {
  name: string;
  construction_site: {
    street: string;
    city: string;
    postal_code: string;
    country: string;
  };
}) =>
  apiRequest({
    url: getProjectListUrl(),
    method: "post",
    data: project,
    onSuccess: projectCreated.type,
  });

export const acceptProjectInvitation = (
  projectId: number,
  stakeholderId: number
) =>
  apiRequest({
    url: getInvitationStatusUrl(projectId, stakeholderId),
    method: "put",
    data: { invitation_status: "accepted" },
    onSuccess: projectJoined.type,
    onSuccessPayload: projectId,
  });

export const refuseProjectInvitation = (
  projectId: number,
  stakeholderId: number
) =>
  apiRequest({
    url: getStakeholderItemUrl(projectId, stakeholderId),
    method: "delete",
    onSuccess: projectDeleted.type,
    onSuccessPayload: projectId,
  });

export const updateProject = (projectId: number, project: any) =>
  apiRequest({
    url: getProjectItemUrl(projectId),
    method: "patch",
    data: project,
    onSuccess: projectUpdated.type,
  });

export const bookmarkProject = (projectId: number, bookmark: boolean) =>
  apiRequest({
    url: getProjectBookmarkUrl(projectId),
    method: "put",
    data: { bookmark: bookmark },
    onSuccess: projectBookmarkUpdated.type,
    onSuccessPayload: { id: projectId, bookmark: bookmark },
  });

export const fetchProjects = () =>
  apiRequest({
    url: getProjectListUrl(),
    onStart: projectsPending.type,
    onSuccess: projectsFulfilled.type,
    onError: projectsRejected.type,
  });

export const deleteProject = (projectId: number) =>
  apiRequest({
    url: getProjectItemUrl(projectId),
    method: "delete",
    onSuccess: projectDeleted.type,
    onSuccessPayload: projectId,
  });

export const fetchProjectStatusList = () =>
  apiRequest({
    url: getProjectStatusUrl(),
    onSuccess: projectStatusFulfilled.type,
  });

export const setFilter = (payload: string) => ({
  type: filterChanged.type,
  payload,
});

const {
  projectsPending,
  projectsFulfilled,
  projectsRejected,
  projectCreated,
  projectJoined,
  projectDeleted,
  projectUpdated,
  projectBookmarkUpdated,
  projectStatusFulfilled,
  filterChanged,
} = listSlice.actions;
export default listSlice.reducer;

export const getProjects = (state: any) => state.entities.projects.list.data;
export const getProjectStatusList = (state: any) =>
  state.entities.projects.list.options.projectStatus;
export const getApi = (state: any) => state.entities.projects.list.api;
export const getAddresses = (state: any) =>
  state.entities.projects.list.addresses;
export const getFilter = (state: any) => state.entities.projects.list.filter;
