import AppBar from "@mui/material/AppBar";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import React, { useState, useEffect } from "react";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Zoom from "@mui/material/Zoom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";

import { useParams } from "react-router-dom";
import {
  getInvitationStatus,
  getRoles,
} from "store/entities/projects/stakeholders/stakeholders.slice";
import {
  deleteStakeholder,
  fetchInvitationStatus,
  fetchRoles,
  updateStakeholder,
} from "store/entities/projects/stakeholders/stakeholders.actions";
import {
  getSelectedStakeholder,
  getStakeholderDialogs,
  handleUpdateStakeholderDialogClose,
} from "store/ui/projects/stakeholders/stakeholders.slice";
import PermissionList from "./permissionList";
import { useAppDispatch, useAppSelector } from "store";
import { Divider, List, ListItem, ListItemText } from "@mui/material";
import type {
  IRoleOption,
  IStakeholder,
  IStakeholderUpdateForm,
  IInvitationStatusOption,
} from "types";

const ZoomTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

export default function UpdateStakeholderDialog() {
  const { t } = useTranslation("stakeholders", {
    keyPrefix: "edit_dialog",
  });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const { projectId } = useParams();
  const stakeholderToUpdate: IStakeholder = useAppSelector(
    getSelectedStakeholder
  );
  const roles = useAppSelector(getRoles);
  const dialogs = useAppSelector(getStakeholderDialogs);
  const invitationStatus: IInvitationStatusOption[] =
    useAppSelector(getInvitationStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (projectId !== undefined) {
      dispatch(fetchRoles(+projectId));
      dispatch(fetchInvitationStatus(+projectId));
    }
  }, []);

  useEffect(() => {
    const index = roles.findIndex(
      (role: IRoleOption) => role.label === stakeholderToUpdate.role
    );
    if (index !== -1) {
      stakeholderForm.values.role = roles[index].value;
    }
    stakeholderForm.values.permissions = stakeholderToUpdate.permissions.map(
      ({ id, is_member }: { id: number; is_member: boolean }) => ({
        id,
        is_member,
      })
    );
  }, [roles, stakeholderToUpdate]);

  const [initialValues, setInitialValues] = useState<IStakeholderUpdateForm>({
    role: "",
    permissions: [],
  });

  const validationSchema = yup.object({
    role: yup.string().required(t("required") as string),
  });

  const stakeholderForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      if (projectId !== undefined) {
        dispatch(updateStakeholder(+projectId, stakeholderToUpdate.id, values));
        dispatch(handleUpdateStakeholderDialogClose());
        stakeholderForm.resetForm();
      }
    },
  });

  const handleUpdateStakeholderCancel = () => {
    dispatch(handleUpdateStakeholderDialogClose());
    stakeholderForm.resetForm();
  };

  const handleDeleteStakeholder = () => {
    projectId !== undefined &&
      dispatch(deleteStakeholder(+projectId, stakeholderToUpdate.id));
    dispatch(handleUpdateStakeholderDialogClose());
  };

  const [value, setValue] = useState<{
    value: string;
    label: string;
  }>({
    value: "",
    label: "",
  });

  useEffect(() => {
    const index = roles.findIndex(
      (role: IRoleOption) => role.value === stakeholderForm.values.role
    );
    if (index !== -1) {
      setValue(roles[index]);
    }
  }, [stakeholderForm, roles]);

  return (
    <Dialog
      fullScreen={matches ? false : true}
      open={dialogs.updateStakeholder}
      TransitionComponent={matches ? ZoomTransition : SlideTransition}
      keepMounted
      onClose={() => dispatch(handleUpdateStakeholderDialogClose())}
      aria-describedby="dialog-slide-edit"
      maxWidth="lg"
    >
      {matches ? (
        <DialogTitle>{t("edit_stakeholder")}</DialogTitle>
      ) : (
        <AppBar sx={{ position: "relative" }} color="inherit">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleUpdateStakeholderCancel}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t("edit_stakeholder")}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              type="submit"
              form="update-stakeholder-form"
            >
              {t("save")}
            </Button>
          </Toolbar>
        </AppBar>
      )}
      <DialogContent sx={matches ? { minWidth: "400px" } : { width: "100%" }}>
        <form
          id="update-stakeholder-form"
          onSubmit={stakeholderForm.handleSubmit}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <List sx={{ width: "100%" }}>
                    <ListItem disableGutters alignItems="flex-start">
                      <ListItemText primary={t("name")} />
                      <ListItemText
                        primary={`${stakeholderToUpdate.first_name} ${stakeholderToUpdate.last_name}`}
                        sx={{
                          textAlign: "right",
                          flexGrow: 1,
                          pl: 2,
                        }}
                        primaryTypographyProps={{
                          fontWeight: "bold",
                        }}
                      />
                    </ListItem>
                    <ListItem disableGutters alignItems="flex-start">
                      <ListItemText primary={t("invitation_status")} />
                      <ListItemText
                        primary={
                          invitationStatus.find(
                            (status) =>
                              status.value ===
                              stakeholderToUpdate.invitation_status
                          )?.label
                        }
                        sx={{
                          textAlign: "right",
                          flexGrow: 1,
                          pl: 2,
                        }}
                        primaryTypographyProps={{
                          fontWeight: "bold",
                        }}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    id="email"
                    name="email"
                    label={t("email")}
                    defaultValue={stakeholderToUpdate.email}
                    value={stakeholderToUpdate.email}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disableClearable
                    includeInputInList
                    filterSelectedOptions
                    id="role"
                    onChange={(e, value) => {
                      stakeholderForm.setFieldValue("role", value.value);
                    }}
                    value={value}
                    options={roles.map((role: IRoleOption) => role)}
                    getOptionLabel={(option: IRoleOption) => option.label}
                    renderOption={(props: any, option: IRoleOption) => (
                      <li key={option.value} {...props}>
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label={t("role")} />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                <PermissionList
                  stakeholderToUpdate={stakeholderToUpdate}
                  stakeholderForm={stakeholderForm}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {matches ? (
          <>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleUpdateStakeholderCancel}>
              {t("cancel")}
            </Button>
            <Button type="submit" form="update-stakeholder-form">
              {t("save")}
            </Button>
          </>
        ) : (
          <Button
            onClick={handleDeleteStakeholder}
            variant="contained"
            sx={{
              width: "100%",
              mx: 2,
              mb: 2,
              color: "text.primary",
              backgroundColor: "background.paper",
            }}
          >
            {t("remove_stakeholder")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
