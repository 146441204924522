export const getUploadedFilesListUrl = (projectId: number) =>
  `projects/${projectId}/uploaded-files/`

export const getUploadedFilesItemUrl = (
  projectId: number,
  uploadedFileId: number
) => `projects/${projectId}/uploaded-files/${uploadedFileId}/`

export const getUploadedFilesItemDownloadUrl = (
  projectId: number,
  uploadedFileId: number
) => `projects/${projectId}/uploaded-files/${uploadedFileId}/download`