import { Backdrop, Typography, keyframes } from "@mui/material";
import { useState } from "react";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { useTranslation } from "react-i18next";
import BottomNavigation from "../../navigation/bottomNavigation";
import Menu from "./menu";

const SelectionDialog = () => {
    const { t } = useTranslation("component_catalogue", {
        keyPrefix: "library.dialogs.selection",
    });

    const [backdropOpen, setBackdropOpen] = useState(true);

    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    return (
        <>
            <Backdrop
                sx={{
                    color: '#fff',
                    backdropFilter: 'blur(4px)',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={backdropOpen}
                onClick={handleBackdropClose}
            >
                <TouchAppIcon
                    sx={{
                        animation: `${keyframes`
                        from { opacity: 1; }
                        to { opacity: 0.3; }
                      `} 1s linear infinite`,
                        animationDuration: '600ms',
                        animationIterationCount: 'infinite',
                        animationDirection: 'alternate',
                        animationTimingFunction: 'ease-in-out',
                        mr: 1,
                    }}
                    fontSize="large"
                />
                <Typography variant="h4">
                    {t("backdrop")}
                </Typography>
            </Backdrop>
            <BottomNavigation>
                <Menu />
            </BottomNavigation>
        </>
    );
}

export default SelectionDialog;