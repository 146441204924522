import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  getApi,
  getMyUserDetails,
} from "store/entities/authentication/authentication.slice";
import { setUsername } from "store/entities/authentication/authentication.actions";
import { useAppDispatch, useAppSelector } from "store";
import {
  closeUsernameChangeDialog,
  getDialogs,
  openUsernameChangeDialog,
} from "store/ui/settings/settings.slice";

interface Username {
  current_password: string;
  new_username: string;
}

export default function Username() {
  const { t } = useTranslation("settings", {
    keyPrefix: "security.set_username",
  });
  const dispatch = useAppDispatch();

  const authApi = useAppSelector(getApi);
  const me = useAppSelector(getMyUserDetails);
  const dialog = useAppSelector(getDialogs).confirmUsernameChange;

  const emailValidationSchema = yup.object({
    current_password: yup.string().required(t("required") as string),
    new_username: yup.string().required(t("required") as string),
  });

  const [initialUsernameValues, setInitialUsernameValues] = useState<Username>({
    current_password: "",
    new_username: "",
  });

  const usernameForm = useFormik({
    initialValues: initialUsernameValues,
    validationSchema: emailValidationSchema,
    enableReinitialize: true,
    onSubmit: (values: Username) => {
      dispatch(setUsername(values));
      dispatch(closeUsernameChangeDialog());
      usernameForm.resetForm();
    },
  });

  const handleCancel = () => {
    dispatch(closeUsernameChangeDialog());
    usernameForm.resetForm();
  };

  const handleOk = () => {
    dispatch(openUsernameChangeDialog());
  };

  return (
    <Box
      sx={{ m: 0, p: 0 }}
      component="form"
      id="username-form"
      noValidate
      onSubmit={usernameForm.handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            color="text.primary"
            align="left"
            sx={{ fontWeight: "bold" }}
          >
            {t("change_username")}
          </Typography>
        </Grid>
        <Grid item xs={12} direction="row" sx={{ display: "flex" }}>
          <TextField
            id="new_username"
            name="new_username"
            label={t("new_username")}
            value={usernameForm.values.new_username}
            onBlur={usernameForm.handleBlur}
            error={
              usernameForm.touched.new_username &&
              Boolean(usernameForm.errors.new_username)
            }
            onChange={usernameForm.handleChange}
            autoComplete="new_username"
            variant="outlined"
            sx={{ flexGrow: 1, mr: 2 }}
          />
          <Button
            disabled={authApi.submitting}
            variant="contained"
            onClick={handleOk}
          >
            {t("change")}
          </Button>
        </Grid>
      </Grid>
      <Dialog maxWidth="xs" open={dialog.open}>
        <DialogTitle fontWeight="bold">{t("confirm_password")}</DialogTitle>
        <DialogContent>
          <TextField
            id="current_password"
            name="current_password"
            label={t("current_password")}
            value={usernameForm.values.current_password}
            onBlur={usernameForm.handleBlur}
            error={
              usernameForm.touched.current_password &&
              Boolean(usernameForm.errors.current_password)
            }
            helperText={
              usernameForm.touched.current_password &&
              usernameForm.errors.current_password
            }
            onChange={usernameForm.handleChange}
            fullWidth
            type="password"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            form="username-form"
            disabled={authApi.submitting}
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
