import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  getApi,
  getMyUserDetails,
} from "store/entities/authentication/authentication.slice";
import { updateMyUserData } from "store/entities/authentication/authentication.actions";
import { useAppDispatch, useAppSelector } from "store";

interface Email {
  email: string;
}

interface Profile {
  first_name: string;
  last_name: string;
}

export default function Name() {
  const { t } = useTranslation("settings", { keyPrefix: "profile" });
  const dispatch = useAppDispatch();

  const authApi = useAppSelector(getApi);
  const me = useAppSelector(getMyUserDetails);

  const profileValidationSchema = yup.object({
    first_name: yup.string().min(
      3,
      t("must_contain_at_least", {
        min_number: 3,
      }) as string
    ),
    last_name: yup.string().min(
      3,
      t("must_contain_at_least", {
        min_number: 3,
      }) as string
    ),
  });

  const [initialProfileValues, setInitialProfileValues] = useState<Profile>({
    first_name: me.first_name,
    last_name: me.last_name,
  });

  const profileForm = useFormik({
    initialValues: initialProfileValues,
    validationSchema: profileValidationSchema,
    enableReinitialize: true,
    onSubmit: (values: Profile) => {
      dispatch(updateMyUserData(values));
      setInitialProfileValues({
        first_name: me.first_name,
        last_name: me.last_name,
      });
    },
  });

  return (
    <>
      <Grid
        container
        spacing={2}
        component="form"
        id="profile-form"
        noValidate
        onSubmit={profileForm.handleSubmit}
      >
        <Grid item xs={12}>
          <Typography
            color="text.primary"
            align="left"
            sx={{ fontWeight: "bold" }}
          >
            {t("your_profile")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="first_name"
            name="first_name"
            label={t("first_name")}
            value={profileForm.values.first_name}
            onBlur={profileForm.handleBlur}
            error={
              profileForm.touched.first_name &&
              Boolean(profileForm.errors.first_name)
            }
            helperText={
              profileForm.touched.first_name && profileForm.errors.first_name
            }
            onChange={profileForm.handleChange}
            fullWidth
            autoComplete="given-name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="last_name"
            name="last_name"
            label={t("last_name")}
            value={profileForm.values.last_name}
            onBlur={profileForm.handleBlur}
            error={
              profileForm.touched.last_name &&
              Boolean(profileForm.errors.last_name)
            }
            helperText={
              profileForm.touched.last_name && profileForm.errors.last_name
            }
            onChange={profileForm.handleChange}
            fullWidth
            autoComplete="family-name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }}>
          <Button
            type="submit"
            variant="contained"
            disabled={authApi.submitting}
          >
            {t("submit")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
