import * as React from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'

interface INameProps {
  newProjectForm: any
}

export default function Name(props: INameProps) {
  const { newProjectForm } = props
  const { t } = useTranslation('projects', {
    keyPrefix: 'list.dialog.name',
  })

  return (
    <Grid container spacing={3} sx={{ pt: 2 }}>
      <Grid item xs={12}>
        <TextField
          required
          id='name'
          name='name'
          label={t('name')}
          fullWidth={true}
          value={newProjectForm.values.name}
          onChange={newProjectForm.handleChange}
          onBlur={newProjectForm.handleBlur}
          error={
            newProjectForm.touched.name && Boolean(newProjectForm.errors.name)
          }
          helperText={newProjectForm.touched.name && newProjectForm.errors.name}
        />
      </Grid>
    </Grid>
  )
}
