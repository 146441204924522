import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store'
import { getIfcModelCreationsLevels } from 'store/entities/projects/ifcModelCreations/ifcModelCreations.slice'
import { MenuItem, Typography } from '@mui/material'
import { IIfcCreationLevelChoice } from 'types'

interface ICreationLevelProps {
  newIfcModelCreationForm: any
}

export default function CreationLevel(props: ICreationLevelProps) {
  const { newIfcModelCreationForm } = props
  const { t } = useTranslation("ifc_model_creations", {
    keyPrefix: "dialogs.create_dialog.creation_level",
  });

  const ifcModelCreationsLevels: IIfcCreationLevelChoice[] = useAppSelector(getIfcModelCreationsLevels);

  const filteredIfcModelCreationsLevels = ifcModelCreationsLevels.filter((level: IIfcCreationLevelChoice) => {
    const givenIndex = ifcModelCreationsLevels.findIndex((item: IIfcCreationLevelChoice) =>
      item.value === newIfcModelCreationForm.values.base_model_file.creation_level
    );
    return ifcModelCreationsLevels.indexOf(level) > givenIndex;
  });

  return (
    <Grid container spacing={3} sx={{ pt: 2 }} alignItems="center" justifyContent="center">
      <Grid item xs={4}>
        {filteredIfcModelCreationsLevels.length > 0 ? (
          <TextField
            select
            required
            id='creation_level'
            name='creation_level'
            label={t('creation_level')}
            fullWidth={true}
            value={newIfcModelCreationForm.values.creation_level}
            onChange={newIfcModelCreationForm.handleChange}
            onBlur={newIfcModelCreationForm.handleBlur}
            error={
              newIfcModelCreationForm.touched.creation_level && Boolean(newIfcModelCreationForm.errors.creation_level)
            }
            helperText={newIfcModelCreationForm.touched.creation_level && newIfcModelCreationForm.errors.creation_level}
          >
            {filteredIfcModelCreationsLevels.map((type: IIfcCreationLevelChoice) => {
              return <MenuItem key={type.value} value={type.value}>{type.label}</ MenuItem>
            })
            }
          </TextField>) : (
          <Typography>
            {t('no_options_available')}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}
