import { createSlice } from "@reduxjs/toolkit";
import type { IIfcCreationLevelChoice, IIfcCreationStatusState, IIfcModelCreation, IIfcModelCreationsOptimizationPriorityChoice } from "types";


type SliceState = {
  list: IIfcModelCreation[];
  options: {
    creation_states: IIfcCreationStatusState[];
    creation_levels: IIfcCreationLevelChoice[];
    creation_optimization_priority_choices: IIfcModelCreationsOptimizationPriorityChoice[];
  };
  loading: {
    isLoading: boolean,
    type: "" | "fetch" | "creation" | "delete";
    content: string,
  };
  lastFetch: null | number;
};

const initialState: SliceState = {
  list: [],
  options: {
    creation_states: [],
    creation_levels: [],
    creation_optimization_priority_choices: [],
  },
  loading: {
    isLoading: false,
    type: "",
    content: "",
  },
  lastFetch: null,
};

const ifcModelCreationsSlice = createSlice({
  name: "ifcModelCreations",
  initialState,
  reducers: {
    ifcModelCreationsRequested: (ifcModelCreations) => {
      ifcModelCreations.loading.isLoading = true;
      ifcModelCreations.loading.type = "fetch";
    },
    ifcModelCreationsReceived: (ifcModelCreations, action) => {
      ifcModelCreations.list = action.payload;
      ifcModelCreations.lastFetch = Date.now();
      ifcModelCreations.loading.isLoading = false;
      ifcModelCreations.loading.type = "";
    },
    ifcModelCreationsRequestFailed: (ifcModelCreations) => {
      ifcModelCreations.loading.isLoading = false;
      ifcModelCreations.loading.type = "";
    },
    ifcModelCreationsCreateRequested: (ifcModelCreations, action) => {
      ifcModelCreations.loading.isLoading = true;
      ifcModelCreations.loading.type = "creation";
      ifcModelCreations.loading.content = action.payload;
    },
    ifcModelCreationsCreateFulfilled: (ifcModelCreations, action) => {
      ifcModelCreations.list.unshift(action.payload);
      ifcModelCreations.loading.isLoading = false;
      ifcModelCreations.loading.content = "";
      ifcModelCreations.loading.type = "";
    },
    ifcModelCreationsCreateFailed: (ifcModelCreations) => {
      ifcModelCreations.loading.isLoading = false;
      ifcModelCreations.loading.content = "";
      ifcModelCreations.loading.type = "";
    },
    ifcModelCreationsDeleteRequested: (ifcModelCreations, action) => {
      ifcModelCreations.loading.isLoading = true;
      ifcModelCreations.loading.type = "delete";
      ifcModelCreations.loading.content = action.payload;
    },
    ifcModelCreationsDeleteFulfilled: (ifcModelCreations, action) => {
      const oldCreation = action.payload;
      const index = ifcModelCreations.list.findIndex(
        (ifcModelCreation) => ifcModelCreation.created_at === oldCreation.created_at
      );
      ifcModelCreations.list.splice(index, 1);
      ifcModelCreations.loading.isLoading = false;
      ifcModelCreations.loading.content = "";
      ifcModelCreations.loading.type = "";
    },
    ifcModelCreationsDeleteFailed: (ifcModelCreations) => {
      ifcModelCreations.loading.isLoading = false;
      ifcModelCreations.loading.content = "";
      ifcModelCreations.loading.type = "";
    },
    ifcModelCreationsStatesRequested: (ifcModelCreations) => {
      ifcModelCreations.loading.isLoading = true;
      ifcModelCreations.loading.type = "fetch";
      ifcModelCreations.loading.content = "creation_states";
    },
    ifcModelCreationsStatesReceived: (ifcModelCreations, action) => {
      ifcModelCreations.options.creation_states = action.payload;
      ifcModelCreations.lastFetch = Date.now();
      ifcModelCreations.loading.isLoading = false;
      ifcModelCreations.loading.type = "";
      ifcModelCreations.loading.content = "";
    },
    ifcModelCreationsStatesRequestFailed: (ifcModelCreations) => {
      ifcModelCreations.loading.isLoading = false;
      ifcModelCreations.loading.type = "";
      ifcModelCreations.loading.content = "";
    },
    ifcModelCreationsLevelsRequested: (ifcModelCreations) => {
      ifcModelCreations.loading.isLoading = true;
      ifcModelCreations.loading.type = "fetch";
      ifcModelCreations.loading.content = "creation_levels";
    },
    ifcModelCreationsLevelsReceived: (ifcModelCreations, action) => {
      ifcModelCreations.options.creation_levels = action.payload;
      ifcModelCreations.lastFetch = Date.now();
      ifcModelCreations.loading.isLoading = false;
      ifcModelCreations.loading.type = "";
      ifcModelCreations.loading.content = "";
    },
    ifcModelCreationsLevelsRequestFailed: (ifcModelCreations) => {
      ifcModelCreations.loading.isLoading = false;
      ifcModelCreations.loading.type = "";
      ifcModelCreations.loading.content = "";
    },
    ifcModelCreationsOptimizationPriorityChoicesRequested: (ifcModelCreations) => {
      ifcModelCreations.loading.isLoading = true;
      ifcModelCreations.loading.type = "fetch";
      ifcModelCreations.loading.content = "creation_optimization_priority_choices";
    },
    ifcModelCreationsOptimizationPriorityChoicesReceived: (ifcModelCreations, action) => {
      ifcModelCreations.options.creation_optimization_priority_choices = action.payload;
      ifcModelCreations.lastFetch = Date.now();
      ifcModelCreations.loading.isLoading = false;
      ifcModelCreations.loading.type = "";
      ifcModelCreations.loading.content = "";
    },
    ifcModelCreationsOptimizationPriorityChoicesRequestFailed: (ifcModelCreations) => {
      ifcModelCreations.loading.isLoading = false;
      ifcModelCreations.loading.type = "";
      ifcModelCreations.loading.content = "";
    },
  },
});

export const {
  ifcModelCreationsRequested,
  ifcModelCreationsReceived,
  ifcModelCreationsRequestFailed,
  ifcModelCreationsCreateRequested,
  ifcModelCreationsCreateFulfilled,
  ifcModelCreationsCreateFailed,
  ifcModelCreationsDeleteRequested,
  ifcModelCreationsDeleteFulfilled,
  ifcModelCreationsDeleteFailed,
  ifcModelCreationsStatesRequested,
  ifcModelCreationsStatesReceived,
  ifcModelCreationsStatesRequestFailed,
  ifcModelCreationsLevelsRequested,
  ifcModelCreationsLevelsReceived,
  ifcModelCreationsLevelsRequestFailed,
  ifcModelCreationsOptimizationPriorityChoicesRequested,
  ifcModelCreationsOptimizationPriorityChoicesReceived,
  ifcModelCreationsOptimizationPriorityChoicesRequestFailed,
} = ifcModelCreationsSlice.actions;
export default ifcModelCreationsSlice.reducer;

export const getIfcModelCreations = (state: any) =>
  state.entities.projects.selectedProject.ifcModelCreations.list;

export const getIfcModelCreationsLoading = (state: any) =>
  state.entities.projects.selectedProject.ifcModelCreations.loading;

export const getIfcModelCreationsStates = (state: any) =>
  state.entities.projects.selectedProject.ifcModelCreations.options.creation_states;

export const getIfcModelCreationsLevels = (state: any) =>
  state.entities.projects.selectedProject.ifcModelCreations.options.creation_levels;

export const getIfcModelOptimizationPriorityChoices = (state: any) =>
  state.entities.projects.selectedProject.ifcModelCreations.options.creation_optimization_priority_choices;