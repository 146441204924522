import React, { useState, useMemo, useEffect } from 'react';
import {
    Box,
    Button,
    CssBaseline,
    Grid,
    IconButton,
    Switch,
    TextField,
    Theme,
    ThemeProvider,
    Typography
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import { IFCCLASSES } from "./ifcClasses";
import { FragmentsGroup } from "bim-fragment";
import { getKeyFromName } from '../ifcConElementAssignment/helpers/helpers';
import { IfcPropertiesUtils } from 'openbim-components';

interface IVisibilityFilterProps {
    theme: Theme;
    model: FragmentsGroup | null;
    allModelExpressIds: number[];
    displayedModelExpressIds: number[];
    showHideItemsById: (show: boolean, expressIds: number[]) => Promise<void>;
}

interface IFilter {
    id: string;
    type: string;
    active: boolean;
}

const VisibilityFilter: React.FC<IVisibilityFilterProps> = ({
    theme,
    model,
    allModelExpressIds,
    displayedModelExpressIds,
    showHideItemsById,
}) => {
    const [filters, setFilters] = useState<IFilter[]>([
        { id: '1', type: "IfcMember", active: false },
        { id: '2', type: "IfcBuildingElementPart", active: false }
    ]);

    const availableIFCClasses = useMemo(() => {
        return IFCCLASSES.filter(ifcClass => !filters.some(filter => filter.type === ifcClass));
    }, [filters]);

    const addFilter = () => {
        const newFilter: IFilter = {
            id: Date.now().toString(),
            type: "",
            active: false
        };
        setFilters([...filters, newFilter]);
    };

    const removeFilter = (id: string) => {
        setFilters(filters.filter(filter => filter.id !== id));
    };

    const updateFilterType = (id: string, newType: string) => {
        setFilters(filters.map(filter =>
            filter.id === id ? { ...filter, type: newType } : filter
        ));
    };

    const toggleFilterActive = (id: string) => {
        setFilters(filters.map(filter =>
            filter.id === id ? { ...filter, active: !filter.active } : filter
        ));
    };

    const disableAllFilters = () => {
        setFilters(filters.map(filter => ({ ...filter, active: false })));
    };

    const enableAllFilters = () => {
        setFilters(filters.map(filter =>
            filter.type !== ""
                ? { ...filter, active: true }
                : filter
        ));
    };


    const filterElements = async () => {
        if (model) {
            let ifcExpressIdsToFilter: number[] = [];
            let hasActiveFilters = false;
            filters.map((filter) => {
                if (model.properties && filter.active && filter.type !== "") {
                    hasActiveFilters = true;
                    const key = getKeyFromName(filter.type.toUpperCase());
                    if (key !== null) {
                        const elements = IfcPropertiesUtils.getAllItemsOfType(model.properties, key);
                        elements.forEach((element) => { if (element.expressID) ifcExpressIdsToFilter.push(Number(element.expressID)); });
                    }
                }
            });
            if (ifcExpressIdsToFilter.length === 0 && !hasActiveFilters) {
                await showHideItemsById(true, allModelExpressIds);
                return
            }
            await showHideItemsById(false, allModelExpressIds);
            await showHideItemsById(true, ifcExpressIdsToFilter);
        }
    }

    useEffect(() => {
        filterElements();
    }, [filters]);

    useEffect(() => {
        if (displayedModelExpressIds.length === allModelExpressIds.length) {
            disableAllFilters();
        }
    }, [displayedModelExpressIds, allModelExpressIds]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme />
            <Box
                sx={{
                    color: "white",
                    width: "500px",
                }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        <Button startIcon={<FilterAltOffIcon />} variant="outlined" onClick={disableAllFilters}>Disable all</Button>
                        <Button startIcon={<FilterAltIcon />} variant="contained" onClick={enableAllFilters}>Filter All</Button>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6">IFC Type Filters</Typography>
                        <Button
                            startIcon={<AddIcon />}
                            variant="outlined"
                            onClick={addFilter}
                        >
                            Add Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {filters.map((filter) => (
                            <Grid item xs={12} key={filter.id} sx={{
                                border: '1px solid',
                                borderColor: (theme: Theme) => theme.palette.divider,
                                backgroundColor: (theme: Theme) => theme.palette.background.default,
                                p: 1
                            }}>
                                <Grid container spacing={1}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    <Grid item xs={8}>
                                        <Autocomplete
                                            id={filter.id}
                                            value={filter.type}
                                            onChange={(_event, _newValue) => {
                                                // This onChange is still needed to update the input value
                                                // but it won't update the filter state
                                            }}
                                            onBlur={(event) => {
                                                const inputValue = (event.target as HTMLInputElement).value;
                                                updateFilterType(filter.id, inputValue);
                                            }}
                                            options={[...availableIFCClasses, filter.type]}
                                            renderInput={(params) => <TextField {...params} id={filter.id} label="IFC Type" />}
                                            freeSolo
                                        />
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                        <IconButton aria-label="delete" onClick={() => removeFilter(filter.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <Switch
                                            checked={filter.active}
                                            onClick={() => toggleFilterActive(filter.id)}
                                            disabled={filter.type === ""}
                                            color="primary"
                                            icon={<FilterAltOffIcon />}
                                            checkedIcon={<FilterAltIcon />}
                                            inputProps={{ 'aria-label': 'filter toggle' }}
                                        />
                                        <Box sx={{ flexGrow: 1 }} />
                                        <Typography variant="body1">
                                            {filter.active ? 'Active' : 'Inactive'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
}

export default VisibilityFilter;