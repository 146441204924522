import { AppBar, IconButton, Toolbar, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import CloseIcon from "@mui/icons-material/Close";

interface topNavigationProps {
    handleDialogClose: () => void;
    titleTranslationPrefix: string;
    closeTranslationPrefix: string;
}

const appBarStyle = {
    height: 50,
};

const TopNavigation: React.FC<topNavigationProps> = ({
    handleDialogClose,
    titleTranslationPrefix,
    closeTranslationPrefix,
}) => {
    const { t } = useTranslation("component_catalogue", {
        keyPrefix: "library",
    });

    return (
        <>
            <AppBar
                sx={{
                    position: "fixed",
                    zIndex: 1400,
                    display: "flex",
                    justifyContent: "center",
                }}
                color="inherit"
                style={appBarStyle}
            >
                <Toolbar>
                    <Tooltip title={t(closeTranslationPrefix)}>
                        <IconButton
                            edge="start"
                            aria-label="close"
                            onClick={handleDialogClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {t(titleTranslationPrefix)}
                    </Typography>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default TopNavigation;