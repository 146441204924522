import React from 'react'
import ElementCreateDialog from './create/createDialog'
import SelectionDialog from './selection/selection'
import { useAppSelector } from 'store';
import { getPersonalLibraryDialogs } from 'store/ui/projects/componentCatalogue/componentCatalogue.slice';
import ElementDeleteDialog from '../../dialogs/element/deleteDialog';

const Dialogs: React.FC = () => {
    const selection = useAppSelector(getPersonalLibraryDialogs).selection;

    return (
        <>
            <ElementCreateDialog />
            <ElementDeleteDialog />
            {selection ? <SelectionDialog /> : null}
        </>
    )
}

export default Dialogs
