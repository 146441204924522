import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import { TransitionProps } from '@mui/material/transitions'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Slide from '@mui/material/Slide'
import Zoom from '@mui/material/Zoom'
import FileUploadForm from './form'
import { useAppDispatch, useAppSelector } from 'store'
import { getUploadedFilesDialogs, handleAddUploadedFilesDialogClose } from 'store/ui/projects/uploadedFiles/uploadedFiles.slice'
import { addUploadedFile } from 'store/entities/projects/uploadedFiles/uploadedFiles.actions'
import { useParams } from 'react-router-dom'
import { IFileValidationResponse } from 'types'
import { validateFile } from './validation'
import uuid from 'react-uuid';
import { useIndexedDB } from 'services/indexeddb'
import { IndexedDBConfig } from 'services/indexeddb/indexeddbConfig'
import { fileToSha256Hex } from 'common/utils/helpers/getFileHash.helpers'


const ZoomTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />
})

const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />
})

interface IProps {

}

const UploadedFileUploadDialog: React.FC<IProps> = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation('uploaded_files', {
    keyPrefix: 'dialogs.add_dialog',
  })
  const { add } = useIndexedDB("files");

  const [files, setFiles] = useState<File[]>([]);

  const open = useAppSelector(getUploadedFilesDialogs).addUploadedFile
  const { projectId } = useParams();

  const handleDialogClose = () => {
    dispatch(handleAddUploadedFilesDialogClose());
    setFiles([])
  }

  const handleDialogUpload = () => {
    handleDialogClose()
    files.forEach((file, fileIndex) => {
      const validation: IFileValidationResponse = validateFile(file, fileIndex);
      if (validation.valid) {
        if (projectId !== undefined) {
          const fileUuid = uuid()
          let fileSha256HexHash = ""

          fileToSha256Hex(file)
            .then((hash) => {
              fileSha256HexHash = hash
              dispatch(addUploadedFile(+projectId, fileUuid, file, fileSha256HexHash))

              add({
                name: file.name,
                uuid: fileUuid,
                file: file,
                expiryDate: new Date().setDate(new Date().getDate() + IndexedDBConfig.expirationDeltaDays),
              }).then(
                (event) => {
                  console.log(`Successfully created new entry in indexedDB for ${file.name} with uuid ${fileUuid}`);
                },
                (error) => {
                  console.log(error);
                },
              );
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    })
    setFiles([])
  }

  return (
    <Container>
      <Dialog
        fullScreen={matches ? false : true}
        open={open}
        TransitionComponent={matches ? ZoomTransition : SlideTransition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby='dialog-slide-edit'
      >
        <form
          id='file-upload-form'
        >
          {matches ? (
            <DialogTitle>
              {t('dialog_title')}
            </DialogTitle>
          ) : (
            <AppBar sx={{ position: 'relative' }} color='inherit'>
              <Toolbar>
                <IconButton
                  edge='start'
                  color='inherit'
                  onClick={handleDialogClose}
                  aria-label='cancel'
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                  {t('dialog_title')}
                </Typography>
                <Button
                  autoFocus
                  color='inherit'
                  onClick={handleDialogUpload}
                  id='element-create-form'
                >
                  {t('upload')}
                </Button>
              </Toolbar>
            </AppBar>
          )}
          <DialogContent>
            <FileUploadForm setFiles={setFiles} files={files} />
          </DialogContent>
          <DialogActions>
            {matches && (
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: '100%' }}>
                <Button onClick={handleDialogClose}>{t("cancel")}</Button>
                <Button onClick={handleDialogUpload}>{t('upload')}</Button>
              </Box>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  )
}

export default UploadedFileUploadDialog
