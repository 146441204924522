import { apiRequest } from "common/utils/constants/api.constants";
import * as uploadedFilesSlice from "./uploadedFiles.slice";
import * as uploadedFilesEndpoints from "assets/endpoints/api/projects/uploadedFilesEndpoints";
import http from "services/httpService";
import { AppDispatch } from "store";
import { IndexedDBConfig } from "services/indexeddb/indexeddbConfig";

export const fetchUploadedFiles = (projectId: number) =>
  apiRequest({
    url: uploadedFilesEndpoints.getUploadedFilesListUrl(projectId),
    onStart: uploadedFilesSlice.uploadedFilesRequested.type,
    onSuccess: uploadedFilesSlice.uploadedFilesReceived.type,
    onError: uploadedFilesSlice.uploadedFilesRequestFailed.type,
  });

export const addUploadedFile = (
  projectId: number,
  uuid: string,
  file: File,
  fileSha256HexHash: string,
) =>
  apiRequest({
    url: uploadedFilesEndpoints.getUploadedFilesListUrl(projectId),
    method: "post",
    data: { file: file, uuid: uuid, frontend_file_sha256_hex_hash: fileSha256HexHash },
    headers: {
      'content-type': 'multipart/form-data'
    },
    onStart: uploadedFilesSlice.uploadedFilesAddRequested.type,
    onStartPayload: file.name ? file.name : null,
    onSuccess: uploadedFilesSlice.uploadedFileAddFulfilled.type,
    onError: uploadedFilesSlice.uploadedFilesAddFailed.type,
  });

export const deleteUploadedFile = (projectId: number, uploadedFileId: number, uploadedFileName: string) =>
  apiRequest({
    url: uploadedFilesEndpoints.getUploadedFilesItemUrl(projectId, uploadedFileId),
    method: "delete",
    onStart: uploadedFilesSlice.uploadedFilesDeleteRequested.type,
    onStartPayload: uploadedFileName,
    onSuccess: uploadedFilesSlice.uploadedFileDeleteFulfilled.type,
    onError: uploadedFilesSlice.uploadedFilesDeleteFailed.type,
  });

export const downloadUploadedFile = (project_id: number, file_id: number, fileName: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: uploadedFilesSlice.uploadedFileDownloadRequested.type });
      const url = uploadedFilesEndpoints.getUploadedFilesItemDownloadUrl(project_id, file_id)
      await http.get(
        url,
        {
          responseType: "blob",
        }
      ).then(response => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type })
        const file_url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = file_url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(file_url);
        dispatch({
          type: uploadedFilesSlice.uploadedFileDownloadReceived.type,
        });
      })
    } catch (error) {
      dispatch({ type: uploadedFilesSlice.uploadedFileDownloadRequestFailed.type });
      console.error('Error downloading file:', error);
    }
  }
}

export const downloadUploadedFileToIndexedDB = (project_id: number, file_id: number, file_name: string, file_uuid: string, add: any) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: uploadedFilesSlice.uploadedFileDownloadRequested.type });
      const url = uploadedFilesEndpoints.getUploadedFilesItemDownloadUrl(project_id, file_id)
      await http.get(
        url,
        {
          responseType: "blob",
        }
      ).then(response => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type })
        const file = new File([blob], file_name, { lastModified: new Date().getTime(), type: blob.type })
        add({
          name: file_name,
          uuid: file_uuid,
          file: file,
          expiryDate: new Date().setDate(new Date().getDate() + IndexedDBConfig.expirationDeltaDays),
        }).then(
          () => {
            console.log(`Successfully created new entry in indexedDB for ${file.name} with uuid ${file_uuid}`);
          },
          (error: any) => {
            console.log(error);
          },
        );
        dispatch({
          type: uploadedFilesSlice.uploadedFileDownloadReceived.type,
        });
      })
    } catch (error) {
      dispatch({ type: uploadedFilesSlice.uploadedFileDownloadRequestFailed.type });
      console.error('Error downloading file:', error);
    }
  }
}