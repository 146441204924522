import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";

import { useAppDispatch, useAppSelector } from "store";
import { getApi } from "store/entities/authentication/authentication.slice";
import { setPassword } from "store/entities/authentication/authentication.actions";

export default function Password() {
  const { t } = useTranslation("settings", { keyPrefix: "security" });
  const dispatch = useAppDispatch();

  const authApi = useAppSelector(getApi);

  const [showPassword, setShowPassword] = useState({
    current_password: false,
    new_password: false,
  });

  const handleClickShowPassword = (key: keyof typeof showPassword) => {
    setShowPassword({ ...showPassword, [key]: !showPassword[key] });
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [initialValues, setInitialValues] = useState({
    current_password: "",
    new_password: "",
    re_new_password: "",
  });

  const validationSchema = yup.object({
    current_password: yup.string().required(t("required") as string),
    new_password: yup
      .string()
      .required(t("required") as string)
      .min(
        8,
        t("password_must_contain_at_least", {
          min_number: 8,
        }) as string
      )
      .matches(
        /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{6,20}$/,
        t("password_must_contain_number_and_letter") as string
      ),
    re_new_password: yup
      .string()
      .required(t("required") as string)
      .oneOf([yup.ref("new_password")], t("passwords_must_match") as string),
  });

  const passwordForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      dispatch(setPassword(values));
      setInitialValues({
        current_password: "",
        new_password: "",
        re_new_password: "",
      });
    },
  });

  return (
    <Grid
      container
      spacing={2}
      component="form"
      id="set-password-form"
      noValidate
      onSubmit={passwordForm.handleSubmit}
    >
      <Grid item xs={12}>
        <Typography
          color="text.primary"
          align="left"
          sx={{ fontWeight: "bold" }}
        >
          {t("change_password")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="current-password"
          variant="outlined"
          name="current_password"
          value={passwordForm.values.current_password}
          onChange={passwordForm.handleChange}
          label={t("current_password")}
          onBlur={passwordForm.handleBlur}
          error={
            passwordForm.touched.current_password &&
            Boolean(passwordForm.errors.current_password)
          }
          helperText={
            passwordForm.touched.current_password &&
            Boolean(passwordForm.errors.current_password)
          }
          type={showPassword.current_password ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle current password visibility"
                  onClick={() => handleClickShowPassword("current_password")}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword.current_password ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="new-password"
          variant="outlined"
          name="new_password"
          value={passwordForm.values.new_password}
          onChange={passwordForm.handleChange}
          label={t("new_password")}
          onBlur={passwordForm.handleBlur}
          error={
            passwordForm.touched.new_password &&
            Boolean(passwordForm.errors.new_password)
          }
          helperText={
            passwordForm.touched.new_password &&
            Boolean(passwordForm.errors.new_password)
          }
          type={showPassword.new_password ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle new password visibility"
                  onClick={() => handleClickShowPassword("new_password")}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword.new_password ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="re-new-password"
          variant="outlined"
          name="re_new_password"
          value={passwordForm.values.re_new_password}
          onChange={passwordForm.handleChange}
          label={t("confirm_new_password")}
          onBlur={passwordForm.handleBlur}
          error={
            passwordForm.touched.re_new_password &&
            Boolean(passwordForm.errors.re_new_password)
          }
          helperText={
            passwordForm.touched.re_new_password &&
            Boolean(passwordForm.errors.re_new_password)
          }
          type={showPassword.new_password ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle retype new password visibility"
                  onClick={() => handleClickShowPassword("new_password")}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword.new_password ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          color="text.secondary"
          align="left"
          sx={{ fontWeight: "bold" }}
        >
          {t("password_requirements")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="text.secondary" variant="body2" gutterBottom>
          {t("requirement_one")}
        </Typography>
        <Typography color="text.secondary" variant="body2" gutterBottom>
          {t("requirement_two")}
        </Typography>
        <Typography color="text.secondary" variant="body2" gutterBottom>
          {t("requirement_three")}
        </Typography>
        <Typography color="text.secondary" variant="body2" gutterBottom>
          {t("requirement_four")}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }}>
        <Button variant="text" sx={{ mr: 3 }}>
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          type="submit"
          form="set-password-form"
          disabled={authApi.submitting}
        >
          {t("submit")}
        </Button>
      </Grid>
    </Grid>
  );
}
