import React, { createContext, useContext } from 'react';
import { IPatternEntry, patterns } from './patterns';
import { IConstructionMaterial } from 'types';

interface IPatternSvgComponent {
    patternSvg: IPatternEntry;
    patternSvgWidth: number;
    patternSvgHeight: number;
}

interface PatternContextType {
    getPatternSvg: (material: IConstructionMaterial["visual_representation_category"]) => IPatternSvgComponent;
    strokeWidth: number;
    strokeColor: string;
}

const PatternContext = createContext<PatternContextType | undefined>(undefined);

export const usePatternContext = () => {
    const context = useContext(PatternContext);
    if (!context) {
        throw new Error('usePatternContext must be used within a PatternProvider');
    }
    return context;
};

interface PatternProviderProps {
    strokeWidth: number;
    strokeColor: string;
    children: any;
}

export const PatternProvider: React.FC<PatternProviderProps> = ({
    strokeWidth,
    strokeColor,
    children,
}) => {
    const getPatternSvg = (material: IConstructionMaterial["visual_representation_category"]): IPatternSvgComponent => {
        return {
            patternSvg: patterns[material],
            patternSvgWidth: 100,
            patternSvgHeight: 200
        };
    };

    const patternContextValue: PatternContextType = {
        getPatternSvg,
        strokeWidth,
        strokeColor
    };

    return (
        <PatternContext.Provider value={patternContextValue}>
            {children}
        </PatternContext.Provider>
    );
};
