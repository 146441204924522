import React from 'react'
import { Box, Fab, Stack, Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store';
import { getActions, openElementCreateDialog, openElementSelectionDialog } from 'store/ui/projects/componentCatalogue/componentCatalogue.slice';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import BottomNavigation from './bottomNavigation';

const Actions: React.FC = () => {
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "selected_element.actions",
  });
  const dispatch = useAppDispatch();

  const show = useAppSelector(getActions).show;

  const handleCreateNew = () => {
    dispatch(openElementCreateDialog());
  };

  const handleSelection = () => {
    dispatch(openElementSelectionDialog());
  };

  return (
    <BottomNavigation
    >
      {show ? (
        <Stack direction="row" spacing={1}>
          <Tooltip title={t("select_descr")}>
            <Fab color="secondary" size="large" variant="extended" onClick={handleSelection}>
              {t("select")}
            </Fab>
          </Tooltip>
          <Tooltip title={t("create")}>
            <Fab color="primary" aria-label="cancel" size="medium" onClick={handleCreateNew}>
              <AddIcon />
            </Fab>
          </Tooltip>
        </Stack>
      ) : (null)}
    </BottomNavigation>
  );
}

export default Actions
