import { createSlice } from "@reduxjs/toolkit";
import type { IInvitationStatusOption, IRoleOption, IStakeholder } from "types";

type SliceState = {
  list: IStakeholder[];
  options: {
    invitationStatus: IInvitationStatusOption[];
    roles: IRoleOption[];
  };
  me: {};
  loading: boolean;
  lastFetch: null | number;
};

const initialState: SliceState = {
  list: [],
  options: {
    invitationStatus: [],
    roles: [],
  },
  me: {},
  loading: false,
  lastFetch: null,
};

const stakeholdersSlice = createSlice({
  name: "stakeholders",
  initialState,
  reducers: {
    stakeholdersRequested: (stakeholders) => {
      stakeholders.loading = true;
    },
    stakeholdersReceived: (stakeholders, action) => {
      stakeholders.list = action.payload;
      stakeholders.lastFetch = Date.now();
      stakeholders.loading = false;
    },
    stakeholdersRequestFailed: (stakeholders) => {
      stakeholders.loading = false;
    },
    myProfileReceived: (stakeholders, action) => {
      const myProfileIndex = stakeholders.list.findIndex(
        (stakeholder: IStakeholder) => stakeholder.id === action.payload
      );
      stakeholders.me = stakeholders.list[myProfileIndex];
    },
    invitationStatusReceived: (stakeholders, action) => {
      stakeholders.options.invitationStatus = action.payload;
    },
    rolesReceived: (stakeholders, action) => {
      stakeholders.options.roles = action.payload;
    },
    stakeholderAdded: (stakeholders, action) => {
      stakeholders.list.push(action.payload);
    },
    stakeholderUpdated: (stakeholders, action) => {
      const newStakeholder = action.payload;
      const updatedList = stakeholders.list.map((stakeholder) =>
        stakeholder.id === newStakeholder.id ? newStakeholder : stakeholder
      );
      stakeholders.list = updatedList;
    },
    stakeholderDeleted: (stakeholders, action) => {
      const oldStakeholder = action.payload;
      const index = stakeholders.list.findIndex(
        (stakeholder) => stakeholder.id === oldStakeholder.id
      );
      stakeholders.list.splice(index, 1);
    },
  },
});

export const {
  stakeholdersRequested,
  stakeholdersReceived,
  stakeholdersRequestFailed,
  stakeholderAdded,
  stakeholderUpdated,
  stakeholderDeleted,
  rolesReceived,
  invitationStatusReceived,
  myProfileReceived,
} = stakeholdersSlice.actions;
export default stakeholdersSlice.reducer;

export const getStakeholders = (state: any) =>
  state.entities.projects.selectedProject.stakeholders.list;

export const getMyProfile = (state: any) =>
  state.entities.projects.selectedProject.stakeholders.me;

export const getRoles = (state: any) =>
  state.entities.projects.selectedProject.stakeholders.options.roles;

export const getInvitationStatus = (state: any) =>
  state.entities.projects.selectedProject.stakeholders.options.invitationStatus;

export const getLoading = (state: any) =>
  state.entities.projects.selectedProject.stakeholders.loading;
