import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenterOutlined";

import {
  getAccountUrl,
  getAppUrl,
  getPaymentUrl,
  getProfileUrl,
  getSecurityUrl,
  getSettingsUrl,
} from "./endpoints/app/settingsEndpoints";

export const menu = [
  {
    id: 1,
    name: "projects",
    icon: BusinessCenterIcon,
    url: "/projects",
  },
  {
    id: 2,
    name: "settings",
    icon: SettingsIcon,
    url: "/settings",
  },
];

export const projectTabs = [
  {
    id: 1,
    name: "dashboard",
    url: "dashboard",
    permissions: ["Project read only", "Project maintainer"],
  },
  {
    id: 2,
    name: "stakeholders",
    url: "stakeholders",
    permissions: [
      "Project stakeholder read only",
      "Project stakeholder maintainer",
    ],
  },
  {
    id: 3,
    name: "component_catalogue",
    url: "component-catalogue",
    permissions: [
      "Project component catalogue read only",
      "Project component catalogue maintainer",
    ],
  },
  {
    id: 4,
    name: "uploaded_files",
    url: "uploaded-files",
    permissions: [
      "Project uploaded files read only",
      "Project uploaded files maintainer",
    ],
  },
  {
    id: 5,
    name: "ifc_viewer",
    url: "ifc-viewer",
    permissions: [
      //"Project ifc viewer read only",
    ],
  },
  {
    id: 6,
    name: "ifc_model_creations",
    url: "ifc-model-creations",
    permissions: [
      "Project ifc model creations read only",
      "Project ifc model creations maintainer",
    ],
  },
];

export const settingsTabs = [
  {
    id: 1,
    name: "app",
    url: getSettingsUrl() + getAppUrl(),
  },
  {
    id: 2,
    name: "profile",
    url: getSettingsUrl() + getProfileUrl(),
  },
  // {
  //   id: 3,
  //   name: 'account',
  //   url: getSettingsUrl() + getAccountUrl(),
  // },
  // {
  //   id: 4,
  //   name: 'payments',
  //   url: getSettingsUrl() + getPaymentUrl(),
  // },
  {
    id: 5,
    name: "security",
    url: getSettingsUrl() + getSecurityUrl(),
  },
];
