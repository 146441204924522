import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import ProjectUpdateForm from "./form";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import {
  closeProjectUpdateDialog,
  getProjectDialogs,
} from "store/ui/projects/dashboard/dashboard.ui.slice";
import { getSelectedProject } from "store/entities/projects/project.slice";
import type { IProjectUpdateForm } from "types";
import { updateProject } from "store/entities/projects/projects.slice";

const ProjectUpdateDialog: React.FC = () => {
  const { t } = useTranslation("dashboard", {
    keyPrefix: "project.dialogs.update",
  });

  const { projectId } = useParams();
  const open = useAppSelector(getProjectDialogs).update;
  const project = useAppSelector(getSelectedProject);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeProjectUpdateDialog());
    projectForm.resetForm();
  };

  useEffect(() => {
    setInitialValues({
      name: project.name,
      status: project.status,
      bookmark: project.bookmark,
      construction_site: {
        street: project.construction_site.street,
        city: project.construction_site.city,
        postal_code: project.construction_site.postal_code,
        country: project.construction_site.country,
      },
    });
  }, [project]);

  const [initialValues, setInitialValues] = useState<IProjectUpdateForm>({
    name: "",
    status: "",
    bookmark: false,
    construction_site: {
      street: "",
      city: "",
      postal_code: "",
      country: "",
    },
  });

  const validationSchema = yup.object().shape({
    name: yup.string(),
    status: yup.string(),
    bookmark: yup.string(),
    construction_site: yup.object().shape({
      street: yup.string(),
      city: yup.string(),
      postal_code: yup.string(),
      country: yup.string(),
    }),
  });

  const projectForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      if (projectId !== undefined) {
        dispatch(updateProject(+projectId, values));
        dispatch(closeProjectUpdateDialog());
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="project-update-dialog"
      maxWidth="sm"
    >
      <DialogTitle style={{ cursor: "move" }} id="project-update-dialog">
        {t("edit_project")}
      </DialogTitle>
      <DialogContent>
        <ProjectUpdateForm projectForm={projectForm} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {t("cancel")}
        </Button>
        <Button type="submit" form="project-update-form">
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectUpdateDialog;
