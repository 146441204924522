import { useState } from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import TuneIcon from "@mui/icons-material/TuneOutlined";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from "store";
import { closeElementSelectionDialog, getPersonalLibraryProjectId, getSelectedElements, openElementDeleteDialog, openPersonalLibrary } from "store/ui/projects/componentCatalogue/componentCatalogue.slice";
import { Fab, Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { clearElementFromList, createElement, createLayer, createLayerFilling, updateElement } from "store/entities/projects/componentCatalogue/componentCatalogue.actions";
import { ILayerFilling } from "types";

const Menu = () => {
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "selected_element.dialogs.element.selection",
  });

  const { projectId } = useParams();

  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const personalLibraryProjectId = useAppSelector(getPersonalLibraryProjectId);
  const selectedElements = useAppSelector(getSelectedElements);

  const handleElementCopy = async (targetProjectId: number, toPersonalLibrary: boolean) => {
    for (const element of selectedElements) {
      try {
        const created_element: any = await dispatch(createElement(targetProjectId, element));
        const updated_element: any = await dispatch(updateElement(targetProjectId, created_element.id, element));

        if (toPersonalLibrary) {
          dispatch(clearElementFromList(created_element));
        }

        for (const layer of element.layers) {
          try {
            const created_layer: any = await dispatch(createLayer(targetProjectId, created_element.id, {
              ...layer,
              position: element.layers.length + 1
            }));
            layer.layer_fillings.map(async (filling: ILayerFilling) => {
              if (created_layer.layers && created_layer.layers.length !== 0) {
                const newLayer_id = created_layer.layers.at(-1).id;
                const created_layer_filling: any = await dispatch(createLayerFilling(targetProjectId, created_element.id, newLayer_id, filling));
              }
            });
          } catch (error) {
            console.error(`Duplicate Layer: ${layer.id} failed:`, error);
          }
        }
      } catch (error) {
        console.error(`Copy/Duplicate Construction-Element: ${element.id} failed:`, error);
      }
    }
    dispatch(closeElementSelectionDialog());

    if (toPersonalLibrary && personalLibraryProjectId !== null) {
      dispatch(openPersonalLibrary());
      navigate("/projects/" + personalLibraryProjectId + "/component-catalogue/personal-library");
    }
  };

  const handleCloseButtonClicked = () => {
    dispatch(closeElementSelectionDialog());
  };

  const handleCopyToPersonalLibraryClicked = () => {
    if (personalLibraryProjectId !== null) {
      handleElementCopy(+personalLibraryProjectId, true);
    }
  };

  const handleDuplicateClicked = () => {
    if (projectId !== undefined) {
      handleElementCopy(+projectId, false);
    }
  };

  const handleDeleteClicked = () => {
    dispatch(openElementDeleteDialog());
  };

  const actions = [
    {
      icon: <MoveUpIcon />,
      name: t("copy_to_project"),
      action: handleCopyToPersonalLibraryClicked,
      hide: personalLibraryProjectId === null,
    },
    {
      icon: <ContentCopyIcon />,
      name: t("duplicate"),
      action: handleDuplicateClicked,
      hide: projectId === undefined,
    },
    {
      icon: <DeleteIcon />,
      name: t("delete"),
      action: handleDeleteClicked,
      hide: projectId === undefined,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Tooltip title={t("cancel_descr")}>
        <Fab
          color="secondary"
          size="large"
          variant="extended"
          onClick={handleCloseButtonClicked}
          sx={{ alignSelf: "flex-end", mr: 1 }}
        >
          {t("cancel")}
        </Fab>
      </Tooltip>
      <SpeedDial
        ariaLabel={t("speed_dial_label")}
        icon={<TuneIcon />}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        FabProps={{ size: "medium" }}
      >
        {actions.map(
          (action) =>
            !action.hide && (
              <SpeedDialAction
                FabProps={{ disabled: selectedElements.length === 0 }}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.action}
              />
            )
        )}
      </SpeedDial>
    </Box>
  );
};

export default Menu;
