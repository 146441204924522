import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Dispatch, SetStateAction, useRef } from "react";
import { useTranslation } from "react-i18next";

interface FilterProps {
    nameFilter: string
    setNameFilter: Dispatch<SetStateAction<string>>;
}

const GeneralFilter: React.FC<FilterProps> = ({ nameFilter, setNameFilter }) => {
    const { t } = useTranslation("component_catalogue", {
        keyPrefix: "library.filter.sections.general",
    });

    const inputRef = useRef<HTMLInputElement>(null);

    const handleTextFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNameFilter(event.target.value);
        if (inputRef.current) {
            inputRef.current.focus();
        };
    }

    return (
        <Box
            sx={{ display: "flex", flexDirection: "row" }}
        >
            <TextField
                inputRef={inputRef}
                sx={{ width: 200 }}
                size="small"
                id="outlined-search-field"
                label={t("name_filter")}
                value={nameFilter}
                onChange={handleTextFilterChange}
            />
        </Box>
    );
}

export default GeneralFilter;
