import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tab, Tabs } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface IIfcGroupCategory {
    id: number;
    name: string;
    ifcCategories: string[];
}

const IfcGroupedCategories: IIfcGroupCategory[] = [
    {
        id: 0,
        name: "Walls",
        ifcCategories: ["IFCWALL", "IFCWALLSTANDARDCASE"],
    },
]

interface IAssignmentTabs {
    setIfcElementCategories: Dispatch<SetStateAction<string[]>>;
}

const CategorySelect: React.FC<IAssignmentTabs> = ({
    setIfcElementCategories
}) => {
    const [selectedCategory, setSelectedCategory] = useState(IfcGroupedCategories[0].name);

    const selectMenuItems = IfcGroupedCategories.map((element: any) => <MenuItem key={element.id} value={element.name}>{element.name}</MenuItem>)

    const handleSelectCategoryChange = (event: SelectChangeEvent) => {
        setSelectedCategory(event.target.value);
        IfcGroupedCategories.map((element: IIfcGroupCategory) => {
            if (event.target.value === element.name) {
                setIfcElementCategories(element.ifcCategories);
            }
        })
    };

    useEffect(() => {
        setIfcElementCategories(IfcGroupedCategories[0].ifcCategories);
    }, []);

    return (
        <FormControl variant="filled" sx={{ mt: 1, minWidth: 250 }}>
            <InputLabel id="demo-multiple-checkbox-label">Category</InputLabel>
            <Select
                labelId="assignmentCategorySelect"
                id="assignmentCategorySelect"
                onChange={handleSelectCategoryChange}
                value={selectedCategory}
            >
                {selectMenuItems}
            </Select>
        </FormControl>
    );
}

export default CategorySelect;