import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { Dispatch, SetStateAction } from "react";
import CategorySelect from './select/categorySelect';
import AssignmentSelect from './select/assignmentSelect';
import { properties } from '../../properties/properties';
import { IIfcConElementAssignmentError } from '../ifcConElementAssignment';

interface IActions {
    tableSelection: GridRowSelectionModel;
    setTableSelection: Dispatch<SetStateAction<GridRowSelectionModel>>;
    setIfcElementCategories: Dispatch<SetStateAction<string[]>>;
    getModelPropertiesByExpressId: (expressId: number) => properties[] | null;
    project_id: number;
    setIfcConElementAssignmentErrors: Dispatch<SetStateAction<IIfcConElementAssignmentError[]>>;
    ifcConElementAssignmentErrors: IIfcConElementAssignmentError[];
}

const Actions: React.FC<IActions> = ({
    tableSelection,
    setTableSelection,
    setIfcElementCategories,
    getModelPropertiesByExpressId,
    project_id,
    setIfcConElementAssignmentErrors,
    ifcConElementAssignmentErrors
}) => {

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
            }}
        >
            <Box sx={{}}>
                <Typography variant="h6">Category</Typography>
                <CategorySelect setIfcElementCategories={setIfcElementCategories} />
            </Box>

            <Box sx={{}}>
                <Typography variant="h6">Assign selected to</Typography>
                <AssignmentSelect
                    tableSelection={tableSelection}
                    setTableSelection={setTableSelection}
                    getModelPropertiesByExpressId={getModelPropertiesByExpressId}
                    project_id={project_id}
                    setIfcConElementAssignmentErrors={setIfcConElementAssignmentErrors}
                    ifcConElementAssignmentErrors={ifcConElementAssignmentErrors}
                />
            </Box>
        </Box >
    );
}

export default Actions;
