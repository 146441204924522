import React from "react";
import cookies from "js-cookie";
import IconButton from "@mui/material/IconButton";
import i18next from "i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import TranslateSharpIcon from "@mui/icons-material/TranslateSharp";
import { useTranslation } from "react-i18next";

import supportedLanguages from "assets/languages";
import {
  getLocales,
  selectLanguage,
} from "store/entities/settings/settings.slice";
import { useAppDispatch, useAppSelector } from "store";
import {
  closeLanguageMenu,
  getMenus,
  openLanguageMenu,
} from "store/ui/navigation/navigation.slice";

interface LanguageMenuProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

export default function LanguageMenu(props: LanguageMenuProps) {
  const { anchorEl, setAnchorEl } = props;
  const { t } = useTranslation("navigation", { keyPrefix: "appbar.language" });
  const dispatch = useAppDispatch();
  const languageMenu = useAppSelector(getMenus).language;
  const locales = useAppSelector(getLocales);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(openLanguageMenu());
    setAnchorEl(event.currentTarget);
  };

  return (
    <React.Fragment>
      <Tooltip title={t<string>("language_settings")}>
        <IconButton
          aria-label="locales"
          color="inherit"
          sx={{ ml: 2 }}
          onClick={handleMenuOpen}
          size="large"
          aria-controls={languageMenu.open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={languageMenu.open ? "true" : undefined}
        >
          <TranslateSharpIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={languageMenu.open}
        onClose={() => {
          dispatch(closeLanguageMenu());
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {supportedLanguages.map((language: any) => (
          <MenuItem
            key={language.code}
            disabled={language.code === "none"}
            selected={language.code === locales.code}
            onClick={() => {
              dispatch(closeLanguageMenu());
              dispatch(selectLanguage(language));
            }}
          >
            {language.language}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
