import React from 'react'
import ProjectDeleteDialog from './deleteDialog'
import ProjectUpdateDialog from './updateDialog/updateDialog'

const Dialogs: React.FC = () => {
  return (
    <>
      <ProjectUpdateDialog />
      <ProjectDeleteDialog />
    </>
  )
}

export default Dialogs
