import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Zoom from "@mui/material/Zoom";
import { TransitionProps } from "@mui/material/transitions";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getUploadedFilesDialogs, handleDeleteUploadedFilesDialogClose } from "store/ui/projects/uploadedFiles/uploadedFiles.slice";
import { getSelectedUploadedFile } from "store/ui/projects/uploadedFiles/uploadedFiles.slice";
import { deleteUploadedFile } from "store/entities/projects/uploadedFiles/uploadedFiles.actions";
import { useIndexedDB } from "services/indexeddb";
import { IIndexedDBFiles } from "services/indexeddb/indexeddbConfig";

const ZoomTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

const UploadedFileDeleteDialog: React.FC = () => {
  const { projectId } = useParams();
  const { t } = useTranslation("uploaded_files", {
    keyPrefix: "dialogs.delete_dialog",
  });

  const { getAll, deleteRecord } = useIndexedDB("files");
  const dispatch = useAppDispatch();
  const file = useAppSelector(getSelectedUploadedFile);
  const open = useAppSelector(getUploadedFilesDialogs).deleteUploadedFile

  const handleDialogClose = () => {
    dispatch(handleDeleteUploadedFilesDialogClose());
  };

  const handleDelete = () => {
    if (projectId !== undefined && file.id !== undefined) {
      dispatch(deleteUploadedFile(+projectId, file.id, file.name));
      getAll().then(async (dbItems: IIndexedDBFiles[]) => {
        for (const dbItem of dbItems) {
          if (dbItem.uuid === file.uuid) {
            console.log(`Model ${dbItem.name} with ${dbItem.uuid} found in IndexedDB`)
            console.log(`Delete Model ${dbItem.name} with ${dbItem.uuid} from IndexedDB`);
            await deleteRecord(dbItem.id);
            break;
          }
        }
      })
      handleDialogClose();
    }
  };

  return (
    <Dialog
      sx={{ width: "100%", height: "100%" }}
      open={open}
      TransitionComponent={ZoomTransition}
      keepMounted
      onClose={handleDialogClose}
      maxWidth="sm"
    >
      <DialogTitle>
        {t("delete_file", { file: file?.name })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t("info")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>{t("cancel")}</Button>
        <Button onClick={handleDelete} autoFocus>
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadedFileDeleteDialog;
