import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import RequestDetailsTab from "./requestDetailsTab";
import { IIfcModelCreation } from "types";
import ResultTab from "./resultTab/resultTab";

interface IDetailTabs {
  ifcModelCreation: IIfcModelCreation;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && <Box sx={{ pl: 5, pr: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DetailTabs: React.FC<IDetailTabs> = ({ ifcModelCreation }) => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  useEffect(() => {
    setSelectedTabIndex(0);
  }, []);

  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: "transparent", display: 'flex', height: "100%" }}
    >
      <Tabs
        value={selectedTabIndex}
        onChange={handleChange}
        aria-label="ifcmodelcreation request tabs"


        orientation="vertical"
        sx={{ borderRight: 1, borderColor: 'divider', minWidth: 150 }}
      >
        <Tab sx={{ alignItems: 'flex-start', justifyContent: 'flex-start' }} label="Request details" {...a11yProps(0)} />
        <Tab sx={{ alignItems: 'flex-start', justifyContent: 'flex-start' }} label="Result" {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={selectedTabIndex} index={0}>
        <RequestDetailsTab ifcModelCreation={ifcModelCreation} />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={1}>
        <ResultTab ifcModelCreation={ifcModelCreation} />
      </CustomTabPanel>
    </Box>
  );
}

export default DetailTabs;
