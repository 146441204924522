import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { IProject } from "types";

type SliceState = IProject;

const initialState: SliceState = {
  id: 0,
  name: "",
  status: "",
  created_by: 0,
  bookmark: false,
  invitation_status: "",
  create_time: "",
  last_change: "",
  construction_site: {
    id: 0,
    street: "",
    city: "",
    postal_code: "",
    country: "",
  },
  me: {
    id: 0,
    role: "",
    user: 0,
    first_name: "",
    last_name: "",
    email: "",
    invited_at: "",
    invited_by: null,
    invitation_status: "",
    permissions: [],
  },
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    projectOpened: (project, action) => {
      return { ...project, ...action.payload };
    },
    projectClosed: () => {
      return initialState;
    },
  },
});

export const selectProject = (payload: IProject) => ({
  type: projectOpened.type,
  payload,
});

export const closeProject = () => ({
  type: projectClosed.type,
});

const { projectOpened, projectClosed } = projectSlice.actions;
export default projectSlice.reducer;

export const getSelectedProject = (state: any) =>
  state.entities.projects.selectedProject.project;

export const getProjectPermissions = (state: any) =>
  state.entities.projects.selectedProject.project.me.permissions;
