import { createSlice } from "@reduxjs/toolkit";

type SliceState = {
  selectedFeature: { title: string };
  sidebar: { open: boolean };
  menus: {
    account: { open: boolean };
    language: { open: boolean };
    notifications: { open: boolean };
  };
};

const initialState: SliceState = {
  selectedFeature: { title: "welcome" },
  sidebar: { open: false },
  menus: {
    account: { open: false },
    language: { open: false },
    notifications: { open: false },
  },
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    FeatureSelected: (navigation, action) => {
      navigation.selectedFeature.title = action.payload;
    },
    SidebarOpened: (navigation) => {
      navigation.sidebar.open = true;
    },
    SidebarClosed: (navigation) => {
      navigation.sidebar.open = false;
    },
    accountMenuOpened: (navigation) => {
      navigation.menus.account = { open: true };
    },
    accountMenuClosed: (navigation) => {
      navigation.menus.account = { open: false };
    },
    languageMenuOpened: (navigation) => {
      navigation.menus.language = { open: true };
    },
    languageMenuClosed: (navigation) => {
      navigation.menus.language = { open: false };
    },
    notificationsMenuOpened: (navigation) => {
      navigation.menus.notifications = { open: true };
    },
    notificationsMenuClosed: (navigation) => {
      navigation.menus.notifications = { open: false };
    },
  },
});

export const changeNavigationTitle = (payload: string) => ({
  type: FeatureSelected.type,
  payload,
});

export const openSidebar = () => ({
  type: SidebarOpened.type,
});

export const closeSidebar = () => ({
  type: SidebarClosed.type,
});

export const openAccountMenu = () => ({
  type: accountMenuOpened.type,
});

export const closeAccountMenu = () => ({
  type: accountMenuClosed.type,
});

export const openLanguageMenu = () => ({
  type: languageMenuOpened.type,
});

export const closeLanguageMenu = () => ({
  type: languageMenuClosed.type,
});

export const openNotificationsMenu = () => ({
  type: notificationsMenuOpened.type,
});

export const closeNotificationsMenu = () => ({
  type: notificationsMenuClosed.type,
});

export const {
  FeatureSelected,
  SidebarOpened,
  SidebarClosed,
  accountMenuOpened,
  accountMenuClosed,
  languageMenuOpened,
  languageMenuClosed,
  notificationsMenuOpened,
  notificationsMenuClosed,
} = navigationSlice.actions;

export default navigationSlice.reducer;

export const getSelectedFeature = (state: any) =>
  state.ui.navigation.selectedFeature;
export const getMenus = (state: any) => state.ui.navigation.menus;
export const getSidebar = (state: any) => state.ui.navigation.sidebar;
export const getCookieConsentMenu = (state: any) =>
  state.ui.navigation.cookieConsent;
