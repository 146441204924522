import React from 'react'

import { Layers } from './layers'
import { Annotations } from './annotations'
import { IElement } from 'types'
import { DimensionAnnotations } from './dimensionAnnotations'
import { PatternProvider } from './patterns/patternProvider'

interface WallProps {
  element: IElement
  dimensions: any
  fitToContainer: boolean
  minimalTheme: boolean
  strokeColor: string
  annotationColor: string
}

function Wall(props: WallProps) {
  const {
    element,
    dimensions = { x: 0, y: 0, width: 300, height: 726 },
    fitToContainer,
    minimalTheme,
    strokeColor,
    annotationColor,
  } = props

  let xScale = dimensions.width / element.performance.total_width
  let wallPositionX = 0

  if (minimalTheme) {
  } else {
    wallPositionX = dimensions.height * 0.1
    xScale = (dimensions.width * 0.62) / element.performance.total_width
    dimensions.width = dimensions.width * 2
  }

  const wallPositionY = 0
  const wallHeight = dimensions.height

  const labelNumber = element.layers.length
  const labelsEndX = dimensions.width * 0.62
  const labelsHeight = dimensions.height * 0.62
  const labelsStartY = (dimensions.height - labelsHeight) / 2
  const labelSpacing = labelsHeight / (labelNumber - 1)

  const labelSpacingX = 30

  const numLayerFillings = element.layers.reduce((acc, layer) => {
    return acc + layer.layer_fillings.filter(filling => filling.category === "stud").length;
  }, 0);

  // Space left of wall render for the diemension annotations
  const dimensionAnnotationsWidth = numLayerFillings * labelSpacingX

  // maximum y interval from stud baseline to other stud baseline + also the width of that specific filling
  const maxStudIntervallData = element.layers.reduce((maxData, layer) => {
    const maxStudIntervallInLayerData = layer.layer_fillings.reduce((maxInLayerData, filling) => {
      const studIntervall = filling.additional_material_clearance + filling.additional_material_width;

      if (studIntervall > maxInLayerData.maxStudIntervall) {
        return {
          maxStudIntervall: studIntervall,
          additionalMaterialWidth: filling.additional_material_width
        };
      } else {
        return maxInLayerData;
      }
    }, { maxStudIntervall: 0, additionalMaterialWidth: 0 });

    if (maxStudIntervallInLayerData.maxStudIntervall > maxData.maxStudIntervall) {
      return maxStudIntervallInLayerData;
    } else {
      return maxData;
    }
  }, { maxStudIntervall: 0, additionalMaterialWidth: 0 });

  const wallMaxStudIntervall = maxStudIntervallData.maxStudIntervall;
  const additionalMaterialWidth = maxStudIntervallData.additionalMaterialWidth;

  const wallBaselineOffsetY = wallHeight * (1 / 8);
  const wallRenderClearance = wallHeight - (2 * wallBaselineOffsetY)

  const yScale = wallRenderClearance / wallMaxStudIntervall

  // ensure that the top stud with the maximum clearance is rendered fully
  const wallBaselineOffsetYScaled =
    additionalMaterialWidth / 2 * yScale > wallBaselineOffsetY
      ? additionalMaterialWidth / 2 * yScale
      : wallBaselineOffsetY;

  return (
    <PatternProvider strokeWidth={0.4} strokeColor={strokeColor}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        id={`element_${element.id}`}
        x='0'
        y='0'
        width={fitToContainer ? '100%' : dimensions.width}
        height={fitToContainer ? '100%' : dimensions.height}
        viewBox={minimalTheme ? `0 0 ${dimensions.width} ${dimensions.height}` : `-${dimensionAnnotationsWidth} 0 ${dimensions.width} ${dimensions.height}`}
      >
        <g id='wall'>
          <g id={'layers'}>
            <Layers
              element={element}
              xScale={xScale}
              wallPositionX={wallPositionX}
              wallPositionY={wallPositionY}
              wallHeight={wallHeight}
              strokeColor={strokeColor}
              yScale={yScale}
              wallBaselineOffsetY={wallBaselineOffsetYScaled}
            />
          </g>
          <g id={'annotations'}>
            <Annotations
              element={element}
              xScale={xScale}
              wallPositionX={wallPositionX}
              wallPositionY={wallPositionY}
              labelsStartY={labelsStartY}
              labelsEndX={labelsEndX}
              labelSpacing={labelSpacing}
              minimalTheme={minimalTheme}
              annotationColor={annotationColor}
            />
          </g>
          <g id={'dimension-annotations'}>
            <DimensionAnnotations
              element={element}
              wallPositionX={wallPositionX}
              wallPositionY={wallPositionY}
              wallHeight={wallHeight}
              labelSpacing={labelSpacingX}
              minimalTheme={minimalTheme}
              annotationColor={annotationColor}
              wallBaselineOffsetY={wallBaselineOffsetYScaled}
              yScale={yScale}
            />
          </g>
        </g>
      </svg >
    </PatternProvider>
  )
}

export default Wall
