import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Account from "./account/account";
import App from "./app/app";
import {
  getAccountUrl,
  getAppUrl,
  getPaymentUrl,
  getProfileUrl,
  getSecurityUrl,
  getSettingsUrl,
} from "assets/endpoints/app/settingsEndpoints";
import Layout from "./layout";
import Profile from "./profile/profile";
import ProtectedRoute from "common/components/protectedRoute";
import Payments from "./payments/payments";
import Security from "./security/security";

export default function Settings() {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to={getAppUrl()} />} />
      <Route element={<Layout />}>
        <Route path={getAppUrl()} element={<App />} />
        <Route
          element={
            <ProtectedRoute redirectPath={getSettingsUrl() + getAppUrl()} />
          }
        >
          <Route path={getProfileUrl()} element={<Profile />} />
          <Route path={getAccountUrl()} element={<Account />} />
          <Route path={getPaymentUrl()} element={<Payments />} />
          <Route path={getSecurityUrl()} element={<Security />} />
        </Route>
      </Route>
    </Routes>
  );
}
