import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { getUser } from "store/entities/authentication/authentication.slice";
import {
  getAuthenticationUrl,
  getSignInUrl,
} from "assets/endpoints/app/authEndpoints";
import { useAppSelector } from "store";

interface ProtectedRouteProps {
  requiredPermissions?: string[];
  userPermissions?: string[];
  redirectPath?: string;
  children?: any;
}

const ProtectedRoute = ({
  requiredPermissions,
  userPermissions,
  redirectPath = getAuthenticationUrl() + getSignInUrl(),
  children,
}: ProtectedRouteProps) => {
  const loggedIn = useAppSelector(getUser).loggedIn;

  if (!loggedIn) {
    return <Navigate to={redirectPath} replace />;
  }

  if (requiredPermissions && userPermissions) {
    const hasRequiredPermission = userPermissions.some((value: any) =>
      requiredPermissions.includes(value)
    );

    if (!hasRequiredPermission) {
      return <Navigate to={redirectPath} replace />;
    }
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
