import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import { getMyUserDetails } from "store/entities/authentication/authentication.slice";
import { markNotificationAsRead } from "store/entities/authentication/authentication.actions";
import { useAppDispatch, useAppSelector } from "store";
import {
  closeNotificationsMenu,
  getMenus,
  openNotificationsMenu,
} from "store/ui/navigation/navigation.slice";
import { useNavigate } from "react-router-dom";

interface NotificationsMenuProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

export default function Notifications(props: NotificationsMenuProps) {
  const { anchorEl, setAnchorEl } = props;

  const { t } = useTranslation("navigation", {
    keyPrefix: "appbar.notifications",
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notificationsMenu = useAppSelector(getMenus).notifications;
  const me = useAppSelector(getMyUserDetails);

  const [initialNotifications, setInitialNoctifications] = useState(
    me.profile.notifications
  );

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(openNotificationsMenu());
    setAnchorEl(event.currentTarget);
    me.profile.notifications.map((notification: any, index: number) => {
      if (notification.marked_as_read !== true) {
        dispatch(markNotificationAsRead(notification.id));
      }
    });
  };

  const handleMenuClose = () => {
    dispatch(closeNotificationsMenu());
    setInitialNoctifications(me.profile.notifications);
  };

  const handleMenuItemClick = () => {
    handleMenuClose();
    navigate("/settings/profile/", { replace: true });
  };

  return (
    <React.Fragment>
      <Tooltip title={t<string>("your_notifications")}>
        <IconButton
          size="large"
          aria-label="show notifications"
          color="inherit"
          onClick={handleMenuOpen}
          aria-controls={notificationsMenu.open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={notificationsMenu.open ? "true" : undefined}
        >
          <Badge
            badgeContent={
              me.profile.notifications.filter(
                (notification: any) => notification.marked_as_read === false
              ).length
            }
            color="secondary"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={notificationsMenu.open}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {initialNotifications
          .slice(0, 5)
          .map(
            (notification: {
              id: number;
              subject: string;
              message: string;
              notified_on: string;
              expiry_date: string;
              marked_as_read: boolean;
            }) => (
              <MenuItem key={notification.id} onClick={handleMenuItemClick}>
                <Typography
                  variant="inherit"
                  noWrap
                  color={
                    notification.marked_as_read
                      ? "text.secondary"
                      : "text.primary"
                  }
                >
                  {notification.subject}
                </Typography>
              </MenuItem>
            )
          )}
      </Menu>
    </React.Fragment>
  );
}
