import React, { useEffect, useRef, useState } from "react";
import { millimeterPerMeter, rawBoardWidthMeter } from "../layerVisualizer";
import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import Plot from "react-plotly.js";
import { ILayerOptimized, IPlattenDimension, IPlattenAusschnitt } from "types";
import { Shape } from "plotly.js";
import { ColorData, getPlatteColorFromLayerColorData } from "../layerVisualizer";

interface BinPlotProps {
    layer: ILayerOptimized;
    colorData: ColorData;
    plattenType: 'neu' | 'src';
}

interface IPlatteCenter {
    x: number;
    y: number;
    platte: IPlattenDimension | IPlattenAusschnitt;
    platteWidth: number;
    platteHeight: number;
    platteArea: number;
}

interface IBinShapeDict {
    [key: number]: Partial<Shape>[];
}

interface IPlatteCentersDict {
    [key: number]: IPlatteCenter[];
}

const BinPlot: React.FC<BinPlotProps> = ({
    layer,
    colorData,
    plattenType
}) => {
    const plotRefs = useRef<(HTMLDivElement | null)[]>([]);
    const theme = useTheme();

    const [binShapes, setBinShapes] = useState<IBinShapeDict>({});
    const [platteShapes, setPlatteShapes] = useState<IBinShapeDict>({});
    const [platteCenters, setPlatteCenters] = useState<IPlatteCentersDict>({});

    const handleResize = () => {
        if (plotRefs.current.length > 0) {
            plotRefs.current.forEach((plotRef) => {
                if (plotRef) {
                    const resetAxesButton = plotRef.querySelector('[data-title="Reset axes"]');
                    if (resetAxesButton) {
                        resetAxesButton.dispatchEvent(new MouseEvent('click', { bubbles: true }));
                    }
                }
            });
        }
    };

    useEffect(() => {
        const platten = plattenType === 'neu' ? layer.platten_neu : layer.platten_src;
        const newBins = platten.reduce((acc, platte) => {
            if (!acc[platte.bin_number]) {
                acc[platte.bin_number] = [];
            }
            acc[platte.bin_number].push(platte);
            return acc;
        }, {} as { [key: number]: (IPlattenDimension | IPlattenAusschnitt)[] });

        const newBinShapes: IBinShapeDict = {};
        const newPlatteShapes: IBinShapeDict = {};
        const newPlatteCenters: IPlatteCentersDict = {};

        Object.keys(newBins).forEach((key) => {
            const binNumber = Number(key);

            newBinShapes[binNumber] = [{
                type: 'rect',
                x0: 0,
                y0: 0,
                x1: rawBoardWidthMeter,
                y1: layer.rohplatten_hoehe_neu / millimeterPerMeter,
                line: {
                    color: theme.palette.common.white,
                    width: 1
                },
                fillcolor: theme.palette.common.white,
            }];

            newPlatteShapes[binNumber] = newBins[binNumber].map((platte): Partial<Shape> => {
                const platteWidth = getPlatteWidth(platte, plattenType); //m
                const platteHeight = getPlatteHeight(platte, plattenType); //m

                const colorObject = getPlatteColorFromLayerColorData(platte.id, colorData, theme);

                return {
                    type: 'rect',
                    x0: platte.x_coord_in_bin / (plattenType === 'neu' ? 1 : 1000),
                    y0: platte.y_coord_in_bin / (plattenType === 'neu' ? 1 : 1000),
                    x1: platte.x_coord_in_bin / (plattenType === 'neu' ? 1 : 1000) + platteWidth,
                    y1: platte.y_coord_in_bin / (plattenType === 'neu' ? 1 : 1000) + platteHeight,
                    line: {
                        color: colorObject.lineColor,
                        width: colorObject.lineWidth
                    },
                    fillcolor: colorObject.fillColor,
                };
            });

            newPlatteCenters[binNumber] = newBins[binNumber].map((platte) => {
                const scale = plattenType === 'neu' ? 1 : 1000;

                const platteWidth = getPlatteWidth(platte, plattenType);  //m
                const platteHeight = getPlatteHeight(platte, plattenType);  //m
                const platteArea = platteWidth * platteHeight;

                return {
                    x: platte.x_coord_in_bin / scale + platteWidth / 2,
                    y: platte.y_coord_in_bin / scale + platteHeight / 2,
                    platte: platte,
                    platteWidth: platteWidth * 1000,
                    platteHeight: platteHeight * 1000,
                    platteArea: platteArea
                };
            });
        });

        setBinShapes(newBinShapes);
        setPlatteShapes(newPlatteShapes);
        setPlatteCenters(newPlatteCenters);

        setTimeout(handleResize, 100);

    }, [layer, colorData, plattenType]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [plotRefs]);

    if (plattenType === "src" && layer.platten_src.length === 0) {
        return (
            <Typography>No Baseline Panels provided</Typography>
        );
    }
    else if (plattenType === "neu" && layer.platten_neu.length === 0) {
        return (
            <Typography>No Optimized Panels provided</Typography>
        );
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography sx={{ mb: 2 }} variant="h6">
                    Panels needed: <span style={{ fontSize: 'h6.fontsize', fontWeight: 'bold' }}>{Object.keys(binShapes).length}</span>
                </Typography>
                <Divider sx={{ my: 2, width: "100%" }} />
            </Grid>
            {Object.keys(binShapes).map((binNumber, index) => {
                const currentPlatteCenters = platteCenters[Number(binNumber)] || [];

                return (
                    <Grid key={`${binNumber}-${index}`} item xs={12} md={6} lg={3} xl={2}>
                        <Typography variant="body1">
                            Raw Panel: <span style={{ fontSize: 'h6.fontsize', fontWeight: 'bold' }}>{Number(binNumber) + 1}</span>
                        </Typography>
                        <Typography variant="body1">
                            Cutted panels: <span style={{ fontSize: 'h6.fontsize', fontWeight: 'bold' }}>{(platteShapes[Number(binNumber)] || []).length}</span>
                        </Typography>
                        <Box component={"div"} ref={(el: HTMLDivElement | null) => (plotRefs.current[index] = el)}>
                            <Plot
                                style={{
                                    width: "100%",
                                    height: "100%"
                                }}
                                data={[
                                    {
                                        type: 'scatter',
                                        mode: 'markers',
                                        x: currentPlatteCenters.map(p => p.x),
                                        y: currentPlatteCenters.map(p => p.y),
                                        customdata: currentPlatteCenters.map(p => getCustomData(p.platte, plattenType, p.platteWidth, p.platteHeight, p.platteArea)),
                                        hovertemplate: getHoverTemplate(plattenType),
                                        marker: { opacity: 0 },
                                        showlegend: false
                                    }
                                ]}
                                layout={{
                                    modebar: { bgcolor: 'rgba(0,0,0,0)' },
                                    paper_bgcolor: 'rgba(0,0,0,0)',
                                    plot_bgcolor: 'rgba(0,0,0,0)',
                                    hovermode: "closest",
                                    hoverlabel: { bgcolor: "#FFF" },
                                    showlegend: false,
                                    dragmode: false,
                                    margin: {
                                        l: 10,
                                        r: 10,
                                        b: 10,
                                        t: 10,
                                        pad: 4
                                    },
                                    xaxis: {
                                        range: [0, rawBoardWidthMeter],
                                        showgrid: false,
                                        visible: false,
                                    },
                                    yaxis: {
                                        range: [0, layer.rohplatten_hoehe_neu / millimeterPerMeter],
                                        showgrid: false,
                                        visible: false,
                                        scaleanchor: "x",
                                        scaleratio: 1,
                                    },
                                    shapes: [...(binShapes[Number(binNumber)] || []), ...(platteShapes[Number(binNumber)] || [])]
                                }}
                                config={{
                                    displaylogo: false,
                                    scrollZoom: false,
                                    responsive: true,
                                    modeBarButtonsToRemove: ["zoom2d", "select2d", "lasso2d", "autoScale2d"],
                                }}
                            />
                        </Box>
                    </Grid>
                );
            })}
        </Grid>
    );
}

function getPlatteWidth(platte: IPlattenDimension | IPlattenAusschnitt, plattenType: 'neu' | 'src'): number {
    if (plattenType === 'neu') {
        return (platte as IPlattenDimension).rotated ? platte.top - platte.bottom : platte.x1 - platte.x0;
    } else {
        return platte.x1 - platte.x0;
    }
}

function getPlatteHeight(platte: IPlattenDimension | IPlattenAusschnitt, plattenType: 'neu' | 'src'): number {
    if (plattenType === 'neu') {
        return (platte as IPlattenDimension).rotated ? platte.x1 - platte.x0 : platte.top - platte.bottom;
    } else {
        return platte.top - platte.bottom;
    }
}

function getCustomData(platte: IPlattenDimension | IPlattenAusschnitt, plattenType: 'neu' | 'src', platteWidth: number, platteHeight: number, platteArea: number): any[] {
    const baseData = [
        platte.id,
        platte.bin_number + 1,
        platteWidth,
        platteHeight,
        platteArea
    ];

    if (plattenType === 'neu') {
        return [...baseData, (platte as IPlattenDimension).rotated ? "True" : "False"];
    }

    return baseData;
}

function getHoverTemplate(plattenType: 'neu' | 'src'): string {
    const baseTemplate = `<b>Id:</b> %{customdata[0]}<br><b>Bin Number:</b> %{customdata[1]}<br><b>Width:</b> %{customdata[2]:.1f} mm<br><b>Height:</b> %{customdata[3]:.1f} mm<br><b>Area:</b> %{customdata[4]:.2f} m^2`;

    if (plattenType === 'neu') {
        return `${baseTemplate}<br><b>Is Rotated:</b> %{customdata[5]}<br><extra></extra>`;
    }

    return `${baseTemplate}<br><extra></extra>`;
}

export default BinPlot;