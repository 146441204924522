import React from 'react'

export const generateCSV = (filename: string, elements: any) => {
  const header = [
    'element_id',
    'element_name',
    'element_category',
    'element_width',
    'element_thermal_transmittance',
    'layer_id',
    'layer_name',
    'layer_thickness',
    'layer_construction_section',
    'layer_material',
    'layer_density',
  ]

  const rows = [header]

  for (const element of elements) {
    if (element.layers.length > 0) {
      for (const layer of element.layers) {
        const row = [
          element.id,
          element.name,
          element.category.name,
          element.total_width,
          element.thermal_transmittance,
          layer.id,
          layer.name,
          layer.thickness,
          layer.construction_section.name,
          layer.material.name,
          layer.material.density,
        ]
        rows.push(row)
      }
    } else {
      const row = [
        element.id,
        element.name,
        element.category.name,
        element.total_width,
        element.thermal_transmittance,
        null,
        null,
        null,
        null,
        null,
        null,
      ]
      rows.push(row)
    }
  }

  var processRow = function(row: any) {
    var finalVal = ''
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? '' : row[j].toString()
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString()
      }
      var result = innerValue.replace(/"/g, '""')
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"'
      if (j > 0) finalVal += ','
      finalVal += result
    }
    return finalVal + '\n'
  }

  var csvFile = ''
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i])
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
  {
    var link = document.createElement('a')
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
