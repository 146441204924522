import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import Zoom from "@mui/material/Zoom";
import { useAppDispatch, useAppSelector } from "store";
import {
  closeLayerFillingCreateDialog,
  getLayerFillingDialogs,
  getSelectedLayer,
} from "store/ui/projects/componentCatalogue/componentCatalogue.slice";
import LayerFillingCreateForm from "./form";
import { ILayer, ILayerFillingForm, IStakeholderPermission } from "types";
import {
  createLayerFilling,
} from "store/entities/projects/componentCatalogue/componentCatalogue.actions";
import { useParams } from "react-router-dom";
import { getProjectPermissions } from "store/entities/projects/project.slice";
import { hasProjectPerm } from "services/authService";
import { projectPermissions } from "common/utils/constants/auth.constants";

const ZoomTransition: any = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

const LayerFillingCreateDialog: React.FC = () => {
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "selected_element.dialogs.layerFillings.create",
  });
  const { projectId, elementId } = useParams();

  const open = useAppSelector(getLayerFillingDialogs).create.open;
  const selectedLayer: ILayer = useAppSelector(getSelectedLayer);

  const dispatch = useAppDispatch();

  const initialValues: ILayerFillingForm = {
    category: "layer",
    construction_material: 0,
    thickness: 0,
    additional_material_clearance: null,
    additional_material_width: null,
  };

  const validationSchema = yup.object({
    thickness: yup
      .number()
      .moreThan(0)
      .required(t("required") as string),
    construction_material: yup
      .number()
      .moreThan(0)
      .required(t("required") as string),
    additional_material_clearance: yup
      .number()
      .moreThan(0)
      .nullable(),
    additional_material_width: yup
      .number()
      .moreThan(0)
      .nullable(),
  });

  const layerFillingForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      if (projectId !== undefined && elementId !== undefined) {
        dispatch(createLayerFilling(+projectId, +elementId, selectedLayer.id, values));
        dispatch(closeLayerFillingCreateDialog());
        layerFillingForm.resetForm();
      }
    },
  });

  const handleDialogClose = () => {
    dispatch(closeLayerFillingCreateDialog());
    layerFillingForm.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      TransitionComponent={ZoomTransition}
      aria-labelledby="layer-filling-create-dialog"
      hideBackdrop
      maxWidth="lg"
    >
      <DialogTitle style={{ cursor: "move" }} id="layer-filling-create-dialog">
        {t("add_layer_filling_title")}
      </DialogTitle>
      <DialogContent>
        <LayerFillingCreateForm layerFillingForm={layerFillingForm} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleDialogClose}>
          {t("cancel")}
        </Button>
        <Button type="submit" form="layerFilling-create-form">
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LayerFillingCreateDialog;

const PaperComponent: React.FC = (props: PaperProps) => {
  return (
    <Draggable
      handle="#layer-filling-create-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};
