import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

import {
  getConstructionMaterials,
} from "store/entities/projects/componentCatalogue/componentCatalogue.slice";

import { useAppSelector } from "store";
import { getLocales } from "store/entities/settings/settings.slice";
import type { IConstructionMaterial, ILayer, ILayerFilling } from "types";
import { getLayerFillingDialogs, getSelectedElement, getSelectedLayer } from "store/ui/projects/componentCatalogue/componentCatalogue.slice";
import { NumericFormat } from "react-number-format";

interface IProps {
  layerFillingForm: any;
}

const LayerFillingCreateForm: React.FC<IProps> = ({ layerFillingForm }) => {
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "selected_element.dialogs.layerFillings.create",
  });

  const locales = useAppSelector(getLocales);
  const constructionMaterials: IConstructionMaterial[] = useAppSelector(getConstructionMaterials);
  const [typeFilteredConstructionMaterials, setTypeFilteredConstructionMaterials] = useState(constructionMaterials);
  const createMaterialType = useAppSelector(getLayerFillingDialogs).create.type;

  const selectedElement = useAppSelector(getSelectedElement);
  const selectedLayerId = useAppSelector(getSelectedLayer).id;
  const selectedLayer = selectedElement.layers.find((layer: ILayer) => layer.id === selectedLayerId);

  useEffect(() => {
    switch (createMaterialType) {
      case "stud":
        setTypeFilteredConstructionMaterials(constructionMaterials.filter((material) => material.can_be_additional_material === true));
        layerFillingForm.setFieldValue(
          "category",
          "stud"
        );
        layerFillingForm.setFieldValue(
          "additional_material_clearance",
          0
        );
        layerFillingForm.setFieldValue(
          "additional_material_width",
          0
        );
        break;
      case "layer":
        setTypeFilteredConstructionMaterials(constructionMaterials);
        let hasStud = false;
        selectedLayer?.layer_fillings.map((filling: ILayerFilling) => { if (filling.category === "stud") hasStud = true });
        if (hasStud) setTypeFilteredConstructionMaterials(constructionMaterials.filter((material) => material.can_contain_additional_material === true));
        layerFillingForm.setFieldValue(
          "category",
          "layer"
        );
        break;
      default:
        setTypeFilteredConstructionMaterials(constructionMaterials);
    }
  }, [createMaterialType]);

  return (
    <form id="layerFilling-create-form" onSubmit={layerFillingForm.handleSubmit}>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <InputLabel id="construction_material-label">
              {t("construction_material")}
            </InputLabel>
            <Select
              labelId="construction_material-label"
              id="construction_material"
              name="construction_material"
              value={layerFillingForm.values.construction_material}
              label={t("construction_material")}
              onChange={(event) => {
                const selectedMaterialId = event.target.value;
                layerFillingForm.setFieldValue(
                  "construction_material",
                  selectedMaterialId
                );
              }
              }
            >
              {typeFilteredConstructionMaterials.map(
                (
                  material: IConstructionMaterial,
                  index: number
                ) => (
                  <MenuItem key={index} value={material.id}>
                    {material.name}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <NumericFormat
            id="thickness"
            name="thickness"
            label={t("thickness")}
            fullWidth
            variant="outlined"
            customInput={TextField}
            decimalSeparator={locales.code === "de-DE" ? "," : "."}
            suffix={" mm"}
            decimalScale={1}
            allowNegative={false}
            value={layerFillingForm.values.thickness}
            onBlur={layerFillingForm.handleBlur}
            error={
              layerFillingForm.touched.thickness &&
              layerFillingForm.touched.thickness &&
              Boolean(layerFillingForm.errors.thickness) &&
              Boolean(
                layerFillingForm.errors.thickness
              )
            }
            helperText={
              layerFillingForm.touched.thickness &&
              layerFillingForm.touched.thickness &&
              layerFillingForm.errors.thickness &&
              layerFillingForm.errors.thickness
            }
            onValueChange={(values, sourceInfo) => {
              if (values.floatValue !== undefined) {
                const formattedValue = parseFloat(values.floatValue.toFixed(1));
                layerFillingForm.setFieldValue(
                  "thickness",
                  formattedValue
                );
              }
            }}
          />
        </Grid>
        {createMaterialType === "stud" ? (
          <>
            <Grid item xs={12} md={12}>
              <NumericFormat
                id="additional_material_width"
                name="additional_material_width"
                label={t("additional_material_width")}
                fullWidth
                variant="outlined"
                customInput={TextField}
                decimalSeparator={locales.code === "de-DE" ? "," : "."}
                suffix={" mm"}
                decimalScale={1}
                allowNegative={false}
                value={layerFillingForm.values.additional_material_width}
                onBlur={layerFillingForm.handleBlur}
                error={
                  layerFillingForm.touched.additional_material_width &&
                  layerFillingForm.touched.additional_material_width &&
                  Boolean(layerFillingForm.errors.additional_material_width) &&
                  Boolean(
                    layerFillingForm.errors.additional_material_width
                  )
                }
                helperText={
                  layerFillingForm.touched.additional_material_width &&
                  layerFillingForm.touched.additional_material_width &&
                  layerFillingForm.errors.additional_material_width &&
                  layerFillingForm.errors.additional_material_width
                }
                onValueChange={(values, sourceInfo) => {
                  if (values.floatValue !== undefined) {
                    const formattedValue = parseFloat(values.floatValue.toFixed(1));
                    layerFillingForm.setFieldValue(
                      "additional_material_width",
                      formattedValue
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <NumericFormat
                id="additional_material_clearance"
                name="additional_material_clearance"
                label={t("additional_material_clearance")}
                fullWidth
                variant="outlined"
                customInput={TextField}
                decimalSeparator={locales.code === "de-DE" ? "," : "."}
                suffix={" mm"}
                decimalScale={1}
                allowNegative={false}
                value={layerFillingForm.values.additional_material_clearance}
                onBlur={layerFillingForm.handleBlur}
                error={
                  layerFillingForm.touched.additional_material_clearance &&
                  layerFillingForm.touched.additional_material_clearance &&
                  Boolean(layerFillingForm.errors.additional_material_clearance) &&
                  Boolean(
                    layerFillingForm.errors.additional_material_clearance
                  )
                }
                helperText={
                  layerFillingForm.touched.additional_material_clearance &&
                  layerFillingForm.touched.additional_material_clearance &&
                  layerFillingForm.errors.additional_material_clearance &&
                  layerFillingForm.errors.additional_material_clearance
                }
                onValueChange={(values, sourceInfo) => {
                  if (values.floatValue !== undefined) {
                    const formattedValue = parseFloat(values.floatValue.toFixed(1));
                    layerFillingForm.setFieldValue(
                      "additional_material_clearance",
                      formattedValue
                    );
                  }
                }}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </form>
  );
};

export default LayerFillingCreateForm;
