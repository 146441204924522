import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

import {
  getConstructionSectionChoices,
} from "store/entities/projects/componentCatalogue/componentCatalogue.slice";

import { useAppSelector } from "store";
import { getLocales } from "store/entities/settings/settings.slice";
import type { IConstructionSectionChoice } from "types";

interface IProps {
  layerForm: any;
  isMaintainer: boolean;
}

const LayerUpdateForm: React.FC<IProps> = ({ layerForm, isMaintainer }) => {
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "selected_element.dialogs.layers.create",
  });

  const locales = useAppSelector(getLocales);
  const constructionSections = useAppSelector(getConstructionSectionChoices);

  return (
    <form id="layer-update-form" onSubmit={layerForm.handleSubmit}>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="name"
            name="name"
            label={t("layer_name")}
            value={layerForm.values.name}
            onBlur={layerForm.handleBlur}
            error={layerForm.touched.name && Boolean(layerForm.errors.name)}
            helperText={layerForm.touched.name && layerForm.errors.name}
            onChange={layerForm.handleChange}
            fullWidth
            autoComplete="name"
            variant="outlined"
            InputProps={{
              readOnly: !isMaintainer,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="construction-section-label">
              {t("construction_section")}
            </InputLabel>
            <Select
              labelId="construction-section-label"
              id="construction-section"
              name="construction_section"
              value={layerForm.values.construction_section}
              label={t("construction_section")}
              onChange={layerForm.handleChange}
              readOnly={!isMaintainer}
            >
              {constructionSections.map(
                (
                  constructionSection: IConstructionSectionChoice,
                  index: number
                ) => (
                  <MenuItem key={index} value={constructionSection.value}>
                    {constructionSection.label}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default LayerUpdateForm;
