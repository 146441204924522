import React, { useEffect } from "react";
import { Add as AddIcon } from "@mui/icons-material";
import {
  Box,
  Container,
  Fab,
  Grid,
  Skeleton,
  useMediaQuery,
} from "@mui/material";

import { openAddProjectDialog } from "store/ui/projects/projects.slice";
import {
  fetchProjectStatusList,
  fetchProjects,
  getApi,
  getFilter,
  getProjects,
} from "store/entities/projects/projects.slice";
import InvitationCard from "./cards/invitiation";
import { useAppDispatch, useAppSelector } from "store";
import { IProject } from "types";
import { getLocales } from "store/entities/settings/settings.slice";
import DefaultCard from "./cards/default";
import Toolbar from "./toolbar";
import AddProjectDialog from "./dialogs/addProjectDialog";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";

const List: React.FC = () => {
  const dispatch = useAppDispatch();
  const api = useAppSelector(getApi);
  const projects = useAppSelector(getProjects);
  const locales = useAppSelector(getLocales);
  const filter = useAppSelector(getFilter);

  useEffect(() => {
    dispatch(fetchProjectStatusList());
    dispatch(fetchProjects());
  }, [locales]);

  const filteredProjects = projects.filter((project: IProject) => {
    if (project.name && project.name.includes("PERSONAL_LIBRARY")) {
      return false;
    }
    if (filter === "") {
      return true;
    }
    return project.status === filter;
  });

  return (
    <Container maxWidth="lg">
      <Toolbar />
      <Box sx={{ flexGrow: 1, mb: 3 }}>
        <Grid
          container
          spacing={{ xs: 2, sm: 4, md: 6 }}
          columns={{ xs: 4, sm: 8, md: 12, xl: 12 }}
        >
          {(api.loading ? Array.from(new Array(11)) : filteredProjects).map(
            (project: IProject, index: number) => (
              <Grid item xs={12} sm={4} md={4} lg={4} xl={3} key={index}>
                {project ? (
                  project.me.invitation_status === "accepted" ? (
                    <DefaultCard project={project} />
                  ) : (
                    <InvitationCard project={project} />
                  )
                ) : (
                  <Box height={"350px"}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={"68%"}
                    />
                    <Box sx={{ height: "32%", pt: 0.5 }}>
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" width="60%" />
                    </Box>
                  </Box>
                )}
              </Grid>
            )
          )}
        </Grid>
        <AddButton />
      </Box>
      <AddProjectDialog />
    </Container>
  );
};

export default List;

const AddButton: React.FC = () => {
  const { t } = useTranslation("projects", {
    keyPrefix: "list",
  });
  const api = useAppSelector(getApi);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleOrderEntryDialogOpen = () => {
    dispatch(openAddProjectDialog());
  };

  return (
    <Fab
      color="secondary"
      aria-label="add"
      sx={{
        position: "fixed",
        bottom: { xs: 16, md: 72 },
        right: { xs: 16, md: 72 },
        float: "right",
      }}
      variant={matches ? "extended" : "circular"}
      onClick={handleOrderEntryDialogOpen}
    >
      <AddIcon
        sx={{
          mr: { xs: 0, md: 1 },
        }}
      />
      {matches ? t("add_project") : null}
    </Fab>
  );
};
