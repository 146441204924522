declare module "@mui/material/styles" {
    interface Theme {
        topNavigation: {
            appbar: {
              height: number  
            }
        };
        bottomNavigation: {
            appbar: {
              height: number  
            }
        };
    }

    interface ThemeOptions {
        topNavigation: {
            appbar: {
              height: number  
            }
        };
        bottomNavigation: {
            appbar: {
              height: number  
            }
        };
    }
}

export const commonThemeOptions = {
    topNavigation: {
        appbar: {
            height: 112,
        },
    },
    bottomNavigation: {
        appbar: {
          height: 70  
        }
    },
    palette: {
        white: { color: { main: "#ffffff" } },
      },
}