import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Zoom from "@mui/material/Zoom";
import { TransitionProps } from "@mui/material/transitions";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getIfcModelCreation, getIfcModelCreationsDialogs, handleDeleteIfcModelCreationsDialogClose } from "store/ui/projects/ifcModelCreations/ifcModelCreations.slice";
import { deleteIfcModelCreation } from "store/entities/projects/ifcModelCreations/ifcModelCreations.actions";

const ZoomTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

const IfcModelCreationDeleteDialog: React.FC = () => {
  const { projectId } = useParams();
  const { t } = useTranslation("ifc_model_creations", {
    keyPrefix: "dialogs.delete_dialog",
  });

  const dispatch = useAppDispatch();
  const ifcModelCreation = useAppSelector(getIfcModelCreation);
  const open = useAppSelector(getIfcModelCreationsDialogs).deleteIfcModelCreation

  const handleDialogClose = () => {
    dispatch(handleDeleteIfcModelCreationsDialogClose());
  };

  const handleDelete = () => {
    if (projectId !== undefined && ifcModelCreation.id !== undefined) {
      dispatch(deleteIfcModelCreation(+projectId, ifcModelCreation.id));
      handleDialogClose();
    }
  };

  return (
    <Dialog
      sx={{ width: "100%", height: "100%" }}
      open={open}
      TransitionComponent={ZoomTransition}
      keepMounted
      onClose={handleDialogClose}
      maxWidth="sm"
    >
      <DialogTitle>
        {t("delete_ifc_model_creation", { ifcModelCreation: ifcModelCreation?.id })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t("info")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>{t("cancel")}</Button>
        <Button onClick={handleDelete} autoFocus>
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IfcModelCreationDeleteDialog;
