import React, { useState, useEffect, ReactNode } from 'react'

import Ceiling from './ceiling/ceiling'
import NoPreviewAvailable from './noPreviewAvailable'
import Wall from './wall/wall'
import { IElement } from 'types'

interface ElementImageProps {
  element: IElement
  dimensions?: any
  fitToContainer?: boolean
  minimalTheme?: boolean
  colorTheme?: string
}

const ElementImage: React.FC<ElementImageProps> = ({
  element,
  dimensions,
  fitToContainer = false,
  minimalTheme = false,
  colorTheme = 'light',
}) => {
  const [strokeColor, setStrokeColor] = useState('black')
  const [annotationColor, setAnnotationColor] = useState(
    'rgba(255, 255, 255, 0.7)'
  )

  useEffect(() => {
    if (colorTheme === 'dark') {
      setStrokeColor('white')
      setAnnotationColor('rgba(255, 255, 255, 0.7)')
    } else {
      setStrokeColor('black')
      setAnnotationColor('rgba(0, 0, 0, 0.6)')
    }
  }, [element, colorTheme])

  const getImageByElementCategory = (element: IElement) => {
    let image: ReactNode
    switch (element.category) {
      // case 'ceiling':
      // case 'baseplate':
      //   image = (
      //     <Ceiling
      //       element={element}
      //       dimensions={dimensions}
      //       fitToContainer={fitToContainer}
      //       minimalTheme={minimalTheme}
      //       strokeColor={strokeColor}
      //       annotationColor={annotationColor}
      //     />
      //   )
      //   break
      case 'exterior_wall':
      case 'interior_wall':
        image = (
          <Wall
            element={element}
            dimensions={dimensions}
            fitToContainer={fitToContainer}
            minimalTheme={minimalTheme}
            strokeColor={strokeColor}
            annotationColor={annotationColor}
          />
        )
        break
      default:
        image = (
          <NoPreviewAvailable
            element={element}
            fitToContainer={fitToContainer}
            minimalTheme={minimalTheme}
            strokeColor={strokeColor}
            annotationColor={annotationColor}
          />
        )
        break
    }
    return image
  }

  return getImageByElementCategory(element)
}

export default ElementImage
