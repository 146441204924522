import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import { useAppDispatch, useAppSelector } from "store";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Paper } from "@mui/material";


interface LoadingSnackbar {
    open: boolean;
    message: string;
}

interface ILoadingSnackbarProps {
    localeUrl: string;
    localeUrlKeyPrefix: string;
    getSnackbar: (state: any) => any;
    closeSnackbar: () => any;
    getLoading: (state: any) => any;
}

const LoadingSnackbar = (
    {
        localeUrl,
        localeUrlKeyPrefix,
        getSnackbar,
        closeSnackbar,
        getLoading,
    }: ILoadingSnackbarProps
) => {

    const { t } = useTranslation(localeUrl, {
        keyPrefix: localeUrlKeyPrefix,
    });
    const dispatch = useAppDispatch();

    const snackbar: LoadingSnackbar = useAppSelector(getSnackbar);
    const loading = useAppSelector(getLoading);

    const handleSnackbarClose = () => {
        dispatch(closeSnackbar());
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbar.open}
            onClose={handleSnackbarClose}
            TransitionComponent={Slide}
            sx={{ justifySelf: "center", mt: 1 }}
        >
            <Paper elevation={10} sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                <CircularProgress size="1rem" />
                <Box
                    sx={{ pl: 2 }}
                >
                    {t(snackbar.message, { element: loading?.content })}
                </Box>
            </ Paper>
        </Snackbar>
    );
}

export default LoadingSnackbar