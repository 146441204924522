import { AlertColor } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";
import {
  userUpdateFulfilled,
  userUpdateRejected,
  accountUpdateFulfilled,
  accountUpdateRejected,
  profileUpdateFulfilled,
  profileUpdateRejected,
  passwordUpdateFulfilled,
  passwordUpdateRejected,
  notificationsDeleteFulfilled,
  notificationsDeleteRejected,
  usernameUpdateFulfilled,
  usernameUpdateRejected,
  emailUpdateFulfilled,
  emailUpdateRejected,
} from "../../entities/authentication/authentication.slice";

type SliceState = {
  snackbar: { open: boolean; message: string; severity: AlertColor };
  dialogs: {
    profilePicture: { open: boolean };
    confirmUsernameChange: { open: boolean };
    confirmEmailChange: { open: boolean };
  };
};

const initialState: SliceState = {
  snackbar: { open: false, message: "", severity: "success" },
  dialogs: {
    profilePicture: { open: false },
    confirmUsernameChange: { open: false },
    confirmEmailChange: { open: false },
  },
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(userUpdateFulfilled, (settings) => {
        settings.snackbar = {
          open: true,
          message: "profile_update_fulfilled",
          severity: "success",
        };
      })
      .addCase(userUpdateRejected, (settings) => {
        settings.snackbar = {
          open: true,
          message: "profile_update_rejected",
          severity: "error",
        };
      })
      .addCase(profileUpdateFulfilled, (settings) => {
        settings.snackbar = {
          open: true,
          message: "profile_update_fulfilled",
          severity: "success",
        };
        settings.dialogs.profilePicture.open = false;
      })
      .addCase(profileUpdateRejected, (settings) => {
        settings.snackbar = {
          open: true,
          message: "profile_update_rejected",
          severity: "error",
        };
      })
      .addCase(accountUpdateFulfilled, (settings) => {
        settings.snackbar = {
          open: true,
          message: "account_update_fulfilled",
          severity: "success",
        };
      })
      .addCase(accountUpdateRejected, (settings) => {
        settings.snackbar = {
          open: true,
          message: "account_update_rejected",
          severity: "error",
        };
      })
      .addCase(usernameUpdateFulfilled, (settings) => {
        settings.snackbar = {
          open: true,
          message: "username_update_fulfilled",
          severity: "success",
        };
      })
      .addCase(usernameUpdateRejected, (settings) => {
        settings.snackbar = {
          open: true,
          message: "username_update_rejected",
          severity: "error",
        };
      })
      .addCase(emailUpdateFulfilled, (settings) => {
        settings.snackbar = {
          open: true,
          message: "email_update_fulfilled",
          severity: "success",
        };
      })
      .addCase(emailUpdateRejected, (settings) => {
        settings.snackbar = {
          open: true,
          message: "email_update_rejected",
          severity: "error",
        };
      })
      .addCase(passwordUpdateFulfilled, (settings) => {
        settings.snackbar = {
          open: true,
          message: "password_update_fulfilled",
          severity: "success",
        };
      })
      .addCase(passwordUpdateRejected, (settings) => {
        settings.snackbar = {
          open: true,
          message: "password_update_rejected",
          severity: "error",
        };
      })
      .addCase(notificationsDeleteFulfilled, (settings) => {
        settings.snackbar = {
          open: true,
          message: "notifications_delete_fulfilled",
          severity: "success",
        };
      })
      .addCase(notificationsDeleteRejected, (settings) => {
        settings.snackbar = {
          open: true,
          message: "notifications_delete_rejected",
          severity: "error",
        };
      });
  },
  reducers: {
    snackbarOpened: (settings, action) => {
      settings.snackbar = {
        open: true,
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    snackbarClosed: (settings) => {
      settings.snackbar = {
        ...settings.snackbar,
        open: false,
      };
    },
    profilePictureDialogOpened: (settings) => {
      settings.dialogs.profilePicture.open = true;
    },
    profilePictureDialogClosed: (settings) => {
      settings.dialogs.profilePicture.open = false;
    },
    confirmUsernameChangeDialogOpened: (settings) => {
      settings.dialogs.confirmUsernameChange.open = true;
    },
    confirmUsernameChangeDialogClosed: (settings) => {
      settings.dialogs.confirmUsernameChange.open = false;
    },
    confirmEmailChangeDialogOpened: (settings) => {
      settings.dialogs.confirmEmailChange.open = true;
    },
    confirmEmailChangeDialogClosed: (settings) => {
      settings.dialogs.confirmEmailChange.open = false;
    },
  },
});

export const openSnackbar = (payload: {
  message: string;
  severity: string;
}) => ({
  type: snackbarOpened.type,
  payload,
});

export const closeSnackbar = () => ({
  type: snackbarClosed.type,
});

export const openProfilePictureDialog = () => ({
  type: profilePictureDialogOpened.type,
});

export const closeProfilePictureDialog = () => ({
  type: profilePictureDialogClosed.type,
});

export const openUsernameChangeDialog = () => ({
  type: confirmUsernameChangeDialogOpened.type,
});

export const closeUsernameChangeDialog = () => ({
  type: confirmUsernameChangeDialogClosed.type,
});

export const openEmailChangeDialog = () => ({
  type: confirmEmailChangeDialogOpened.type,
});

export const closeEmailChangeDialog = () => ({
  type: confirmEmailChangeDialogClosed.type,
});

const {
  snackbarOpened,
  snackbarClosed,
  profilePictureDialogOpened,
  profilePictureDialogClosed,
  confirmUsernameChangeDialogOpened,
  confirmUsernameChangeDialogClosed,
  confirmEmailChangeDialogOpened,
  confirmEmailChangeDialogClosed,
} = settingsSlice.actions;

export const getSnackbar = (state: any) => state.ui.settings.snackbar;
export const getDialogs = (state: any) => state.ui.settings.dialogs;

export default settingsSlice.reducer;
