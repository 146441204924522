import { Fab, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store";
import { Add as AddIcon } from "@mui/icons-material";
import { handleAddUploadedFilesDialogOpen } from "store/ui/projects/uploadedFiles/uploadedFiles.slice";

export const AddButton: React.FC = () => {
  const { t } = useTranslation("uploaded_files", {
    keyPrefix: "list.button",
  });

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleUploadFileDialogOpen = () => {
    dispatch(handleAddUploadedFilesDialogOpen());
  };

  return (
    <Fab
      color="secondary"
      aria-label="add"
      sx={{
        position: "fixed",
        bottom: { xs: 16, md: 72 },
        right: { xs: 16, md: 72 },
        float: "right",
      }}
      variant={matches ? "extended" : "circular"}
      onClick={handleUploadFileDialogOpen}
    >
      <AddIcon
        sx={{
          mr: { xs: 0, md: 1 },
        }}
      />
      {matches ? t("add_file") : null}
    </Fab>
  );
};