import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "store";
import {
  closeElementUpdateDialog,
  getElementDialogs,
  getSelectedElement,
} from "store/ui/projects/componentCatalogue/componentCatalogue.slice";
import { IElementForm } from "types";
import ElementUpdateForm from "./form";
import { updateElement } from "store/entities/projects/componentCatalogue/componentCatalogue.actions";
import { useParams } from "react-router-dom";

import { getProjectPermissions } from "store/entities/projects/project.slice";
import { hasProjectPerm } from "services/authService";
import { projectPermissions } from "common/utils/constants/auth.constants";
import type { IStakeholder, IStakeholderPermission } from "types";

const ElementUpdateDialog: React.FC = () => {
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "selected_element.dialogs.element.update",
  });

  const { projectId, elementId } = useParams();
  const open = useAppSelector(getElementDialogs).update;
  const element = useAppSelector(getSelectedElement);
  const dispatch = useAppDispatch();

  const userPermissions: IStakeholderPermission[] = useAppSelector(
    getProjectPermissions
  );

  const isMaintainer = hasProjectPerm(userPermissions, [
    projectPermissions.component_catalogue.maintainer,
  ]);

  const handleClose = () => {
    dispatch(closeElementUpdateDialog());
    elementForm.resetForm();
  };

  useEffect(() => {
    setInitialValues({
      name: element.name,
      identifier: element.identifier,
      description: element.description,
      performance: {
        fire_protection: element.performance.fire_protection,
        direct_sound_reduction_index:
          element.performance.direct_sound_reduction_index,
        sound_reduction_index: element.performance.sound_reduction_index,
        weighted_normalized_impact_sound_pressure_level:
          element.performance.weighted_normalized_impact_sound_pressure_level,
        normalized_impact_sound_pressure_level:
          element.performance.normalized_impact_sound_pressure_level,
      },
      requirements: {},
      layers: element.layers,
    });
  }, [element]);

  const [initialValues, setInitialValues] = useState<IElementForm>({
    name: "",
    identifier: "",
    description: "",
    performance: {
      fire_protection: "",
      direct_sound_reduction_index: 0,
      sound_reduction_index: 0,
      weighted_normalized_impact_sound_pressure_level: 0,
      normalized_impact_sound_pressure_level: 0,
    },
    requirements: {},
    layers: [],
  });

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("required") as string),
    identifier: yup
      .string()
      .required(t("required") as string)
      .max(10, t<string>("max_char_length", { length: 10 })),
    description: yup.string(),
    performance: yup.object().shape({
      fire_protection: yup
        .string()
        .nullable(),
      direct_sound_reduction_index: yup
        .number()
        .nullable()
        .positive(t("number_must_be_positive") as string)
        .moreThan(
          0,
          t("number_larger_than", {
            min_number: 0,
          }) as string
        )
        .lessThan(
          100,
          t("num_less_than", {
            max_number: 100,
          }) as string
        ),
      sound_reduction_index: yup
        .number()
        .nullable()
        .positive(t("number_must_be_positive") as string)
        .moreThan(
          0,
          t("number_larger_than", {
            min_number: 0,
          }) as string
        )
        .lessThan(
          100,
          t("num_less_than", {
            max_number: 100,
          }) as string
        ),
      weighted_normalized_impact_sound_pressure_level: yup
        .number()
        .nullable()
        .positive(t("number_must_be_positive") as string)
        .moreThan(
          0,
          t("number_larger_than", {
            min_number: 0,
          }) as string
        )
        .lessThan(
          100,
          t("num_less_than", {
            max_number: 100,
          }) as string
        ),
      normalized_impact_sound_pressure_level: yup
        .number()
        .nullable()
        .positive(t("number_must_be_positive") as string)
        .moreThan(
          0,
          t("number_larger_than", {
            min_number: 0,
          }) as string
        )
        .lessThan(
          100,
          t("num_less_than", {
            max_number: 100,
          }) as string
        ),
    }),
  });

  const elementForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      if (projectId !== undefined && elementId !== undefined) {
        dispatch(updateElement(+projectId, +elementId, values));
        dispatch(closeElementUpdateDialog());
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="element-update-dialog"
      hideBackdrop
      maxWidth="sm"
    >
      <DialogTitle style={{ cursor: "move" }} id="element-update-dialog">
        {t("edit_element")}
      </DialogTitle>
      <DialogContent>
        <ElementUpdateForm
          elementForm={elementForm}
          isMaintainer={isMaintainer}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {t("cancel")}
        </Button>
        {isMaintainer && (
          <Button type="submit" form="element-update-form">
            {t("save")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ElementUpdateDialog;

const PaperComponent: React.FC = (props: PaperProps) => {
  return (
    <Draggable
      handle="#element-update-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};
