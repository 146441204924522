import { Avatar, Chip, Divider, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { stringToColor } from "common/utils/helpers/stringToColor.helpers";
import { IIfcModelCreation } from "types";

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    }).format(date);
};

interface IHeaderProps {
    ifcModelCreation: IIfcModelCreation
}

const Header: React.FC<IHeaderProps> = ({ ifcModelCreation }) => {
    return (
        <Box>
            <Typography variant="h4">{ifcModelCreation.base_model_file.name}</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", mb: 1 }}>
                <Typography sx={{ mr: 1 }} color="GrayText" variant="h6">Creation level:</Typography>
                <Typography variant="h6">{ifcModelCreation.creation_level}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", mb: 1 }}>
                <Box sx={{ display: "flex", flexDirection: "column", mr: 5 }}>
                    <Typography sx={{ mb: 0.5 }} color="GrayText" variant="body1">Creation id</Typography>
                    <Typography variant="body1">{ifcModelCreation.id}</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", mr: 5 }}>
                    <Typography sx={{ mb: 0.5 }} color="GrayText" variant="body1">Creation level</Typography>
                    <Chip
                        size="small"
                        label={ifcModelCreation.creation_status}
                        color={
                            ifcModelCreation.creation_status === "completed" ? "success" :
                                ifcModelCreation.creation_status === "failed" ? "error" : "info"}
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", mr: 5 }}>
                    <Typography sx={{ mb: 0.5 }} color="GrayText" variant="body1">Created at</Typography>
                    <Typography variant="body1">{ifcModelCreation.created_at ? formatDate(ifcModelCreation.created_at) : null}</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", mr: 5 }}>
                    <Typography sx={{ mb: 0.5 }} color="GrayText" variant="body1">Created by</Typography>
                    <Stack direction="row" spacing={-2}>
                        <ListItemAvatar>
                            <Avatar
                                sx={{
                                    bgcolor: stringToColor(
                                        `${ifcModelCreation.created_by.first_name} ${ifcModelCreation.created_by.last_name}`
                                    ),
                                    width: 24,
                                    height: 24,
                                    fontSize: 17
                                }}
                            >
                                {`${ifcModelCreation.created_by.first_name.slice(0, 1)}${ifcModelCreation.created_by.last_name.slice(
                                    0,
                                    1
                                )}`}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${ifcModelCreation.created_by.first_name} ${ifcModelCreation.created_by.last_name}`}
                        />
                    </Stack>
                </Box>
            </Box>
            <Divider sx={{ width: "100%" }} />
        </Box>
    );
}

export default Header;