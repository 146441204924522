import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyUserDetails,
  getUser,
} from "store/entities/authentication/authentication.slice";
import Password from "./password";
import Email from "./email";
import Username from "./username";

export default function Security() {
  const { t } = useTranslation("settings", { keyPrefix: "security" });
  const me = useSelector(getMyUserDetails);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Username />
      </Grid>
      <Grid item xs={12}>
        <Email />
      </Grid>
      <Grid item xs={12}>
        <Password />
      </Grid>
    </Grid>
  );
}
