import React, { useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import Error from "common/components/error/error";
import List from './list/list';
import { Container } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store';
import { useTranslation } from 'react-i18next';
import Actions from './actions/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { getLocales } from 'store/entities/settings/settings.slice';
import Filter from './filter/filter';
import { closePersonalLibrary, getPersonalLibraryProjectId, getPersonalLibraryReturnProjectId, showPersonalLibraryActions } from 'store/ui/projects/componentCatalogue/componentCatalogue.slice';
import { fetchCategoryChoices, fetchConstructionElements } from 'store/entities/projects/componentCatalogue/componentCatalogue.actions';
import Dialogs from './dialogs/dialogs';
import TopNavigation from './navigation/topNavigation';

const appBarStyle = {
    height: 50,
};

const PersonalLibrary = ({ }) => {
    const { t } = useTranslation("component_catalogue", {
        keyPrefix: "library",
    });
    const { projectId } = useParams();
    const locales = useAppSelector(getLocales);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const personalLibraryReturnProjectId = useAppSelector(getPersonalLibraryReturnProjectId);
    const personalLibraryProjectId = useAppSelector(getPersonalLibraryProjectId);

    const containerRef = useRef<HTMLDivElement>(null);

    const handleDialogClose = () => {
        dispatch(closePersonalLibrary());
        navigate(`/projects/${personalLibraryReturnProjectId}/component-catalogue/`);
    };

    useEffect(() => {
        personalLibraryProjectId !== null
            && dispatch(fetchConstructionElements(+personalLibraryProjectId))
            && dispatch(fetchCategoryChoices(+personalLibraryProjectId))
            && dispatch(showPersonalLibraryActions())
    }, [locales, projectId]);

    return (
        <React.Fragment>
            {personalLibraryProjectId ? (
                <Dialog
                    keepMounted
                    disableEnforceFocus
                    disableAutoFocus
                    disableRestoreFocus
                    hideBackdrop
                    fullScreen
                    open
                    onClose={handleDialogClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <TopNavigation
                        handleDialogClose={handleDialogClose}
                        titleTranslationPrefix="title"
                        closeTranslationPrefix="close"
                    />
                    <Container
                        maxWidth="xl"
                        fixed
                        sx={{ my: 3 }}
                        ref={containerRef}
                        style={{ paddingTop: appBarStyle.height, paddingBottom: appBarStyle.height }}
                    >
                        <Filter />
                        <List containerRef={containerRef} />
                    </Container>
                    <Dialogs />
                    <Actions />
                </Dialog>
            ) : (
                <Error httpStatusCode={404} />
            )}
        </React.Fragment>
    );
}

export default PersonalLibrary;