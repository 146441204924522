import * as React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

interface ITitleProps {
  steps: string[]
  activeStep: number
  skipped: any
}

export default function Title(props: ITitleProps) {
  const { steps, activeStep, skipped } = props
  const { t } = useTranslation("ifc_model_creations", {
    keyPrefix: "dialogs.create_dialog",
  });
  const theme = useTheme()

  const isStepOptional = (step: number) => {
    return step === 10
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  return (
    <DialogTitle>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant='caption'>{t('optional')}</Typography>
            )
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{t(label)}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </DialogTitle>
  )
}
