import { AlertColor } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";
import {
  authenticationFulfilled,
  authenticationRejected,
  passwordResetFulfilled,
  passwordResetRejected,
  passwordUpdateFulfilled,
  passwordUpdateRejected,
  resendActivationEmailFulfilled,
  resendActivationEmailRejected,
  userCreationFulfilled,
  userCreationRejected,
} from "../../entities/authentication/authentication.slice";

type SliceState = {
  snackbar: { open: boolean; message: string; severity: AlertColor };
};

const initialState: SliceState = {
  snackbar: { open: false, message: "", severity: "success" },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(userCreationFulfilled, (auth) => {
        auth.snackbar = {
          open: true,
          message: "user_creation_fulfilled",
          severity: "success",
        };
      })
      .addCase(userCreationRejected, (auth) => {
        auth.snackbar = {
          open: true,
          message: "user_creation_rejected",
          severity: "error",
        };
      })
      .addCase(resendActivationEmailFulfilled, (auth) => {
        auth.snackbar = {
          open: true,
          message: "resend_activation_email_fulfilled",
          severity: "success",
        };
      })
      .addCase(resendActivationEmailRejected, (auth) => {
        auth.snackbar = {
          open: true,
          message: "resend_activation_email_rejected",
          severity: "error",
        };
      })
      .addCase(authenticationFulfilled, (auth) => {
        auth.snackbar = {
          open: true,
          message: "authentication_fulfilled",
          severity: "success",
        };
      })
      .addCase(authenticationRejected, (auth) => {
        auth.snackbar = {
          open: true,
          message: "authentication_rejected",
          severity: "error",
        };
      })
      .addCase(passwordResetFulfilled, (auth) => {
        auth.snackbar = {
          open: true,
          message: "password_reset_fulfilled",
          severity: "success",
        };
      })
      .addCase(passwordResetRejected, (auth) => {
        auth.snackbar = {
          open: true,
          message: "password_reset_rejected",
          severity: "error",
        };
      })
      .addCase(passwordUpdateFulfilled, (auth) => {
        auth.snackbar = {
          open: true,
          message: "password_update_fulfilled",
          severity: "success",
        };
      })
      .addCase(passwordUpdateRejected, (auth) => {
        auth.snackbar = {
          open: true,
          message: "password_update_rejected",
          severity: "error",
        };
      });
  },
  reducers: {
    snackbarOpened: (auth, action) => {
      auth.snackbar = {
        open: true,
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    snackbarClosed: (auth) => {
      auth.snackbar = {
        ...auth.snackbar,
        open: false,
      };
    },
  },
});

export const openSnackbar = (payload: {
  message: string;
  severity: string;
}) => ({
  type: snackbarOpened.type,
  payload,
});

export const closeSnackbar = () => ({
  type: snackbarClosed.type,
});

const { snackbarOpened, snackbarClosed } = authSlice.actions;

export const getSnackbar = (state: any) => state.ui.auth.snackbar;

export default authSlice.reducer;
