import * as OBC from 'openbim-components';
import { properties } from '../../properties/properties';

export const getKeyFromName = (name: string): number | null => {
    for (const key in OBC.IfcCategoryMap) {
        if (OBC.IfcCategoryMap[key] === name) {
            return Number(key);
        }
    }
    return null;
}

export const getNameFromType = (type: number): string | null => {
    return OBC.IfcCategoryMap[type];
}

export const getIfcElementThicknessByExpressId = (
    getModelPropertiesByExpressId: (expressId: number) => properties[] | null,
    ifc_element_express_id: number
): Number => {
    const IFC_WIDTH_SEARCH_VALUE_NAME = "Width";

    const props = getModelPropertiesByExpressId(ifc_element_express_id);
    let ifcElementThickness = 0;
    if (props !== null) {
        for (const prop of props) {
            for (const value of prop.values) {
                if (value.name === IFC_WIDTH_SEARCH_VALUE_NAME) {
                    ifcElementThickness = Number(value.value) * 1000;
                }
            }
        }
    }
    return ifcElementThickness
}