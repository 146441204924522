import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { IConstructionMaterial } from 'types';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';

interface ConstructionMaterialInfoPopoverProps {
    popoverAnchorEl: HTMLElement | null;
    handlePopoverClose: () => void;
    isPopoverOpen: boolean;
    hoveredConstructionMaterial: IConstructionMaterial | undefined
}

const ConstructionMaterialInfoPopover: React.FC<ConstructionMaterialInfoPopoverProps> = ({
    popoverAnchorEl,
    handlePopoverClose,
    isPopoverOpen,
    hoveredConstructionMaterial,
}) => {
    const { t } = useTranslation("component_catalogue", {
        keyPrefix: "selected_element.dialogs.layerFillings.retrieve.material_popover",
    });

    return (
        <Popover
            id="mouse-over-popover"
            sx={{
                pointerEvents: 'none',
            }}
            open={isPopoverOpen}
            anchorEl={popoverAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <Box sx={{ m: 2 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                    {hoveredConstructionMaterial?.name ? hoveredConstructionMaterial?.name : null}
                </Typography>
                <Typography variant="h6">
                    {t("thermal_performance")}
                </Typography>
                <Divider sx={{ mb: 1 }} />
                <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                            <Typography sx={{ mr: 1 }} variant="body1">{"λ = "}</Typography>
                            <Typography sx={{ mr: 1 }} variant="body1">{"μ = "}</Typography>
                            <Typography sx={{ mr: 1 }} variant="body1">{"ρ = "}</Typography>
                            <Typography sx={{ mr: 1 }} variant="body1">{"c = "}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                {hoveredConstructionMaterial?.meta_information.physical_properties.thermal_conductivity ? (
                                    <Typography sx={{ mr: 1 }} variant="body1">{`${hoveredConstructionMaterial?.meta_information.physical_properties.thermal_conductivity} W/mk`}</Typography>
                                ) : (
                                    <Typography sx={{ mr: 1 }} variant="body1">{`${t("no_material_prop_data")}`}</Typography>
                                )}
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                {hoveredConstructionMaterial?.meta_information.physical_properties.water_vapor_diffusion_resistance_factor ? (
                                    <Typography sx={{ mr: 1 }} variant="body1">{`${hoveredConstructionMaterial?.meta_information.physical_properties.water_vapor_diffusion_resistance_factor} (min - max) `}</Typography>
                                ) : (
                                    <Typography sx={{ mr: 1 }} variant="body1">{`${t("no_material_prop_data")}`}</Typography>
                                )}
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                {hoveredConstructionMaterial?.meta_information.physical_properties.raw_density ? (
                                    <Typography sx={{ mr: 1 }} variant="body1">{`${hoveredConstructionMaterial?.meta_information.physical_properties.raw_density} kg/m3`}</Typography>
                                ) : (
                                    <Typography sx={{ mr: 1 }} variant="body1">{`${t("no_material_prop_data")}`}</Typography>
                                )}
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                {hoveredConstructionMaterial?.meta_information.physical_properties.heat_capacity ? (
                                    <Typography sx={{ mr: 1 }} variant="body1">{`${hoveredConstructionMaterial?.meta_information.physical_properties.heat_capacity} J/(Kg * K)`}</Typography>
                                ) : (
                                    <Typography sx={{ mr: 1 }} variant="body1">{`${t("no_material_prop_data")}`}</Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                            <Typography variant="body1" color="text.secondary" >{t("thermal_conductivity")}</Typography>
                            <Typography variant="body1" color="text.secondary" >{t("water_vapor_diffusion_resistance_factor")}</Typography>
                            <Typography variant="body1" color="text.secondary" >{t("raw_density")}</Typography>
                            <Typography variant="body1" color="text.secondary" >{t("heat_capacity")}</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <Typography variant="h6">
                        {t("fire_performance")}
                    </Typography>
                    <Divider sx={{ mb: 1 }} />
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography sx={{ mr: 1 }} variant="body1">{`${t("fire_reaction_class")} (EN) = `}</Typography>
                        {hoveredConstructionMaterial?.meta_information.fire_protection_properties.fire_reaction_class ? (
                            <Typography sx={{ mr: 1 }} variant="body1">{`${hoveredConstructionMaterial?.meta_information.fire_protection_properties.fire_reaction_class}`.toUpperCase()}</Typography>
                        ) : (
                            <Typography sx={{ mr: 1 }} variant="body1">{`${t("no_material_prop_data")}`}</Typography>
                        )}
                    </Box>
                </Box>
            </Box>
        </Popover>
    );
}

export default ConstructionMaterialInfoPopover;