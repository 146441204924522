import React from "react";
import { Route, Routes } from "react-router-dom";

import Activation from "./users/activation";
import {
  getActivationUrl,
  getResetPasswordConfirmationUrl,
  getResetPasswordUrl,
  getSignInUrl,
  getSignOutUrl,
  getSignUpUrl,
  getUsersUrl,
} from "assets/endpoints/app/authEndpoints";
import Layout from "./layout";
import PasswordResetConfirmation from "./users/passwordResetConfirmation";
import PasswordReset from "./users/passwordReset";
import SignIn from "./signIn/signIn";
import SignOut from "./signOut/signOut";
import SignUp from "./signUp/signUp";

export default function Authentication() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path={getSignInUrl()} element={<SignIn />} />
        <Route path={getSignOutUrl()} element={<SignOut />} />
        <Route path={getSignUpUrl()} element={<SignUp />} />
        <Route path={getUsersUrl()}>
          <Route path={getActivationUrl()} element={<Activation />} />
          <Route path={getResetPasswordUrl()} element={<PasswordReset />} />
          <Route
            path={getResetPasswordConfirmationUrl()}
            element={<PasswordResetConfirmation />}
          />
        </Route>
      </Route>
    </Routes>
  );
}
