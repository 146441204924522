import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import ProjectList from "./list/list";
import Layout from "./item/layout";
import Dashboard from "./item/dashboard/dashboard";
import Stakeholders from "./item/stakeholders/stakeholders";
import ComponentCatalogue from "./item/componentCatalogue/componentCatalogue";
import ConstructionDetails from "./item/componentCatalogue/constructionDetails/constructionDetails";
import Error from "common/components/error/error";
import UploadedFiles from "./item/uploadedFiles/uploadedFiles";
import IfcViewer from "./item/ifcViewer/ifcViewer";
import IfcModedlCreations from "./item/ifcModelCreations/ifcModelCreations";
import PersonalLibrary from "./item/componentCatalogue/personalLibrary/personalLibrary";

export default function Projects() {
  return (
    <Routes>
      <Route index element={<ProjectList />} />
      <Route path="/" element={<Layout />}>
        <Route path=":projectId/">
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="stakeholders" element={<Stakeholders />} />
          <Route path="component-catalogue" element={<ComponentCatalogue />} />
          <Route path="component-catalogue/personal-library" element={<PersonalLibrary />} />
          <Route
            path="component-catalogue/:elementId"
            element={<ConstructionDetails />}
          />
          <Route path="uploaded-files" element={<UploadedFiles />} />
          <Route path="ifc-viewer" element={<IfcViewer />} />
          <Route path="ifc-model-creations" element={<IfcModedlCreations />} />

          <Route path="*" element={<Error httpStatusCode={404} />} />
          <Route element={<Navigate replace to="stakeholders" />} />
        </Route>
      </Route>
    </Routes>
  );
}
