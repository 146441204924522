import Autocomplete from '@mui/material/Autocomplete'
import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import { getIn } from 'formik'

interface IAddressProps {
  newProjectForm: any
}

export default function Address(props: IAddressProps) {
  const { newProjectForm } = props
  const { t } = useTranslation('projects', {
    keyPrefix: 'list.dialog.address',
  })

  return (
    <Grid container spacing={3} sx={{ pt: 2 }}>
      <Grid item xs={12}>
        <TextField
          required
          id='street'
          name='construction_site.street'
          label={t('street')}
          fullWidth={true}
          value={newProjectForm.values.construction_site.street}
          onChange={newProjectForm.handleChange}
          onBlur={newProjectForm.handleBlur}
          error={Boolean(
            getIn(newProjectForm.touched, 'construction_site.street') &&
              getIn(newProjectForm.errors, 'construction_site.street')
          )}
          helperText={
            getIn(newProjectForm.touched, 'construction_site.street') &&
            getIn(newProjectForm.errors, 'construction_site.street')
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='postal_code'
          name='construction_site.postal_code'
          label={t('postal_code')}
          fullWidth={true}
          value={newProjectForm.values.construction_site.postal_code}
          onChange={newProjectForm.handleChange}
          onBlur={newProjectForm.handleBlur}
          error={Boolean(
            getIn(newProjectForm.touched, 'construction_site.postal_code') &&
              getIn(newProjectForm.errors, 'construction_site.postal_code')
          )}
          helperText={
            getIn(newProjectForm.touched, 'construction_site.postal_code') &&
            getIn(newProjectForm.errors, 'construction_site.postal_code')
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='city'
          name='construction_site.city'
          label={t('city')}
          fullWidth={true}
          value={newProjectForm.values.construction_site.city}
          onChange={newProjectForm.handleChange}
          onBlur={newProjectForm.handleBlur}
          error={Boolean(
            getIn(newProjectForm.touched, 'construction_site.city') &&
              getIn(newProjectForm.errors, 'construction_site.city')
          )}
          helperText={
            getIn(newProjectForm.touched, 'construction_site.city') &&
            getIn(newProjectForm.errors, 'construction_site.city')
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id='country'
          name='construction_site.country'
          label={t('country')}
          fullWidth={true}
          value={newProjectForm.values.construction_site.country}
          onChange={newProjectForm.handleChange}
          onBlur={newProjectForm.handleBlur}
          error={Boolean(
            getIn(newProjectForm.touched, 'construction_site.country') &&
              getIn(newProjectForm.errors, 'construction_site.country')
          )}
          helperText={
            getIn(newProjectForm.touched, 'construction_site.country') &&
            getIn(newProjectForm.errors, 'construction_site.country')
          }
        />
      </Grid>
    </Grid>
  )
}
