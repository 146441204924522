import { ReactElement } from 'react';
import { SimpleUIComponent } from 'openbim-components';
import { createRoot, Root } from 'react-dom/client';

export class MaterialUIContainer<T extends HTMLElement = HTMLElement> extends SimpleUIComponent<T> {
    private root: Root | null = null;
    private onCloseCallback: (() => void) | null = null;
    private onUpdateCallback: (() => void) | null = null;

    constructor(components: any, id?: string) {
        super(components, undefined, id);
        this.setVisibilityHandler();
    }

    insertMaterialUIComponent(materialUIComponent: ReactElement): void {
        const container = this.domElement;
        this.root = createRoot(container);
        this.root.render(materialUIComponent);
    }

    updateMaterialUIComponent(materialUIComponent: ReactElement): void {
        if (this.root) {
            this.root.render(materialUIComponent);
            if (this.onUpdateCallback) {
                this.onUpdateCallback();
            }
        } else {
            console.warn('Kein React-Root gefunden. Rufen Sie zuerst insertMaterialUIComponent auf.');
        }
    }

    setOnClose(callback: () => void): void {
        this.onCloseCallback = callback;
    }

    setOnUpdate(callback: () => void): void {
        this.onUpdateCallback = callback;
    }

    private setVisibilityHandler(): void {
        this.onHidden.add(() => {
            if (this.onCloseCallback) {
                this.onCloseCallback();
            }
        });
    }

    dispose(onlyChildren?: boolean): Promise<void> {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
        return super.dispose(onlyChildren);
    }
}