import { combineReducers } from "redux";
import authenticationReducer from "store/entities/authenticationReducer";
import entitiesReducer from "store/entities/entitiesReducer";
import uiReducer from "store/ui/uiReducer";
import { store } from "store/index";

const rootReducer = combineReducers({
  auth: authenticationReducer,
  entities: entitiesReducer,
  ui: uiReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
