import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IConstructionMaterial, ILayer, ILayerFilling, IStakeholderPermission } from 'types';
import { useAppDispatch, useAppSelector } from 'store';
import { getConstructionMaterials } from 'store/entities/projects/componentCatalogue/componentCatalogue.slice';
import { Box, Button, FormControl, IconButton, InputLabel, Tooltip, Typography, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { deleteLayerFilling } from 'store/entities/projects/componentCatalogue/componentCatalogue.actions';
import { getSelectedElement, getSelectedLayer, openLayerFillingCreateDialog, openLayerFillingUpdateDialog, selectLayerFilling } from 'store/ui/projects/componentCatalogue/componentCatalogue.slice';
import { useParams } from 'react-router-dom';
import { getProjectPermissions } from 'store/entities/projects/project.slice';
import { hasProjectPerm } from 'services/authService';
import { projectPermissions } from 'common/utils/constants/auth.constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayerIcon, StudIcon } from './buttonIcons';
import ConstructionMaterialInfoPopover from './constructionMaterialInfoPopover';

interface IMaterialViewTableProps {
}

const LayerFillingsRetrieveDialog: React.FC<IMaterialViewTableProps> = ({ }) => {
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "selected_element.dialogs.layerFillings.retrieve",
  });
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const selectedElement = useAppSelector(getSelectedElement);
  const selectedLayer: ILayer = useAppSelector(getSelectedLayer);
  const constructionMaterials: IConstructionMaterial[] = useAppSelector(getConstructionMaterials);
  const [studDisabled, setStudDisabled] = useState(false);
  const [layerDisabled, setLayerDisabled] = useState(false);

  const userPermissions: IStakeholderPermission[] = useAppSelector(
    getProjectPermissions
  );

  const isMaintainer = hasProjectPerm(userPermissions, [
    projectPermissions.component_catalogue.maintainer,
  ]);

  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<HTMLElement | null>(null);
  const [hoveredConstructionMaterial, setHoveredConstructionMaterial] = useState<IConstructionMaterial>();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, hoveredConstructionMaterial: IConstructionMaterial | undefined) => {
    setPopoverAnchorEl(event.currentTarget);
    setHoveredConstructionMaterial(hoveredConstructionMaterial);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const isPopoverOpen = Boolean(popoverAnchorEl);

  const handleLayerFillingDeleteButtonClicked = (layerFillingId: number) => {
    if (projectId && selectedLayer) dispatch(deleteLayerFilling(+projectId, selectedElement.id, selectedLayer.id, layerFillingId))
  }

  const handleLayerFillingEditButtonClicked = (layerFillingId: number, category: string) => {
    const layerFilling = selectedLayer?.layer_fillings.find((filling) => filling.id === layerFillingId);
    if (projectId && selectedLayer && layerFilling) {
      dispatch(selectLayerFilling(layerFilling));
      dispatch(openLayerFillingUpdateDialog(category));
    }
  }

  const handleAddMaterialButtonClicked = (category: string) => {
    dispatch(openLayerFillingCreateDialog(category));
  };

  useEffect(() => {
    setStudDisabled(false);
    setLayerDisabled(false);

    selectedLayer?.layer_fillings.map((filling: ILayerFilling) => {
      if (filling.category === "stud") {
        setStudDisabled(true);
      }
      if (filling.category === "layer") {
        const material = constructionMaterials.find((material) => material.id === filling.construction_material);
        if (material && !material.can_contain_additional_material) setStudDisabled(true);
        setLayerDisabled(true);
      }
    });
  }, [selectedLayer, constructionMaterials]);

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", mt: 2 }}
      >
        <Typography variant="h6">
          {t("materials")}
        </Typography>
        {isMaintainer && (
          <Box
            sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
          >
            <Button
              color="secondary"
              variant="contained"
              onClick={() => handleAddMaterialButtonClicked("layer")}
              startIcon={<LayerIcon />}
              disabled={layerDisabled}
              sx={{ mr: 1 }}
            >
              {t("add_layer")}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => handleAddMaterialButtonClicked("stud")}
              startIcon={<StudIcon />}
              disabled={studDisabled}
            >
              {t("add_stud")}
            </Button>
          </Box>
        )}
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body1">{t("material")}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{t("type")}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{t("thickness")}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{t("width")}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{t("clearance")}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{t("actions")}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedLayer?.layer_fillings.map((filling: ILayerFilling, index: number) => {
              const constructionMaterial = constructionMaterials.find((material: IConstructionMaterial) => material.id === filling.construction_material);
              return (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 }
                  }}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      aria-owns={isPopoverOpen ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={(event) => handlePopoverOpen(event, constructionMaterial)}
                      onMouseLeave={handlePopoverClose}
                    >
                      {constructionMaterial?.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{filling.category}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{filling.thickness ? `${filling.thickness} mm` : null}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{filling.additional_material_width ? `${filling.additional_material_width} mm` : null}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{filling.additional_material_clearance ? `${filling.additional_material_clearance} mm` : null}</Typography>
                  </TableCell>
                  <TableCell>
                    {isMaintainer && (
                      <>
                        <Tooltip title={t("delete")}>
                          <IconButton aria-label="delete" onClick={() => handleLayerFillingDeleteButtonClicked(filling.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("edit")}>
                          <IconButton aria-label="edit" onClick={() => handleLayerFillingEditButtonClicked(filling.id, filling.category)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedLayer?.layer_fillings.length !== 0 ? null : (
        <Typography variant="subtitle1" sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          {t("no_materials")}
        </Typography>
      )}
      <ConstructionMaterialInfoPopover
        hoveredConstructionMaterial={hoveredConstructionMaterial}
        popoverAnchorEl={popoverAnchorEl}
        handlePopoverClose={handlePopoverClose}
        isPopoverOpen={isPopoverOpen}
      />
    </>
  );
}

export default LayerFillingsRetrieveDialog;