import React from 'react'
import ElementDeleteDialog from './element/deleteDialog'
import ElementUpdateDialog from './element/updateDialog/updateDialog'
import LayersRetrieveDialog from './layers/retrieveDialog/retrieveDialog'
import LayerCreateDialog from './layers/createDialog/createDialog'
import LayerUpdateDialog from './layers/updateDialog/updateDialog'
import LayerFillingCreateDialog from './layerFillings/createDialog/createDialog'
import LayerFillingUpdateDialog from './layerFillings/updateDialog/updateDialog'

const Dialogs: React.FC = () => {
  return (
    <>
      <ElementDeleteDialog />
      <ElementUpdateDialog />
      <LayersRetrieveDialog />
      {/* <LayerCreateDialog /> */}
      <LayerUpdateDialog />
      <LayerFillingCreateDialog />
      <LayerFillingUpdateDialog />
    </>
  )
}

export default Dialogs
