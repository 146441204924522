export const colorThemes = [
  {
    name: 'Light',
    value: 'light',
  },
  {
    name: 'Minimal',
    value: 'minimal',
  },

  {
    name: 'Dark',
    value: 'dark',
  },
]
