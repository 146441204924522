import { combineReducers } from "redux";

import authReducer from "./authentication/authentication.slice";
import navigationReducer from "./navigation/navigation.slice";
import settingsReducer from "./settings/settings.slice";
import componentCatalogueReducer from "./projects/componentCatalogue/componentCatalogue.slice";
import listReducer from "./projects/projects.slice";
import stakeholdersReducer from "./projects/stakeholders/stakeholders.slice";
import dashboardReducer from "./projects/dashboard/dashboard.ui.slice";
import uploadedFilesReducer from "./projects/uploadedFiles/uploadedFiles.slice";
import ifcViewerReducer from "./projects/ifcViewer/ifcViewer.slice";
import ifcModelCreationsReducer from "./projects/ifcModelCreations/ifcModelCreations.slice";

export default combineReducers({
  navigation: navigationReducer,
  settings: settingsReducer,
  auth: authReducer,
  projects: combineReducers({
    list: listReducer,
    selectedProject: combineReducers({
      dashboard: dashboardReducer,
      stakeholders: stakeholdersReducer,
      componentCatalogue: componentCatalogueReducer,
      uploadedFiles: uploadedFilesReducer,
      ifcViewer: ifcViewerReducer,
      ifcModelCreations: ifcModelCreationsReducer,
    }),
  }),
});
