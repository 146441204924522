import React from 'react'
import { useTranslation } from 'react-i18next'
import { IElement, ILayer, ILayerFilling } from 'types'

interface AnnotationsProps {
  element: IElement
  xScale: number
  wallPositionX: number
  wallPositionY: number
  labelsStartY: number
  labelsEndX: number
  labelSpacing: number
  annotationColor: string
  minimalTheme: boolean
}

export const Annotations = (props: AnnotationsProps) => {
  const {
    element,
    xScale,
    wallPositionX,
    wallPositionY,
    labelsStartY,
    labelsEndX,
    labelSpacing,
    annotationColor,
    minimalTheme,
  } = props
  const { t } = useTranslation('common', { keyPrefix: 'element_image' })

  let x = wallPositionX
  let y = labelsStartY

  return !minimalTheme ? (
    <>
      <text
        x={wallPositionX - 15}
        y={wallPositionY}
        alignmentBaseline='hanging'
        textAnchor='end'
        style={{ fill: annotationColor, fontSize: '1rem' }}
      >
        {t('inner')}
      </text>
      {element.layers.map((layer: ILayer, index: number) => {
        if (layer.layer_fillings.length === 0) return null;
        const layerThickness = layer.layer_fillings.length !== 0 ? Math.max(...layer.layer_fillings.map((filling: ILayerFilling) => { return filling.thickness })) : 0;
        let labelsStartX = x + layerThickness * xScale * 0.5
        x += layerThickness * xScale

        let labelPositionY = y
        y += labelSpacing

        return (
          <React.Fragment key={index}>
            <line
              x1={labelsStartX}
              y1={labelPositionY}
              x2={labelsEndX}
              y2={labelPositionY}
              stroke={annotationColor}
              style={{ strokeWidth: 0.5 }}
            />
            <text
              x={labelsEndX + 10}
              y={labelPositionY}
              alignmentBaseline='middle'
              style={{ fill: annotationColor, fontSize: '1rem' }}
            >
              {`${layer.position} - ${layer.name}`}
            </text>
          </React.Fragment>
        )
      })}
      <text
        x={wallPositionX + element.performance.total_width * xScale + 15}
        y={0}
        alignmentBaseline='hanging'
        textAnchor='start'
        style={{ fill: annotationColor, fontSize: '1rem' }}
      >
        {t('outer')}
      </text>
    </>
  ) : (
    <></>
  )
}
