import { ReactComponent as Soil_terrain_unchanged } from './img/soil_terrain_unchanged.svg';
import { ReactComponent as Soil_terrain_changed } from './img/soil_terrain_changed.svg';
import { ReactComponent as Gravel } from './img/gravel.svg';
import { ReactComponent as Sand } from './img/sand.svg';
import { ReactComponent as Brickwork } from './img/brickwork.svg';
import { ReactComponent as Concrete_reinforced } from './img/concrete_reinforced.svg';
import { ReactComponent as Concrete_unreinforced } from './img/concrete_unreinforced.svg';
import { ReactComponent as Steel_metal } from './img/steel_metal.svg';
import { ReactComponent as Sealing } from './img/sealing.svg';
import { ReactComponent as Sealant } from './img/sealant.svg';
import { ReactComponent as Plaster } from './img/plaster.svg';
import { ReactComponent as Insulation } from './img/insulation.svg';
import { ReactComponent as Solidwood_lengthwise } from './img/solidwood_lengthwise.svg';
import { ReactComponent as Solidwood_crosswise } from './img/solidwood_crosswise.svg';
import { IConstructionMaterial } from 'types';

type IPattern = {
    [key in IConstructionMaterial["visual_representation_category"]]: IPatternEntry;
}

export interface IPatternEntry {
    patternSvgElement: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    isStretched: boolean;
    keepAspectRatio_on_isStretched: boolean;
}

/* 
ensure to use the PatternTemplate.svg when creating 
new patterns with width: 100, height: 200

patternSvgElement: the patternas a rect component e.g:
import { ReactComponent as Soil_terrain_unchanged } from './img/soil_terrain_unchanged.svg';

isStretched: indicates weather an svg needs to be streched in its frame or
gets patterned in the frame (stretch svg width to frame width)

keepAspectRatio_on_isStretched: when isStretched is true, indicates weather the svg has the 
right aspect ratio
*/
export const patterns: IPattern = {
    soil_terrain_unchanged:
    {
        patternSvgElement: Soil_terrain_unchanged,
        isStretched: false,
        keepAspectRatio_on_isStretched: false
    },

    soil_terrain_changed:
    {
        patternSvgElement: Soil_terrain_changed,
        isStretched: false,
        keepAspectRatio_on_isStretched: false
    },

    gravel:
    {
        patternSvgElement: Gravel,
        isStretched: false,
        keepAspectRatio_on_isStretched: false
    },

    sand:
    {
        patternSvgElement: Sand,
        isStretched: true,
        keepAspectRatio_on_isStretched: false
    },

    concrete_unreinforced:
    {
        patternSvgElement: Concrete_unreinforced,
        isStretched: false,
        keepAspectRatio_on_isStretched: false
    },

    concrete_reinforced:
    {
        patternSvgElement: Concrete_reinforced,
        isStretched: false,
        keepAspectRatio_on_isStretched: false
    },

    brickwork:
    {
        patternSvgElement: Brickwork,
        isStretched: false,
        keepAspectRatio_on_isStretched: false
    },

    solidwood_crosswise:
    {
        patternSvgElement: Solidwood_crosswise,
        isStretched: true,
        keepAspectRatio_on_isStretched: false
    },

    solidwood_lengthwise:
    {
        patternSvgElement: Solidwood_lengthwise,
        isStretched: false,
        keepAspectRatio_on_isStretched: false
    },

    steel_metal:
    {
        patternSvgElement: Steel_metal,
        isStretched: false,
        keepAspectRatio_on_isStretched: false
    },

    plaster:
    {
        patternSvgElement: Plaster,
        isStretched: false,
        keepAspectRatio_on_isStretched: false
    },

    insulation:
    {
        patternSvgElement: Insulation,
        isStretched: true,
        keepAspectRatio_on_isStretched: true
    },

    sealing:
    {
        patternSvgElement: Sealing,
        isStretched: true,
        keepAspectRatio_on_isStretched: false
    },

    sealant:
    {
        patternSvgElement: Sealant,
        isStretched: false,
        keepAspectRatio_on_isStretched: false
    },
}
