import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useAppSelector } from "store";
import { getProjectStatusList } from "store/entities/projects/projects.slice";
import { IProjectStatus } from "types";

interface IProps {
  projectForm: any;
}

const ProjectUpdateForm: React.FC<IProps> = ({ projectForm }) => {
  const { t } = useTranslation("dashboard", {
    keyPrefix: "project.dialogs.update",
  });

  const statusChoices = useAppSelector(getProjectStatusList);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    projectForm.setFieldValue(`bookmark`, event.target.checked);
  };

  return (
    <form id="project-update-form" onSubmit={projectForm.handleSubmit}>
      <FormControl component="fieldset" sx={{ width: "100%" }}>
        <FormGroup aria-label="position" row>
          <Grid container spacing={3} sx={{ py: 3 }}>
            <Grid item xs={12}>
              <TextField
                id="name"
                name="name"
                label={t("name")}
                variant="outlined"
                fullWidth
                value={projectForm.values.name}
                onBlur={projectForm.handleBlur}
                error={
                  projectForm.touched.name && Boolean(projectForm.errors.name)
                }
                helperText={projectForm.touched.name && projectForm.errors.name}
                onChange={projectForm.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="status-label">{t("status")}</InputLabel>
                <Select
                  labelId="status-label"
                  id="status"
                  name="status"
                  label={t("status")}
                  value={projectForm.values.status}
                  onChange={projectForm.handleChange}
                >
                  {statusChoices.map(
                    (elementCategory: IProjectStatus, index: number) => (
                      <MenuItem key={index} value={elementCategory.value}>
                        {elementCategory.label}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </FormGroup>
      </FormControl>
      <FormControl component="fieldset" sx={{ width: "100%" }}>
        <FormLabel component="legend">{t("construction_site")}</FormLabel>
        <FormGroup aria-label="position" row>
          <Grid container spacing={3} sx={{ pt: 3 }}>
            <Grid item xs={12}>
              <TextField
                id="construction_site.street"
                name="construction_site.street"
                label={t("street")}
                fullWidth
                variant="outlined"
                value={projectForm.values.construction_site.street}
                onBlur={projectForm.handleBlur}
                error={
                  projectForm.touched.construction_site &&
                  projectForm.touched.construction_site.street &&
                  Boolean(projectForm.errors.construction_site) &&
                  Boolean(projectForm.errors.construction_site.street)
                }
                helperText={
                  projectForm.touched.construction_site &&
                  projectForm.touched.construction_site.street &&
                  projectForm.errors.construction_site &&
                  projectForm.errors.construction_site.street
                }
                onChange={projectForm.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="construction_site.postal_code"
                name="construction_site.postal_code"
                label={t("postal_code")}
                fullWidth
                variant="outlined"
                value={projectForm.values.construction_site.postal_code}
                onBlur={projectForm.handleBlur}
                error={
                  projectForm.touched.construction_site &&
                  projectForm.touched.construction_site.postal_code &&
                  Boolean(projectForm.errors.construction_site) &&
                  Boolean(projectForm.errors.construction_site.postal_code)
                }
                helperText={
                  projectForm.touched.construction_site &&
                  projectForm.touched.construction_site.postal_code &&
                  projectForm.errors.construction_site &&
                  projectForm.errors.construction_site.postal_code
                }
                onChange={projectForm.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="construction_site.city"
                name="construction_site.city"
                label={t("city")}
                fullWidth
                variant="outlined"
                value={projectForm.values.construction_site.city}
                onBlur={projectForm.handleBlur}
                error={
                  projectForm.touched.construction_site &&
                  projectForm.touched.construction_site.city &&
                  Boolean(projectForm.errors.construction_site) &&
                  Boolean(projectForm.errors.construction_site.city)
                }
                helperText={
                  projectForm.touched.construction_site &&
                  projectForm.touched.construction_site.city &&
                  projectForm.errors.construction_site &&
                  projectForm.errors.construction_site.city
                }
                onChange={projectForm.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="construction_site.country"
                name="construction_site.country"
                label={t("country")}
                fullWidth
                variant="outlined"
                value={projectForm.values.construction_site.country}
                onBlur={projectForm.handleBlur}
                error={
                  projectForm.touched.construction_site &&
                  projectForm.touched.construction_site.country &&
                  Boolean(projectForm.errors.construction_site) &&
                  Boolean(projectForm.errors.construction_site.country)
                }
                helperText={
                  projectForm.touched.construction_site &&
                  projectForm.touched.construction_site.country &&
                  projectForm.errors.construction_site &&
                  projectForm.errors.construction_site.country
                }
                onChange={projectForm.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={t("bookmark")}
                value={projectForm.values.bookmark}
                labelPlacement="end"
                control={
                  <Checkbox
                    icon={<BookmarkBorderIcon />}
                    checkedIcon={<BookmarkIcon />}
                    checked={projectForm.values.bookmark}
                    onChange={handleChange}
                  />
                }
              />
            </Grid>
          </Grid>
        </FormGroup>
      </FormControl>
    </form>
  );
};

export default ProjectUpdateForm;
