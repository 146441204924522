import * as OBC from 'openbim-components';
import * as WEBIFC from "web-ifc";
import { FragmentsGroup } from "bim-fragment";

export interface PropertiesIndexMap {
    [modelID: string]: { [expressID: string]: Set<number> };
}

interface property {
    name: string;
    value: any;
}

export interface properties {
    type: string;
    name: string;
    values: property[];
}

export const initializePropertiesIndexMap = (model: FragmentsGroup) => {
    const relationsToProcess = [
        WEBIFC.IFCRELDEFINESBYPROPERTIES,
        WEBIFC.IFCRELDEFINESBYTYPE,
        WEBIFC.IFCRELASSOCIATESMATERIAL,
        WEBIFC.IFCRELCONTAINEDINSPATIALSTRUCTURE,
        WEBIFC.IFCRELASSOCIATESCLASSIFICATION,
        WEBIFC.IFCRELASSIGNSTOGROUP,
    ];

    const properties = model.properties;
    const newIndexMap: PropertiesIndexMap = {};
    if (!newIndexMap[model.uuid]) {
        newIndexMap[model.uuid] = {};
    }
    if (!properties) throw new Error("FragmentsGroup properties not found");
    const setEntities = [WEBIFC.IFCPROPERTYSET, WEBIFC.IFCELEMENTQUANTITY];
    for (const relation of relationsToProcess) {
        OBC.IfcPropertiesUtils.getRelationMap(
            properties,
            relation,
            (relationID, relatedIDs) => {
                const relationEntity = properties[relationID];
                if (!setEntities.includes(relationEntity.type))
                    if (!newIndexMap[model.uuid][relationID]) {
                        newIndexMap[model.uuid][relationID] = new Set<number>();
                    }
                for (const expressID of relatedIDs) {
                    if (!newIndexMap[model.uuid][expressID]) {
                        newIndexMap[model.uuid][expressID] = new Set<number>();
                    }

                    newIndexMap[model.uuid][expressID].add(relationID);
                }
            }
        );
    }
    return newIndexMap;
}

export const getPropertiesByExpressId = (indexMap: PropertiesIndexMap, model: FragmentsGroup, id: string) => {
    if (!model.properties) return null;
    const map = indexMap[model.uuid];
    if (!map) return null;
    const indices = map[id];

    if (indices) {
        const properties: properties[] = []

        // get attributes
        let element = null;
        for (const key in model.properties) {
            if (model.properties[key].expressID === Number(id)) {
                element = model.properties[key];
                break;
            }
        }
        if (element !== null) {
            const property: properties = { type: "IFCELEMENTATTRIBUTES", name: "Attributes", values: [] };
            if (element.constructor) {
                const name = element.constructor.name
                const propEntry = { name: "IFC Class", value: name };
                property.values.push(propEntry);
            }
            for (const key in element) {
                if (element[key] && typeof element[key] === 'object' && 'value' in element[key]) {
                    //if (typeof element[key].value === 'string') {
                    const propValue = element[key].value;
                    const propEntry = { name: key, value: propValue };
                    property.values.push(propEntry);
                    //}

                }
            }
            properties.push(property);
        }

        // check for p und q - sets
        const indicesArray = Array.from(indices);
        for (const index of indicesArray) {
            const pset = cloneProperty(model.properties[index]);
            if (!pset) continue;

            // get propertysets if existing
            const props = OBC.IfcPropertiesUtils.getPsetProps(model.properties, pset.expressID)
            if (props !== null) {
                const name = OBC.IfcPropertiesUtils.getEntityName(model.properties, pset.expressID)?.name
                if (name !== null) {
                    const property: properties = { type: "IFCELEMENTPROPERTYSET", name: name, values: [] }
                    for (const prop of props) {
                        const propName = OBC.IfcPropertiesUtils.getEntityName(model.properties, prop)?.name
                        const propValue = model.properties[prop]?.NominalValue?.value
                        if (propName && propValue !== undefined && propValue !== null) {
                            const propEntry = { name: propName, value: propValue };
                            property.values.push(propEntry);
                        }
                    }
                    properties.push(property)
                }
            }

            // get quantitysets if existing
            const quants = OBC.IfcPropertiesUtils.getQsetQuantities(model.properties, pset.expressID)
            if (quants !== null) {
                const name = OBC.IfcPropertiesUtils.getEntityName(model.properties, pset.expressID)?.name
                if (name !== null) {
                    const property: properties = { type: "IFCELEMENTQUANTITY", name: name, values: [] }
                    for (const quant of quants) {
                        const quantName = OBC.IfcPropertiesUtils.getEntityName(model.properties, quant)?.name
                        const quantValue = OBC.IfcPropertiesUtils.getQuantityValue(model.properties, quant)?.value
                        if (quantName && quantValue !== undefined && quantValue !== null) {
                            const propEntry = { name: quantName, value: quantValue };
                            property.values.push(propEntry);
                        }
                    }
                    properties.push(property)
                }
            }
        }
        return properties;
    }
    return null;
}

const cloneProperty = (
    item: { [name: string]: any },
    result: { [name: string]: any } = {}
) => {
    if (!item) {
        return result;
    }
    for (const key in item) {
        const value = item[key];

        const isArray = Array.isArray(value);
        const isObject = typeof value === "object" && !isArray && value !== null;

        if (isArray) {
            result[key] = [];
            const subResult = result[key] as any[];
            clonePropertyArray(value, subResult);
        } else if (isObject) {
            result[key] = {};
            const subResult = result[key];
            cloneProperty(value, subResult);
        } else {
            result[key] = value;
        }
    }
    return result;
}

const clonePropertyArray = (item: any[], result: any[]) => {
    for (const value of item) {
        const isArray = Array.isArray(value);
        const isObject = typeof value === "object" && !isArray && value !== null;

        if (isArray) {
            const subResult = [] as any[];
            result.push(subResult);
            clonePropertyArray(value, subResult);
        } else if (isObject) {
            const subResult = {} as any;
            result.push(subResult);
            cloneProperty(value, subResult);
        } else {
            result.push(value);
        }
    }
}