import { createSlice } from "@reduxjs/toolkit";
import type { IIfcConElementAssignment } from "types";


type SliceState = {
  list: IIfcConElementAssignment[];
  loading: {
    isLoading: boolean,
    type: "" | "fetch" | "creation" | "update" | "delete";
    content: string,
  };
  lastFetch: null | number;
};

const initialState: SliceState = {
  list: [],
  loading: {
    isLoading: false,
    type: "",
    content: "",
  },
  lastFetch: null,
};

const ifcConElementAssignmentsSlice = createSlice({
  name: "ifcConElementAssignments",
  initialState,
  reducers: {
    ifcConElementAssignmentsRequested: (ifcConElementAssignments) => {
      ifcConElementAssignments.loading.isLoading = true;
      ifcConElementAssignments.loading.type = "fetch";
    },
    ifcConElementAssignmentsReceived: (ifcConElementAssignments, action) => {
      ifcConElementAssignments.list = action.payload;
      ifcConElementAssignments.lastFetch = Date.now();
      ifcConElementAssignments.loading.isLoading = false;
      ifcConElementAssignments.loading.type = "";
    },
    ifcConElementAssignmentsRequestFailed: (ifcConElementAssignments) => {
      ifcConElementAssignments.loading.isLoading = false;
      ifcConElementAssignments.loading.type = "";
    },
    ifcConElementAssignmentsCreateRequested: (ifcConElementAssignments, action) => {
      ifcConElementAssignments.loading.isLoading = true;
      ifcConElementAssignments.loading.type = "creation";
      ifcConElementAssignments.loading.content = action.payload;
    },
    ifcConElementAssignmentsCreateFulfilled: (ifcConElementAssignments, action) => {
      ifcConElementAssignments.list.push(action.payload);
      ifcConElementAssignments.loading.isLoading = false;
      ifcConElementAssignments.loading.content = "";
      ifcConElementAssignments.loading.type = "";
    },
    ifcConElementAssignmentsCreateFailed: (ifcConElementAssignments) => {
      ifcConElementAssignments.loading.isLoading = false;
      ifcConElementAssignments.loading.content = "";
      ifcConElementAssignments.loading.type = "";
    },
    ifcConElementAssignmentsDeleteRequested: (ifcConElementAssignments, action) => {
      ifcConElementAssignments.loading.isLoading = true;
      ifcConElementAssignments.loading.type = "delete";
      ifcConElementAssignments.loading.content = action.payload;
    },
    ifcConElementAssignmentsDeleteFulfilled: (ifcConElementAssignments, action) => {
      const oldCreation = action.payload;
      const index = ifcConElementAssignments.list.findIndex(
        (ifcConElementAssignment) => ifcConElementAssignment.created_at === oldCreation.created_at
      );
      ifcConElementAssignments.list.splice(index, 1);
      ifcConElementAssignments.loading.isLoading = false;
      ifcConElementAssignments.loading.content = "";
      ifcConElementAssignments.loading.type = "";
    },
    ifcConElementAssignmentsDeleteFailed: (ifcConElementAssignments) => {
      ifcConElementAssignments.loading.isLoading = false;
      ifcConElementAssignments.loading.content = "";
      ifcConElementAssignments.loading.type = "";
    },
    ifcConElementAssignmentsUpdateRequested: (ifcConElementAssignments, action) => {
      ifcConElementAssignments.loading.isLoading = true;
      ifcConElementAssignments.loading.type = "update";
      ifcConElementAssignments.loading.content = action.payload;
    },
    ifcConElementAssignmentsUpdateFulfilled: (ifcConElementAssignments, action) => {
      const newIfcConElementAssignment = action.payload;
      const updatedList = ifcConElementAssignments.list.map((ifcConElementAssignment) =>
        ifcConElementAssignment.id === newIfcConElementAssignment.id ? newIfcConElementAssignment : ifcConElementAssignment
      );
      ifcConElementAssignments.list = updatedList;
      ifcConElementAssignments.loading.isLoading = false;
      ifcConElementAssignments.loading.content = "";
      ifcConElementAssignments.loading.type = "";
    },
    ifcConElementAssignmentsUpdateFailed: (ifcConElementAssignments) => {
      ifcConElementAssignments.loading.isLoading = false;
      ifcConElementAssignments.loading.content = "";
      ifcConElementAssignments.loading.type = "";
    },
  },
});

export const {
  ifcConElementAssignmentsRequested,
  ifcConElementAssignmentsReceived,
  ifcConElementAssignmentsRequestFailed,
  ifcConElementAssignmentsCreateRequested,
  ifcConElementAssignmentsCreateFulfilled,
  ifcConElementAssignmentsCreateFailed,
  ifcConElementAssignmentsDeleteRequested,
  ifcConElementAssignmentsDeleteFulfilled,
  ifcConElementAssignmentsDeleteFailed,
  ifcConElementAssignmentsUpdateRequested,
  ifcConElementAssignmentsUpdateFulfilled,
  ifcConElementAssignmentsUpdateFailed,
} = ifcConElementAssignmentsSlice.actions;
export default ifcConElementAssignmentsSlice.reducer;

export const getIfcConElementAssignments = (state: any) =>
  state.entities.projects.selectedProject.ifcViewer.ifcConElementAssignments.list;

export const getIfcConElementAssignmentsLoading = (state: any) =>
  state.entities.projects.selectedProject.ifcViewer.ifcConElementAssignments.loading;
