import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import React, { useState, useEffect } from "react";
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Slide from "@mui/material/Slide";
import Zoom from "@mui/material/Zoom";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import { getRoles } from "store/entities/projects/stakeholders/stakeholders.slice";
import {
  addStakeholder,
  fetchRoles,
} from "store/entities/projects/stakeholders/stakeholders.actions";
import {
  getStakeholderDialogs,
  handleAddStakeholderDialogClose,
} from "store/ui/projects/stakeholders/stakeholders.slice";
import { useAppDispatch, useAppSelector } from "store";
import type { IRoleOption } from "types";

const ZoomTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

const AddStakeholderDialog: React.FC = () => {
  const { t } = useTranslation("stakeholders", {
    keyPrefix: "add_dialog",
  });
  const dialogs = useAppSelector(getStakeholderDialogs);
  const roles = useAppSelector(getRoles);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    if (projectId !== undefined) {
      dispatch(fetchRoles(+projectId));
    }
  }, []);

  const { projectId } = useParams();

  const [initialValues, setInitialValues] = useState<{
    email: string;
    role: string;
  }>({
    email: "",
    role: "",
  });

  const validationSchema = yup.object({
    email: yup.string().required(t("required") as string),
    role: yup.string().required(t("required") as string),
  });

  const stakeholderForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      if (projectId !== undefined) {
        dispatch(addStakeholder(+projectId, values));
        dispatch(handleAddStakeholderDialogClose());
        stakeholderForm.resetForm();
      }
    },
  });

  const handleAddStakeholderClose = () => {
    dispatch(handleAddStakeholderDialogClose());
    stakeholderForm.resetForm();
  };

  const [value, setValue] = useState<{
    value: string;
    label: string;
  }>({
    value: "",
    label: "",
  });

  useEffect(() => {
    const index = roles.findIndex(
      (role: IRoleOption) => role.value === stakeholderForm.values.role
    );
    if (index !== -1) {
      setValue(roles[index]);
    }
  }, [stakeholderForm, roles]);

  return (
    <Dialog
      fullScreen={matches ? false : true}
      open={dialogs.addStakeholder}
      TransitionComponent={matches ? ZoomTransition : SlideTransition}
      keepMounted
      onClose={handleAddStakeholderClose}
      aria-describedby="dialog-slide-edit"
    >
      {matches ? (
        <DialogTitle>{t("add_stakeholder")}</DialogTitle>
      ) : (
        <AppBar sx={{ position: "relative" }} color="inherit">
          <Toolbar>
            <IconButton
              edge="start"
              onClick={handleAddStakeholderClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t("add_stakeholder")}
            </Typography>
            <Button autoFocus type="submit" form="add-stakeholder-form">
              {t("save")}
            </Button>
          </Toolbar>
        </AppBar>
      )}
      <DialogContent sx={matches ? { minWidth: "400px" } : { width: "100%" }}>
        <form id="add-stakeholder-form" onSubmit={stakeholderForm.handleSubmit}>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="email"
                name="email"
                label={t("email")}
                value={stakeholderForm.values.email}
                onBlur={stakeholderForm.handleBlur}
                error={
                  stakeholderForm.touched.email &&
                  Boolean(stakeholderForm.errors.email)
                }
                helperText={
                  stakeholderForm.touched.email &&
                  Boolean(stakeholderForm.errors.email)
                }
                onChange={stakeholderForm.handleChange}
                fullWidth
                autoComplete="email"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disableClearable
                includeInputInList
                filterSelectedOptions
                id="role"
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(e, value) => {
                  stakeholderForm.setFieldValue("role", value.value);
                }}
                value={value}
                options={roles.map((role: IRoleOption) => role)}
                getOptionLabel={(option: IRoleOption) => option.label}
                renderOption={(props: any, option: IRoleOption) => (
                  <li key={option.value} {...props}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label={t("role")} />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {matches && (
          <>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleAddStakeholderClose}>{t("cancel")}</Button>
            <Button type="submit" form="add-stakeholder-form">
              {t("save")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddStakeholderDialog;
