import { combineReducers } from "redux";
import listReducer from "./projects/projects.slice";
import stakeholdersReducer from "./projects/stakeholders/stakeholders.slice";
import componentCatalogueReducer from "./projects/componentCatalogue/componentCatalogue.slice";
import settingsReducer from "./settings/settings.slice";
import projectReducer from "./projects/project.slice";
import uploadedFilesReducer from "./projects/uploadedFiles/uploadedFiles.slice";
import ifcModelCreationsReducer from "./projects/ifcModelCreations/ifcModelCreations.slice";
import ifcViewerReducerReducer from "./projects/ifcViewer/ifcViewerReducer";

export default combineReducers({
  settings: settingsReducer,
  projects: combineReducers({
    list: listReducer,
    selectedProject: combineReducers({
      project: projectReducer,
      stakeholders: stakeholdersReducer,
      componentCatalogue: componentCatalogueReducer,
      uploadedFiles: uploadedFilesReducer,
      ifcViewer: ifcViewerReducerReducer,
      ifcModelCreations: ifcModelCreationsReducer,
    }),
  }),
});
