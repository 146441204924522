import React from 'react'
import jsPDF from 'jspdf'
import { callAddBarlowSemiCondensedRegular } from './fonts/barlowSemiCondensedRegular'
import { callAddBarlowSemiCondensedBold } from './fonts/barlowSemiCondensedBold'
import { TFunction } from 'i18next'

import content from './content'
import footer from './footer'
import header from './header'
import titlePage from './titlePage'

export const generatePDF = async (
  constructionElements: any,
  project: any,
  t: TFunction<'component_catalogue', 'pdf_export'>
) => {
  const filteredList = constructionElements

  var doc = new jsPDF({ unit: 'mm', format: 'a4' })
  await jsPDF.API.events.push(['addFonts', callAddBarlowSemiCondensedRegular])
  await jsPDF.API.events.push(['addFonts', callAddBarlowSemiCondensedBold])
  doc.setFont('BarlowSemiCondensed-Regular', 'normal')

  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height

  const layout = {
    PageWidth: pageWidth,
    PageHeight: pageHeight,
    StartX: 20,
    EndX: pageWidth - 20,
    StartY: 20,
    EndY: pageHeight - 20,
    ContentWidth: pageWidth - 2 * 20,
    ContentHeight: pageHeight - 2 * 20,
    PageCenterX: pageWidth / 2,
  }

  const fontSizes = {
    TitleFontSize: 14,
    SubTitleFontSize: 12,
    NormalFontSize: 10,
    SmallFontSize: 9,
  }

  const lineSpacing = 12
  doc.setDrawColor(102, 102, 102)

  let startY = layout.StartY

  titlePage(project, doc, layout, fontSizes, lineSpacing, t)

  await content(filteredList, doc, layout, fontSizes, lineSpacing, t)

  //toc
  header(project, doc, layout, fontSizes, lineSpacing, t)
  footer(doc, layout, fontSizes)

  await Promise.all([content]).then(() => {
    doc.save(`component_catalog`)
  })
}
