import React from 'react'
import { useAppSelector } from 'store'
import { getConstructionMaterials } from 'store/entities/projects/componentCatalogue/componentCatalogue.slice'
import { IConstructionMaterial, IElement, ILayer, ILayerFilling } from 'types'
import { useTheme } from '@mui/material/styles';
import WallPattern from './patterns/wallPattern';

interface WallElementProps {
  element: IElement
  xScale: number
  wallPositionX: number
  wallPositionY: number
  wallHeight: number
  strokeColor: string
  yScale: number
  wallBaselineOffsetY: number
}

export const Layers = (props: WallElementProps) => {
  const {
    element,
    xScale,
    wallPositionX,
    wallPositionY,
    wallHeight,
    strokeColor,
    yScale,
    wallBaselineOffsetY
  } = props

  let x = wallPositionX
  const constructionMaterials: IConstructionMaterial[] = useAppSelector(getConstructionMaterials);
  const theme = useTheme();

  return (
    <g key={`${element.id}-layers`}>
      {element.layers.map((layer: ILayer) => {
        if (layer.layer_fillings.length === 0) { return null };

        let layerPositionX = x
        const layerThickness = layer.layer_fillings.length !== 0 ? Math.max(...layer.layer_fillings.map((filling: ILayerFilling) => { return filling.thickness })) : 0;
        x += layerThickness * xScale

        // sort layer fillings to always render layer in the background
        const orderedLayerFillings = layer.layer_fillings.slice().sort((a, b) => {
          if (a.category === 'layer' && b.category !== 'layer') {
            return -1;
          } else if (a.category !== 'layer' && b.category === 'layer') {
            return 1;
          } else {
            return 0;
          }
        });

        const layerBoxBaseRender = [
          // the base frame for a layer without a filling - it gets either filled with a layer or with studs
          <rect
            id={`wall-layer-outer-frame-${layer.id}`}
            x={layerPositionX}
            y={wallPositionY}
            width={layerThickness * xScale}
            height={wallHeight}
            stroke={strokeColor}
            strokeWidth={1.5}
            fill={"none"}
          />
        ]

        const layerFillingsRender = orderedLayerFillings.map((filling: ILayerFilling) => {
          const constructionMaterial = constructionMaterials.find((material: IConstructionMaterial) => material.id === filling.construction_material);
          if (constructionMaterial === undefined) { return null };

          // Aligning of the filling rendering if smaller thickness than layer
          const isLeftAligned = true;

          const x = isLeftAligned ? layerPositionX : layerPositionX + (layerThickness - filling.thickness);

          const rectWidth = filling.thickness * xScale;
          // rect if filling is a layer material
          if (filling.category === "layer") {
            return (
              <g key={`layer-pattern-${layer.id}-${filling.id}`}>
                <defs>
                  <WallPattern
                    id={`layer-pattern-${layer.id}-${filling.id}`}
                    x={x}
                    y={wallPositionY}
                    frameWidth={rectWidth}
                    frameHeight={rectWidth}
                    material={constructionMaterial.visual_representation_category}
                  />
                </defs>
                <rect
                  id={`wall-layer-outer-frame-${layer.id}`}
                  x={x}
                  y={wallPositionY}
                  width={rectWidth}
                  height={wallHeight}
                  stroke={strokeColor}
                  strokeWidth={1.5}
                  fill={`url(#layer-pattern-${layer.id}-${filling.id})`}
                />
              </g>
            );
            // rect if filling is a stud material
          } else if (filling.category === "stud") {
            // rotate the filling 90 degrees
            const isRotated = false;

            const materialWidth = filling.additional_material_width * yScale
            const studIntervall = (filling.additional_material_clearance + filling.additional_material_width) * yScale

            // filling rect base x and y coords
            const rectX = isLeftAligned ? layerPositionX : layerPositionX + (layerThickness - filling.thickness)
            const rectY = wallPositionY + wallBaselineOffsetY - (materialWidth / 2)

            const numPossibleStuds = Math.ceil((wallHeight / studIntervall))

            return (
              <g>
                {
                  Array.from({ length: numPossibleStuds * 2 }, (_, index) => {
                    const i = index % numPossibleStuds;
                    const isNegative = index >= numPossibleStuds;
                    const studsRectY = isNegative ? rectY + (-i * studIntervall) : rectY + (i * studIntervall);
                    const rectKey = `wall-layer${layer.id}-${isNegative ? 'background-negative' : 'background'}-${i}`;
                    const fillKey = `wall-layer${layer.id}-${isNegative ? 'fill-negative' : 'fill'}-${i}`;

                    return (
                      <React.Fragment key={rectKey}>
                        {/* -------- filling background -----------  */}
                        <rect
                          id={rectKey}
                          x={rectX}
                          y={studsRectY}
                          width={filling.thickness * xScale}
                          height={materialWidth}
                          stroke={strokeColor}
                          strokeWidth={1.5}
                          fill={theme.palette.background.default}
                        />
                        {/* -------- filling material fill pattern -----------  */}
                        <WallPattern
                          id={`stud-pattern-${layer.id}-${filling.id}-${index}`}
                          x={rectX}
                          y={studsRectY}
                          frameWidth={filling.thickness * xScale}
                          frameHeight={materialWidth}
                          material={constructionMaterial.visual_representation_category}
                        />
                        {/* -------- filling -----------  */}
                        <rect
                          id={fillKey}
                          x={rectX}
                          y={studsRectY}
                          width={filling.thickness * xScale}
                          height={materialWidth}
                          stroke={strokeColor}
                          strokeWidth={1.5}
                          fill={`url(#stud-pattern-${layer.id}-${filling.id}-${index})`}
                        />
                      </React.Fragment>
                    );
                  })
                }
              </g>
            );
          }
          return null;
        });

        return layerFillingsRender.concat(layerBoxBaseRender)
      })}
    </g>
  );
}
