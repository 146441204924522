import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { projectTabs } from "assets/menu";
import { useSelector } from "react-redux";
import { getProjectPermissions } from "store/entities/projects/project.slice";
import { hasProjectPerm } from "services/authService";
import { IStakeholderPermission } from "types";

interface ProjectTabsProps {
  isProjectsPath: any;
}

export default function ProjectTabs(props: ProjectTabsProps) {
  const { isProjectsPath } = props;
  const { t } = useTranslation("navigation", {
    keyPrefix: "tabs",
  });
  const userPermissions: IStakeholderPermission[] = useSelector(
    getProjectPermissions
  );
  const projectId = isProjectsPath.params.projectId;
  const location = useLocation();

  const [openTab, setOpenTab] = useState(0);

  useEffect(() => {
    const currentTabIndex = projectTabs.findIndex((projectTab) =>
      matchPath(
        `projects/${projectId}/${projectTab.url}`,
        location.pathname
      )
    );
    if (currentTabIndex !== -1) {
      setOpenTab(currentTabIndex);
    }
  }, [location, projectId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setOpenTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={openTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        indicatorColor="primary"
      >
        {projectTabs.map((projectTab: any, index: number) => (
          <Tab
            key={projectTab.id}
            label={t(projectTab.name)}
            component={RouterLink}
            to={`projects/${projectId}/${projectTab.url}`}
            disabled={!hasProjectPerm(userPermissions, projectTab.permissions)}
          />
        ))}
      </Tabs>
    </Box>
  );
}