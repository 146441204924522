import React, { ReactNode } from 'react'
import NotFound from './notFound/notFound'
import BadRequest from './badRequest/badRequest'
import { Container } from '@mui/system'
import { useTheme } from '@mui/material/styles'

interface ErrorProps {
  httpStatusCode: number
}

const Error: React.FC<ErrorProps> = ({ httpStatusCode }) => {
  const theme = useTheme()

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: `calc(100vh - ${theme.topNavigation.appbar.height}px)`,
      }}
    >
      {getContentByErrorCode(httpStatusCode)}
    </Container>
  )
}

export default Error

const getContentByErrorCode = (errorCode: number) => {
  let content: ReactNode
  switch (errorCode) {
    case 400:
      content = <BadRequest />
      break
    case 404:
      content = <NotFound />
      break
    default:
      content = <NotFound />
      break
  }
  return content
}
