import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store'
import { getConstructionMaterials } from 'store/entities/projects/componentCatalogue/componentCatalogue.slice'
import { IConstructionMaterial, IElement, ILayer, ILayerFilling } from 'types'

interface DimensionAnnotationsProps {
  element: IElement
  wallPositionX: number
  wallPositionY: number
  wallHeight: number
  labelSpacing: number
  annotationColor: string
  minimalTheme: boolean
  wallBaselineOffsetY: number
  yScale: number
}

export const DimensionAnnotations = (props: DimensionAnnotationsProps) => {
  const {
    element,
    wallPositionX,
    wallPositionY,
    wallHeight,
    labelSpacing,
    annotationColor,
    minimalTheme,
    wallBaselineOffsetY,
    yScale
  } = props
  const { t } = useTranslation('common', { keyPrefix: 'element_image' })
  const constructionMaterials: IConstructionMaterial[] = useAppSelector(getConstructionMaterials);

  let x = wallPositionX

  return !minimalTheme ? (
    <>
      {element.layers.map((layer: ILayer, index: number) => {
        if (layer.layer_fillings.length === 0) return null;

        return layer.layer_fillings.filter(filling => filling.category === "stud").map((filling) => {
          const constructionMaterial = constructionMaterials.find((material: IConstructionMaterial) => material.id === filling.construction_material);
          if (constructionMaterial === undefined) { return null };

          const materialWidth = filling.additional_material_width * yScale
          const studIntervall = (filling.additional_material_clearance + filling.additional_material_width) * yScale

          const labelYTop = wallPositionY + wallBaselineOffsetY - (materialWidth / 2)
          const labelYBot = labelYTop + studIntervall

          let labelsStartX = x - labelSpacing
          x -= labelSpacing
          return (
            <>
              {/* -------- dimension-line -----------  */}
              <line
                x1={labelsStartX}
                y1={labelYTop}
                x2={labelsStartX}
                y2={labelYBot}
                stroke={annotationColor}
                style={{ strokeWidth: 0.5 }}
              />
              {/* -------- dimension-dividers -----------  */}
              <line
                x1={labelsStartX - 5}
                y1={labelYTop}
                x2={labelsStartX + 5}
                y2={labelYTop}
                stroke={annotationColor}
                style={{ strokeWidth: 0.5 }}
              />
              <line
                x1={labelsStartX - 5}
                y1={labelYTop + materialWidth}
                x2={labelsStartX + 5}
                y2={labelYTop + materialWidth}
                stroke={annotationColor}
                style={{ strokeWidth: 0.5 }}
              />
              <line
                x1={labelsStartX - 5}
                y1={labelYBot}
                x2={labelsStartX + 5}
                y2={labelYBot}
                stroke={annotationColor}
                style={{ strokeWidth: 0.5 }}
              />
              {/* -------- dimensions -----------  */}
              <text
                x={labelsStartX}
                y={labelYTop + materialWidth / 2}
                alignmentBaseline='baseline'
                text-anchor="middle"
                transform={`rotate(-90 ${labelsStartX} ${labelYTop + materialWidth / 2})`}
                style={{ fill: annotationColor, fontSize: '1rem' }}
              >
                {filling.additional_material_width}
              </text>
              <text
                x={labelsStartX}
                y={labelYBot - ((labelYBot - (labelYTop + materialWidth)) / 2)}
                alignmentBaseline='baseline'
                text-anchor="middle"
                transform={`rotate(-90 ${labelsStartX} ${labelYBot - ((labelYBot - (labelYTop + materialWidth)) / 2)})`}
                style={{ fill: annotationColor, fontSize: '1rem' }}
              >
                {filling.additional_material_clearance}
              </text>
            </>
          )
        })
      })}
    </>
  ) : (
    <></>
  )
}
