import { createSvgIcon } from "@mui/material/utils";

// const StudIcon = createSvgIcon(
//   <svg width="800px" height="800px" viewBox="0 0 24 24" id="Line" xmlns="http://www.w3.org/2000/svg">
//     <rect id="primary" x="6" y="3" width="12" height="18" rx="1" style="fill:none;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px"></rect>
//     <path id="primary-2" data-name="primary" d="M14.22,3a19.44,19.44,0,0,1-.88,3.5c-.91,2.67-.2,5.53,4.66,6" style="fill:none;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px"></path>
//     <path id="primary-3" data-name="primary" d="M9.52,11A8.86,8.86,0,0,0,8,7.55,5.13,5.13,0,0,0,6,6" style="fill:none;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px"></path>
//     <path id="primary-4" data-name="primary" d="M12,21c0-2-.55-4.61-3.33-5.07A12.6,12.6,0,0,1,6,15.14" style="fill:none;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px"></path
//     ><path id="primary-5" data-name="primary" d="M15,16a9.6,9.6,0,0,0,3,2" style="fill:none;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px"></path>
//     </svg>,
//   'Stud',
// );

export const StudIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" focusable="false" viewBox="0 0 16 16">
    <path fill="none" stroke="currentColor" stroke-linejoin="round" d="M 14,15.5 H 2 C 1.7,15.5 1.5,15.3 1.5,15 V 1 C 1.5,0.7 1.7,0.5 2,0.5 h 12 c 0.3,0 0.5,0.2 0.5,0.5 v 14 c 0,0.3 -0.2,0.5 -0.5,0.5 z" />
    <path fill="none" stroke="currentColor" stroke-linejoin="round" d="M 3.7857143,0.52857143 C 4.8840451,1.5339084 5.447331,8.5860276 1.4368421,9.743609" />
    <path fill="none" stroke="currentColor" stroke-linejoin="round" d="M 6.0233083,0.55263158 C 8.5680781,2.1002694 8.6873679,6.1714856 1.6052632,12.245864" />
    <path fill="none" stroke="currentColor" stroke-linejoin="round" d="M 8.862406,0.60075188 C 10.879648,3.9232665 7.0334768,16.930792 1.7496241,15.133082" />
    <path fill="none" stroke="currentColor" stroke-linejoin="round" d="M 12.447368,0.52857143 C 17.271762,7.9987551 10.379173,8.9360057 10.988722,0.69699177" />
    <path fill="none" stroke="currentColor" stroke-linejoin="round" d="M 14,15.5 H 2 C 1.7,15.5 1.5,15.3 1.5,15 V 1 C 1.5,0.7 1.7,0.5 2,0.5 h 12 c 0.3,0 0.5,0.2 0.5,0.5 v 14 c 0,0.3 -0.2,0.5 -0.5,0.5 z m 0.37218,-6.8390977 c 1.124085,4.6655917 -6.5570534,6.0138007 -4.321804,6.6887207" />
    <path fill="none" stroke="currentColor" stroke-linejoin="round" d="M 14,15.5 H 2 C 1.7,15.5 1.5,15.3 1.5,15 V 1 C 1.5,0.7 1.7,0.5 2,0.5 h 12 c 0.3,0 0.5,0.2 0.5,0.5 v 14 c 0,0.3 -0.2,0.5 -0.5,0.5 z M 14.37218,7.5541353 C 12.304329,7.1999703 7.8136413,17.589543 1.7496241,15.133082" />
  </svg>,
  'Stud',
);



export const LayerIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" focusable="false" viewBox="0 0 16 16">
    <path fill="none" stroke="currentColor" stroke-linejoin="round" d="M14 15.5H2c-.3 0-.5-.2-.5-.5V1c0-.3.2-.5.5-.5h12c.3 0 .5.2.5.5v14c0 .3-.2.5-.5.5zM7.5.5v15m-6-4h6m0-7h7" />
  </svg>,
  'Layer',
);
