import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  getAuthenticationUrl,
  getSignUpConfirmationUrl,
  getSignInUrl,
  getSignUpUrl,
} from "assets/endpoints/app/authEndpoints";
import { useAppDispatch } from "store";
import { resetPasswordConfirm } from "store/entities/authentication/authentication.actions";

export default function PasswordResetConfirmation() {
  const { t } = useTranslation("authentication", {
    keyPrefix: "password_reset",
  });
  const { uid, token } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState({
    new_password: false,
    re_new_password: false,
  });

  const handleClickShowPassword = (key: keyof typeof showPassword) => {
    setShowPassword({ ...showPassword, [key]: !showPassword[key] });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    setInitialValues({
      uid: uid as string,
      token: token as string,
      new_password: "",
      re_new_password: "",
    });
  }, []);

  const [initialValues, setInitialValues] = useState({
    uid: "",
    token: "",
    new_password: "",
    re_new_password: "",
  });

  const validationSchema = yup.object({
    new_password: yup
      .string()
      .required(t("required") as string)
      .min(
        8,
        t("new_password_must_contain_at_least", {
          min_number: 8,
        }) as string
      )
      .matches(
        /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{6,20}$/,
        t("new_password_must_contain_number_and_letter") as string
      ),
    re_new_password: yup
      .string()
      .required(t("required") as string)
      .oneOf(
        [yup.ref("new_password")],
        t("new_passwords_must_match") as string
      ),
  });

  const resetPasswordForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      navigate(getAuthenticationUrl() + getSignInUrl(), { replace: true });
      dispatch(resetPasswordConfirm(values));
      setInitialValues({
        uid: "",
        token: "",
        new_password: "",
        re_new_password: "",
      });
    },
  });

  return (
    <Box>
      <Typography component="h1" variant="h5" textAlign={"center"}>
        {t("set_new_password")}
      </Typography>
      <Box
        component="form"
        id="password-reset-form"
        noValidate
        onSubmit={resetPasswordForm.handleSubmit}
        sx={{ mt: 3 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="new_password"
              name="new_password"
              label={t("new_password")}
              value={resetPasswordForm.values.new_password}
              onBlur={resetPasswordForm.handleBlur}
              error={
                resetPasswordForm.touched.new_password &&
                Boolean(resetPasswordForm.errors.new_password)
              }
              helperText={
                resetPasswordForm.touched.new_password &&
                Boolean(resetPasswordForm.errors.new_password)
              }
              onChange={resetPasswordForm.handleChange}
              fullWidth
              autoComplete="new_password"
              variant="outlined"
              required
              type={showPassword.new_password ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle current new_password visibility"
                      onClick={() => handleClickShowPassword("new_password")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword.new_password ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="re_new_password"
              name="re_new_password"
              label={t("re_new_password")}
              value={resetPasswordForm.values.re_new_password}
              onBlur={resetPasswordForm.handleBlur}
              error={
                resetPasswordForm.touched.re_new_password &&
                Boolean(resetPasswordForm.errors.re_new_password)
              }
              helperText={
                resetPasswordForm.touched.re_new_password &&
                Boolean(resetPasswordForm.errors.re_new_password)
              }
              onChange={resetPasswordForm.handleChange}
              fullWidth
              autoComplete="re_new_password"
              variant="outlined"
              required
              type={showPassword.re_new_password ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle current new_password visibility"
                      onClick={() => handleClickShowPassword("re_new_password")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword.re_new_password ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          form="password-reset-form"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {t("submit")}
        </Button>
      </Box>
    </Box>
  );
}
