import React from 'react'
import { TFunction } from 'i18next'

import image from './image'
import layers from './layers'
import notes from './notes'
import requirements from './requirements'

export default async function element(
  element: any,
  doc: any,
  layout: any,
  fontSizes: any,
  lineSpacing: number,
  startY: number,
  t: TFunction<'component_catalogue', 'pdf_export'>,
  section: number,
  subsection: number
) {
  let startX = layout.StartX
  let endX = layout.EndX
  doc.setFontSize(fontSizes.SubTitleFontSize)
  doc.text(`${section}.${subsection} ${element.name}`, startX, startY)
  doc.text(element.identifier, endX, startY, { align: 'right' })
  startY += lineSpacing / 2

  doc.setFontSize(fontSizes.NormalFontSize)

  startY = await image(doc, layout, startY, lineSpacing, element)

  if (element.layers.length !== 0)
    startY = await layers(
      element,
      doc,
      layout,
      fontSizes,
      lineSpacing,
      startY,
      t
    )

  doc.addPage()
  startY = layout.StartY * 2

  startY = await requirements(
    element,
    doc,
    layout,
    fontSizes,
    lineSpacing,
    startY,
    t
  )

  startY = await notes(element, doc, layout, fontSizes, lineSpacing, startY, t)

  startY += lineSpacing
}
