export const getSettingsUrl = () => `/settings/`

export const getAppUrl = () => `app/`

export const getAccountUrl = () => `account/`

export const getProfileUrl = () => `profile/`

export const getPaymentUrl = () => `payment/`

export const getSecurityUrl = () => `security/`
