import React from 'react'
import { Fab, Stack, Tooltip } from '@mui/material';
import BottomNavigation from '../navigation/bottomNavigation';
import { useAppDispatch, useAppSelector } from 'store';
import { getPersonalLibraryActions, openPersonalLibraryCreateDialog, openPersonalLibrarySelectionDialog } from 'store/ui/projects/componentCatalogue/componentCatalogue.slice';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';

const Actions: React.FC = () => {
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "library.actions",
  });
  const dispatch = useAppDispatch();

  const show = useAppSelector(getPersonalLibraryActions);

  const handleCreateNew = () => {
    dispatch(openPersonalLibraryCreateDialog());
  };

  const handleSelection = () => {
    dispatch(openPersonalLibrarySelectionDialog());
  };

  return (
    <>
      <BottomNavigation>
        {show ? (
          <Stack direction="row" spacing={1}>
            <Tooltip title={t("select_descr")}>
              <Fab color="secondary" size="large" variant="extended" onClick={handleSelection}>
                {t("select")}
              </Fab>
            </Tooltip>
            <Tooltip title={t("create")}>
              <Fab color="primary" aria-label="cancel" size="medium" onClick={handleCreateNew}>
                <AddIcon />
              </Fab>
            </Tooltip>
          </Stack>
        ) : (null)}
      </BottomNavigation>
    </>
  );
}

export default Actions
