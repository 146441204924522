import Container from "@mui/material/Container";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import { Outlet } from "react-router-dom";
import Paper from "@mui/material/Paper";
import React from "react";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import { useTheme } from '@mui/material/styles'
import { useAppDispatch, useAppSelector } from "store";
import { useTranslation } from "react-i18next";
import { closeSnackbar, getSnackbar } from "store/ui/settings/settings.slice";
import BuildVersionInfo from "common/components/buildVersionInfo/buildVersionInfo";

interface Snackbar {
  open: boolean;
  message: string;
  severity: AlertColor;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
  }
);

export default function Layout() {
  const { t } = useTranslation("settings");

  const theme = useTheme()
  const dispatch = useAppDispatch();
  const snackbar: Snackbar = useAppSelector(getSnackbar);

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(closeSnackbar());
  };

  return (
    <>
      <Container maxWidth={"md"} sx={{ minHeight: `calc(100vh - ${theme.topNavigation.appbar.height}px)` }}>
        <Paper
          elevation={8}
          sx={{
            m: { xs: 2, sm: 4 },
            p: { xs: 2, sm: 4 },
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Outlet />
        </Paper>
        <BuildVersionInfo />
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        TransitionComponent={Slide}
        sx={{ justifySelf: "center" }}
      >
        <Alert
          severity={snackbar.severity}
          onClose={handleSnackbarClose}
          sx={{ width: "100%" }}
        >
          {t(snackbar.message)}
        </Alert>
      </Snackbar>
    </>
  );
}
