import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { apiRequest } from "common/utils/constants/api.constants";
import { RootState } from "store/rootReducer";
import type { IStakeholder } from "types";

interface DialogsState {
  addStakeholder: boolean;
  updateStakeholder: boolean;
  deleteStakeholder: boolean;
}

interface SliceState {
  selectedStakeholder: IStakeholder;
  dialogs: DialogsState;
}

const initialState: SliceState = {
  selectedStakeholder: {
    id: 0,
    role: "",
    user: 0,
    first_name: "",
    last_name: "",
    email: "",
    invited_at: "",
    invited_by: "",
    invitation_status: "",
    permissions: [],
  },
  dialogs: {
    addStakeholder: false,
    updateStakeholder: false,
    deleteStakeholder: false,
  },
};

const stakeholdersSlice = createSlice({
  name: "stakeholders",
  initialState,
  reducers: {
    stakeholderSelected: (state, action: PayloadAction<IStakeholder>) => {
      state.selectedStakeholder = action.payload;
    },
    addStakeholderDialogOpened: (state) => {
      state.dialogs.addStakeholder = true;
    },
    addStakeholderDialogClosed: (state) => {
      state.dialogs.addStakeholder = false;
    },
    updateStakeholderDialogOpened: (state) => {
      state.dialogs.updateStakeholder = true;
    },
    updateStakeholderDialogClosed: (state) => {
      state.dialogs.updateStakeholder = false;
    },
    deleteStakeholderDialogOpened: (state) => {
      state.dialogs.deleteStakeholder = true;
    },
    deleteStakeholderDialogClosed: (state) => {
      state.dialogs.deleteStakeholder = false;
    },
  },
});

export const {
  stakeholderSelected,
  addStakeholderDialogOpened,
  addStakeholderDialogClosed,
  updateStakeholderDialogOpened,
  updateStakeholderDialogClosed,
  deleteStakeholderDialogOpened,
  deleteStakeholderDialogClosed,
} = stakeholdersSlice.actions;

export default stakeholdersSlice.reducer;

export const selectStakeholder = (payload: IStakeholder) =>
  stakeholderSelected(payload);

export const handleAddStakeholderDialogOpen = () =>
  addStakeholderDialogOpened();

export const handleAddStakeholderDialogClose = () =>
  addStakeholderDialogClosed();

export const handleUpdateStakeholderDialogOpen = () =>
  updateStakeholderDialogOpened();

export const handleUpdateStakeholderDialogClose = () =>
  updateStakeholderDialogClosed();

export const handleDeleteStakeholderDialogOpen = () =>
  deleteStakeholderDialogOpened();

export const handleDeleteStakeholderDialogClose = () =>
  deleteStakeholderDialogClosed();

export const getSelectedStakeholder = (state: RootState) =>
  state.ui.projects.selectedProject.stakeholders.selectedStakeholder;

export const getStakeholderDialogs = (state: RootState) =>
  state.ui.projects.selectedProject.stakeholders.dialogs;
