import React from "react";
import { Grid } from "@mui/material";

import Card from "./card/card";
import ConstructionSite from "./content/constructionSite";
import Dialogs from "./dialogs/dialogs";
import Project from "./content/project";
import { hasPerm } from "services/authService";
import { getProjectPermissions } from "store/entities/projects/project.slice";

const ProjectCard: React.FC = () => {
  return (
    <>
      <Card>
        <Grid container spacing={{ xs: 4, md: 8, lg: 12 }}>
          <Grid item xs={12} md={6}>
            <Project />
          </Grid>
          <Grid item xs={12} md={6}>
            <ConstructionSite />
          </Grid>
        </Grid>
      </Card>
      <Dialogs />
    </>
  );
};

export default ProjectCard;
