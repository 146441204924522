import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PageNotFound } from './illustration'
import { useTheme } from '@mui/system'
import { useNavigate } from 'react-router-dom'

const NotFound: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'error_page' })
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <Grid container maxWidth='sm' spacing={4}>
      <Grid item xs={12}>
        <Typography variant='h5' fontWeight='bold' textAlign='center'>
          {t('not_found')}
        </Typography>
      </Grid>
      <Grid item xs={12} display='flex' justifyContent='center'>
        <Button color='primary' onClick={() => navigate(-1)}>
          {t('return_to_last_location')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <PageNotFound primaryColor={theme.palette.primary.main} />
      </Grid>
    </Grid>
  )
}

export default NotFound
