import { IFileValidationResponse } from "types";

const hasValidExtension = (file: File): boolean => {
    if (process.env.REACT_APP_UPLOADED_FILES_FILE_FORMATS !== undefined) {
        const validFileExtensions = process.env.REACT_APP_UPLOADED_FILES_FILE_FORMATS.split(",");
        let valid = false
        for (let i = 0; i < validFileExtensions.length; i++) {
            if (file.name.toLowerCase().endsWith(`.${validFileExtensions[i]}`)) {
                valid = true
            }
        }
        return valid
    } else {
        return false
    }
}

const doesntExceedFileLimit = (fileIndex: number): boolean => {
    if (process.env.REACT_APP_UPLOADED_FILES_MAX_FILES !== undefined) {
        if (fileIndex >= +process.env.REACT_APP_UPLOADED_FILES_MAX_FILES) {
            return false
        } else {
            return true
        }
    } else {
        return false
    }
}

export const validateFile = (file: File, fileIndex: number): IFileValidationResponse => {
    let errors: string[] = [];
    let valid: boolean = true
    if (!hasValidExtension(file)) {
        errors.push("invald_extension")
        valid = false
    }
    if (!doesntExceedFileLimit(fileIndex)) {
        errors.push("file_limit_exceeded")
        valid = false
    }
    return { valid: valid, errors: errors };
}