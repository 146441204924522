import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React, { useState, useEffect } from "react";
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import Zoom from "@mui/material/Zoom";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import {
  closeElementCreateDialog,
  getElementDialogs,
  getSelectedElement,
} from "store/ui/projects/componentCatalogue/componentCatalogue.slice";
import { createElement } from "store/entities/projects/componentCatalogue/componentCatalogue.actions";
import { useAppSelector } from "store";
import ElementCreateForm from "./form";

const ZoomTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

const ElementCreateDialog: React.FC = () => {
  const { projectId } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "selected_element.dialogs.element.create",
  });

  const open = useAppSelector(getElementDialogs).create;
  const selectedElement = useSelector(getSelectedElement);

  const handleDialogClose = () => {
    dispatch(closeElementCreateDialog());
    elementForm.resetForm();
  };

  const [initialValues, setInitialValues] = useState<{
    name: string;
    category: string;
    identifier: string;
  }>({
    name: "",
    category: "",
    identifier: "",
  });

  const validationSchema = yup.object({
    name: yup.string().required(t("required") as string),
    identifier: yup.string().required(t("required") as string),
    category: yup.string().required(t("required") as string),
  });

  const elementForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      console.log("hi");
      if (projectId !== undefined) {
        dispatch(createElement(+projectId, values));
        handleDialogClose();
        elementForm.resetForm();
      }
    },
  });

  return (
    <Dialog
      fullScreen={matches ? false : true}
      open={open}
      TransitionComponent={matches ? ZoomTransition : SlideTransition}
      keepMounted
      onClose={handleDialogClose}
      aria-describedby="dialog-slide-edit"
    >
      <form id="element-create-form" onSubmit={elementForm.handleSubmit}>
        {matches ? (
          <DialogTitle>
            {t("add_element", {
              element: selectedElement.name,
            })}
          </DialogTitle>
        ) : (
          <AppBar sx={{ position: "relative" }} color="inherit">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleDialogClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {t("add_element", {
                  element: selectedElement.name,
                })}
              </Typography>
              <Button
                autoFocus
                color="inherit"
                type="submit"
                id="element-create-form"
              >
                {t("save")}
              </Button>
            </Toolbar>
          </AppBar>
        )}
        <DialogContent>
          <ElementCreateForm constructionElementForm={elementForm} />
        </DialogContent>
        <DialogActions>
          {matches && (
            <>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleDialogClose}>{t("cancel")}</Button>
              <Button type="submit" id="element-create-form">
                {t("save")}
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ElementCreateDialog;
