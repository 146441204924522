import Grid from "@mui/material/Grid";
import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import Display from "./display";
import Language from "./language";

export default function App() {
  const { t } = useTranslation("settings", { keyPrefix: "app" });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          color="text.primary"
          align="left"
          sx={{ fontWeight: "bold" }}
        >
          {t("appearance")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Display />
      </Grid>
      <Grid item xs={12}>
        <Language />
      </Grid>
    </Grid>
  );
}
