import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import React from 'react'

import List from './list/list'

export default function Stakeholders() {
  return (
    <Grid
      container
      rowSpacing={{ xs: 2, sm: 4, md: 6 }}
      sx={{ mt: 3, justifyContent: 'center' }}
    >
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            width: '100%',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <List />
        </Card>
      </Grid>
    </Grid>
  )
}
