import { Box } from '@mui/material'
import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { HouseForSale } from './illustrations/HouseForSale'
import { HouseUnderConstruction } from './illustrations/HouseUnderConstruction'
import { ScrumBoard } from './illustrations/ScrumBoard'
import { SelectHouse } from './illustrations/SelectHouse'
import { BuildYourHome } from './illustrations/BuildYourHome'
import { Approve } from './illustrations/Approve'
import { Agreement } from './illustrations/Agreement'
import { HouseSearching } from './illustrations/HouseSearching'

interface IImageProps {
  projectStatus: string
}

interface Illustrations {
  [key: string]: JSX.Element
}

const Illustration: React.FC<IImageProps> = ({ projectStatus }) => {
  const theme = useTheme()
  const smallSize = useMediaQuery(theme.breakpoints.up('sm'))

  const illustrations: Illustrations = {
    basic_determination: (
      <ScrumBoard primaryColor={theme.palette.primary.main} />
    ),
    preliminary_design: (
      <SelectHouse primaryColor={theme.palette.primary.main} />
    ),
    design_planning: (
      <BuildYourHome primaryColor={theme.palette.primary.main} />
    ),
    approval_planning: <Approve primaryColor={theme.palette.primary.main} />,
    execution_planning: (
      <HouseUnderConstruction primaryColor={theme.palette.primary.main} />
    ),
    award_of_contracts: <Agreement primaryColor={theme.palette.primary.main} />,
    property_monitoring: (
      <HouseSearching primaryColor={theme.palette.primary.main} />
    ),
    property_management: (
      <HouseForSale primaryColor={theme.palette.primary.main} />
    ),
  }

  const selectedIllustration = illustrations[projectStatus]

  return selectedIllustration
}

export default Illustration
