import { createSlice } from '@reduxjs/toolkit'

const listSlice = createSlice({
  name: 'list',
  initialState: {
    dialogs: { addProject: { open: false } },
  },
  reducers: {
    addProjectDialogOpened: (list) => {
      list.dialogs.addProject.open = true
    },
    addProjectDialogClosed: (list) => {
      list.dialogs.addProject.open = false
    },
  },
})

export const openAddProjectDialog = () => ({
  type: addProjectDialogOpened.type,
})

export const closeAddProjectDialog = () => ({
  type: addProjectDialogClosed.type,
})

export const { addProjectDialogOpened, addProjectDialogClosed } =
  listSlice.actions
export default listSlice.reducer

export const getDialogs = (state: any) => state.ui.projects.list.dialogs
