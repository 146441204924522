import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";
import React, { useEffect, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import Zoom from "@mui/material/Zoom";
import { useAppDispatch, useAppSelector } from "store";
import {
  closeLayerFillingUpdateDialog,
  getLayerFillingDialogs,
  getSelectedLayer,
  getSelectedLayerFilling,
} from "store/ui/projects/componentCatalogue/componentCatalogue.slice";
import LayerFillingUpdateForm from "./form";
import { ILayer, ILayerFilling, ILayerFillingForm, IStakeholderPermission } from "types";
import {
  updateLayerFilling,
} from "store/entities/projects/componentCatalogue/componentCatalogue.actions";
import { useParams } from "react-router-dom";
import { hasProjectPerm } from "services/authService";
import { projectPermissions } from "common/utils/constants/auth.constants";
import { getProjectPermissions } from "store/entities/projects/project.slice";

const ZoomTransition: any = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

const LayerFillingUpdateDialog: React.FC = () => {
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "selected_element.dialogs.layerFillings.update",
  });
  const { projectId, elementId } = useParams();

  const open = useAppSelector(getLayerFillingDialogs).update.open;
  const selectedLayer: ILayer = useAppSelector(getSelectedLayer);
  const selectedLayerFilling: ILayerFilling = useAppSelector(getSelectedLayerFilling);

  const dispatch = useAppDispatch();

  const userPermissions: IStakeholderPermission[] = useAppSelector(
    getProjectPermissions
  );

  const isMaintainer = hasProjectPerm(userPermissions, [
    projectPermissions.component_catalogue.maintainer,
  ]);

  const [initialValues, setInitialValues] = useState<ILayerFillingForm>({
    category: "layer",
    construction_material: 0,
    thickness: 0,
    additional_material_clearance: null,
    additional_material_width: null,
  });

  useEffect(() => {
    setInitialValues({
      category: selectedLayerFilling.category,
      construction_material: selectedLayerFilling.construction_material,
      thickness: selectedLayerFilling.thickness,
      additional_material_clearance: selectedLayerFilling.additional_material_clearance,
      additional_material_width: selectedLayerFilling.additional_material_width,
    });
  }, [selectedLayerFilling]);

  const validationSchema = yup.object({
    thickness: yup
      .number()
      .moreThan(0)
      .required(t("required") as string),
    construction_material: yup
      .number()
      .moreThan(0)
      .required(t("required") as string),
    additional_material_clearance: yup
      .number()
      .moreThan(0)
      .nullable(),
    additional_material_width: yup
      .number()
      .moreThan(0)
      .nullable(),
  });

  const layerFillingForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      if (projectId !== undefined && elementId !== undefined) {
        dispatch(updateLayerFilling(+projectId, +elementId, selectedLayer.id, selectedLayerFilling.id, values));
        dispatch(closeLayerFillingUpdateDialog());
        layerFillingForm.resetForm();
      }
    },
  });

  const handleDialogClose = () => {
    dispatch(closeLayerFillingUpdateDialog());
    layerFillingForm.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      TransitionComponent={ZoomTransition}
      aria-labelledby="layer-filling-update-dialog"
      hideBackdrop
      maxWidth="lg"
    >
      <DialogTitle style={{ cursor: "move" }} id="layer-filling-update-dialog">
        {t("edit_layer_filling_title")}
      </DialogTitle>
      <DialogContent>
        <LayerFillingUpdateForm layerFillingForm={layerFillingForm} isMaintainer={isMaintainer} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleDialogClose}>
          {t("cancel")}
        </Button>
        {isMaintainer && (
          <Button type="submit" form="layerFilling-update-form">
            {t("save")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default LayerFillingUpdateDialog;

const PaperComponent: React.FC = (props: PaperProps) => {
  return (
    <Draggable
      handle="#layer-filling-update-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};
