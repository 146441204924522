import { apiRequest } from "common/utils/constants/api.constants";
import * as componentCatalogueSlice from "./componentCatalogue.slice";
import * as componentCatalogueEndpoints from "assets/endpoints/api/projects/componentCatalogueEndpoints";
import type { IElement, IElementForm } from "types";

export const fetchConstructionElements = (projectId: number) =>
  apiRequest({
    url: componentCatalogueEndpoints.getConstructionElementListUrl(projectId),
    onStart: componentCatalogueSlice.elementsRequested.type,
    onSuccess: componentCatalogueSlice.elementsReceived.type,
    onError: componentCatalogueSlice.elementsRequestFailed.type,
  });

export const fetchConstructionMaterials = () =>
  apiRequest({
    url: componentCatalogueEndpoints.getConstructionMaterialListUrl(),
    onStart: componentCatalogueSlice.constructionMaterialsRequested.type,
    onSuccess: componentCatalogueSlice.constructionMaterialsReceived.type,
    onError: componentCatalogueSlice.constructionMaterialsRequestFailed.type,
  });

export const fetchCategoryChoices = (projectId: number) =>
  apiRequest({
    url: componentCatalogueEndpoints.getCategoryChoicesUrl(projectId),
    onStart: componentCatalogueSlice.categoriesRequested.type,
    onSuccess: componentCatalogueSlice.categoriesReceived.type,
    onError: componentCatalogueSlice.categoriesRequestFailed.type,
  });

export const fetchConstructionSectionChoices = (projectId: number) =>
  apiRequest({
    url: componentCatalogueEndpoints.getConstructionSectionChoicesUrl(
      projectId
    ),
    onStart: componentCatalogueSlice.constructionSectionsRequested.type,
    onSuccess: componentCatalogueSlice.constructionSectionsReceived.type,
    onError: componentCatalogueSlice.constructionSectionsRequestFailed.type,
  });

export const createElement = (projectId: number, element: any) =>
  apiRequest({
    url: componentCatalogueEndpoints.getConstructionElementListUrl(projectId),
    method: "post",
    data: element,
    onSuccess: componentCatalogueSlice.elementCreated.type,
  });

export const deleteElement = (projectId: number, elementId: number) =>
  apiRequest({
    url: componentCatalogueEndpoints.getConstructionElementItemUrl(
      projectId,
      elementId
    ),
    method: "delete",
    onSuccess: componentCatalogueSlice.elementDeleted.type,
    onSuccessPayload: elementId,
  });

export const clearElementFromList = (element: IElement) => ({
  type: componentCatalogueSlice.elementDeleted.type,
  payload: element,
});

export const updateElement = (
  projectId: number,
  elementId: number,
  element: IElementForm
) =>
  apiRequest({
    url: componentCatalogueEndpoints.getConstructionElementItemUrl(
      projectId,
      elementId
    ),
    method: "put",
    data: element,
    onSuccess: componentCatalogueSlice.elementUpdated.type,
  });

export const createLayer = (projectId: number, elementId: number, layer: any) =>
  apiRequest({
    url: componentCatalogueEndpoints.getConstructionElementLayerListUrl(
      projectId,
      elementId
    ),
    method: "post",
    data: layer,
    onSuccess: componentCatalogueSlice.layerCreated.type,
  });

export const deleteLayer = (
  projectId: number,
  elementId: number,
  layerId: number
) =>
  apiRequest({
    url: componentCatalogueEndpoints.getConstructionElementLayerItemUrl(
      projectId,
      elementId,
      layerId
    ),
    method: "delete",
    onSuccess: componentCatalogueSlice.layerDeleted.type,
  });

export const updateLayer = (
  projectId: number,
  elementId: number,
  layerId: number,
  layer: any
) =>
  apiRequest({
    url: componentCatalogueEndpoints.getConstructionElementLayerItemUrl(
      projectId,
      elementId,
      layerId
    ),
    method: "put",
    data: layer,
    onSuccess: componentCatalogueSlice.layerUpdated.type,
  });

export const reorderLayers = (
  element: any,
  startIndex: number,
  endIndex: number
) => ({
  type: componentCatalogueSlice.layerReordered.type,
  payload: {
    element: element,
    startIndex: startIndex,
    endIndex: endIndex,
  },
});

export const createLayerFilling = (projectId: number, elementId: number, layerId: number, layerFilling: any) =>
  apiRequest({
    url: componentCatalogueEndpoints.getConstructionElementLayerLayerFillingListUrl(
      projectId,
      elementId,
      layerId
    ),
    method: "post",
    data: layerFilling,
    onSuccess: componentCatalogueSlice.layerFillingCreated.type,
  });

export const deleteLayerFilling = (
  projectId: number,
  elementId: number,
  layerId: number,
  layerFillingId: number,
) =>
  apiRequest({
    url: componentCatalogueEndpoints.getConstructionElementLayerLayerFillingItemUrl(
      projectId,
      elementId,
      layerId,
      layerFillingId
    ),
    method: "delete",
    onSuccess: componentCatalogueSlice.layerFillingDeleted.type,
  });

export const updateLayerFilling = (
  projectId: number,
  elementId: number,
  layerId: number,
  layerFillingId: number,
  layerFilling: any
) =>
  apiRequest({
    url: componentCatalogueEndpoints.getConstructionElementLayerLayerFillingItemUrl(
      projectId,
      elementId,
      layerId,
      layerFillingId,
    ),
    method: "put",
    data: layerFilling,
    onSuccess: componentCatalogueSlice.layerFillingUpdated.type,
  });