import React from "react";
import { Box, Accordion, AccordionSummary, AccordionDetails, Theme, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ILayerOptimized, IPlattenAusschnitt, IPlattenDimension } from "types";
import BinPlot from "./plots/binPlot";
import LayerPlot from "./plots/layerPlot";

interface LayerVisualizerProps {
    layer: ILayerOptimized;
    colorPreference: "random" | "monocolor" | "bincolor";
}

interface ColorObject {
    fillColor: string;
    lineColor: string;
    lineWidth: number;
}

interface ColorDataEntry {
    [platteId: string]: ColorObject;
}

export type ColorData = ColorDataEntry[];

const getRandomColor = (theme: Theme): ColorObject => {
    const color = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 1)`;
    return {
        fillColor: color,
        lineColor: theme.palette.common.black,
        lineWidth: 1
    };
};

const getSingleColorSecondary = (theme: Theme): ColorObject => {
    return {
        fillColor: theme.palette.secondary.main,
        lineColor: theme.palette.common.black,
        lineWidth: 1
    };
};

const getSingleColorError = (theme: Theme): ColorObject => {
    return {
        fillColor: theme.palette.error.light,
        lineColor: theme.palette.common.black,
        lineWidth: 1
    };
};

export const getPlatteColorFromLayerColorData = (platteId: number, colorData: ColorData, theme: Theme): ColorObject => {
    const entry = colorData.find((data) => data[platteId]);
    if (entry) {
        return entry[platteId];
    }
    return getSingleColorSecondary(theme);
};

export const millimeterPerMeter = 1000;
export const rawBoardWidthMillimeter = 1250;
export const rawBoardWidthMeter = rawBoardWidthMillimeter / millimeterPerMeter;

const LayerVisualizer: React.FC<LayerVisualizerProps> = ({ layer, colorPreference }) => {
    const layerX1 = Math.max(...layer.platten_neu.map(platte => platte.x1));
    const layerTop = Math.max(...layer.platten_neu.map(platte => platte.top));

    const layerSrcX1 = new Set(layer.platten_src.map(item => item.bin_number)).size * 1250 / 1000;
    const layerSrcTop = layer.rohplatten_hoehe_neu / 1000;

    const theme = useTheme();

    const getLayerColorData = (
        platten: IPlattenDimension[] | IPlattenAusschnitt[],
        theme: Theme,
        getRandomColor: (theme: Theme) => ColorObject,
        getSingleColor: (theme: Theme) => ColorObject,
    ): ColorData => {
        if (colorPreference === 'bincolor') {
            const binColors: { [binNumber: number]: ColorObject } = {};
            return platten.map(platte => {
                if (!binColors[platte.bin_number]) {
                    binColors[platte.bin_number] = getRandomColor(theme);
                }
                return {
                    [platte.id]: binColors[platte.bin_number]
                };
            });
        } else {
            return platten.map(platte => {
                const colorObject = colorPreference === 'random' ? getRandomColor(theme) : getSingleColor(theme);
                return {
                    [platte.id]: colorObject
                };
            });
        }
    };


    const colorDataPlattenNeu = getLayerColorData(layer.platten_neu, theme, getRandomColor, getSingleColorSecondary);
    const colorDataPlattenSrc = getLayerColorData(layer.platten_src, theme, getRandomColor, getSingleColorError);

    return (
        <Box sx={{ mt: 2 }}>
            <style>
                {`
                    .js-plotly-plot .plotly .modebar svg {
                        display: inline;
                    }
                `}
            </style>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                    <Typography variant="h6">Layer Baseline</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <LayerPlot
                        layer={layer}
                        layerX1={layerSrcX1}
                        layerTop={layerSrcTop}
                        colorData={colorDataPlattenSrc}
                        plattenType="src"
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                    <Typography variant="h6">Panels Baseline</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <BinPlot layer={layer} colorData={colorDataPlattenSrc} plattenType="src" />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                    <Typography variant="h6">Layer Optimized</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <LayerPlot
                        layer={layer}
                        layerX1={layerX1}
                        layerTop={layerTop}
                        colorData={colorDataPlattenNeu}
                        plattenType="neu"
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                    <Typography variant="h6">Panels Optimized</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <BinPlot layer={layer} colorData={colorDataPlattenNeu} plattenType="neu" />
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default LayerVisualizer;
