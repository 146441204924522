import * as React from 'react'

interface IProps {
  primaryColor: string
}

export const SelectHouse = ({ primaryColor }: IProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      //   width='734.11559'
      //   height='458.67929'
      width='100%'
      height='100%'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 734.11559 458.67929'
    >
      <g>
        <g>
          <polygon
            points='387.13005 451.57101 398.98917 451.56985 404.63062 405.82702 387.12767 405.82824 387.13005 451.57101'
            fill='#ffb6b6'
          />
          <path
            d='M427.76088,451.302h0c.36925,.62189,.56409,2.62906,.56409,3.35226h0c0,2.22293-1.80202,4.02502-4.02502,4.02502h-36.7263c-1.51648,0-2.7459-1.22934-2.7459-2.74591v-1.52902s-1.8168-4.59555,1.92369-10.2598c0,0,4.64894,4.43521,11.59566-2.51151l2.04852-3.71106,14.82851,10.84482,8.21921,1.01172c1.7982,.22131,3.39246-.03436,4.31743,1.52347h.00012Z'
            fill='#2f2e41'
          />
        </g>
        <g>
          <polygon
            points='342.13005 451.57101 353.98917 451.56985 359.63062 405.82702 342.12767 405.82824 342.13005 451.57101'
            fill='#ffb6b6'
          />
          <path
            d='M382.76088,451.302h0c.36925,.62189,.56409,2.62906,.56409,3.35226h0c0,2.22293-1.80202,4.02502-4.02502,4.02502h-36.72631c-1.51648,0-2.7459-1.22934-2.7459-2.74591v-1.52902s-1.81681-4.59555,1.92369-10.2598c0,0,4.64894,4.43521,11.59566-2.51151l2.04852-3.71106,14.82851,10.84482,8.21921,1.01172c1.7982,.22131,3.39246-.03436,4.31743,1.52347h.00014Z'
            fill='#2f2e41'
          />
        </g>
        <polygon
          points='333.39629 205.85551 318.39629 239.85551 337.39629 425.85553 360.89629 427.35553 370.39629 262.85553 379.39629 427.85553 407.39629 424.85553 414.03801 215.39489 333.39629 205.85551'
          fill='#2f2e41'
        />
        <path
          d='M392.49472,70.35552l-30.09843-5.5-6.07874,6.07874-30.92126,12.92126,5,97.00001s-10,6.66142-5,15.8307,4,20.1693,4,20.1693h84.64174l-3.64172-55,10-70-23.36613-11.0625-4.53543-10.4375h-.00002Z'
          fill={primaryColor}
        />
        <path
          id='b-382'
          d='M370.67922,183.36398c8.55096,2.04129,16.53548-.71614,17.83366-6.15823,1.2982-5.44209-4.58109-11.5069-13.13538-13.54778-3.4059-.86212-6.96874-.89606-10.39044-.09895l-36.34-8.1915-3.48054,17.13522,36.19433,6.25613c2.69377,2.2572,5.88904,3.83629,9.31834,4.60509l.00002,.00002Z'
          fill='#ffb6b6'
        />
        <path
          d='M331.39629,88.85552l-6-5s-12,6-14,11-14,53.00001-14,53.00001c0,0-2.35433,26.30624,3.82283,29.65312s45.17717,1.34688,45.17717,1.34688l3-20-16-6-2-64.00001Z'
          fill={primaryColor}
        />
        <circle cx='379.33504' cy='35.58432' r='26.43324' fill='#ffb6b6' />
        <path
          d='M360.37674,59.04964c-3.16664,.28297-8.33604-7.81783-8.10563-15.66315,.14823-5.04705-4.23354-6.03942-4.55911-12.54824-.18969-3.79259,5.63598-4.97511,6.03616-8.01702,.72506-5.51164,4.28094-9.22043,4.72488-9.6713,3.83216-3.89193,6.53676-2.62671,11.0433-7.13736,2.71317-2.71563,2.01797-3.45962,3.84521-4.66415,5.27354-3.47634,14.74936,.29135,20.41591,5.36658,1.96088,1.75623,9.27003-.16323,12.46283,4.79619,3.76167,5.8431,2.88828,17.7594,1.27522,19.33202-.98001,.95544-3.21809-1.37882-9.284-2.87694-5.61601-1.38702-.31822,5.4722-6.81078,4.32115-7.67747-1.36114-9.18057-3.69693-12.05589-2.29598-3.03947,1.48093-5.41446,6.06658-4.72488,9.6713,.34218,1.78881,1.33388,2.78625,.77461,3.69587-1.021,1.66068-2.19258,.03197-3.50221,1.69859-1.52328,1.9385,2.26202,6.9115,1.54924,7.39175-.14362,.09678-.57838,.08416-4.66415-3.84522-1.63144-1.56899-2.10973-2.53248-2.4289-2.38459-.5167,.2394-3.90345,3.39443-2.61026,5.88597,2.01246,3.87731-.56309,3.9981,0,6.60831,.58094,2.69284-1.36913,.1564-3.38159,.33623h.00003Z'
          fill='#2f2e41'
        />
        <path
          id='c-383'
          d='M388.47574,187.47883c-7.79353,4.06776-12.01196,11.38629-9.42239,16.34572s11.00543,5.68114,18.80121,1.61087c3.13638-1.58319,5.84802-3.89445,7.90804-6.74046l32.80383-17.65234-8.61185-15.21732-31.42445,19.01768c-3.51389,.06303-6.96155,.96686-10.05438,2.63585Z'
          fill='#ffb6b6'
        />
        <path
          d='M414.39629,92.85552s9-6,15,10c4.73351,12.62267,13.51254,49.82973,17.00784,64.97489,.92789,4.02048-.41492,8.22028-3.49884,10.96156l-4.009,3.56355-19.5,14.5-13.29527-19.34688,16.14764-9.73579-18.41534-39.94857,10.56299-34.96875-.00002-.00002Z'
          fill={primaryColor}
        />
      </g>
      <g>
        <circle cx='668.82521' cy='47.54448' r='26.5252' fill='#ff6884' />
        <path
          d='M629.69353,213.22126l-29.91588-1.90699-1.61321-134.52105c-.03906-3.26508,1.26269-6.46231,3.57181-8.77142l32.04943-32.04943c4.48645-4.48604,11.54783-4.81024,16.42591-.75223l34.78061,28.93278c2.83746,2.3603,4.43999,5.83076,4.3964,9.52139l-1.19506,101.16658,15.11676,20.99541,.03207,.04441,2.56136,22.19849-76.21019-4.85794Z'
          fill='#e6e6e6'
        />
        <path
          d='M634.72131,67.29423v-6.23781c0-1.64651,1.33958-2.9861,2.9861-2.9861h8.76423c1.64652,0,2.98589,1.33958,2.98589,2.9861v6.23781c0,1.64651-1.33938,2.9861-2.98589,2.9861h-8.76423c-1.64652,0-2.9861-1.33958-2.9861-2.9861Z'
          fill={primaryColor}
        />
        <path
          d='M616.19577,115.71324v-23.07921c0-1.64652,1.33958-2.9861,2.9861-2.9861h12.97458c1.64652,0,2.98589,1.33958,2.98589,2.9861v23.07921c0,1.64652-1.33938,2.9861-2.98589,2.9861h-12.97458c-1.64652,0-2.9861-1.33958-2.9861-2.9861Z'
          fill='#3f3d56'
        />
        <path
          d='M645.52366,169.73665c-1.56819-.05551-2.83273-1.32807-2.8792-2.89667l-.65602-22.30704c-.02796-.95226,.38424-1.83709,1.13091-2.42876,.74668-.59126,1.70285-.78862,2.62345-.54438l.65602,.17516c1.30504,.34826,2.2166,1.53447,2.2166,2.88516v22.13229c0,.8174-.324,1.58053-.91217,2.14835-.56207,.54274-1.29374,.83755-2.07084,.83755-.03618,0-.07237-.00041-.10875-.00164Z'
          fill='#3f3d56'
        />
        <path
          d='M605.73998,168.90075c-.58817-.56782-.91217-1.33095-.91217-2.14835v-22.13229c0-1.35068,.91156-2.5369,2.21681-2.88516l.65602-.17516c.92122-.24423,1.87677-.04687,2.62324,.54438,.74668,.59126,1.15887,1.4765,1.13112,2.42876l-.65622,22.30704c-.04626,1.5686-1.3108,2.84116-2.8792,2.89667-.03639,.00123-.07257,.00164-.10875,.00164-.7769,0-1.50878-.29522-2.07084-.83755Z'
          fill='#3f3d56'
        />
        <path
          d='M618.30095,166.65867v-23.14623c0-1.63398,1.27976-2.94519,2.91332-2.98548l11.79247-.28741c1.63459-.02261,3.00995,1.26023,3.05785,2.89873l.67925,23.43363c.02344,.8137-.27589,1.5834-.8433,2.16726-.567,.58386-1.32766,.90539-2.14156,.90539h-12.47192c-1.64652,0-2.9861-1.33958-2.9861-2.98589Z'
          fill='#3f3d56'
        />
        <path
          d='M688.00232,182.47496l9.24275,16.43155,1.06102,18.47923,7.35968,.46734-2.52621-21.89381-15.15726-21.05174h0c-1.30818,2.35473-1.3006,5.21966,.02002,7.56744Z'
          fill='#ccc'
        />
        <rect
          x='668.01381'
          y='212.30184'
          width='30.00094'
          height='.42107'
          transform='translate(-2.84134 9.35705) rotate(-.78329)'
          fill='#3f3d56'
        />
        <rect
          x='667.74907'
          y='208.49199'
          width='28.44375'
          height='.42105'
          transform='translate(-1.60961 5.33405) rotate(-.44761)'
          fill='#3f3d56'
        />
        <rect
          x='667.50903'
          y='204.84938'
          width='27.21226'
          height='.42111'
          transform='translate(-3.87445 13.33964) rotate(-1.11892)'
          fill='#3f3d56'
        />
        <rect
          x='667.74213'
          y='201.24115'
          width='26.14199'
          height='.42127'
          transform='translate(-6.31017 22.70362) rotate(-1.9017)'
          fill='#3f3d56'
        />
        <rect
          x='667.5033'
          y='197.88618'
          width='25.11852'
          height='.42129'
          transform='translate(-6.54018 24.01605) rotate(-2.01348)'
          fill='#3f3d56'
        />
        <rect
          x='666.47945'
          y='194.48579'
          width='24.45004'
          height='.42103'
          fill='#3f3d56'
        />
        <rect
          x='665.90388'
          y='191.67052'
          width='23.76321'
          height='.42106'
          transform='translate(-2.2019 7.95538) rotate(-.6714)'
          fill='#3f3d56'
        />
        <rect
          x='665.37216'
          y='188.96864'
          width='23.03729'
          height='.42127'
          transform='translate(-5.90507 22.5667) rotate(-1.9017)'
          fill='#3f3d56'
        />
        <rect
          x='664.82339'
          y='185.85453'
          width='22.73979'
          height='.4211'
          transform='translate(-3.16573 11.91313) rotate(-1.00705)'
          fill='#3f3d56'
        />
        <rect
          x='664.10146'
          y='183.07867'
          width='21.78069'
          height='.42124'
          transform='translate(-5.39565 21.17263) rotate(-1.78991)'
          fill='#3f3d56'
        />
        <rect
          x='663.45644'
          y='180.27586'
          width='21.57907'
          height='.42106'
          transform='translate(-1.73033 6.59271) rotate(-.55951)'
          fill='#3f3d56'
        />
        <path
          d='M661.87806,171.50057v-28.55266c0-1.64672,1.33958-2.9863,2.9861-2.9863h13.81665c1.64652,0,2.98589,1.33958,2.98589,2.9863v28.55266c0,1.64631-1.33938,2.98589-2.98589,2.98589h-13.81665c-1.64652,0-2.9861-1.33958-2.9861-2.98589Z'
          fill={primaryColor}
        />
        <path
          d='M629.69353,213.22126l-24.45806-1.55791-.19715-.01275v-18.92848c0-2.39381,1.94749-4.34151,4.34131-4.34151h24.15811c2.39381,0,4.34131,1.9477,4.34131,4.34151v20.49955h-.21052l-7.97499-.00041Z'
          fill='#ccc'
        />
        <path
          d='M663.5622,119.71327v-21.81631c0-1.64652,1.33958-2.9861,2.9861-2.9861h4.55388c1.64652,0,2.98589,1.33958,2.98589,2.9861v21.81631c0,1.64631-1.33938,2.98589-2.98589,2.98589h-4.55388c-1.64652,0-2.9861-1.33958-2.9861-2.98589Z'
          fill='#3f3d56'
        />
        <path
          d='M668.19366,215.32687l-1.2631-18.52553-3.78931-17.68346-1.05259-4.8419h0c-2.16299-1.47083-5.07573,.16528-4.9451,2.77771l.1032,2.06419,3.78931,17.68346,1.2631,18.52553'
          fill='#ccc'
        />
        <path
          d='M583.16463,175.7384l9.24275,16.43155,1.06102,18.47923,7.35968,.46734-2.52621-21.89381-15.15726-21.05174h0c-1.30818,2.35473-1.3006,5.21966,.02002,7.56744Z'
          fill='#ccc'
        />
        <polygon
          points='350.97025 195.80537 351.02452 194.96495 734.11559 219.70472 732.58874 243.92628 350.97025 195.80537'
          fill='#ccc'
        />
      </g>
      <g>
        <circle cx='65.29038' cy='30.29445' r='26.5252' fill='#ff6884' />
        <path
          d='M28.21186,200.82917l2.56136-22.19849,.03207-.04441,15.11676-20.99541-1.19506-101.16658c-.04358-3.69064,1.55894-7.16109,4.3964-9.52139l34.78061-28.93278c4.87808-4.05801,11.93946-3.73381,16.42591,.75223l32.04943,32.04943c2.30911,2.30911,3.61087,5.50635,3.57181,8.77142l-1.61321,134.52105-29.91588,1.90699-76.21019,4.85794Z'
          fill='#e6e6e6'
        />
        <path
          d='M96.40818,53.0303h-8.76423c-1.64652,0-2.98589-1.33959-2.98589-2.9861v-6.23781c0-1.64652,1.33938-2.9861,2.98589-2.9861h8.76423c1.64652,0,2.9861,1.33959,2.9861,2.9861v6.23781c0,1.64652-1.33958,2.9861-2.9861,2.9861Z'
          fill={primaryColor}
        />
        <path
          d='M114.93371,101.44931h-12.97458c-1.64652,0-2.98589-1.33958-2.98589-2.9861v-23.07921c0-1.64652,1.33938-2.9861,2.98589-2.9861h12.97458c1.64652,0,2.9861,1.33958,2.9861,2.9861v23.07921c0,1.64652-1.33958,2.9861-2.9861,2.9861Z'
          fill='#3f3d56'
        />
        <path
          d='M88.48317,152.48827c-.77711,0-1.50878-.29481-2.07084-.83755-.58817-.56782-.91217-1.33095-.91217-2.14835v-22.13229c0-1.35068,.91156-2.5369,2.2166-2.88516l.65602-.17516c.9206-.24423,1.87677-.04687,2.62345,.54438,.74668,.59167,1.15887,1.4765,1.13091,2.42876l-.65602,22.30704c-.04646,1.5686-1.31101,2.84116-2.8792,2.89667-.03639,.00123-.07257,.00164-.10875,.00164Z'
          fill='#3f3d56'
        />
        <path
          d='M126.30476,152.48827c-.03618,0-.07237-.00041-.10875-.00164-1.5684-.05551-2.83294-1.32807-2.8792-2.89667l-.65622-22.30704c-.02775-.95226,.38444-1.8375,1.13112-2.42876,.74647-.59126,1.70203-.78862,2.62324-.54438l.65602,.17516c1.30525,.34826,2.21681,1.53447,2.21681,2.88516v22.13229c0,.8174-.324,1.58053-.91217,2.14835-.56207,.54233-1.29394,.83755-2.07084,.83755Z'
          fill='#3f3d56'
        />
        <path
          d='M112.82854,152.39453h-12.47192c-.8139,0-1.57456-.32153-2.14156-.90539-.56741-.58386-.86674-1.35356-.8433-2.16726l.67925-23.43363c.0479-1.6385,1.42325-2.92134,3.05785-2.89873l11.79247,.28741c1.63357,.04029,2.91332,1.35151,2.91332,2.98548v23.14623c0,1.64631-1.33958,2.98589-2.9861,2.98589Z'
          fill='#3f3d56'
        />
        <path
          d='M46.13329,157.6575h0l-15.15726,21.05174-2.52621,21.89381,7.35968-.46734,1.06102-18.47923,9.24275-16.43155c1.32062-2.34778,1.3282-5.21271,.02002-7.56744Z'
          fill='#ccc'
        />
        <rect
          x='50.89076'
          y='180.26187'
          width='.42107'
          height='30.00094'
          transform='translate(-144.84138 243.68952) rotate(-89.21671)'
          fill='#3f3d56'
        />
        <rect
          x='51.93412'
          y='177.23061'
          width='.42105'
          height='28.44375'
          transform='translate(-139.70937 242.09987) rotate(-89.55239)'
          fill='#3f3d56'
        />
        <rect
          x='52.78987'
          y='174.20378'
          width='.42111'
          height='27.21226'
          transform='translate(-135.80864 237.13275) rotate(-88.88108)'
          fill='#3f3d56'
        />
        <rect
          x='53.09183'
          y='171.13076'
          width='.42127'
          height='26.14199'
          transform='translate(-132.56667 231.36215) rotate(-88.0983)'
          fill='#3f3d56'
        />
        <rect
          x='53.84238'
          y='168.28754'
          width='.42129'
          height='25.11852'
          transform='translate(-128.58125 228.51246) rotate(-87.98652)'
          fill='#3f3d56'
        />
        <rect
          x='43.1861'
          y='177.23576'
          width='24.45004'
          height='.42103'
          fill='#3f3d56'
        />
        <rect
          x='56.11957'
          y='162.74942'
          width='.42106'
          height='23.76321'
          transform='translate(-118.94901 228.91096) rotate(-89.3286)'
          fill='#3f3d56'
        />
        <rect
          x='57.01415'
          y='160.4106'
          width='.42127'
          height='23.03729'
          transform='translate(-116.50877 223.41706) rotate(-88.0983)'
          fill='#3f3d56'
        />
        <rect
          x='57.71175'
          y='157.44516'
          width='.4211'
          height='22.73979'
          transform='translate(-111.88468 223.76141) rotate(-88.99295)'
          fill='#3f3d56'
        />
        <rect
          x='58.91316'
          y='155.14892'
          width='.42124'
          height='21.78069'
          transform='translate(-108.68119 219.948) rotate(-88.21009)'
          fill='#3f3d56'
        />
        <rect
          x='59.65908'
          y='152.44683'
          width='.42106'
          height='21.57907'
          transform='translate(-103.9436 221.50909) rotate(-89.44049)'
          fill='#3f3d56'
        />
        <path
          d='M69.25143,157.23644h-13.81665c-1.64652,0-2.98589-1.33958-2.98589-2.98589v-28.55266c0-1.64672,1.33938-2.9863,2.98589-2.9863h13.81665c1.64652,0,2.9861,1.33958,2.9861,2.9863v28.55266c0,1.64631-1.33958,2.98589-2.9861,2.98589Z'
          fill={primaryColor}
        />
        <path
          d='M96.44706,195.97164h-.21052v-20.49955c0-2.39381,1.94749-4.34151,4.34131-4.34151h24.15811c2.39381,0,4.34131,1.9477,4.34131,4.34151v18.92848l-.19715,.01275-24.45806,1.55791-7.97499,.00041Z'
          fill='#ccc'
        />
        <path
          d='M67.56729,105.44914h-4.55388c-1.64652,0-2.98589-1.33958-2.98589-2.98589v-21.81631c0-1.64652,1.33938-2.9861,2.98589-2.9861h4.55388c1.64652,0,2.9861,1.33958,2.9861,2.9861v21.81631c0,1.64631-1.33958,2.98589-2.9861,2.98589Z'
          fill='#3f3d56'
        />
        <path
          d='M71.81642,198.07684l1.2631-18.52553,3.78931-17.68346,.1032-2.06419c.13062-2.61244-2.78211-4.24855-4.9451-2.77771h0l-1.05259,4.8419-3.78931,17.68346-1.2631,18.52553'
          fill='#ccc'
        />
        <path
          d='M150.97097,150.92094h0l-15.15726,21.05174-2.52621,21.89381,7.35968-.46734,1.06102-18.47923,9.24275-16.43155c1.32062-2.34778,1.3282-5.21271,.02002-7.56744Z'
          fill='#ccc'
        />
        <polygon
          points='1.52685 226.67626 0 202.45469 383.09107 177.71492 383.14534 178.55534 1.52685 226.67626'
          fill='#ccc'
        />
      </g>
    </svg>
  )
}
