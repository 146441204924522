import { useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  AppBar,
  Container,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "store";
import { getConstructionElements } from "store/entities/projects/componentCatalogue/componentCatalogue.slice";
import Image from "./image/image";
import Actions from "./actions/actions";
import Dialogs from "../dialogs/dialogs";
import {
  getPersonalLibraryOpen,
  getPersonalLibraryReturnProjectId,
  getSelectedElement,
  selectElement,
} from "store/ui/projects/componentCatalogue/componentCatalogue.slice";
import type { IElement } from "types";
import ImageContext from "./image/imageContext";
import { fetchConstructionMaterials, fetchConstructionSectionChoices } from "store/entities/projects/componentCatalogue/componentCatalogue.actions";
import { getLocales } from "store/entities/settings/settings.slice";

export default function ConstructionDetails() {
  const { projectId, elementId } = useParams();
  const dispatch = useAppDispatch();

  const locales = useAppSelector(getLocales);
  const isPersonalLibraryOpen = useAppSelector(getPersonalLibraryOpen);
  const elements = useAppSelector(getConstructionElements);
  const personalLibraryReturnProjectId = useAppSelector(getPersonalLibraryReturnProjectId);
  const selectedElement = useAppSelector(getSelectedElement);

  const imageRef = useRef<any>(null);

  useEffect(() => {
    if (elementId !== undefined) {
      let index;
      index = elements.findIndex(
        (element: IElement) => element.id === +elementId
      );
      dispatch(selectElement(elements[index]));
    }
  }, [elements, elementId, projectId, dispatch]);

  useEffect(() => {
    if (projectId !== undefined) {
      dispatch(fetchConstructionMaterials());
      dispatch(fetchConstructionSectionChoices(+projectId));
    }
  }, [locales, projectId]);

  return (
    <Dialog
      fullScreen
      open
      keepMounted
      disableEnforceFocus
      disableAutoFocus
      disableRestoreFocus
      hideBackdrop
    >
      <AppBar
        sx={{
          position: "relative",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        color="inherit"
      >
        <Toolbar>
          <IconButton
            edge="start"
            aria-label="close"
            sx={{ textDecoration: "none" }}
            component={Link}
            to={`/projects/${projectId}/component-catalogue` + (isPersonalLibraryOpen ? `/personal-library` : ``)}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {selectedElement.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <ImageContext.Provider value={imageRef}>
        <Container
          sx={{
            height: "calc(100vh - 64px)",
            overflow: "hidden",
            display: "flex",
          }}
        >
          <Image element={selectedElement} imageRef={imageRef} />
        </Container>
        <Actions />
        <Dialogs />
      </ImageContext.Provider>
    </Dialog>
  );
}
