import React from 'react'

export default function footer(doc: any, layout: any, fontSizes: any) {
  const pageNr = doc.getNumberOfPages()

  let n = 1

  if (pageNr > 1) {
    while (n < pageNr) {
      n++

      doc.setPage(n)

      doc.setFontSize(fontSizes.SmallFontSize)

      doc.text(n + ' / ' + pageNr, layout.PageCenterX, layout.PageHeight - 20, {
        align: 'center',
      })
    }
  }
}
