import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React, { ChangeEvent, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { getMyUserDetails } from "store/entities/authentication/authentication.slice";
import { updateMyProfile } from "store/entities/authentication/authentication.actions";
import { stringToColor } from "common/utils/helpers/stringToColor.helpers";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store";
import {
  closeProfilePictureDialog,
  getDialogs,
  openProfilePictureDialog,
} from "store/ui/settings/settings.slice";

export default function Picture() {
  const { t } = useTranslation("settings", { keyPrefix: "profile" });
  const me = useAppSelector(getMyUserDetails);
  console.log(process.env.REACT_APP_API_URL + me.profile.profile_picture);
  const dispatch = useAppDispatch();
  const dialog = useAppSelector(getDialogs).profilePicture;

  const maxImageSizeKb = 500;
  const [profilePicture, setProfilePicture] = useState<{
    file: any;
    preview: any;
  }>({ file: null, preview: null });

  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setProfilePicture({
        file: event.target.files[0],
        preview: URL.createObjectURL(event.target.files[0]),
      });
    }
  };

  useEffect(() => {
    profilePicture.file !== null && handleAddPhoto();
  }, [profilePicture]);

  const handleCancel = () => {
    dispatch(closeProfilePictureDialog());
    setProfilePicture({ file: null, preview: null });
  };

  const handleAddPhoto = () => {
    let formData = new FormData();
    formData.append("profile_picture", profilePicture.file);
    dispatch(
      updateMyProfile(formData, {
        "Content-Type": "multipart/form-data",
      })
    );
    setProfilePicture({ file: null, preview: null });
  };

  const handleDeletePhoto = () => {
    dispatch(updateMyProfile({ profile_picture: null }));
    setProfilePicture({ file: null, preview: null });
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "top",
        }}
      >
        <Tooltip title={t<string>("set_profile_picture")}>
          <IconButton
            size="large"
            aria-haspopup="true"
            sx={{ p: 0 }}
            onClick={() => dispatch(openProfilePictureDialog())}
          >
            {me.profile.profile_picture ? (
              <Avatar
                alt={me.email}
                src={me.profile.profile_picture}
                sx={{
                  width: { sx: 32, sm: 64 },
                  height: { sx: 32, sm: 64 },
                }}
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: stringToColor(me.email),
                  width: { sx: 32, sm: 64 },
                  height: { sx: 32, sm: 64 },
                }}
              >
                {me.first_name && me.last_name
                  ? `${me.first_name.slice(0, 1).toUpperCase()}${me.last_name
                      .slice(0, 1)
                      .toUpperCase()}`
                  : me.email.slice(0, 2).toUpperCase()}
              </Avatar>
            )}
          </IconButton>
        </Tooltip>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            ml: 2,
          }}
        >
          <Typography gutterBottom sx={{ fontWeight: "bold" }}>
            {me.username}
          </Typography>
          <Typography>{me.email}</Typography>
        </Box>
      </Box>
      <Dialog maxWidth={"sm"} open={dialog.open} onClose={handleCancel}>
        <DialogTitle>
          {t("set_profile_picture")}
          <IconButton
            aria-label="close"
            onClick={handleCancel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          dividers
        >
          <DialogContentText sx={{ pb: 2 }}>
            {t("profile_picture_upload_description")}
          </DialogContentText>
          <Box
            sx={{
              width: { xs: "200px", sm: "400px" },
              height: { xs: "200px", sm: "400px" },
              borderRadius: "50%",
              border:
                profilePicture.file &&
                profilePicture.file.size >= maxImageSizeKb * 1024
                  ? "2px dashed red"
                  : "2px dashed grey",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {me.profile.profile_picture ? (
              <img
                src={me.profile.profile_picture}
                alt={me.email}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            ) : profilePicture.preview ? (
              <img
                src={profilePicture.preview}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            ) : null}
          </Box>
          {profilePicture.file &&
            profilePicture.file.size >= maxImageSizeKb * 1024 && (
              <Typography color={"error.main"}>
                {t("file_too_large")}
              </Typography>
            )}
        </DialogContent>
        <DialogActions disableSpacing>
          <Stack direction="row" spacing={2}>
            <Tooltip title={t("delete")} arrow>
              <IconButton onClick={handleDeletePhoto}>
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("add_photo")} arrow>
              <IconButton component="label" onClick={handleAddPhoto}>
                <AddAPhotoIcon />
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={(event) => {
                    onImageChange(event);
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
