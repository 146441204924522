import { Box, CardActionArea, CardActions, Chip, lighten } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { useTranslation } from "react-i18next";

import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  acceptProjectInvitation,
  getProjectStatusList,
  refuseProjectInvitation,
} from "store/entities/projects/projects.slice";
import { getLocales } from "store/entities/settings/settings.slice";
import { IProject, IProjectStatus } from "types";
import { ProjectInvitation } from "./illustrations/ProjectInvitation";
import { useAppSelector } from "store";

interface InvitationCardProps {
  project: IProject;
}

export default function InvitationCard(props: InvitationCardProps) {
  const { t } = useTranslation("projects", {
    keyPrefix: "list.invitation_card",
  });
  const { project } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.up("sm"));
  const lighterPrimaryLight = lighten(theme.palette.primary.light, 0.6);

  const handleAcceptInvitation = () => {
    dispatch(acceptProjectInvitation(project.id, project.me.id));
  };

  const handleRefuseInvitation = () => {
    dispatch(refuseProjectInvitation(project.id, project.me.id));
  };

  return (
    <>
      {smallSize ? (
        <Card
          sx={{
            width: "100%",
            height: "350px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            backgroundColor:
              theme.palette.mode === "dark"
                ? "primary.light"
                : lighterPrimaryLight,
          }}
          variant="elevation"
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              p: 2,
              overflow: "hidden",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ProjectInvitation primaryColor={theme.palette.primary.main} />
          </Box>
          <CardContent
            sx={{
              width: "100%",
            }}
          >
            <Typography gutterBottom variant="subtitle2" fontWeight="bold">
              {project.name}
            </Typography>
            <Typography gutterBottom variant="subtitle2">
              {t("invited_by", { stakeholder: project.me.invited_by })}
            </Typography>
          </CardContent>
          <Divider variant="middle" />
          <CardActions disableSpacing>
            <Tooltip title={t<string>("accept_invitation")}>
              <IconButton onClick={handleAcceptInvitation}>
                <CheckCircleOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t<string>("refuse_invitation")}>
              <IconButton onClick={handleRefuseInvitation}>
                <CancelOutlinedIcon />
              </IconButton>
            </Tooltip>
          </CardActions>
        </Card>
      ) : (
        <Card sx={{ height: "150px", display: "flex" }} variant="elevation">
          <Box
            sx={{
              width: "38.2%",
              height: "auto",
              p: 1,
              overflow: "hidden",
              backgroundColor: "initial",
            }}
          >
            <ProjectInvitation primaryColor={theme.palette.primary.main} />
          </Box>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography
              noWrap
              variant="subtitle2"
              fontWeight="bold"
              gutterBottom
            >
              {project.name}
            </Typography>
            <Typography
              noWrap
              variant="caption"
              color="text.secondary"
              gutterBottom
            >
              {t("invited_by", { stakeholder: project.me.invited_by })}
            </Typography>
          </CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              pr: 1,
              pb: 1,
            }}
          >
            <Tooltip title={t<string>("accept_invitation")}>
              <IconButton onClick={handleAcceptInvitation}>
                <CheckCircleOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t<string>("refuse_invitation")}>
              <IconButton onClick={handleRefuseInvitation}>
                <CancelOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Card>
      )}
    </>
  );
}
