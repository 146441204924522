import { Box, Theme } from "@mui/material";
import Table from "./table/table";
import { FragmentsGroup } from "bim-fragment";
import * as OBC from 'openbim-components';
import { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Actions from "./actions/actions";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { persistor, store } from "store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { getKeyFromName } from "./helpers/helpers";
import { properties } from "../properties/properties";

interface IProps {
    theme: Theme;
    model: FragmentsGroup | null;
    selectedModelExpressIds: number[];
    selectElementsByExpressId: (expressIds: number[]) => Promise<void>;
    getModelPropertiesByExpressId: (expressId: number) => properties[] | null;
    project_id: number;
}

export interface IIfcConElementAssignmentError {
    ifc_element_express_id: Number;
    assignmentError: String;
}

export interface IIfcConElementMinimalBracingForce {
    ifc_element_express_id: Number;
    minimal_bracing_force: number;
}

const IfcConElementAssignment: React.FC<IProps> = ({
    theme,
    model,
    selectedModelExpressIds,
    selectElementsByExpressId,
    getModelPropertiesByExpressId,
    project_id,
}) => {
    const [ifcElementCategories, setIfcElementCategories] = useState<string[]>([]);
    const [ifcElements, setIfcElements] = useState<any[]>([]);
    const [tableSelection, setTableSelection] = useState<GridRowSelectionModel>([]);
    const [ifcConElementAssignmentErrors, setIfcConElementAssignmentErrors] = useState<IIfcConElementAssignmentError[]>([]);

    const updateIfcElements = () => {
        let tempIfcElements: any[] = [];
        ifcElementCategories.map((category) => {
            if (model && model.properties) {
                const key = getKeyFromName(category);
                if (key !== null) {
                    const elements = OBC.IfcPropertiesUtils.getAllItemsOfType(model.properties, key);
                    tempIfcElements.push(...elements);
                }
            }
        });
        setIfcElements(tempIfcElements);
    }

    useEffect(() => {
        updateIfcElements();
    }, [model, ifcElementCategories]);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <CssBaseline enableColorScheme />
                    <Box
                        sx={{
                            color: "white",
                            width: "800px",
                        }}
                    >
                        <Actions
                            tableSelection={tableSelection}
                            setTableSelection={setTableSelection}
                            setIfcElementCategories={setIfcElementCategories}
                            getModelPropertiesByExpressId={getModelPropertiesByExpressId}
                            project_id={project_id}
                            setIfcConElementAssignmentErrors={setIfcConElementAssignmentErrors}
                            ifcConElementAssignmentErrors={ifcConElementAssignmentErrors}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                mt: 2,
                            }}>
                            <Table
                                model={model}
                                ifcElements={ifcElements}
                                selectedModelExpressIds={selectedModelExpressIds}
                                tableSelection={tableSelection}
                                setTableSelection={setTableSelection}
                                selectElementsByExpressId={selectElementsByExpressId}
                                ifcConElementAssignmentErrors={ifcConElementAssignmentErrors}
                                setIfcConElementAssignmentErrors={setIfcConElementAssignmentErrors}
                                project_id={project_id}
                            />
                        </Box>
                    </Box>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    );
}

export default IfcConElementAssignment;