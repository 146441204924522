import React from "react";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import Logout from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import LoginIcon from "@mui/icons-material/Login";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import PersonAddIcon from "@mui/icons-material/PersonAddOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircleOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

import {
  changeNavigationTitle,
  closeAccountMenu,
  getMenus,
  openAccountMenu,
} from "store/ui/navigation/navigation.slice";
import { stringToColor } from "common/utils/helpers/stringToColor.helpers";
import { getUser } from "store/entities/authentication/authentication.slice";
import {
  getAuthenticationUrl,
  getSignInUrl,
  getSignOutUrl,
  getSignUpUrl,
} from "assets/endpoints/app/authEndpoints";
import { useAppDispatch, useAppSelector } from "store";
import {
  getProfileUrl,
  getSettingsUrl,
} from "assets/endpoints/app/settingsEndpoints";

interface AccountMenuProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

export default function AccountMenu(props: AccountMenuProps) {
  const { anchorEl, setAnchorEl } = props;
  const { t } = useTranslation("navigation", { keyPrefix: "appbar.account" });
  const user = useAppSelector(getUser);
  const accountMenu = useAppSelector(getMenus).account;
  const dispatch = useAppDispatch();

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(openAccountMenu());
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (featureName: string) => {
    dispatch(closeAccountMenu());
    dispatch(changeNavigationTitle(featureName));
  };

  return (
    <React.Fragment>
      <Tooltip title={t<string>("account_settings")}>
        <IconButton
          onClick={handleMenuOpen}
          size="large"
          aria-controls={accountMenu.open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={accountMenu.open ? "true" : undefined}
          color="inherit"
        >
          {user.loggedIn ? (
            user.me.profile.profile_picture ? (
              <Avatar
                alt={user.me.email}
                src={user.me.profile.profile_picture}
                sx={{ width: 24, height: 24 }}
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: stringToColor(user.me.email),
                  typography: "body2",
                  width: 24,
                  height: 24,
                }}
              >
                {user.me.first_name
                  ? user.me.first_name.slice(0, 1).toUpperCase()
                  : user.me.email.slice(0, 1).toUpperCase()}
              </Avatar>
            )
          ) : (
            <AccountCircleIcon />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={accountMenu.open}
        onClose={() => dispatch(closeAccountMenu())}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {user.loggedIn && (
          <div>
            <MenuItem
              onClick={() => handleMenuClose("settings")}
              component={Link}
              to={getSettingsUrl() + getProfileUrl()}
            >
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" />
              </ListItemIcon>
              {t("profile")}
            </MenuItem>
            <Divider />
          </div>
        )}
        <MenuItem
          onClick={() => handleMenuClose("settings")}
          component={Link}
          to={getSettingsUrl()}
        >
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          {t("settings")}
        </MenuItem>
        {user.loggedIn ? (
          <MenuItem
            onClick={() => handleMenuClose("sign_out")}
            component={Link}
            to={getAuthenticationUrl() + getSignOutUrl()}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            {t("sign_out")}
          </MenuItem>
        ) : (
          <div>
            <MenuItem
              onClick={() => handleMenuClose("sign_in")}
              component={Link}
              to={getAuthenticationUrl() + getSignInUrl()}
            >
              <ListItemIcon>
                <LoginIcon fontSize="small" />
              </ListItemIcon>
              {t("sign_in")}
            </MenuItem>
            <MenuItem
              onClick={() => handleMenuClose(t("sign_up"))}
              component={Link}
              to={getAuthenticationUrl() + getSignUpUrl()}
            >
              <ListItemIcon>
                <PersonAddIcon fontSize="small" />
              </ListItemIcon>
              {t("sign_up")}
            </MenuItem>
          </div>
        )}
      </Menu>
    </React.Fragment>
  );
}
