import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { resetPassword } from "../../../store/entities/authentication/authentication.actions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";

export default function PasswordReset() {
  const { t } = useTranslation("authentication", {
    keyPrefix: "password_reset",
  });
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState({
    email: "",
  });

  const validationSchema = yup.object({
    email: yup
      .string()
      .email()
      .required(t("required") as string),
  });

  const resetPasswordForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      dispatch(resetPassword(values));
      setInitialValues({
        email: "",
      });
    },
  });

  return (
    <Box>
      <Typography component="h1" variant="h5" textAlign={"center"} gutterBottom>
        {t("enter_email")}
      </Typography>
      <Box
        component="form"
        id="reset-password-form"
        noValidate
        onSubmit={resetPasswordForm.handleSubmit}
        sx={{ mt: 3 }}
      >
        <TextField
          id="email"
          name="email"
          label={t("email")}
          value={resetPasswordForm.values.email}
          onBlur={resetPasswordForm.handleBlur}
          error={
            resetPasswordForm.touched.email &&
            Boolean(resetPasswordForm.errors.email)
          }
          helperText={
            resetPasswordForm.touched.email &&
            Boolean(resetPasswordForm.errors.email)
          }
          onChange={resetPasswordForm.handleChange}
          fullWidth
          autoComplete="email"
          variant="outlined"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {t("submit")}
        </Button>
      </Box>
    </Box>
  );
}
