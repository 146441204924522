import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getMyUserDetails } from "store/entities/authentication/authentication.slice";
import Notifications from "./notifications";
import Name from "./name";
import Picture from "./picture";

export default function Profile() {
  const { t } = useTranslation("settings", { keyPrefix: "profile" });
  const me = useSelector(getMyUserDetails);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Picture />
      </Grid>
      <Grid item xs={12}>
        <Name />
      </Grid>
      <Grid item xs={12}>
        <Notifications />
      </Grid>
    </Grid>
  );
}
