import React from "react";
import Navigation from "./main/navigation/navigation";
import { Outlet } from "react-router-dom";

export const Layout = () => {
  return (
    <Navigation>
      <main>
        <Outlet />
      </main>
    </Navigation>
  );
};
