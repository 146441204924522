import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCategoryChoices } from "store/entities/projects/componentCatalogue/componentCatalogue.slice";
import { fetchCategoryChoices } from "store/entities/projects/componentCatalogue/componentCatalogue.actions";
import { getSelectedElement } from "store/ui/projects/componentCatalogue/componentCatalogue.slice";
import { ICategoryChoice } from "types";
import { useAppDispatch } from "store";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";

interface IProps {
  constructionElementForm: any;
}

const ElementCreateForm: React.FC<IProps> = ({ constructionElementForm }) => {
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "selected_element.dialogs.element.create",
  });
  const { projectId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (projectId !== undefined) {
      dispatch(fetchCategoryChoices(+projectId));
    }
  }, []);

  const categories = useSelector(getCategoryChoices);
  const selectedElement = useSelector(getSelectedElement);

  return (
    <Grid container spacing={2} sx={{ pt: 2 }} maxWidth="xs">
      <Grid item xs={12}>
        <TextField
          id="name"
          name="name"
          label={t("name")}
          fullWidth
          variant="outlined"
          value={constructionElementForm.values.name}
          onBlur={constructionElementForm.handleBlur}
          error={
            constructionElementForm.touched.name &&
            Boolean(constructionElementForm.errors.name)
          }
          helperText={
            constructionElementForm.touched.name &&
            constructionElementForm.errors.name
          }
          onChange={constructionElementForm.handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="identifier"
          name="identifier"
          label={t("identifier")}
          fullWidth
          variant="outlined"
          value={constructionElementForm.values.identifier}
          onBlur={constructionElementForm.handleBlur}
          error={
            constructionElementForm.touched.identifier &&
            Boolean(constructionElementForm.errors.identifier)
          }
          helperText={
            constructionElementForm.touched.identifier &&
            constructionElementForm.errors.identifier
          }
          onChange={constructionElementForm.handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="category-label">{t("category")}</InputLabel>
          <Select
            labelId="category-label"
            id="category"
            name="category"
            label={t("category")}
            defaultValue={selectedElement.category}
            value={constructionElementForm.values.category}
            onChange={constructionElementForm.handleChange}
          >
            {categories.map(
              (elementCategory: ICategoryChoice, index: number) => (
                <MenuItem key={index} value={elementCategory.value}>
                  {elementCategory.label}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ElementCreateForm;
