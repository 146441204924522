import type { IPermissionName } from "types";

export const projectPermissions = {
  dashboard: {
    read_only: <IPermissionName>"Project read only",
    maintainer: <IPermissionName>"Project maintainer",
  },
  stakeholder: {
    read_only: <IPermissionName>"Project stakeholder read only",
    maintainer: <IPermissionName>"Project stakeholder maintainer",
  },
  component_catalogue: {
    read_only: <IPermissionName>"Project component catalogue read only",
    maintainer: <IPermissionName>"Project component catalogue maintainer",
  },
  uploaded_files: {
    read_only: <IPermissionName>"Project uploaded files read only",
    maintainer: <IPermissionName>"Project uploaded files maintainer",
  },
  ifc_model_creations: {
    read_only: <IPermissionName>"Project ifc model creations read only",
    maintainer: <IPermissionName>"Project ifc model creations maintainer",
  },
  ifc_con_element_assignments: {
    read_only: <IPermissionName>"Project ifc-con-element-assignments read only",
    maintainer: <IPermissionName>"Project ifc-con-element-assignments maintainer",
  },
};
