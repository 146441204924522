import { TFunction } from 'i18next'
import React from 'react'
import { AcroFormCheckBox } from 'jspdf'

export default async function requirements(
  element: any,
  doc: any,
  layout: any,
  fontSizes: any,
  lineSpacing: number,
  startY: number,
  t: TFunction<'component_catalogue', 'pdf_export'>
) {
  const addCheckbox = (
    name: string,
    x: number,
    y: number,
    checked: boolean
  ) => {
    let checkbox = new AcroFormCheckBox()
    checkbox.fieldName = name
    checkbox.x = x
    checkbox.y = y
    checkbox.width = 4
    checkbox.height = 4
    checkbox.maxFontSize = 4
    checkbox.textAlign = 'center'
    checkbox.appearanceState = checked ? 'On' : 'Off'
    doc.addField(checkbox)
  }

  let startX = layout.StartX
  let endX = layout.EndX

  const req = element.requirements
  const per = element.performance

  const tablecol1X = startX
  const tablecol2X = startX + (layout.ContentWidth / 4) * 2.5
  const tablecol3X = startX + (layout.ContentWidth / 4) * 3.5
  const tablecol4X = endX

  doc.line(startX, startY, endX, startY)
  startY += lineSpacing * 0.5

  doc.text(t('pdf_export.feature'), startX, startY, { align: 'left' })
  doc.text(t('pdf_export.requirements'), tablecol2X, startY, { align: 'right' })
  doc.text(t('pdf_export.performance'), tablecol3X, startY, { align: 'right' })
  doc.text(t('pdf_export.fulfilled'), tablecol4X, startY, { align: 'right' })

  startY += lineSpacing * 0.3
  doc.line(startX, startY, endX, startY)
  startY += lineSpacing * 0.5

  if (req.area_load) {
    doc.text(t('pdf_export.area_load'), startX, startY, { align: 'left' })
    doc.text(req.area_load.toString(), tablecol2X, startY, { align: 'right' })
    doc.text(per.area_load.toString(), tablecol3X, startY, { align: 'right' })
    addCheckbox(
      'area load',
      tablecol4X - 4,
      startY - 3,
      Boolean(req.area_load <= per.area_load)
    )
    startY += lineSpacing * 0.65
  }

  if (req.fire_protection) {
    doc.text(t('pdf_export.fire_protection'), tablecol1X, startY, {
      align: 'left',
    })
    doc.text(req.fire_protection, tablecol2X, startY, {
      align: 'right',
    })
    doc.text(per.fire_protection, tablecol3X, startY, {
      align: 'right',
    })
    addCheckbox(
      'fire protection',
      tablecol4X - 4,
      startY - 3,
      Boolean(req.fire_protection === per.fire_protection)
    )
    startY += lineSpacing * 0.65
  }

  if (req.thermal_transmittance) {
    doc.text(t('pdf_export.thermal_transmittance'), tablecol1X, startY, {
      align: 'left',
    })
    doc.text(req.thermal_transmittance.toString(), tablecol2X, startY, {
      align: 'right',
    })
    doc.text(per.thermal_transmittance.toString(), tablecol3X, startY, {
      align: 'right',
    })
    addCheckbox(
      'thermal transmittance',
      tablecol4X - 4,
      startY - 3,
      Boolean(req.thermal_transmittance >= per.thermal_transmittance)
    )
    startY += lineSpacing * 0.65
  }

  if (req.direct_sound_reduction_index) {
    doc.text(t('pdf_export.direct_sound_reduction_index'), tablecol1X, startY, {
      align: 'left',
    })
    doc.text(req.direct_sound_reduction_index.toString(), tablecol2X, startY, {
      align: 'right',
    })
    doc.text(per.direct_sound_reduction_index.toString(), tablecol3X, startY, {
      align: 'right',
    })
    addCheckbox(
      'direct sound reduction index',
      tablecol4X - 4,
      startY - 3,
      Boolean(
        req.direct_sound_reduction_index <= per.direct_sound_reduction_index
      )
    )
    startY += lineSpacing * 0.65
  }

  if (req.sound_reduction_index) {
    doc.text(t('pdf_export.sound_reduction_index'), tablecol1X, startY, {
      align: 'left',
    })
    doc.text(req.sound_reduction_index.toString(), tablecol2X, startY, {
      align: 'right',
    })
    doc.text(per.sound_reduction_index.toString(), tablecol3X, startY, {
      align: 'right',
    })
    addCheckbox(
      'sound reduction index',
      tablecol4X - 4,
      startY - 3,
      Boolean(req.sound_reduction_index <= per.sound_reduction_index)
    )
    startY += lineSpacing * 0.65
  }

  if (req.weighted_normalized_impact_sound_pressure_level) {
    doc.text(
      t('pdf_export.weighted_normalized_impact_sound_pressure_level'),
      tablecol1X,
      startY,
      {
        align: 'left',
      }
    )
    doc.text(
      req.weighted_normalized_impact_sound_pressure_level.toString(),
      tablecol2X,
      startY,
      {
        align: 'right',
      }
    )
    doc.text(
      per.weighted_normalized_impact_sound_pressure_level.toString(),
      tablecol3X,
      startY,
      {
        align: 'right',
      }
    )
    addCheckbox(
      'weighted normalized impact sound pressure level',
      tablecol4X - 4,
      startY - 3,
      Boolean(
        req.weighted_normalized_impact_sound_pressure_level >=
          per.weighted_normalized_impact_sound_pressure_level
      )
    )
    startY += lineSpacing * 0.65
  }

  if (req.normalized_impact_sound_pressure_level) {
    doc.text(
      t('pdf_export.normalized_impact_sound_pressure_level'),
      tablecol1X,
      startY,
      {
        align: 'left',
      }
    )
    doc.text(
      req.normalized_impact_sound_pressure_level.toString(),
      tablecol2X,
      startY,
      {
        align: 'right',
      }
    )
    doc.text(
      per.normalized_impact_sound_pressure_level.toString(),
      tablecol3X,
      startY,
      {
        align: 'right',
      }
    )
    addCheckbox(
      'normalized impact sound pressure level',
      tablecol4X - 4,
      startY - 3,
      Boolean(
        req.normalized_impact_sound_pressure_level >=
          per.normalized_impact_sound_pressure_level
      )
    )
    startY += lineSpacing * 0.65
  }

  return startY
}
