import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import BottomNavigation from './appbar/bottomNavigation'
import { TopNavigation } from './appbar/topNavigation'

interface Navigation {
  children: any
}

const Navigation = (props: Navigation) => {
  const { children } = props

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <>
      <TopNavigation matches={matches} />
      {children}
      <BottomNavigation />
    </>
  )
}

export default Navigation
