import React from 'react'
import { TFunction } from 'i18next'

export default function header(
  project: any,
  doc: any,
  layout: any,
  fontSizes: any,
  lineSpacing: number,
  t: TFunction<'component_catalogue', 'pdf_export'>
) {
  const pageNr = doc.getNumberOfPages()

  let n = 1

  if (pageNr > 1) {
    while (n < pageNr) {
      n++

      doc.setPage(n)

      doc.setFontSize(fontSizes.SmallFontSize)

      let startY = layout.StartY
      let startX = layout.StartX
      let endX = layout.EndX

      doc.text(`${t('pdf_export.project_no')}: ${project.id}`, startX, startY, {
        align: 'left',
      })
      doc.text(
        `${t('pdf_export.project_name')}: ${project.name}`,
        endX,
        startY,
        {
          align: 'right',
        }
      )

      startY += 2

      doc.setLineWidth(0.2)

      doc.line(layout.StartX, startY, layout.EndX, startY)
    }
  }
}
