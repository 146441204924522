import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddIcon from "@mui/icons-material/Add";
import DraggableComponent from "react-draggable";
import {
  createLayer,
  createLayerFilling,
  reorderLayers,
  updateElement,
} from "store/entities/projects/componentCatalogue/componentCatalogue.actions";
import {
  closeLayersRetrieveDialog,
  getLayerDialogs,
  getSelectedElement,
  getSelectedLayer,
  openLayerCreateDialog,
  openLayerUpdateDialog,
  selectLayer,
} from "store/ui/projects/componentCatalogue/componentCatalogue.slice";
import { useAppSelector } from "store";
import { ILayer } from "types";
import { useParams } from "react-router-dom";
import { getProjectPermissions } from "store/entities/projects/project.slice";
import { hasProjectPerm } from "services/authService";
import { projectPermissions } from "common/utils/constants/auth.constants";
import type { IConstructionMaterial, ILayerFilling, IStakeholderPermission } from "types";
import { getConstructionMaterials } from "store/entities/projects/componentCatalogue/componentCatalogue.slice";

const LayersRetrieveDialog: React.FC = () => {
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "selected_element.dialogs.layers.retrieve",
  });
  const { projectId, elementId } = useParams();
  const selectedElement = useAppSelector(getSelectedElement);
  const selectedLayer = useAppSelector(getSelectedLayer);
  const constructionMaterials: IConstructionMaterial[] = useAppSelector(getConstructionMaterials);
  const open = useAppSelector(getLayerDialogs).retrieve;
  const dispatch = useDispatch();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const userPermissions: IStakeholderPermission[] = useAppSelector(
    getProjectPermissions
  );

  const isMaintainer = hasProjectPerm(userPermissions, [
    projectPermissions.component_catalogue.maintainer,
  ]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = () => {
    handleMenuClose();
    // dispatch(openLayerCreateDialog());

    if (projectId !== undefined && elementId !== undefined) {
      const position = selectedElement.layers.length === -1 ? 1 : selectedElement.layers.length + 1;
      dispatch(createLayer(+projectId, +elementId, { name: "newLayer", position: position, }));
    }
  };

  const handleDialogClose = () => {
    dispatch(closeLayersRetrieveDialog());
    if (projectId !== undefined && elementId !== undefined) {
      dispatch(updateElement(+projectId, +elementId, selectedElement));
    }
  };

  const handleLayerItemClick = (layerItem: ILayer) => {
    dispatch(selectLayer(layerItem));
    dispatch(openLayerUpdateDialog());
  };

  const handleLayerDuplicateClick = async (index: number, layerItem: ILayer) => {
    if (projectId !== undefined && elementId !== undefined) {
      try {
        const created_layer: any = await dispatch(createLayer(+projectId, +elementId, {
          ...layerItem,
          position: selectedElement.layers.length + 1
        }));
        layerItem.layer_fillings.map(async (filling: ILayerFilling) => {
          if (created_layer.layers && created_layer.layers.length !== 0) {
            const newLayer_id = created_layer.layers.at(-1).id;
            const created_layer_filling: any = await dispatch(createLayerFilling(+projectId, +elementId, newLayer_id, filling));
          }
        });
      } catch (error) {
        console.error(`Duplicate Layer: ${layerItem.id} failed:`, error);
      }
    }
  };

  const handleMoveLayerUpClick = (index: number) => {
    if (index === 0) return;
    dispatch(reorderLayers(selectedElement, index, index - 1));
  }

  const handleMoveLayerDownClick = (index: number) => {
    if (index === selectedElement.layers.length - 1) return;
    dispatch(reorderLayers(selectedElement, index, index + 1));
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        hideBackdrop
        aria-labelledby="layers-retrieve-dialog"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle style={{ cursor: "move" }} id="layers-retrieve-dialog">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>{t("edit_layers")}</Box>
            {/* <Tooltip title={t("options")}>
              <IconButton
                aria-controls="menu"
                aria-haspopup="true"
                className="editLayersMenuOpenButton"
                onClick={handleMenuOpen}
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {isMaintainer && (
                <MenuItem onClick={handleMenuItemClick}>
                  <AddBoxOutlined sx={{ marginRight: 1 }} />
                  {t("add_layer")}
                </MenuItem>
              )}
            </Menu> */}
            {isMaintainer && (
              <Tooltip open={matches ? false : true} title={t("add_layer")}>
                <Fab
                  variant={matches ? "extended" : "circular"}
                  color="secondary"
                  aria-label="add"
                  size={matches ? "medium" : "small"}
                  onClick={handleMenuItemClick}
                >
                  <AddIcon sx={{ mr: { xs: 0, md: 1 } }} />
                  {matches ? t("add_layer") : null}
                </Fab>
              </Tooltip>
            )}
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Chip size="small" label={t("inner_layer")} />
          </Box>
          <List aria-label="layers" sx={{ width: "100%" }}>
            {selectedElement.layers.length < 1 ? (
              <ListItem disablePadding>
                <ListItemText primary={t("no_existing_layers")} />
              </ListItem>
            ) : (
              <>
                {selectedElement.layers.map(
                  (layer: ILayer, index: number) => (
                    <ListItem
                      disablePadding
                      key={index}
                    >
                      <ListItemButton
                        selected={layer === selectedLayer}
                        onClick={() => handleLayerItemClick(layer)}
                      >
                        <ListItemText
                          primary={
                            <Typography
                            >
                              {layer.name}
                            </Typography>
                          }
                          secondary={
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                              {layer.layer_fillings.map((filling: ILayerFilling) => {
                                return (
                                  <Typography
                                    key={filling.id}
                                    variant="subtitle2"
                                    color="text.secondary"
                                    sx={{ ml: 1 }}
                                  >
                                    {constructionMaterials.find((material: IConstructionMaterial) => material.id === filling.construction_material)?.name}
                                  </Typography>
                                );
                              })}

                            </ Box>
                          }
                        />
                        <ListItemText
                          primary={layer.layer_fillings.length !== 0 ? `${Math.max(...layer.layer_fillings.map((filling: ILayerFilling) => { return filling.thickness }))} mm` : null}
                          sx={{
                            display: "flex",
                            justifyContent: "right",
                          }}
                        />
                      </ListItemButton>
                      {isMaintainer && (
                        <Tooltip title={t("move_up")}>
                          <IconButton onClick={(event) => handleMoveLayerUpClick(index)}>
                            <ArrowUpwardIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {isMaintainer && (
                        <Tooltip title={t("move_down")}>
                          <IconButton onClick={(event) => handleMoveLayerDownClick(index)}>
                            <ArrowDownwardIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {isMaintainer && (
                        <Tooltip title={t("duplicate")}>
                          <IconButton onClick={(event) => handleLayerDuplicateClick(index, layer)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ListItem>
                  ))}
              </>
            )}
          </List>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Chip size="small" label={t("outer_layer")} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LayersRetrieveDialog;

const PaperComponent: React.FC = (props) => (
  <DraggableComponent
    handle="#layers-retrieve-dialog"
    cancel={'[class*="MuiDialogContent-root"], .editLayersMenuOpenButton'}
  >
    <Paper {...props} />
  </DraggableComponent>
);
