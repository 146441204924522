import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import TuneIcon from "@mui/icons-material/TuneOutlined";
import { useTranslation } from "react-i18next";
import { select, zoom, zoomIdentity } from "d3";
import { useTheme } from "@mui/material/styles";

import { useAppDispatch, useAppSelector } from "store";
import {
  openElementDeleteDialog,
  openElementUpdateDialog,
  openLayersRetrieveDialog,
} from "store/ui/projects/componentCatalogue/componentCatalogue.slice";

import {
  DeleteOutlined,
  LayersOutlined,
  EditOutlined,
  CenterFocusWeakOutlined,
} from "@mui/icons-material";
import ImageContext from "../image/imageContext";

import { getProjectPermissions } from "store/entities/projects/project.slice";
import { hasProjectPerm } from "services/authService";
import { IStakeholderPermission } from "types";
import { projectPermissions } from "common/utils/constants/auth.constants";
import { Button, ButtonGroup, useMediaQuery } from "@mui/material";

const Buttons = () => {
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "selected_element.actions",
  });
  const imageRef = useContext(ImageContext);

  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const userPermissions: IStakeholderPermission[] = useAppSelector(
    getProjectPermissions
  );

  const handleDeleteElement = () => {
    dispatch(openElementDeleteDialog());
  };

  const handleEditLayers = () => {
    dispatch(openLayersRetrieveDialog());
  };

  const handleEditElement = () => {
    dispatch(openElementUpdateDialog());
  };

  useEffect(() => {
    const svg = select(imageRef.current);
    const container = select(imageRef.current.parentElement);
    const zoomBehavior = zoom()
      .scaleExtent([0.5, 3])
      .on("zoom", (event) => {
        svg.attr("transform", event.transform);
      });

    svg.attr("transform", zoomIdentity.toString());

    container.call(zoomBehavior);

    return () => {
      container.on(".zoom", null);
    };
  }, [imageRef]);

  const resetZoom = () => {
    const svg = select(imageRef.current);
    const container = select(imageRef.current.parentElement);

    const zoomBehavior = zoom()
      .scaleExtent([0.5, 3])
      .on("zoom", (event) => {
        svg.attr("transform", event.transform);
      });

    container.transition().call(zoomBehavior.transform, zoomIdentity);
  };

  const actions = [
    {
      icon: <DeleteOutlined />,
      name: t("delete_element"),
      action: handleDeleteElement,
      disabled: !hasProjectPerm(userPermissions, [
        projectPermissions.component_catalogue.maintainer,
      ]),
    },
    {
      icon: <CenterFocusWeakOutlined />,
      name: t("center_drawing"),
      action: resetZoom,
      disabled: false,
    },
    {
      icon: <LayersOutlined />,
      name: t("edit_layers"),
      action: handleEditLayers,
      disabled: false,
    },
    {
      icon: <EditOutlined />,
      name: t("edit_element"),
      action: handleEditElement,
      disabled: false,
    },
  ];

  return (
    <>
      {matches ? (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", position: "absolute", bottom: 16 }}>
          <ButtonGroup
            variant="contained"
            color="secondary"
          >
            {actions.reverse().map(
              (action) =>
                !action.disabled && (
                  <Button
                    key={action.name}
                    onClick={action.action}
                    startIcon={action.icon}
                  >
                    {action.name}
                  </Button>
                )
            )}
          </ButtonGroup>
        </Box>
      ) : (
        <Box sx={{ position: "absolute", bottom: 16, right: 16 }}>
          <SpeedDial
            ariaLabel={t("speed_dial_label")}
            icon={<TuneIcon />}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            sx={{ right: 16 }}
          >
            {actions.map(
              (action) =>
                !action.disabled && (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action.action}
                  />
                )
            )}
          </SpeedDial>
        </Box>
      )}
    </>
  );
};

export default Buttons;
