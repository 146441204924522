import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles'
import { useAppDispatch, useAppSelector } from 'store'
import { useParams } from 'react-router-dom'
import Table from './table/table'
import { GridRowSelectionModel } from '@mui/x-data-grid'
import { getLocales } from 'store/entities/settings/settings.slice'


interface IBaseFileProps {
  newIfcModelCreationForm: any;
  rowSelModelBaseFile: GridRowSelectionModel;
  setRowSelModelBaseFile: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
}

const BaseFile: React.FC<IBaseFileProps> = ({
  newIfcModelCreationForm,
  rowSelModelBaseFile,
  setRowSelModelBaseFile,
}) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { t } = useTranslation("ifc_model_creations", {
    keyPrefix: "dialogs.create_dialog.base_file",
  });
  const locales = useAppSelector(getLocales);
  const { projectId } = useParams();

  return (
    <Container>
      <Table rowSelModelBaseFile={rowSelModelBaseFile} setRowSelModelBaseFile={setRowSelModelBaseFile} newIfcModelCreationForm={newIfcModelCreationForm} />
    </Container>
  )
}

export default BaseFile
