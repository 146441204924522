import React, { useState, useEffect } from "react"
import Checkbox from '@mui/material/Checkbox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TreeItem, {
  TreeItemProps,
  useTreeItem,
  TreeItemContentProps,
} from '@mui/lab/TreeItem';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const CustomContent = React.forwardRef(function CustomContent(
  props: TreeItemContentProps & {
    showHideItemsById?: (show: boolean, expressIds: number[], addToOldShown?: boolean) => Promise<void>;
    displayedModelExpressIds?: number[];
  },
  ref,
) {

  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    showHideItemsById,
    displayedModelExpressIds
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const [isVisible, setIsVisible] = useState<boolean>(true);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    handleSelection(event);
  };

  const isItemVisibleById = () => {
    if (displayedModelExpressIds) {
      const isVisible = displayedModelExpressIds.some((displayedId) => displayedId === Number(nodeId));
      return isVisible;
    }
    return false;
  };

  const handleVisivbilityClick = async () => {
    if (showHideItemsById && isItemVisibleById) {
      if (isItemVisibleById()) {
        showHideItemsById(false, [Number(nodeId)]);
        setIsVisible(false);
      } else {
        showHideItemsById(true, [Number(nodeId)], true);
        setIsVisible(true);
      }
    }
  }

  useEffect(() => {
    setIsVisible(isItemVisibleById());
  }, [displayedModelExpressIds]);

  return (
    <Box
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <Box component="div" className={classes.iconContainer} onClick={handleExpansionClick}>
        {icon}
      </Box>
      <Checkbox
        icon={<VisibilityIcon sx={{ width: 16, height: 16, color: "white" }} />}
        checkedIcon={<VisibilityOffIcon sx={{ width: 16, height: 16, color: "white" }} />}
        checked={!isVisible}
        onClick={handleVisivbilityClick}
      />
      <Typography
        onClick={handleSelectionClick}
        variant="caption"
      >
        {label}
      </Typography>
    </Box>
  );
});

function TreeElement(
  props: TreeItemProps & {
    ContentProps: {
      showHideItemsById: (show: boolean, ids: number[]) => Promise<void>,
      displayedModelExpressIds: number[];
    };
  }
) {
  return (
    <TreeItem
      {...props}
      ContentComponent={CustomContent}
    />
  );
}

export default TreeElement;