import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import ElementImage from "common/components/elementImage/image";
import { useAppDispatch, useAppSelector } from "store";
import { getDesign } from "store/entities/settings/settings.slice";
import { IElement } from "types";
import { getElementDialogs, getSelectedElements, selectElement } from "store/ui/projects/componentCatalogue/componentCatalogue.slice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";

interface ElementContainerProps {
  element: IElement;
  projectId: string | undefined;
}

const ElementCard: React.FC<ElementContainerProps> = ({
  element,
  projectId,
}) => {
  const design = useAppSelector(getDesign);
  const dispatch = useAppDispatch();

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [selectedBackgroundColor, setSelectedBackgroundColor] = useState(
    "rgba(255, 255, 255, 0.01)"
  )

  const selectedElements = useAppSelector(getSelectedElements);
  const selection = useAppSelector(getElementDialogs).selection;

  const handleElementClick = () => {
    dispatch(selectElement(element));
  };

  useEffect(() => {
    const isElementSelected = selectedElements.find(
      (selectedElement: IElement) => selectedElement.id === element.id
    );
    if (isElementSelected === undefined) {
      setIsSelected(false);
    } else {
      setIsSelected(true);
    }
  }, [selectedElements]);

  useEffect(() => {
    if (design === "dark") {
      setSelectedBackgroundColor("rgba(255, 255, 255, 0.01)")
    } else {
      setSelectedBackgroundColor("rgba(0, 0, 0, 0.1)")
    }
  }, [design])

  const CardArea = () => {
    return (
      <>
        <CardMedia
          sx={{
            height: "300px",
            textAlign: "center",
            justifyContent: "center",
            p: 2,
          }}
        >
          <ElementImage
            element={element}
            fitToContainer={true}
            minimalTheme={true}
            colorTheme={design.colorTheme}
          />
        </CardMedia>
        <CardContent>
          <Typography
            gutterBottom
            variant="body1"
            component="div"
            align="center"
          >
            {element.name}
          </Typography>
          {selection && isSelected
            && (
              <CheckCircleIcon
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "primary.main",
                }}
              />
            )}
          {selection && !isSelected
            && (
              <CircleIcon
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "primary.main",
                }}
              />
            )}
        </CardContent>
      </>
    );
  }

  return (
    <>
      <Card
        sx={{
          width: { xs: "100%", sm: "300px" },
          backgroundColor: selection && !isSelected ? selectedBackgroundColor : null,
        }}
      >
        {selection ? (
          <CardActionArea
            onClick={handleElementClick}
          >
            <CardArea />
          </CardActionArea>
        )
          : (
            <CardActionArea
              component={RouterLink}
              to={"/projects/" + projectId + "/component-catalogue/" + element.id}
            >
              <CardArea />
            </CardActionArea>
          )}
      </Card>
    </>
  );
};

export default ElementCard;
