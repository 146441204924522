import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Zoom from "@mui/material/Zoom";
import { TransitionProps } from "@mui/material/transitions";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import {
  closeProjectDeleteDialog,
  getProjectDialogs,
} from "store/ui/projects/dashboard/dashboard.ui.slice";
import { deleteProject } from "store/entities/projects/projects.slice";

const ZoomTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

const ProjectDeleteDialog: React.FC = () => {
  const { t } = useTranslation("dashboard", {
    keyPrefix: "project.dialogs.delete",
  });
  const { projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const open = useAppSelector(getProjectDialogs).delete;

  const handleDialogClose = () => {
    dispatch(closeProjectDeleteDialog());
  };

  const handleDelete = () => {
    if (projectId !== undefined) {
      dispatch(deleteProject(+projectId));
      handleDialogClose();
      return navigate("/projects/");
    }
  };

  return (
    <Dialog
      sx={{ width: "100%", height: "100%" }}
      open={open}
      TransitionComponent={ZoomTransition}
      keepMounted
      onClose={handleDialogClose}
      maxWidth="sm"
    >
      <DialogTitle>{t("title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("info")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>{t("cancel")}</Button>
        <Button onClick={handleDelete} autoFocus>
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectDeleteDialog;
