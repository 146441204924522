import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import { useAppDispatch, useAppSelector } from "store";
import { getProjects } from "store/entities/projects/projects.slice";
import { getLocales } from "store/entities/settings/settings.slice";
import { selectProject } from "store/entities/projects/project.slice";
import type { IProject } from "types";

const Layout: React.FC = () => {
  const { projectId } = useParams();

  const dispatch = useAppDispatch();
  const locales = useAppSelector(getLocales);
  const projects = useAppSelector(getProjects);

  useEffect(() => {
    if (projectId !== undefined) {
      const index = projects.findIndex(
        (project: IProject) => project.id === +projectId
      );
      dispatch(selectProject(projects[index]));
    }
  }, [projectId, locales, projects]);

  return (
    <Container maxWidth={false} disableGutters={true}>
      <Outlet />
    </Container>
  );
};
export default Layout;
