import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  authenticateUser,
  loadMyUserProfile,
  resetPassword,
} from "store/entities/authentication/authentication.actions";
import {
  getApi,
  getUser,
} from "store/entities/authentication/authentication.slice";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useAppSelector } from "store";
import {
  getAuthenticationUrl,
  getResetPasswordUrl,
  getSignUpUrl,
  getUsersUrl,
} from "assets/endpoints/app/authEndpoints";
import { changeNavigationTitle } from "../../../store/ui/navigation/navigation.slice";
import { getProjectListUrl } from "assets/endpoints/app/projectEndpoints";

export default function SignIn() {
  const { t } = useTranslation("authentication", { keyPrefix: "sign_in" });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const api = useAppSelector(getApi);
  const user = useAppSelector(getUser);

  useEffect(() => {
    if (user.accessToken !== null) {
      dispatch(loadMyUserProfile());
    }
  }, [user]);

  useEffect(() => {
    if (user.loggedIn === true) {
      dispatch(changeNavigationTitle("welcome"));
      navigate(getProjectListUrl(), { replace: true });
    }
  }, [user]);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
  });

  const validationSchema = yup.object({
    email: yup.string().required(t("required") as string),
    password: yup.string().required(t("required") as string),
  });

  const signInForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      dispatch(authenticateUser(values));
      signInForm.resetForm();
    },
  });

  return (
    <Box>
      <Typography component="h1" variant="h5" textAlign={"center"}>
        {t("sign_in")}
      </Typography>
      <Box
        component="form"
        id="sign-in-form"
        noValidate
        onSubmit={signInForm.handleSubmit}
        sx={{ mt: 3 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="email"
              name="email"
              label={t("email")}
              value={signInForm.values.email}
              onBlur={signInForm.handleBlur}
              error={
                signInForm.touched.email && Boolean(signInForm.errors.email)
              }
              helperText={
                signInForm.touched.email && Boolean(signInForm.errors.email)
              }
              onChange={signInForm.handleChange}
              fullWidth
              autoComplete="email"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="password"
              name="password"
              label={t("password")}
              value={signInForm.values.password}
              onBlur={signInForm.handleBlur}
              error={
                signInForm.touched.password &&
                Boolean(signInForm.errors.password)
              }
              helperText={
                signInForm.touched.password &&
                Boolean(signInForm.errors.password)
              }
              onChange={signInForm.handleChange}
              fullWidth
              autoComplete="password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle current password visibility"
                      onClick={() => handleClickShowPassword()}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              form="sign-in-form"
              fullWidth
              variant="contained"
              disabled={api.submitting}
            >
              {t("sign_in")}
            </Button>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <Link
              component={RouterLink}
              to={
                getAuthenticationUrl() + getUsersUrl() + getResetPasswordUrl()
              }
              variant="body2"
            >
              {t("forgot_password")}
            </Link>
            <Link
              component={RouterLink}
              to={getAuthenticationUrl() + getSignUpUrl()}
              variant="body2"
            >
              {t("link_to_sign_up")}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
