import { Suspense, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "common/components/protectedRoute";
import CssBaseline from "@mui/material/CssBaseline";
import Cookies from "js-cookie";

import Authentication from "main/authentication/authentication";
import Projects from "main/projects/projects";
import {
  selectDesign,
  selectLanguage,
} from "store/entities/settings/settings.slice";
import supportedLanguages from "./assets/languages";
import { useAppDispatch } from "store";
import {
  getAuthenticationUrl,
} from "./assets/endpoints/app/authEndpoints";
import Settings from "main/settings/settings";
import { Layout } from "./layout";
import { useColorTheme } from "common/themes/useColorTheme";
import { useIndexedDB } from "services/indexeddb";
import LandingPage from "landingPage/LandingPage";

const App = () => {
  const dispatch = useAppDispatch();
  const { clearIfExpired } = useIndexedDB("files");

  useEffect(() => {
    const initialColorTheme = Cookies.get("colorTheme") || "light";
    const initialLanguageCode = Cookies.get("i18next") || "en-US";
    dispatch(selectDesign(initialColorTheme));
    const currentLanguage = supportedLanguages.find(
      (l: any) => l.code === initialLanguageCode
    );
    if (currentLanguage !== undefined) {
      dispatch(selectLanguage(currentLanguage));
    }
    clearIfExpired();
  }, []);

  const colorTheme = useColorTheme();

  return (
    <ThemeProvider theme={colorTheme}>
      <CssBaseline enableColorScheme />
      <Suspense>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route
              path="/"
              // element={
              //   <Navigate
              //     replace
              //     to={getAuthenticationUrl() + getSignInUrl()}
              //   />
              // }
              element={<LandingPage />}
            />
            <Route
              path={getAuthenticationUrl() + "*"}
              element={<Authentication />}
            />
            <Route element={<ProtectedRoute />}>
              <Route element={<Layout />}>
                <Route path="projects/*" element={<Projects />} />
                <Route path="settings/*" element={<Settings />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
