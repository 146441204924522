import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  getCategoryChoices, getConstructionElements,
} from "store/entities/projects/componentCatalogue/componentCatalogue.slice";
import ElementCard from "./elementCard";
import Scroll from "./scroll";
import { getLocales } from "store/entities/settings/settings.slice";
import { useAppDispatch, useAppSelector } from "store";
import type { IElement } from "types";
import { filterList, getFilteredList, getPersonalLibraryProjectId, getSelectedElement, selectElement } from "store/ui/projects/componentCatalogue/componentCatalogue.slice";

interface IProps {
  containerRef: React.RefObject<HTMLDivElement>;
}

const List: React.FC<IProps> = ({ containerRef }) => {
  //const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const locales = useAppSelector(getLocales);
  const projectId = useAppSelector(getPersonalLibraryProjectId);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const selectedElement = useAppSelector(getSelectedElement);
  const elements = useAppSelector(getConstructionElements);
  const filteredList = useAppSelector(getFilteredList);

  const categories = useAppSelector(getCategoryChoices);
  const [containerWidth, setContainerWidth] = useState(0) as any;

  useEffect(() => {
    if (containerRef.current !== null) {
      setContainerWidth(containerRef.current.offsetWidth - 48);
    }
  }, [containerRef]);

  useEffect(() => {
    dispatch(filterList({ list: elements, categoryChoices: categories }));

    const elementIndex = elements.findIndex(
      (element: any) => element.id === selectedElement.id
    );
    if (elementIndex !== -1) {
      dispatch(selectElement(elements[elementIndex]));
    }
  }, [elements, categories]);

  return (
    <>
      {projectId ?
        (
          <>
            {Object.keys(filteredList).map((category: any, index: number) => {
              let categoryLabelIndex = categories.findIndex(
                (item: any) => item.value === category
              );
              let categoryLabel = categories[categoryLabelIndex].label;
              return (
                <Grid
                  container
                  justifyContent="space-between"
                  key={index}
                  direction="row"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ width: "100%", ml: { xs: 0, md: "64px" }, mt: 2, mb: 2 }}
                  >
                    <Typography variant="h6">{categoryLabel}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: "100%",
                      overflow: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                      "&::-webkit-scrollbar": {
                        display: "none",
                        width: "0.4em",
                      },
                      "&::-webkit-scrollbar-track": {
                        display: "none",
                        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        display: "none",
                        backgroundColor: "rgba(0,0,0,.1)",
                        outline: "1px solid slategrey",
                      },
                    }}
                  >
                    {matches ? (
                      <Scroll containerWidth={containerWidth}>
                        {filteredList[category].map(
                          (element: IElement, index: number) => (
                            <ElementCard
                              key={index}
                              element={element}
                              projectId={+projectId}
                            />
                          )
                        )}
                      </Scroll>
                    ) : (
                      <Grid container direction={"column"} rowSpacing={2}>
                        {filteredList[category].map((element: any, index: number) => (
                          <Grid item xs={12} key={index}>
                            <ElementCard element={element} projectId={+projectId} />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </>
        ) :
        (null)
      }
    </>
  );
};

export default List;
