import Avatar from '@mui/material/Avatar'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import React from 'react'
import Skeleton from '@mui/material/Skeleton'

export default function LoadingItem() {
  return (
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant='circular' animation='wave'>
          <Avatar />
        </Skeleton>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Skeleton animation='wave' width='70%' style={{ marginBottom: 6 }} />
        }
        secondary={<Skeleton animation='wave' width='50%' />}
      />
    </ListItem>
  )
}
