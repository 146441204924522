import { apiRequest } from "common/utils/constants/api.constants";
import * as stakeholdersSlice from "./stakeholders.slice";
import * as stakeholderEndpoints from "assets/endpoints/api/projects/stakeholderEndpoints";

export const fetchStakeholders = (projectId: number) =>
  apiRequest({
    url: stakeholderEndpoints.getStakeholderListUrl(projectId),
    onStart: stakeholdersSlice.stakeholdersRequested.type,
    onSuccess: stakeholdersSlice.stakeholdersReceived.type,
    onError: stakeholdersSlice.stakeholdersRequestFailed.type,
  });

export const addStakeholder = (
  projectId: number,
  stakeholder: { email: string; role: string }
) =>
  apiRequest({
    url: stakeholderEndpoints.getStakeholderListUrl(projectId),
    method: "post",
    data: stakeholder,
    onSuccess: stakeholdersSlice.stakeholderAdded.type,
  });

export const updateStakeholder = (
  projectId: number,
  stakeholderId: number,
  stakeholder: any
) =>
  apiRequest({
    url: stakeholderEndpoints.getStakeholderItemUrl(projectId, stakeholderId),
    method: "put",
    data: stakeholder,
    onSuccess: stakeholdersSlice.stakeholderUpdated.type,
  });

export const deleteStakeholder = (projectId: number, stakeholderId: number) =>
  apiRequest({
    url: stakeholderEndpoints.getStakeholderItemUrl(projectId, stakeholderId),
    method: "delete",
    onSuccess: stakeholdersSlice.stakeholderDeleted.type,
  });

export const fetchInvitationStatus = (projectId: number) =>
  apiRequest({
    url: stakeholderEndpoints.getStakeholderInvitationStatusChoicesUrl(
      projectId
    ),
    method: "get",
    onSuccess: stakeholdersSlice.invitationStatusReceived.type,
  });

export const fetchRoles = (projectId: number) =>
  apiRequest({
    url: stakeholderEndpoints.getStakeholderRoleChoicesUrl(projectId),
    onSuccess: stakeholdersSlice.rolesReceived.type,
  });

export const findMyProfile = (userId: number) => ({
  type: stakeholdersSlice.myProfileReceived.type,
  payload: userId,
});
