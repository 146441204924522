export const IFCCLASSES = [
    "IfcPerformanceHistoryTypeEnum",
    "IfcPerformanceHistory",
    "IfcRelAssociatesApproval",
    "IfcRelAssociatesConstraint",
    "IfcPropertySetDefinitionSet",
    "IfcComplexPropertyTemplateTypeEnum",
    "IfcObjectTypeEnum",
    "IfcPropertySetTemplateTypeEnum",
    "IfcSimplePropertyTemplateTypeEnum",
    "IfcDefinitionSelect",
    "IfcProcessSelect",
    "IfcProductSelect",
    "IfcPropertySetDefinitionSelect",
    "IfcResourceSelect",
    "IfcActor",
    "IfcComplexPropertyTemplate",
    "IfcContext",
    "IfcControl",
    "IfcGroup",
    "IfcObject",
    "IfcObjectDefinition",
    "IfcPreDefinedPropertySet",
    "IfcProcess",
    "IfcProduct",
    "IfcProject",
    "IfcProjectLibrary",
    "IfcPropertyDefinition",
    "IfcPropertySet",
    "IfcPropertySetDefinition",
    "IfcPropertySetTemplate",
    "IfcPropertyTemplate",
    "IfcPropertyTemplateDefinition",
    "IfcProxy",
    "IfcQuantitySet",
    "IfcRelAggregates",
    "IfcRelAssigns",
    "IfcRelAssignsToActor",
    "IfcRelAssignsToControl",
    "IfcRelAssignsToGroup",
    "IfcRelAssignsToGroupByFactor",
    "IfcRelAssignsToProcess",
    "IfcRelAssignsToProduct",
    "IfcRelAssignsToResource",
    "IfcRelAssociates",
    "IfcRelAssociatesClassification",
    "IfcRelAssociatesDocument",
    "IfcRelAssociatesLibrary",
    "IfcRelationship",
    "IfcRelConnects",
    "IfcRelDeclares",
    "IfcRelDecomposes",
    "IfcRelDefines",
    "IfcRelDefinesByObject",
    "IfcRelDefinesByProperties",
    "IfcRelDefinesByTemplate",
    "IfcRelDefinesByType",
    "IfcRelNests",
    "IfcResource",
    "IfcRoot",
    "IfcSimplePropertyTemplate",
    "IfcTypeObject",
    "IfcTypeProcess",
    "IfcTypeProduct",
    "IfcTypeResource",
    "IfcEventTriggerTypeEnum",
    "IfcEventTypeEnum",
    "IfcProcedureTypeEnum",
    "IfcSequenceEnum",
    "IfcTaskTypeEnum",
    "IfcWorkCalendarTypeEnum",
    "IfcWorkPlanTypeEnum",
    "IfcWorkScheduleTypeEnum",
    "IfcEvent",
    "IfcEventType",
    "IfcProcedure",
    "IfcProcedureType",
    "IfcRelSequence",
    "IfcTask",
    "IfcTaskType",
    "IfcWorkCalendar",
    "IfcWorkControl",
    "IfcWorkPlan",
    "IfcWorkSchedule",
    "IfcAssemblyPlaceEnum",
    "IfcElementAssemblyTypeEnum",
    "IfcElementCompositionEnum",
    "IfcExternalSpatialElementTypeEnum",
    "IfcGeographicElementTypeEnum",
    "IfcGridTypeEnum",
    "IfcInternalOrExternalEnum",
    "IfcOpeningElementTypeEnum",
    "IfcPhysicalOrVirtualEnum",
    "IfcProjectionElementTypeEnum",
    "IfcSpaceTypeEnum",
    "IfcSpatialZoneTypeEnum",
    "IfcTransportElementTypeEnum",
    "IfcSpaceBoundarySelect",
    "IfcAnnotation",
    "IfcBuilding",
    "IfcBuildingElement",
    "IfcBuildingElementType",
    "IfcBuildingStorey",
    "IfcCivilElement",
    "IfcCivilElementType",
    "IfcDistributionElement",
    "IfcDistributionElementType",
    "IfcElement",
    "IfcElementAssembly",
    "IfcElementAssemblyType",
    "IfcElementQuantity",
    "IfcElementType",
    "IfcExternalSpatialElement",
    "IfcExternalSpatialStructureElement",
    "IfcFeatureElement",
    "IfcFeatureElementAddition",
    "IfcFeatureElementSubtraction",
    "IfcFurnishingElement",
    "IfcFurnishingElementType",
    "IfcGeographicElement",
    "IfcGeographicElementType",
    "IfcGrid",
    "IfcOpeningElement",
    "IfcOpeningStandardCase",
    "IfcPort",
    "IfcProjectionElement",
    "IfcRelAssociatesMaterial",
    "IfcRelConnectsElements",
    "IfcRelConnectsPorts",
    "IfcRelConnectsPortToElement",
    "IfcRelConnectsWithRealizingElements",
    "IfcRelContainedInSpatialStructure",
    "IfcRelFillsElement",
    "IfcRelInterferesElements",
    "IfcRelProjectsElement",
    "IfcRelReferencedInSpatialStructure",
    "IfcRelServicesBuildings",
    "IfcRelSpaceBoundary",
    "IfcRelSpaceBoundary1stLevel",
    "IfcRelSpaceBoundary2ndLevel",
    "IfcRelVoidsElement",
    "IfcSite",
    "IfcSpace",
    "IfcSpaceType",
    "IfcSpatialElement",
    "IfcSpatialElementType",
    "IfcSpatialStructureElement",
    "IfcSpatialStructureElementType",
    "IfcSpatialZone",
    "IfcSpatialZoneType",
    "IfcSystem",
    "IfcTransportElement",
    "IfcTransportElementType",
    "IfcVirtualElement",
    "IfcZone",
    "IfcBeamTypeEnum",
    "IfcBuildingElementProxyTypeEnum",
    "IfcBuildingSystemTypeEnum",
    "IfcChimneyTypeEnum",
    "IfcColumnTypeEnum",
    "IfcConnectionTypeEnum",
    "IfcCoveringTypeEnum",
    "IfcCurtainWallTypeEnum",
    "IfcDoorTypeEnum",
    "IfcDoorTypeOperationEnum",
    "IfcMemberTypeEnum",
    "IfcPlateTypeEnum",
    "IfcRailingTypeEnum",
    "IfcRampFlightTypeEnum",
    "IfcRampTypeEnum",
    "IfcRoofTypeEnum",
    "IfcShadingDeviceTypeEnum",
    "IfcSlabTypeEnum",
    "IfcStairFlightTypeEnum",
    "IfcStairTypeEnum",
    "IfcWallTypeEnum",
    "IfcWindowTypeEnum",
    "IfcWindowTypePartitioningEnum",
    "IfcBeam",
    "IfcBeamStandardCase",
    "IfcBeamType",
    "IfcBuildingElementProxy",
    "IfcBuildingElementProxyType",
    "IfcBuildingSystem",
    "IfcChimney",
    "IfcChimneyType",
    "IfcColumn",
    "IfcColumnStandardCase",
    "IfcColumnType",
    "IfcCovering",
    "IfcCoveringType",
    "IfcCurtainWall",
    "IfcCurtainWallType",
    "IfcDoor",
    "IfcDoorStandardCase",
    "IfcDoorType",
    "IfcMember",
    "IfcMemberStandardCase",
    "IfcMemberType",
    "IfcPlate",
    "IfcPlateStandardCase",
    "IfcPlateType",
    "IfcRailing",
    "IfcRailingType",
    "IfcRamp",
    "IfcRampFlight",
    "IfcRampFlightType",
    "IfcRampType",
    "IfcRelConnectsPathElements",
    "IfcRelCoversBldgElements",
    "IfcRelCoversSpaces",
    "IfcRoof",
    "IfcRoofType",
    "IfcShadingDevice",
    "IfcShadingDeviceType",
    "IfcSlab",
    "IfcSlabElementedCase",
    "IfcSlabStandardCase",
    "IfcSlabType",
    "IfcStair",
    "IfcStairFlight",
    "IfcStairFlightType",
    "IfcStairType",
    "IfcWall",
    "IfcWallElementedCase",
    "IfcWallStandardCase",
    "IfcWallType",
    "IfcWindow",
    "IfcWindowStandardCase",
    "IfcWindowType",
    "IfcDistributionChamberElementTypeEnum",
    "IfcDistributionPortTypeEnum",
    "IfcDistributionSystemEnum",
    "IfcFlowDirectionEnum",
    "IfcDistributionChamberElement",
    "IfcDistributionChamberElementType",
    "IfcDistributionCircuit",
    "IfcDistributionControlElement",
    "IfcDistributionControlElementType",
    "IfcDistributionFlowElement",
    "IfcDistributionFlowElementType",
    "IfcDistributionPort",
    "IfcDistributionSystem",
    "IfcEnergyConversionDevice",
    "IfcEnergyConversionDeviceType",
    "IfcFlowController",
    "IfcFlowControllerType",
    "IfcFlowFitting",
    "IfcFlowFittingType",
    "IfcFlowMovingDevice",
    "IfcFlowMovingDeviceType",
    "IfcFlowSegment",
    "IfcFlowSegmentType",
    "IfcFlowStorageDevice",
    "IfcFlowStorageDeviceType",
    "IfcFlowTerminal",
    "IfcFlowTerminalType",
    "IfcFlowTreatmentDevice",
    "IfcFlowTreatmentDeviceType",
    "IfcRelFlowControlElements",
    "IfcBuildingElementPartTypeEnum",
    "IfcDiscreteAccessoryTypeEnum",
    "IfcFastenerTypeEnum",
    "IfcMechanicalFastenerTypeEnum",
    "IfcBuildingElementPart",
    "IfcBuildingElementPartType",
    "IfcDiscreteAccessory",
    "IfcDiscreteAccessoryType",
    "IfcElementComponent",
    "IfcElementComponentType",
    "IfcFastener",
    "IfcFastenerType",
    "IfcMechanicalFastener",
    "IfcMechanicalFastenerType",
    "IfcFurnitureTypeEnum",
    "IfcInventoryTypeEnum",
    "IfcOccupantTypeEnum",
    "IfcSystemFurnitureElementTypeEnum",
    "IfcAsset",
    "IfcFurniture",
    "IfcFurnitureType",
    "IfcInventory",
    "IfcOccupant",
    "IfcSystemFurnitureElement",
    "IfcSystemFurnitureElementType",
    "IfcActionRequestTypeEnum",
    "IfcCostItemTypeEnum",
    "IfcCostScheduleTypeEnum",
    "IfcPermitTypeEnum",
    "IfcProjectOrderTypeEnum",
    "IfcActionRequest",
    "IfcCostItem",
    "IfcCostSchedule",
    "IfcPermit",
    "IfcProjectOrder",
    "IfcDoorPanelOperationEnum",
    "IfcDoorPanelPositionEnum",
    "IfcDoorStyleConstructionEnum",
    "IfcDoorStyleOperationEnum",
    "IfcPermeableCoveringOperationEnum",
    "IfcWindowPanelOperationEnum",
    "IfcWindowPanelPositionEnum",
    "IfcWindowStyleConstructionEnum",
    "IfcWindowStyleOperationEnum",
    "IfcDoorLiningProperties",
    "IfcDoorPanelProperties",
    "IfcDoorStyle",
    "IfcPermeableCoveringProperties",
    "IfcWindowLiningProperties",
    "IfcWindowPanelProperties",
    "IfcWindowStyle",
    "IfcActuatorTypeEnum",
    "IfcAlarmTypeEnum",
    "IfcControllerTypeEnum",
    "IfcFlowInstrumentTypeEnum",
    "IfcSensorTypeEnum",
    "IfcUnitaryControlElementTypeEnum",
    "IfcActuator",
    "IfcActuatorType",
    "IfcAlarm",
    "IfcAlarmType",
    "IfcController",
    "IfcControllerType",
    "IfcFlowInstrument",
    "IfcFlowInstrumentType",
    "IfcSensor",
    "IfcSensorType",
    "IfcUnitaryControlElement",
    "IfcUnitaryControlElementType",
    "IfcConstructionEquipmentResourceTypeEnum",
    "IfcConstructionMaterialResourceTypeEnum",
    "IfcConstructionProductResourceTypeEnum",
    "IfcCrewResourceTypeEnum",
    "IfcLaborResourceTypeEnum",
    "IfcSubContractResourceTypeEnum",
    "IfcConstructionEquipmentResource",
    "IfcConstructionEquipmentResourceType",
    "IfcConstructionMaterialResource",
    "IfcConstructionMaterialResourceType",
    "IfcConstructionProductResource",
    "IfcConstructionProductResourceType",
    "IfcConstructionResource",
    "IfcConstructionResourceType",
    "IfcCrewResource",
    "IfcCrewResourceType",
    "IfcLaborResource",
    "IfcLaborResourceType",
    "IfcSubContractResource",
    "IfcSubContractResourceType",
    "IfcAudioVisualApplianceTypeEnum",
    "IfcCableCarrierFittingTypeEnum",
    "IfcCableCarrierSegmentTypeEnum",
    "IfcCableFittingTypeEnum",
    "IfcCableSegmentTypeEnum",
    "IfcCommunicationsApplianceTypeEnum",
    "IfcElectricApplianceTypeEnum",
    "IfcElectricDistributionBoardTypeEnum",
    "IfcElectricFlowStorageDeviceTypeEnum",
    "IfcElectricGeneratorTypeEnum",
    "IfcElectricMotorTypeEnum",
    "IfcElectricTimeControlTypeEnum",
    "IfcJunctionBoxTypeEnum",
    "IfcLampTypeEnum",
    "IfcLightFixtureTypeEnum",
    "IfcMotorConnectionTypeEnum",
    "IfcOutletTypeEnum",
    "IfcProtectiveDeviceTrippingUnitTypeEnum",
    "IfcProtectiveDeviceTypeEnum",
    "IfcSolarDeviceTypeEnum",
    "IfcSwitchingDeviceTypeEnum",
    "IfcTransformerTypeEnum",
    "IfcAudioVisualAppliance",
    "IfcAudioVisualApplianceType",
    "IfcCableCarrierFitting",
    "IfcCableCarrierFittingType",
    "IfcCableCarrierSegment",
    "IfcCableCarrierSegmentType",
    "IfcCableFitting",
    "IfcCableFittingType",
    "IfcCableSegment",
    "IfcCableSegmentType",
    "IfcCommunicationsAppliance",
    "IfcCommunicationsApplianceType",
    "IfcElectricAppliance",
    "IfcElectricApplianceType",
    "IfcElectricDistributionBoard",
    "IfcElectricDistributionBoardType",
    "IfcElectricFlowStorageDevice",
    "IfcElectricFlowStorageDeviceType",
    "IfcElectricGenerator",
    "IfcElectricGeneratorType",
    "IfcElectricMotor",
    "IfcElectricMotorType",
    "IfcElectricTimeControl",
    "IfcElectricTimeControlType",
    "IfcJunctionBox",
    "IfcJunctionBoxType",
    "IfcLamp",
    "IfcLampType",
    "IfcLightFixture",
    "IfcLightFixtureType",
    "IfcMotorConnection",
    "IfcMotorConnectionType",
    "IfcOutlet",
    "IfcOutletType",
    "IfcProtectiveDevice",
    "IfcProtectiveDeviceTrippingUnit",
    "IfcProtectiveDeviceTrippingUnitType",
    "IfcProtectiveDeviceType",
    "IfcSolarDevice",
    "IfcSolarDeviceType",
    "IfcSwitchingDevice",
    "IfcSwitchingDeviceType",
    "IfcTransformer",
    "IfcTransformerType",
    "IfcAirTerminalBoxTypeEnum",
    "IfcAirTerminalTypeEnum",
    "IfcAirToAirHeatRecoveryTypeEnum",
    "IfcBoilerTypeEnum",
    "IfcBurnerTypeEnum",
    "IfcChillerTypeEnum",
    "IfcCoilTypeEnum",
    "IfcCompressorTypeEnum",
    "IfcCondenserTypeEnum",
    "IfcCooledBeamTypeEnum",
    "IfcCoolingTowerTypeEnum",
    "IfcDamperTypeEnum",
    "IfcDuctFittingTypeEnum",
    "IfcDuctSegmentTypeEnum",
    "IfcDuctSilencerTypeEnum",
    "IfcEngineTypeEnum",
    "IfcEvaporativeCoolerTypeEnum",
    "IfcEvaporatorTypeEnum",
    "IfcFanTypeEnum",
    "IfcFilterTypeEnum",
    "IfcFlowMeterTypeEnum",
    "IfcHeatExchangerTypeEnum",
    "IfcHumidifierTypeEnum",
    "IfcMedicalDeviceTypeEnum",
    "IfcPipeFittingTypeEnum",
    "IfcPipeSegmentTypeEnum",
    "IfcPumpTypeEnum",
    "IfcSpaceHeaterTypeEnum",
    "IfcTankTypeEnum",
    "IfcTubeBundleTypeEnum",
    "IfcUnitaryEquipmentTypeEnum",
    "IfcValveTypeEnum",
    "IfcVibrationIsolatorTypeEnum",
    "IfcAirTerminal",
    "IfcAirTerminalBox",
    "IfcAirTerminalBoxType",
    "IfcAirTerminalType",
    "IfcAirToAirHeatRecovery",
    "IfcAirToAirHeatRecoveryType",
    "IfcBoiler",
    "IfcBoilerType",
    "IfcBurner",
    "IfcBurnerType",
    "IfcChiller",
    "IfcChillerType",
    "IfcCoil",
    "IfcCoilType",
    "IfcCompressor",
    "IfcCompressorType",
    "IfcCondenser",
    "IfcCondenserType",
    "IfcCooledBeam",
    "IfcCooledBeamType",
    "IfcCoolingTower",
    "IfcCoolingTowerType",
    "IfcDamper",
    "IfcDamperType",
    "IfcDuctFitting",
    "IfcDuctFittingType",
    "IfcDuctSegment",
    "IfcDuctSegmentType",
    "IfcDuctSilencer",
    "IfcDuctSilencerType",
    "IfcEngine",
    "IfcEngineType",
    "IfcEvaporativeCooler",
    "IfcEvaporativeCoolerType",
    "IfcEvaporator",
    "IfcEvaporatorType",
    "IfcFan",
    "IfcFanType",
    "IfcFilter",
    "IfcFilterType",
    "IfcFlowMeter",
    "IfcFlowMeterType",
    "IfcHeatExchanger",
    "IfcHeatExchangerType",
    "IfcHumidifier",
    "IfcHumidifierType",
    "IfcMedicalDevice",
    "IfcMedicalDeviceType",
    "IfcPipeFitting",
    "IfcPipeFittingType",
    "IfcPipeSegment",
    "IfcPipeSegmentType",
    "IfcPump",
    "IfcPumpType",
    "IfcSpaceHeater",
    "IfcSpaceHeaterType",
    "IfcTank",
    "IfcTankType",
    "IfcTubeBundle",
    "IfcTubeBundleType",
    "IfcUnitaryEquipment",
    "IfcUnitaryEquipmentType",
    "IfcValve",
    "IfcValveType",
    "IfcVibrationIsolator",
    "IfcVibrationIsolatorType",
    "IfcFireSuppressionTerminalTypeEnum",
    "IfcInterceptorTypeEnum",
    "IfcSanitaryTerminalTypeEnum",
    "IfcStackTerminalTypeEnum",
    "IfcWasteTerminalTypeEnum",
    "IfcFireSuppressionTerminal",
    "IfcFireSuppressionTerminalType",
    "IfcInterceptor",
    "IfcInterceptorType",
    "IfcSanitaryTerminal",
    "IfcSanitaryTerminalType",
    "IfcStackTerminal",
    "IfcStackTerminalType",
    "IfcWasteTerminal",
    "IfcWasteTerminalType",
    "IfcActionSourceTypeEnum",
    "IfcActionTypeEnum",
    "IfcAnalysisModelTypeEnum",
    "IfcAnalysisTheoryTypeEnum",
    "IfcLoadGroupTypeEnum",
    "IfcProjectedOrTrueLengthEnum",
    "IfcStructuralCurveActivityTypeEnum",
    "IfcStructuralCurveMemberTypeEnum",
    "IfcStructuralSurfaceActivityTypeEnum",
    "IfcStructuralSurfaceMemberTypeEnum",
    "IfcStructuralActivityAssignmentSelect",
    "IfcRelConnectsStructuralActivity",
    "IfcRelConnectsStructuralMember",
    "IfcRelConnectsWithEccentricity",
    "IfcStructuralAction",
    "IfcStructuralActivity",
    "IfcStructuralAnalysisModel",
    "IfcStructuralConnection",
    "IfcStructuralCurveAction",
    "IfcStructuralCurveConnection",
    "IfcStructuralCurveMember",
    "IfcStructuralCurveMemberVarying",
    "IfcStructuralCurveReaction",
    "IfcStructuralItem",
    "IfcStructuralLinearAction",
    "IfcStructuralLoadCase",
    "IfcStructuralLoadGroup",
    "IfcStructuralMember",
    "IfcStructuralPlanarAction",
    "IfcStructuralPointAction",
    "IfcStructuralPointConnection",
    "IfcStructuralPointReaction",
    "IfcStructuralReaction",
    "IfcStructuralResultGroup",
    "IfcStructuralSurfaceAction",
    "IfcStructuralSurfaceConnection",
    "IfcStructuralSurfaceMember",
    "IfcStructuralSurfaceMemberVarying",
    "IfcStructuralSurfaceReaction",
    "IfcFootingTypeEnum",
    "IfcPileConstructionEnum",
    "IfcPileTypeEnum",
    "IfcReinforcingBarTypeEnum",
    "IfcReinforcingMeshTypeEnum",
    "IfcSurfaceFeatureTypeEnum",
    "IfcTendonAnchorTypeEnum",
    "IfcTendonTypeEnum",
    "IfcVoidingFeatureTypeEnum",
    "IfcBendingParameterSelect",
    "IfcFooting",
    "IfcFootingType",
    "IfcPile",
    "IfcPileType",
    "IfcReinforcementDefinitionProperties",
    "IfcReinforcingBar",
    "IfcReinforcingBarType",
    "IfcReinforcingElement",
    "IfcReinforcingElementType",
    "IfcReinforcingMesh",
    "IfcReinforcingMeshType",
    "IfcSurfaceFeature",
    "IfcTendon",
    "IfcTendonAnchor",
    "IfcTendonAnchorType",
    "IfcTendonType",
    "IfcVoidingFeature",
    "IfcAddressTypeEnum",
    "IfcRoleEnum",
    "IfcActorSelect",
    "IfcActorRole",
    "IfcAddress",
    "IfcOrganization",
    "IfcOrganizationRelationship",
    "IfcPerson",
    "IfcPersonAndOrganization",
    "IfcPostalAddress",
    "IfcTelecomAddress",
    "IfcApproval",
    "IfcApprovalRelationship",
    "IfcResourceApprovalRelationship",
    "IfcBenchmarkEnum",
    "IfcConstraintEnum",
    "IfcLogicalOperatorEnum",
    "IfcObjectiveEnum",
    "IfcMetricValueSelect",
    "IfcConstraint",
    "IfcMetric",
    "IfcObjective",
    "IfcReference",
    "IfcResourceConstraintRelationship",
    "IfcArithmeticOperatorEnum",
    "IfcAppliedValueSelect",
    "IfcAppliedValue",
    "IfcCostValue",
    "IfcCurrencyRelationship",
    "IfcDataOriginEnum",
    "IfcDate",
    "IfcDateTimeIfcDateTime",
    "IfcDayInMonthNumber",
    "IfcDayInWeekNumber",
    "IfcDuration",
    "IfcRecurrenceTypeEnum",
    "IfcTaskDurationEnum",
    "IfcTimeSeriesDataTypeEnum",
    "IfcTimeOrRatioSelect",
    "IfcEventTime",
    "IfcIrregularTimeSeries",
    "IfcIrregularTimeSeriesValue",
    "IfcLagTime",
    "IfcMonthInYearNumber",
    "IfcRecurrencePattern",
    "IfcRegularTimeSeries",
    "IfcResourceTime",
    "IfcSchedulingTime",
    "IfcTaskTime",
    "IfcTaskTimeRecurring",
    "IfcTime",
    "IfcTimePeriod",
    "IfcTimeSeries",
    "IfcTimeSeriesValue",
    "IfcTimeStamp",
    "IfcWorkTime",
    "IfcLanguageId",
    "IfcDocumentConfidentialityEnum",
    "IfcDocumentStatusEnum",
    "IfcClassificationReferenceSelect",
    "IfcClassificationSelect",
    "IfcDocumentSelect",
    "IfcLibrarySelect",
    "IfcResourceObjectSelect",
    "IfcClassification",
    "IfcClassificationReference",
    "IfcDocumentInformation",
    "IfcDocumentInformationRelationship",
    "IfcDocumentReference",
    "IfcExternalInformation",
    "IfcExternalReference",
    "IfcExternalReferenceRelationship",
    "IfcLibraryInformation",
    "IfcLibraryReference",
    "IfcResourceLevelRelationship",
    "IfcURIReference",
    "IfcCurveOrEdgeCurve",
    "IfcGridPlacementDirectionSelect",
    "IfcPointOrVertexPoint",
    "IfcSolidOrShell",
    "IfcSurfaceOrFaceSurface",
    "IfcConnectionCurveGeometry",
    "IfcConnectionGeometry",
    "IfcConnectionPointEccentricity",
    "IfcConnectionPointGeometry",
    "IfcConnectionSurfaceGeometry",
    "IfcConnectionVolumeGeometry",
    "IfcGridAxis",
    "IfcGridPlacement",
    "IfcLocalPlacement",
    "IfcObjectPlacement",
    "IfcVirtualGridIntersection",
    "IfcBooleanOperator",
    "IfcBooleanOperand",
    "IfcCsgSelect",
    "IfcGeometricSetSelect",
    "IfcAdvancedBrep",
    "IfcAdvancedBrepWithVoids",
    "IfcBlock",
    "IfcBooleanClippingResult",
    "IfcBooleanResult",
    "IfcBoundingBox",
    "IfcBoxedHalfSpace",
    "IfcCartesianPointList",
    "IfcCartesianPointList2D",
    "IfcCartesianPointList3D",
    "IfcCsgPrimitive3D",
    "IfcCsgSolid",
    "IfcExtrudedAreaSolid",
    "IfcExtrudedAreaSolidTapered",
    "IfcFaceBasedSurfaceModel",
    "IfcFacetedBrep",
    "IfcFacetedBrepWithVoids",
    "IfcFixedReferenceSweptAreaSolid",
    "IfcGeometricCurveSet",
    "IfcGeometricSet",
    "IfcHalfSpaceSolid",
    "IfcIndexedPolygonalFace",
    "IfcIndexedPolygonalFaceWithVoids",
    "IfcManifoldSolidBrep",
    "IfcPolygonalBoundedHalfSpace",
    "IfcPolygonalFaceSet",
    "IfcRectangularPyramid",
    "IfcRevolvedAreaSolid",
    "IfcRevolvedAreaSolidTapered",
    "IfcRightCircularCone",
    "IfcRightCircularCylinder",
    "IfcSectionedSpine",
    "IfcShellBasedSurfaceModel",
    "IfcSolidModel",
    "IfcSphere",
    "IfcSurfaceCurveSweptAreaSolid",
    "IfcSweptAreaSolid",
    "IfcSweptDiskSolid",
    "IfcSweptDiskSolidPolygonal",
    "IfcTessellatedFaceSet",
    "IfcTessellatedItem",
    "IfcTriangulatedFaceSet",
    "IfcArcIndex",
    "IfcLineIndex",
    "IfcBSplineCurveForm",
    "IfcBSplineSurfaceForm",
    "IfcKnotType",
    "IfcPreferredSurfaceCurveRepresentation",
    "IfcTransitionCode",
    "IfcTrimmingPreference",
    "IfcAxis2Placement",
    "IfcCurveOnSurface",
    "IfcSegmentIndexSelect",
    "IfcTrimmingSelect",
    "IfcVectorOrDirection",
    "IfcAxis1Placement",
    "IfcAxis2Placement2D",
    "IfcAxis2Placement3D",
    "IfcBoundaryCurve",
    "IfcBoundedCurve",
    "IfcBoundedSurface",
    "IfcBSplineCurve",
    "IfcBSplineCurveWithKnots",
    "IfcBSplineSurface",
    "IfcBSplineSurfaceWithKnots",
    "IfcCartesianPoint",
    "IfcCartesianTransformationOperator",
    "IfcCartesianTransformationOperator2D",
    "IfcCartesianTransformationOperator2DnonUniform",
    "IfcCartesianTransformationOperator3D",
    "IfcCartesianTransformationOperator3DnonUniform",
    "IfcCircle",
    "IfcCompositeCurve",
    "IfcCompositeCurveOnSurface",
    "IfcCompositeCurveSegment",
    "IfcConic",
    "IfcCurve",
    "IfcCurveBoundedPlane",
    "IfcCurveBoundedSurface",
    "IfcCylindricalSurface",
    "IfcDirection",
    "IfcElementarySurface",
    "IfcEllipse",
    "IfcGeometricRepresentationItem",
    "IfcIndexedPolyCurve",
    "IfcIntersectionCurve",
    "IfcLine",
    "IfcMappedItem",
    "IfcOffsetCurve2D",
    "IfcOffsetCurve3D",
    "IfcOuterBoundaryCurve",
    "IfcPcurve",
    "IfcPlacement",
    "IfcPlane",
    "IfcPoint",
    "IfcPointOnCurve",
    "IfcPointOnSurface",
    "IfcPolyline",
    "IfcRationalBSplineCurveWithKnots",
    "IfcRationalBSplineSurfaceWithKnots",
    "IfcRectangularTrimmedSurface",
    "IfcReparametrisedCompositeCurveSegment",
    "IfcRepresentationItem",
    "IfcRepresentationMap",
    "IfcSeamCurve",
    "IfcSphericalSurface",
    "IfcSurface",
    "IfcSurfaceCurve",
    "IfcSurfaceOfLinearExtrusion",
    "IfcSurfaceOfRevolution",
    "IfcSweptSurface",
    "IfcToroidalSurface",
    "IfcTrimmedCurve",
    "IfcVector",
    "IfcCardinalPointReference",
    "IfcDirectionSenseEnum",
    "IfcLayerSetDirectionEnum",
    "IfcMaterialSelect",
    "IfcMaterial",
    "IfcMaterialClassificationRelationship",
    "IfcMaterialConstituent",
    "IfcMaterialConstituentSet",
    "IfcMaterialDefinition",
    "IfcMaterialLayer",
    "IfcMaterialLayerSet",
    "IfcMaterialLayerSetUsage",
    "IfcMaterialLayerWithOffsets",
    "IfcMaterialList",
    "IfcMaterialProfile",
    "IfcMaterialProfileSet",
    "IfcMaterialProfileSetUsage",
    "IfcMaterialProfileSetUsageTapering",
    "IfcMaterialProfileWithOffsets",
    "IfcMaterialProperties",
    "IfcMaterialRelationship",
    "IfcMaterialUsageDefinition",
    "IfcAbsorbedDoseMeasure",
    "IfcAccelerationMeasure",
    "IfcAmountOfSubstanceMeasure",
    "IfcAngularVelocityMeasure",
    "IfcAreaDensityMeasure",
    "IfcAreaMeasure",
    "IfcBinary",
    "IfcBoolean",
    "IfcComplexNumber",
    "IfcCompoundPlaneAngleMeasure",
    "IfcContextDependentMeasure",
    "IfcCountMeasure",
    "IfcCurvatureMeasure",
    "IfcDescriptiveMeasure",
    "IfcDoseEquivalentMeasure",
    "IfcDynamicViscosityMeasure",
    "IfcElectricCapacitanceMeasure",
    "IfcElectricChargeMeasure",
    "IfcElectricConductanceMeasure",
    "IfcElectricCurrentMeasure",
    "IfcElectricResistanceMeasure",
    "IfcElectricVoltageMeasure",
    "IfcEnergyMeasure",
    "IfcForceMeasure",
    "IfcFrequencyMeasure",
    "IfcHeatFluxDensityMeasure",
    "IfcHeatingValueMeasure",
    "IfcIdentifier",
    "IfcIlluminanceMeasure",
    "IfcInductanceMeasure",
    "IfcInteger",
    "IfcIntegerCountRateMeasure",
    "IfcIonConcentrationMeasure",
    "IfcIsothermalMoistureCapacityMeasure",
    "IfcKinematicViscosityMeasure",
    "IfcLabel",
    "IfcLengthMeasure",
    "IfcLinearForceMeasure",
    "IfcLinearMomentMeasure",
    "IfcLinearStiffnessMeasure",
    "IfcLinearVelocityMeasure",
    "IfcLogical",
    "IfcLuminousFluxMeasure",
    "IfcLuminousIntensityDistributionMeasure",
    "IfcLuminousIntensityMeasure",
    "IfcMagneticFluxDensityMeasure",
    "IfcMagneticFluxMeasure",
    "IfcMassDensityMeasure",
    "IfcMassFlowRateMeasure",
    "IfcMassMeasure",
    "IfcMassPerLengthMeasure",
    "IfcNonNegativeLengthMeasure",
    "IfcNormalisedRatioMeasure",
    "IfcPositiveInteger",
    "IfcPositiveLengthMeasure",
    "IfcPositivePlaneAngleMeasure",
    "IfcPositiveRatioMeasure",
    "IfcDerivedUnitEnum",
    "IfcSIPrefix",
    "IfcSIUnitName",
    "IfcUnitEnum",
    "IfcDerivedMeasureValue",
    "IfcMeasureValue",
    "IfcSimpleValue",
    "IfcUnit",
    "IfcValue",
    "IfcContextDependentUnit",
    "IfcConversionBasedUnit",
    "IfcConversionBasedUnitWithOffset",
    "IfcDerivedUnit",
    "IfcDerivedUnitElement",
    "IfcDimensionalExponents",
    "IfcMeasureWithUnit",
    "IfcMonetaryUnit",
    "IfcModulusOfElasticityMeasure",
    "IfcModulusOfLinearSubgradeReactionMeasure",
    "IfcModulusOfRotationalSubgradeReactionMeasure",
    "IfcModulusOfSubgradeReactionMeasure",
    "IfcMoistureDiffusivityMeasure",
    "IfcMolecularWeightMeasure",
    "IfcMomentOfInertiaMeasure",
    "IfcMonetaryMeasure",
    "IfcNamedUnit",
    "IfcSIUnit",
    "IfcUnitAssignment",
    "IfcNullStyle",
    "IfcReflectanceMethodEnum",
    "IfcSurfaceSide",
    "IfcColour",
    "IfcColourOrFactor",
    "IfcCurveFontOrScaledCurveFontSelect",
    "IfcCurveStyleFontSelect",
    "IfcFillStyleSelect",
    "IfcHatchLineDistanceSelect",
    "IfcPresentationStyleSelect",
    "IfcSizeSelect",
    "IfcSpecularHighlightSelect",
    "IfcStyleAssignmentSelect",
    "IfcSurfaceStyleElementSelect",
    "IfcTextFontSelect",
    "IfcBlobTexture",
    "IfcColourRgb",
    "IfcColourRgbList",
    "IfcColourSpecification",
    "IfcCurveStyle",
    "IfcCurveStyleFont",
    "IfcCurveStyleFontAndScaling",
    "IfcCurveStyleFontPattern",
    "IfcDraughtingPreDefinedColour",
    "IfcDraughtingPreDefinedCurveFont",
    "IfcExternallyDefinedHatchStyle",
    "IfcExternallyDefinedSurfaceStyle",
    "IfcExternallyDefinedTextFont",
    "IfcFillAreaStyle",
    "IfcFillAreaStyleHatching",
    "IfcFillAreaStyleTiles",
    "IfcImageTexture",
    "IfcIndexedColourMap",
    "IfcIndexedTextureMap",
    "IfcIndexedTriangleTextureMap",
    "IfcPixelTexture",
    "IfcPreDefinedColour",
    "IfcPreDefinedCurveFont",
    "IfcPreDefinedItem",
    "IfcPreDefinedTextFont",
    "IfcPresentationStyle",
    "IfcPresentationStyleAssignment",
    "IfcStyledItem",
    "IfcSurfaceStyle",
    "IfcSurfaceStyleLighting",
    "IfcSurfaceStyleRefraction",
    "IfcSurfaceStyleRendering",
    "IfcSurfaceStyleShading",
    "IfcSurfaceStyleWithTextures",
    "IfcSurfaceTexture",
    "IfcTextStyle",
    "IfcTextStyleFontModel",
    "IfcTextStyleForDefinedFont",
    "IfcTextStyleTextModel",
    "IfcTextureCoordinate",
    "IfcTextureCoordinateGenerator",
    "IfcTextureMap",
    "IfcTextureVertex",
    "IfcTextureVertexList",
    "IfcBoxAlignment",
    "IfcTextPath",
    "IfcAnnotationFillArea",
    "IfcPlanarBox",
    "IfcPlanarExtent",
    "IfcPresentationItem",
    "IfcTextLiteral",
    "IfcTextLiteralWithExtent",
    "IfcLightDistributionCurveEnum",
    "IfcLightEmissionSourceEnum",
    "IfcLayeredItem",
    "IfcLightDistributionDataSourceSelect",
    "IfcLightDistributionData",
    "IfcLightIntensityDistribution",
    "IfcLightSource",
    "IfcLightSourceAmbient",
    "IfcLightSourceDirectional",
    "IfcLightSourceGoniometric",
    "IfcLightSourcePositional",
    "IfcLightSourceSpot",
    "IfcPresentationLayerAssignment",
    "IfcPresentationLayerWithStyle",
    "IfcProfileTypeEnum",
    "IfcReinforcingBarRoleEnum",
    "IfcReinforcingBarSurfaceEnum",
    "IfcSectionTypeEnum",
    "IfcArbitraryClosedProfileDef",
    "IfcArbitraryOpenProfileDef",
    "IfcArbitraryProfileDefWithVoids",
    "IfcAsymmetricIShapeProfileDef",
    "IfcCenterLineProfileDef",
    "IfcCircleHollowProfileDef",
    "IfcCircleProfileDef",
    "IfcCompositeProfileDef",
    "IfcCShapeProfileDef",
    "IfcDerivedProfileDef",
    "IfcEllipseProfileDef",
    "IfcIShapeProfileDef",
    "IfcLShapeProfileDef",
    "IfcMirroredProfileDef",
    "IfcParameterizedProfileDef",
    "IfcProfileDef",
    "IfcProfileProperties",
    "IfcRectangleHollowProfileDef",
    "IfcRectangleProfileDef",
    "IfcReinforcementBarProperties",
    "IfcRoundedRectangleProfileDef",
    "IfcSectionProperties",
    "IfcSectionReinforcementProperties",
    "IfcTrapeziumProfileDef",
    "IfcTShapeProfileDef",
    "IfcUShapeProfileDef",
    "IfcZShapeProfileDef",
    "IfcCurveInterpolationEnum",
    "IfcObjectReferenceSelect",
    "IfcComplexProperty",
    "IfcExtendedProperties",
    "IfcPreDefinedProperties",
    "IfcProperty",
    "IfcPropertyAbstraction",
    "IfcPropertyBoundedValue",
    "IfcPropertyDependencyRelationship",
    "IfcPropertyEnumeratedValue",
    "IfcPropertyEnumeration",
    "IfcPropertyListValue",
    "IfcPropertyReferenceValue",
    "IfcPropertySingleValue",
    "IfcPropertyTableValue",
    "IfcSimpleProperty",
    "IfcPhysicalComplexQuantity",
    "IfcPhysicalQuantity",
    "IfcPhysicalSimpleQuantity",
    "IfcQuantityArea",
    "IfcQuantityCount",
    "IfcQuantityLength",
    "IfcQuantityTime",
    "IfcQuantityVolume",
    "IfcQuantityWeight",
    "IfcGeometricProjectionEnum",
    "IfcGlobalOrLocalEnum",
    "IfcCoordinateReferenceSystemSelect",
    "IfcProductRepresentationSelect",
    "IfcCoordinateOperation",
    "IfcCoordinateReferenceSystem",
    "IfcGeometricRepresentationContext",
    "IfcGeometricRepresentationSubContext",
    "IfcMapConversion",
    "IfcMaterialDefinitionRepresentation",
    "IfcProductDefinitionShape",
    "IfcProductRepresentation",
    "IfcProjectedCRS",
    "IfcRepresentation",
    "IfcRepresentationContext",
    "IfcShapeAspect",
    "IfcShapeModel",
    "IfcShapeRepresentation",
    "IfcStyledRepresentation",
    "IfcStyleModel",
    "IfcTopologyRepresentation",
    "IfcModulusOfRotationalSubgradeReactionSelect",
    "IfcModulusOfSubgradeReactionSelect",
    "IfcModulusOfTranslationalSubgradeReactionSelect",
    "IfcRotationalStiffnessSelect",
    "IfcTranslationalStiffnessSelect",
    "IfcWarpingStiffnessSelect",
    "IfcBoundaryCondition",
    "IfcBoundaryEdgeCondition",
    "IfcBoundaryFaceCondition",
    "IfcBoundaryNodeCondition",
    "IfcBoundaryNodeConditionWarping",
    "IfcFailureConnectionCondition",
    "IfcSlippageConnectionCondition",
    "IfcStructuralConnectionCondition",
    "IfcStructuralLoad",
    "IfcStructuralLoadConfiguration",
    "IfcStructuralLoadLinearForce",
    "IfcStructuralLoadOrResult",
    "IfcStructuralLoadPlanarForce",
    "IfcStructuralLoadSingleDisplacement",
    "IfcStructuralLoadSingleDisplacementDistortion",
    "IfcStructuralLoadSingleForce",
    "IfcStructuralLoadSingleForceWarping",
    "IfcStructuralLoadStatic",
    "IfcStructuralLoadTemperature",
    "IfcSurfaceReinforcementArea",
    "IfcShell",
    "IfcAdvancedFace",
    "IfcClosedShell",
    "IfcConnectedFaceSet",
    "IfcEdge",
    "IfcEdgeCurve",
    "IfcEdgeLoop",
    "IfcFace",
    "IfcFaceBound",
    "IfcFaceOuterBound",
    "IfcFaceSurface",
    "IfcLoop",
    "IfcOpenShell",
    "IfcOrientedEdge",
    "IfcPath",
    "IfcPolyLoop",
    "IfcSubedge",
    "IfcTopologicalRepresentationItem",
    "IfcVertex",
    "IfcVertexLoop",
    "IfcVertexPoint",
    "IfcChangeActionEnum",
    "IfcStateEnum",
    "IfcApplication",
    "IfcOwnerHistory",
    "IfcTable",
    "IfcTableColumn",
    "IfcTableRow"
];
