import * as React from 'react'

interface IProps {
  primaryColor: string
}

export const HouseForSale = ({ primaryColor }: IProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      // width='997.17528'
      // height='465.45949'
      width='100%'
      height='100%'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 997.17528 465.45949'
    >
      <path
        d='M1009.94629,681.83375H608.34717c-10.92383,0-19.81079-9.83155-19.81079-21.916V361.26734a12.30407,12.30407,0,0,1,6.54272-11.124l151.759-91.87061a60.33754,60.33754,0,0,1,53.543-.0542l222.793,91.91553a12.30542,12.30542,0,0,1,6.583,11.14258V659.91773C1029.75708,672.0022,1020.87012,681.83375,1009.94629,681.83375Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#f2f2f2'
      />
      <path
        d='M950.646,681.51H605.78271c-9.41918,0-17.08251-6.93946-17.08251-15.46973V457.2815a8.67562,8.67562,0,0,1,5.69238-7.92676L754.968,385.12183a62.5155,62.5155,0,0,1,45.93847-.02295l161.12012,64.25879a8.67511,8.67511,0,0,1,5.70215,7.93018V666.04029C967.72876,674.57056,960.06543,681.51,950.646,681.51Z'
        transform='translate(-101.41236 -217.27026)'
        fill={primaryColor}
      />
      <circle cx='298.68503' cy='194.47994' r='50.53168' fill='#ff6584' />
      <ellipse
        cx='319.81038'
        cy='298.3466'
        rx='64.00072'
        ry='80.10973'
        fill='#f2f2f2'
      />
      <polygon
        points='319.986 282.238 320.162 282.238 323.327 463.69 316.646 463.69 319.986 282.238'
        fill='#ccc'
      />
      <path
        d='M432.31477,619.635l0,0a1.58243,1.58243,0,0,1-.663,2.13741l-9.17931,4.83272-1.47438-2.80046,9.17931-4.83271A1.58242,1.58242,0,0,1,432.31477,619.635Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#ccc'
      />
      <ellipse
        cx='135.81038'
        cy='298.3466'
        rx='64.00072'
        ry='80.10973'
        fill='#f2f2f2'
      />
      <path
        d='M248.31494,622.635V622.635a1.58278,1.58278,0,0,0-2.13769-.663l-6.40821,3.37378-2.19482-125.83789h-.17578l-3.34033,181.45245h6.68115l-.90821-52.07037,7.82081-4.11749A1.5831,1.5831,0,0,0,248.31494,622.635Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#ccc'
      />
      <path
        d='M329,331c44.788,0,81.19156,84.39131,81.19156,140.45242s-36.3078,101.50763-81.09578,101.50763S248,527.51354,248,471.45242,284.212,331,329,331Z'
        transform='translate(-101.41236 -217.27026)'
        fill={primaryColor}
      />
      <path
        d='M343.15039,607.05585a2.00473,2.00473,0,0,0-2.708-.84008l-8.12011,4.2749L329.5415,451.04059h-.22265l-4.23291,229.91968h8.46582l-1.15088-65.97882,9.90967-5.21722A2.00478,2.00478,0,0,0,343.15039,607.05585Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#3f3d56'
      />
      <path
        d='M697.69274,538.04858V516.45764A10.61779,10.61779,0,0,0,687.075,505.83988H632.59157a10.61779,10.61779,0,0,0-10.61775,10.61776v21.59094Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#fff'
      />
      <path
        d='M621.97382,541.439v33.45734a10.6178,10.6178,0,0,0,10.61775,10.61775H687.075a10.6178,10.6178,0,0,0,10.61775-10.61775V541.439Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#fff'
      />
      <path
        d='M954.61319,478.78525,791.0374,418.82216a44.73889,44.73889,0,0,0-30.79647,0L596.66515,478.78525A22.81327,22.81327,0,0,1,566,457.36579v0a22.81326,22.81326,0,0,1,14.96139-21.41946l179.27954-65.71972a44.73878,44.73878,0,0,1,30.79647,0l179.27954,65.71972a22.81326,22.81326,0,0,1,14.96139,21.41946v0A22.81327,22.81327,0,0,1,954.61319,478.78525Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#3f3d56'
      />
      <path
        d='M802.05176,682.50977H758.333a17.52,17.52,0,0,1-17.5-17.5v-105.876a17.52,17.52,0,0,1,17.5-17.5h43.71875a17.52,17.52,0,0,1,17.5,17.5v105.876A17.52,17.52,0,0,1,802.05176,682.50977ZM758.333,544.63379a14.51669,14.51669,0,0,0-14.5,14.5v105.876a14.51669,14.51669,0,0,0,14.5,14.5h43.71875a14.51669,14.51669,0,0,0,14.5-14.5v-105.876a14.51669,14.51669,0,0,0-14.5-14.5Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#3f3d56'
      />
      <path
        d='M806.29216,316.45189a31.63726,31.63726,0,0,0-63.02285,0Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#fff'
      />
      <path
        d='M743.15136,319.84228a31.63426,31.63426,0,0,0,63.25875,0Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#fff'
      />
      <path
        d='M801.33887,354.81129H748.22266a9.541,9.541,0,0,1,0-19.082h53.11621a9.541,9.541,0,0,1,0,19.082Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#ccc'
      />
      <path
        d='M622.53906,574.71265a8.541,8.541,0,0,0,0,17.082h74.58863a8.541,8.541,0,0,0,0-17.082Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#ccc'
      />
      <path
        d='M854.21558,541.439v33.45734a10.61781,10.61781,0,0,0,10.61776,10.61775h54.48341a10.61781,10.61781,0,0,0,10.61776-10.61775V541.439Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#fff'
      />
      <path
        d='M929.93451,538.04858V516.45764a10.61779,10.61779,0,0,0-10.61776-10.61776H864.83334a10.61779,10.61779,0,0,0-10.61776,10.61776v21.59094Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#fff'
      />
      <circle cx='704.77278' cy='391.97653' r='6.21572' fill='#3f3d56' />
      <polygon
        points='495.986 395.977 471.503 357.893 472.636 357.164 495.868 393.303 514.799 357.216 515.991 357.841 495.986 395.977'
        fill='#3f3d56'
      />
      <path
        d='M617.335,562.67889H559.09263v-1.01a12.45645,12.45645,0,0,0-12.45646-12.45647h0a12.45646,12.45646,0,0,0-12.45647,12.45647v1.01h-5.72324a12.45646,12.45646,0,1,0,0,24.91292h5.72324v94.13534c24.8203-.49741-.1797.50259,24.91293,0V587.59181H617.335a12.45646,12.45646,0,0,0,0-24.91292Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#3f3d56'
      />
      <circle cx='444.88714' cy='357.52843' r='4.71326' fill='#fff' />
      <path
        d='M624.73682,637.24439H570.88037a9.60541,9.60541,0,0,1-9.59448-9.59473V610.15259a9.60531,9.60531,0,0,1,9.59448-9.59423h53.85645a9.60531,9.60531,0,0,1,9.59448,9.59423v17.49707A9.60542,9.60542,0,0,1,624.73682,637.24439Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#ccc'
      />
      <rect
        x='475.18665'
        y='397.25445'
        width='41.74598'
        height='7.40655'
        rx='3.70327'
        fill={primaryColor}
      />
      <path
        d='M1097.81458,682.72974H102.18542a.77306.77306,0,0,1,0-1.54612h995.62916a.77306.77306,0,0,1,0,1.54612Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#ccc'
      />
      <path
        d='M928.8042,592.79468H854.21558a9.541,9.541,0,1,1,0-19.082H928.8042a9.541,9.541,0,0,1,0,19.082Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#ccc'
      />
      <path
        d='M1029,371.22974s-18.08808-1.28316-55-3c-44-77-187.00332-95.56007-187.00332-150.95934v-.00014H962.76256a66.30762,66.30762,0,0,1,66.30762,66.30762Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#ccc'
      />
      <path
        d='M876.55518,237.51392a1.50164,1.50164,0,0,0-1.5,1.5V289.094a1.50164,1.50164,0,0,0,1.5,1.5h31.19506a1.50164,1.50164,0,0,0,1.5-1.5V239.01392a1.50164,1.50164,0,0,0-1.5-1.5Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#f2f2f2'
      />
      <path
        d='M963.19735,367.19108,799.62157,268.59924a44.73889,44.73889,0,0,0-30.79647,0L605.24931,367.19108a22.81326,22.81326,0,0,1-30.66514-21.41946v0a22.81326,22.81326,0,0,1,14.96139-21.41946L768.8251,220.00364a44.73889,44.73889,0,0,1,30.79647,0L978.90111,324.35212a22.81326,22.81326,0,0,1,14.96139,21.41946v0A22.81327,22.81327,0,0,1,963.19735,367.19108Z'
        transform='translate(-101.41236 -217.27026)'
        fill='#ccc'
      />
    </svg>
  )
}
