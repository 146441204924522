import React from "react";

import ElementImage from "common/components/elementImage/image";
import { useAppSelector } from "store";
import { getDesign } from "store/entities/settings/settings.slice";
import type { IElement } from "types";
import { Box, Container } from "@mui/material";

interface ImageProps {
  element: IElement;
  imageRef: any;
}

const Image: React.FC<ImageProps> = ({ element, imageRef }) => {
  const design = useAppSelector(getDesign);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <svg
        width="100%"
        height="100%"
        style={{
          userSelect: "none",
          cursor: "move",
        }}
        ref={imageRef}
      >
        <ElementImage element={element} colorTheme={design.colorTheme} />
      </svg>
    </Box>
  );
};

export default Image;
