import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Box } from "@mui/system";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface FilterProps {
    fireProtectionFilter: string;
    setFireProtectionFilter: Dispatch<SetStateAction<string>>;
    soundProtectionFilter: string;
    setSoundProtectionFilter: Dispatch<SetStateAction<string>>;
}

const BuildingPhysicsFilter: React.FC<FilterProps> = ({
    fireProtectionFilter,
    setFireProtectionFilter,
    soundProtectionFilter,
    setSoundProtectionFilter
}) => {
    const { t } = useTranslation("component_catalogue", {
        keyPrefix: "library.filter.sections.building_physics",
    });

    const fireProtectionChoices = [
        { value: "REI30", label: "REI30" },
        { value: "REI45", label: "REI45" },
        { value: "REI60", label: "REI60" },
        { value: "REI60 / K260", label: "REI60 / K260" },
        { value: "REI90", label: "REI90" },
        { value: "REI90 / K260", label: "REI90 / K260" },
    ]

    const soundProtectionChoices = [
        { value: "0_43", label: "Rw ≤43 dB" },
        { value: "44_47", label: "Rw 44–47 dB" },
        { value: "48_57", label: "Rw 48–57 dB" },
        { value: "58_999", label: "Rw ≥58 dB" },
    ]

    const handleFireProtectionFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFireProtectionFilter(event.target.value);
    };

    const handleSoundProtectionFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSoundProtectionFilter(event.target.value);
    };

    return (
        <Box
            sx={{ display: "flex", flexDirection: "row" }}
        >
            <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">{t("fire_protection")}</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={fireProtectionFilter}
                    onChange={handleFireProtectionFilterChange}
                >
                    {fireProtectionChoices.map((choice: any) => {
                        return (
                            <FormControlLabel key={choice.value} value={choice.value} control={<Radio />} label={choice.label} />
                        );
                    })}
                </RadioGroup>
            </FormControl>
            <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">{t("sound_protection")}</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={soundProtectionFilter}
                    onChange={handleSoundProtectionFilterChange}
                >
                    {soundProtectionChoices.map((choice: any) => {
                        return (
                            <FormControlLabel key={choice.value} value={choice.value} control={<Radio />} label={choice.label} />
                        );
                    })}
                </RadioGroup>
            </FormControl>
        </Box>
    );
}

export default BuildingPhysicsFilter;
