import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import { TransitionProps } from '@mui/material/transitions'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Slide from '@mui/material/Slide'
import Zoom from '@mui/material/Zoom'
import { useAppDispatch, useAppSelector } from 'store'
import { fetchUploadedFiles } from 'store/entities/projects/uploadedFiles/uploadedFiles.actions'
import { useParams } from 'react-router-dom'
import { IUploadedFileTableRowData } from 'types'
import { closeSelectModelDialog, getSelectModelDialog, selectToBeLoadedIfcModelFile } from 'store/ui/projects/ifcViewer/ifcViewer.slice'
import Table from './table/table'
import { getLocales } from 'store/entities/settings/settings.slice'


const ZoomTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />
})

const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />
})

interface IProps {
  setModelFile: React.Dispatch<React.SetStateAction<File | null>>;
}

const SelectModelDialog: React.FC<IProps> = ({ setModelFile }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation('ifc_viewer', {
    keyPrefix: 'dialogs.select_dialog',
  })
  const locales = useAppSelector(getLocales);
  const { projectId } = useParams();

  const [selectedModel, setSelectedModel] = useState<IUploadedFileTableRowData | null>(null);

  const open = useAppSelector(getSelectModelDialog).open

  const handleDialogClose = () => {
    dispatch(closeSelectModelDialog());
  }

  const handleDialogLoadModel = () => {
    if (selectedModel !== null) {
      dispatch(selectToBeLoadedIfcModelFile(selectedModel));
    }
  }

  const isLoadingButtonDisabled = (): boolean => {
    if (selectedModel !== null) {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    if (projectId !== undefined) {
      dispatch(fetchUploadedFiles(+projectId));
    }
  }, [projectId, locales]);

  return (
    <Container>
      <Dialog
        fullScreen={matches ? false : true}
        open={open}
        TransitionComponent={matches ? ZoomTransition : SlideTransition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby='dialog-slide-edit'
        maxWidth={"xl"}
      >
        {matches ? (
          <DialogTitle>
            {t('dialog_title')}
          </DialogTitle>
        ) : (
          <AppBar sx={{ position: 'relative' }} color='inherit'>
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                onClick={handleDialogClose}
                aria-label='cancel'
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                {t('dialog_title')}
              </Typography>
              <Button
                autoFocus
                color='inherit'
                onClick={handleDialogLoadModel}
                id='element-select-form'
              >
                {t('load')}
              </Button>
            </Toolbar>
          </AppBar>
        )}
        <DialogContent>
          <Table setSelectedModel={setSelectedModel} />
        </DialogContent>
        <DialogActions>
          {matches && (
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: '100%' }}>
              <Button onClick={handleDialogClose}>{t("cancel")}</Button>
              <Button onClick={handleDialogLoadModel} disabled={isLoadingButtonDisabled()}>{t('load')}</Button>
            </Box>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default SelectModelDialog
