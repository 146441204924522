import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Paper from '@mui/material/Paper';
import { properties } from "../properties/properties";
import TreeItem from "@mui/lab/TreeItem";
import { Box, Typography } from "@mui/material";
import uuid from "react-uuid";

interface ITreeProps {
    selectedModelProperties: properties[];
}

const PropertiesPanel: React.FC<ITreeProps> = ({
    selectedModelProperties
}) => {
    const { t } = useTranslation('ifc_viewer', {
        keyPrefix: 'tree',
    });

    const [expanded, setExpanded] = useState<string[]>(["root"]);

    const handleExpansionChange = (event: React.ChangeEvent<{}>, nodes: string[]) => {
        setExpanded(nodes);
    };

    useEffect(() => {
        setExpanded(["root"]);
    }, [selectedModelProperties]);

    return (
        <Paper sx={{ backgroundColor: "transparent", color: "white" }}>
            <TreeView
                aria-label="icon expansion"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={handleExpansionChange}
                sx={{
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    "&& .MuiTreeItem-content": {
                        backgroundColor: 'transparent !important',
                    }
                }}
            >
                {
                    selectedModelProperties.map((property: properties) => {
                        if (property.values.length !== 0) {
                            return (
                                <TreeItem
                                    key={uuid()}
                                    nodeId={property.name}
                                    label={property.type}
                                    sx={{ pb: 1 }}
                                >
                                    {property.values.map((propertyValue) => (
                                        <Box
                                            key={uuid()}
                                            sx={{
                                                py: 1,
                                                pl: 2
                                            }}
                                        >
                                            <Typography
                                                variant="caption"
                                            >
                                                {propertyValue.name}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                            >
                                                {propertyValue.value}
                                            </Typography>
                                        </Box>


                                    ))}
                                </TreeItem>
                            )
                        }
                    })
                }
            </TreeView>
        </Paper>
    );
}

export default PropertiesPanel;