import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { useTranslation } from "react-i18next";
import { IconButton, Menu as MuiMenu, MenuItem, Tooltip } from "@mui/material";
import {
  MoreVert,
  EditOutlined,
  DeleteForeverOutlined,
} from "@mui/icons-material";
import {
  openProjectUpdateDialog,
  openProjectDeleteDialog,
} from "store/ui/projects/dashboard/dashboard.ui.slice";

import { getProjectPermissions } from "store/entities/projects/project.slice";
import { hasProjectPerm } from "services/authService";
import { IStakeholderPermission } from "types";
import { projectPermissions } from "common/utils/constants/auth.constants";

const Menu: React.FC = () => {
  const { t } = useTranslation("dashboard", {
    keyPrefix: "project.menu",
  });
  const dispatch = useAppDispatch();

  const userPermissions: IStakeholderPermission[] = useAppSelector(
    getProjectPermissions
  );

  const isMaintainer = hasProjectPerm(userPermissions, [
    projectPermissions.dashboard.maintainer,
  ]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditProjectClick = () => {
    dispatch(openProjectUpdateDialog());
    handleMenuClose();
  };

  const handleDeleteProjectClick = () => {
    dispatch(openProjectDeleteDialog());
    handleMenuClose();
  };

  return (
    <>
      <Tooltip title={t("options")}>
        <IconButton aria-label="dialog-action" onClick={handleMenuOpen}>
          <MoreVert />
        </IconButton>
      </Tooltip>
      <MuiMenu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {isMaintainer && (
          <MenuItem onClick={handleEditProjectClick}>
            <EditOutlined sx={{ marginRight: 1 }} />
            {t("edit_project")}
          </MenuItem>
        )}
        {isMaintainer && (
          <MenuItem onClick={handleDeleteProjectClick}>
            <DeleteForeverOutlined sx={{ marginRight: 1 }} />
            {t("delete_project")}
          </MenuItem>
        )}
      </MuiMenu>
    </>
  );
};

export default Menu;
