import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Zoom from "@mui/material/Zoom";
import { TransitionProps } from "@mui/material/transitions";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import {
  closeElementDeleteDialog,
  closeElementSelectionDialog,
  closePersonalLibrarySelectionDialog,
  getElementDialogs,
  getPersonalLibraryDialogs,
  getPersonalLibraryOpen,
  getSelectedElement,
  getSelectedElements,
} from "store/ui/projects/componentCatalogue/componentCatalogue.slice";
import { deleteElement } from "store/entities/projects/componentCatalogue/componentCatalogue.actions";
import { useAppDispatch, useAppSelector } from "store";

const ZoomTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

const ElementDeleteDialog: React.FC = () => {
  const { projectId, elementId } = useParams();
  const { t } = useTranslation("component_catalogue", {
    keyPrefix: "selected_element.dialogs.element.delete",
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const element = useAppSelector(getSelectedElement);
  const open = useAppSelector(getElementDialogs).delete;
  const personalLibraryOpen = useAppSelector(getPersonalLibraryOpen);

  const selectedElements = useAppSelector(getSelectedElements);
  const hasSelectedElements = selectedElements.length > 0 ? true : false;

  const copmponentCatalogueSelection = useAppSelector(getElementDialogs).selection;
  const personalLibrarySelection = useAppSelector(getPersonalLibraryDialogs).selection;

  const deleteSingleElement = (elementId !== undefined) && !(copmponentCatalogueSelection || personalLibrarySelection);
  const deleteElementSelection = hasSelectedElements && (copmponentCatalogueSelection || personalLibrarySelection);

  const handleDialogClose = () => {
    dispatch(closeElementDeleteDialog());
  };

  const handleDelete = () => {
    if (projectId !== undefined) {
      if (deleteElementSelection) {
        for (const element of selectedElements) {
          dispatch(deleteElement(+projectId, element.id));
        }
      } else if (deleteSingleElement) {
        dispatch(deleteElement(+projectId, +elementId));
      }

      handleDialogClose();
      if (personalLibraryOpen) {
        dispatch(closePersonalLibrarySelectionDialog());
        return navigate("/projects/" + projectId + "/component-catalogue/personal-library");
      }
      dispatch(closeElementSelectionDialog());
      return navigate("/projects/" + projectId + "/component-catalogue/");
    }
  };

  return (
    <Dialog
      sx={{ width: "100%", height: "100%", zIndex: 1301 }}
      open={open}
      TransitionComponent={ZoomTransition}
      keepMounted
      onClose={handleDialogClose}
      maxWidth="sm"
    >
      {deleteElementSelection && (
        <>
          <DialogTitle>
            {t("delete_selected_elements", { count: selectedElements.length })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{t("delete_selected_elements_info")}</DialogContentText>
          </DialogContent>
        </>
      )}
      {deleteSingleElement && (
        <>
          <DialogTitle>
            {t("delete_element", { element: element.name })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{t("delete_element_info")}</DialogContentText>
          </DialogContent>
        </>
      )}
      {(deleteSingleElement || deleteElementSelection) && (
        <DialogActions>
          <Button onClick={handleDialogClose}>{t("cancel")}</Button>
          <Button onClick={handleDelete} autoFocus>
            {t("delete")}
          </Button>
        </DialogActions>
      )}

    </Dialog>
  );
};

export default ElementDeleteDialog;
