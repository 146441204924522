import AddIcon from "@mui/icons-material/Add";
import { Collapse } from "@mui/material";
import Fab from "@mui/material/Fab";
import { List as MuiList } from "@mui/material";
import React, { useEffect } from "react";
import { TransitionGroup } from "react-transition-group";

import {
  getInvitationStatus,
  getLoading,
  getStakeholders,
} from "store/entities/projects/stakeholders/stakeholders.slice";
import {
  fetchStakeholders,
  findMyProfile,
} from "store/entities/projects/stakeholders/stakeholders.actions";
import { handleAddStakeholderDialogOpen } from "store/ui/projects/stakeholders/stakeholders.slice";
import UpdateStakeholderDialog from "./dialogs/updateStakeholder/updateStakeholder";
import AddStakeholderDialog from "./dialogs/addStakeholder";
import LoadingItem from "./loadingItem";
import { useAppDispatch, useAppSelector } from "store";
import Item from "./item";
import { getSelectedProject } from "store/entities/projects/project.slice";
import { getLocales } from "store/entities/settings/settings.slice";
import { useParams } from "react-router-dom";
import { getMyUserDetails } from "store/entities/authentication/authentication.slice";

import { getProjectPermissions } from "store/entities/projects/project.slice";
import { hasProjectPerm } from "services/authService";
import { IStakeholderPermission } from "types";
import { projectPermissions } from "common/utils/constants/auth.constants";

const List: React.FC = () => {
  const stakeholders = useAppSelector(getStakeholders);
  const project = useAppSelector(getSelectedProject);
  const invitationStatus = useAppSelector(getInvitationStatus);
  const loading = useAppSelector(getLoading);
  const locales = useAppSelector(getLocales);
  const me = useAppSelector(getMyUserDetails);
  const { projectId } = useParams();
  const dispatch = useAppDispatch();

  const userPermissions: IStakeholderPermission[] = useAppSelector(
    getProjectPermissions
  );

  useEffect(() => {
    if (projectId !== undefined) {
      dispatch(fetchStakeholders(+projectId));
    }
  }, [projectId, locales]);

  useEffect(() => {
    dispatch(findMyProfile(me.id));
  }, [me, stakeholders]);

  return (
    <>
      <MuiList sx={{ width: "100%" }}>
        <TransitionGroup>
          {(loading ? Array.from(new Array(3)) : stakeholders).map(
            (stakeholder: any, index: number) => (
              <Collapse key={index}>
                {stakeholder ? (
                  <Item
                    projectOwnerId={project.created_by}
                    stakeholder={stakeholder}
                  />
                ) : (
                  <LoadingItem />
                )}
              </Collapse>
            )
          )}
        </TransitionGroup>
      </MuiList>

      {hasProjectPerm(userPermissions, [
        projectPermissions.stakeholder.maintainer,
      ]) && (
        <Fab
          color="secondary"
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: { xs: 16, md: 72 },
            right: { xs: 16, md: 72 },
            float: "right",
          }}
          onClick={() => dispatch(handleAddStakeholderDialogOpen())}
        >
          <AddIcon />
        </Fab>
      )}

      <AddStakeholderDialog />

      <UpdateStakeholderDialog />
    </>
  );
};

export default List;
