import React, { useEffect, useRef } from "react";
import List from "./list/list";
import { Container } from "@mui/system";
import Toolbar from "./toolbar/toolbar";
import { Fab, useMediaQuery, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store";
import { getElementDialogs, openElementCreateDialog, setPersonalLibraryProjectId, setPersonalLibraryReturnProjectId } from "store/ui/projects/componentCatalogue/componentCatalogue.slice";
import ElementDeleteDialog from "./dialogs/element/deleteDialog";
import ElementCreateDialog from "./dialogs/element/createDialog/createDialog";

import { getProjectPermissions } from "store/entities/projects/project.slice";
import { hasProjectPerm } from "services/authService";
import { projectPermissions } from "common/utils/constants/auth.constants";
import type { IStakeholderPermission } from "types";
import { useParams } from "react-router-dom";
import { getProjects } from "store/entities/projects/projects.slice";
import { getLocales } from "store/entities/settings/settings.slice";
import Actions from "./actions/actions";
import SelectionDialog from "./dialogs/element/selection/selection";

const ComponentCatalogue: React.FC = () => {
  const { t } = useTranslation("projects", {
    keyPrefix: "selected_project.component_catalogue.list",
  });
  const { projectId } = useParams();

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const locales = useAppSelector(getLocales);
  const projects = useAppSelector(getProjects);
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const userPermissions: IStakeholderPermission[] = useAppSelector(
    getProjectPermissions
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const selection = useAppSelector(getElementDialogs).selection;

  const handleCreateElementDialogOpen = () => {
    dispatch(openElementCreateDialog());
  };

  useEffect(() => {
    if (projectId !== undefined) {
      dispatch(setPersonalLibraryReturnProjectId(+projectId));
    }
  }, []);

  useEffect(() => {
    let personalLibraryProjectId: String | null = null;
    for (const project of projects) {
      if (project["name"].includes("PERSONAL_LIBRARY")) {
        personalLibraryProjectId = project["id"];
      };
    };

    personalLibraryProjectId !== null
      && dispatch(setPersonalLibraryProjectId(+personalLibraryProjectId));
  }, [locales, projectId]);

  return (
    <Container maxWidth="xl" fixed sx={{ my: 3 }} ref={containerRef}>
      <Toolbar />
      <List containerRef={containerRef} />
      {hasProjectPerm(userPermissions, [
        projectPermissions.component_catalogue.maintainer,
      ]) && (
          <Actions />
          // <Fab
          //   variant={matches ? "extended" : "circular"}
          //   color="secondary"
          //   aria-label="add"
          //   sx={{
          //     position: "fixed",
          //     bottom: { xs: 16, md: 72 },
          //     right: { xs: 16, md: 72 },
          //     float: "right",
          //   }}
          //   onClick={handleCreateElementDialogOpen}
          // >
          //   <AddIcon sx={{ mr: { xs: 0, md: 1 } }} />
          //   {matches ? t("add_element") : null}
          // </Fab>
        )}
      <ElementDeleteDialog />
      <ElementCreateDialog />
      {selection ? <SelectionDialog /> : null}
    </Container>
  );
};

export default ComponentCatalogue;
