import { Fab, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "store";
import { Add as AddIcon } from "@mui/icons-material";
import { handleCreateIfcModelCreationsDialogOpen } from "store/ui/projects/ifcModelCreations/ifcModelCreations.slice";

export const AddButton: React.FC = () => {
  const { t } = useTranslation("ifc_model_creations", {
    keyPrefix: "list.button",
  });

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDialogOpen = () => {
    dispatch(handleCreateIfcModelCreationsDialogOpen());
  };

  return (
    <Fab
      color="secondary"
      aria-label="add"
      sx={{
        position: "fixed",
        bottom: { xs: 16, md: 72 },
        right: { xs: 16, md: 72 },
        float: "right",
      }}
      variant={matches ? "extended" : "circular"}
      onClick={handleDialogOpen}
    >
      <AddIcon
        sx={{
          mr: { xs: 0, md: 1 },
        }}
      />
      {matches ? t("create_ifc") : null}
    </Fab>
  );
};