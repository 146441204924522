import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from '@mui/material/styles'
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import Title from "./title";
import { useAppDispatch, useAppSelector } from "store";
import type { ICreateIfcModelCreationForm, IIfcCreationLevelChoice } from "types";
import { getIfcModelCreationsDialogs, handleCreateIfcModelCreationsDialogClose } from "store/ui/projects/ifcModelCreations/ifcModelCreations.slice";
import { DialogTitle, useMediaQuery } from "@mui/material";
import { getIfcModelCreationsLevels } from "store/entities/projects/ifcModelCreations/ifcModelCreations.slice";
import { createIfcModelCreation } from "store/entities/projects/ifcModelCreations/ifcModelCreations.actions";
import { useParams } from "react-router-dom";
import BaseFile from "./baseFile/baseFile";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import CreationLevel from "./creationLevel";
import OptimizationPriority from "./optimizationPriority/optimizationPriority";

// const steps = ["create_base_file", "create_creation_level", "create_element_assignment", "create_additional_parameters"];
const steps = ["create_base_file", "create_creation_level", "create_optimization_priority"];

const CreateIfcModelCreationsDialog: React.FC = () => {
  const { t } = useTranslation("ifc_model_creations", {
    keyPrefix: "dialogs.create_dialog",
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const open = useAppSelector(getIfcModelCreationsDialogs).createIfcModelCreation;
  const ifcModelCreationsLevels = useAppSelector(getIfcModelCreationsLevels);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [isStepDisabled, setIsStepDisabled] = useState<boolean>(true);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [initialValues, setInitialValues] = useState<ICreateIfcModelCreationForm>({
    base_model_file: { uuid: "", name: "", creation_level: null },
    creation_level: "",
    creation_optimization_priority_hex: "",
  });
  const [rowSelModelBaseFile, setRowSelModelBaseFile] = useState<GridRowSelectionModel>([]);

  const validationSchema = yup.object({
    base_model_file: yup.object().shape({
      uuid: yup
        .string()
        .required(t("required") as string),
      name: yup
        .string()
        .required(t("required") as string),
      creation_level: yup
        .string()
        .nullable()
        .oneOf(ifcModelCreationsLevels.map((type: IIfcCreationLevelChoice) => { return type.value })),
    }),
    creation_level: yup
      .string()
      .required(t("required") as string)
      .oneOf(ifcModelCreationsLevels.map((type: IIfcCreationLevelChoice) => { return type.value })),
    creation_optimization_priority_hex: yup
      .string()
      .required(t("required") as string),
  });

  const newIfcModelCreationForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: ICreateIfcModelCreationForm) => {
      setActiveStep(0);
      if (projectId !== undefined) {
        dispatch(createIfcModelCreation(
          +projectId,
          values.base_model_file.uuid,
          values.creation_level,
          values.creation_optimization_priority_hex,
        )
        );
      }
      dispatch(handleCreateIfcModelCreationsDialogClose());
      newIfcModelCreationForm.resetForm();
    },
  });

  const isStepOptional = (step: number) => {
    return step === 10;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const isNextDisabled = () => {
    switch (activeStep) {
      case 0:
        if (newIfcModelCreationForm.values.base_model_file.name === "" && newIfcModelCreationForm.values.base_model_file.uuid === "") {
          setIsStepDisabled(true);
          return;
        }
        break;
      case 1:
        if (newIfcModelCreationForm.values.creation_level === "") {
          setIsStepDisabled(true);
          return;
        }
        break;
      case 2:
        if (newIfcModelCreationForm.values.creation_optimization_priority_hex === "") {
          setIsStepDisabled(true);
          return;
        }
        break;
    }
    setIsStepDisabled(false);
    return;
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleCancel = () => {
    setActiveStep(0);
    dispatch(handleCreateIfcModelCreationsDialogClose());
    newIfcModelCreationForm.resetForm();
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <BaseFile rowSelModelBaseFile={rowSelModelBaseFile} setRowSelModelBaseFile={setRowSelModelBaseFile} newIfcModelCreationForm={newIfcModelCreationForm} />;
      case 1:
        return <CreationLevel newIfcModelCreationForm={newIfcModelCreationForm} />;
      case 2:
        return <OptimizationPriority newIfcModelCreationForm={newIfcModelCreationForm} />;
      default:
        return null;
    }
  }

  useEffect(() => {
    isNextDisabled();
  }, [
    activeStep,
    newIfcModelCreationForm.values,
  ]);

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth={"xl"}
      open={open}
      keepMounted
      onClose={() => (
        dispatch(handleCreateIfcModelCreationsDialogClose()),
        newIfcModelCreationForm.resetForm(),
        setActiveStep(0)
      )}
    >
      <DialogTitle>
        {t('dialog_title')}
      </DialogTitle>
      <Title steps={steps} activeStep={activeStep} skipped={skipped} />
      <Box>
        <DialogContent>
          <Box sx={{ pb: 5 }} />
          <form id="new-ifcModelCreation-form" onSubmit={newIfcModelCreationForm.handleSubmit}>
            {getStepContent(activeStep)}
          </form>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            {activeStep !== 0 ?
              (<Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >{t("back")}</Button>
              ) : (<Button onClick={() => handleCancel()}>{t("cancel")}</Button>
              )
            }
            <Box sx={{ flex: "1 1 auto" }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                {t("skip")}
              </Button>
            )} */}
            {activeStep === steps.length - 1 ? (
              <Button disabled={isStepDisabled} type="submit" form="new-ifcModelCreation-form">
                {t("create")}
              </Button>
            ) : null}
            {activeStep !== steps.length - 1 ? (
              <Button onClick={handleNext} disabled={isStepDisabled}>{t("next")}</Button>
            ) : null}
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default CreateIfcModelCreationsDialog;
