import { useEffect, useState } from "react";
import dark from "./darkTheme";
import light from "./lightTheme";
import { useAppDispatch, useAppSelector } from "store";
import {
  getDesign,
  selectDesign,
} from "store/entities/settings/settings.slice";
import Cookies from "js-cookie";
import { useMediaQuery } from "@mui/material";
import minimal from "./minimalTheme";

export const useColorTheme = () => {
  const dispatch = useAppDispatch();
  const design = useAppSelector(getDesign);
  const [colorTheme, setColorTheme] = useState(light);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  useEffect(() => {
    const initialColorMode =
      Cookies.get("colorTheme") || prefersDarkMode ? "dark" : "light";
    dispatch(selectDesign(initialColorMode));
  }, []);

  useEffect(() => {
    switch (design.colorTheme) {
      case "dark":
        setColorTheme(dark);
        break;
      case "light":
        setColorTheme(light);
        break;
      case "minimal":
        setColorTheme(minimal);
        break;
      case "system":
        prefersDarkMode ? setColorTheme(dark) : setColorTheme(light);
        break;
      default:
        setColorTheme(light);
    }
  }, [design]);

  return colorTheme;
};
