import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import type { IStakeholder, IStakeholderPermission } from "types";
import { Checkbox } from "@mui/material";

interface PermissionListProps {
  stakeholderToUpdate: IStakeholder;
  stakeholderForm: any;
}

export default function PermissionList({
  stakeholderToUpdate,
  stakeholderForm,
}: PermissionListProps) {
  const { t } = useTranslation("stakeholders", {
    keyPrefix: "edit_dialog",
  });

  const toggleAdminPermissions = () => {
    const defaultProjectPermission = stakeholderToUpdate.permissions.find(
      (permission: IStakeholderPermission) =>
        permission.name === "Project read only"
    );
    const defaultStakeholderPermission = stakeholderToUpdate.permissions.find(
      (permission: IStakeholderPermission) =>
        permission.name === "Project stakeholder read only"
    );

    const updatedPermissions = stakeholderForm.values.permissions.map(
      (permission: { id: number; is_member: boolean }) => {
        const matchingPermission = stakeholderToUpdate.permissions.find(
          (p: IStakeholderPermission) => p.id === permission.id
        );
        const isDefaultPermission =
          matchingPermission === defaultProjectPermission ||
          matchingPermission === defaultStakeholderPermission;

        if (isDefaultPermission) {
          return { ...permission };
        } else {
          return {
            ...permission,
            is_member: !stakeholderForm.values.permissions.every(
              (p: { id: number; is_member: boolean }) => p.is_member
            ),
          };
        }
      }
    );

    stakeholderForm.setFieldValue("permissions", updatedPermissions);
  };

  const togglePermission = (id: number, checked: boolean) => {
    const index = stakeholderForm.values.permissions.findIndex(
      (item: { id: number; is_member: boolean }) => item.id === id
    );
    stakeholderForm.setFieldValue(`permissions.${index}.is_member`, checked);
  };

  return (
    <List>
      <ListItem
        disableGutters
        secondaryAction={
          <Checkbox
            edge="start"
            checked={stakeholderForm.values.permissions.every(
              (permission: { id: number; is_member: boolean }) =>
                permission.is_member == true
            )}
            onChange={toggleAdminPermissions}
          />
        }
      >
        <ListItemText primary={t("administrator")} />
      </ListItem>
      {stakeholderToUpdate.permissions.map(
        (permission: IStakeholderPermission) => (
          <ListItem
            disableGutters
            key={permission.id}
            secondaryAction={
              <Checkbox
                checked={stakeholderForm.values.permissions.some(
                  (item: { id: number; is_member: boolean }) =>
                    item.id === permission.id && item.is_member === true
                )}
                onChange={(
                  event: ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => togglePermission(permission.id, checked)}
                disabled={
                  permission.name === "Project read only" ||
                  permission.name === "Project stakeholder read only"
                }
              />
            }
          >
            <ListItemText primary={permission.name} />
          </ListItem>
        )
      )}
    </List>
  );
}
