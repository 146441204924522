import { Box, CardActionArea, CardActions, DialogContent } from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import LogoutIcon from "@mui/icons-material/Logout";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {
  bookmarkProject,
  getProjectStatusList,
  refuseProjectInvitation,
} from "store/entities/projects/projects.slice";
import { getMyUserDetails } from "store/entities/authentication/authentication.slice";
import { useAppSelector } from "store";
import { IProject, IProjectStatus } from "types";
import Illustration from "./illustration";

interface IDefaultCardProps {
  project: IProject;
}

const DefaultCard: React.FC<IDefaultCardProps> = ({ project }) => {
  const { t } = useTranslation("projects", {
    keyPrefix: "list.default_card",
  });

  const dispatch = useDispatch();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.up("sm"));

  const userId = useSelector(getMyUserDetails).id;
  const projectStatus: IProjectStatus[] = useAppSelector(getProjectStatusList);

  const handleBookmarkChange = () => {
    project.bookmark === true
      ? dispatch(bookmarkProject(project.id, false))
      : dispatch(bookmarkProject(project.id, true));
  };

  const handleLeaveProject = () => {
    dispatch(refuseProjectInvitation(project.id, project.me.id));
  };

  return (
    <>
      {smallSize ? (
        <Card
          sx={{
            width: "100%",
            height: "350px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
          variant="elevation"
        >
          <CardActionArea
            sx={{
              height: "stretch",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              color: "inherit",
            }}
            component={RouterLink}
            to={"/projects/" + project.id + "/dashboard"}
          >
            <Box
              sx={{
                p: 2,
                overflow: "hidden",
                backgroundColor: "inherit",
              }}
            >
              <Illustration projectStatus={project.status} />
            </Box>
            <CardContent
              sx={{
                width: "100%",
              }}
            >
              <Typography gutterBottom variant="subtitle2" fontWeight="bold">
                {project.name}
              </Typography>
              <Typography gutterBottom variant="subtitle2">
                {
                  projectStatus.find(
                    (status) => status.value === project.status
                  )?.label
                }
              </Typography>
            </CardContent>
          </CardActionArea>
          <Divider variant="middle" />
          <CardActions disableSpacing>
            <Tooltip
              title={
                project.bookmark
                  ? t<string>("remove_bookmark")
                  : t<string>("add_bookmark")
              }
            >
              <IconButton onClick={handleBookmarkChange}>
                {project.bookmark ? <BookmarkIcon /> : <BookmarkBorderIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title={t<string>("leave_project")}>
              <IconButton
                onClick={handleLeaveProject}
                disabled={project.created_by === userId ? true : false}
              >
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </CardActions>
        </Card>
      ) : (
        <Card sx={{ height: "150px", display: "flex" }} variant="elevation">
          <Box
            sx={{
              width: "38.2%",
              height: "auto",
              p: 1,
              overflow: "hidden",
              backgroundColor: "initial",
            }}
          >
            <Illustration projectStatus={project.status} />
          </Box>
          <Box
            sx={{
              width: "61.8%",
              color: "inherit",
              textDecoration: "none",
              display: "flex",
              flexDirection: "column",
            }}
            component={RouterLink}
            to={"/projects/" + project.id + "/dashboard"}
          >
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography
                noWrap
                variant="subtitle2"
                fontWeight="bold"
                gutterBottom
              >
                {project.name}
              </Typography>
              <Typography
                noWrap
                variant="caption"
                color="text.secondary"
                gutterBottom
              >
                {
                  projectStatus.find(
                    (status) => status.value === project.status
                  )?.label
                }
              </Typography>
            </CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                pr: 1,
                pb: 1,
              }}
            >
              <Tooltip
                title={
                  project.bookmark
                    ? t<string>("remove_bookmark")
                    : t<string>("add_bookmark")
                }
              >
                <IconButton onClick={handleBookmarkChange}>
                  {project.bookmark ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip title={t<string>("leave_project")}>
                <IconButton
                  onClick={handleLeaveProject}
                  disabled={project.created_by === userId ? true : false}
                >
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Card>
      )}
    </>
  );
};

export default DefaultCard;
