import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import React from 'react'

import Map from './map'
import ProjectCard from './cards/project/projectCard'

const Dashboard: React.FC = () => {
  return (
    <Container disableGutters maxWidth='lg'>
      <Map />
      <Grid container spacing={2} sx={{ px: { xs: 1, md: 2 } }}>
        <Grid item xs={12}>
          <ProjectCard />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </Container>
  )
}
export default Dashboard
