import { ICreationOptimizationPriority, IIfcModelCreationsOptimizationPriorityChoice } from "types";

type TopicPositionMapping = { [key: string]: number };

export const createTopicPositionMapping = (
    ifcModelCreationsOptimizationPriorityChoices: IIfcModelCreationsOptimizationPriorityChoice[]
): TopicPositionMapping => {
    const mapping: TopicPositionMapping = {};
    ifcModelCreationsOptimizationPriorityChoices.forEach((topic, index) => {
        mapping[topic.value] = index;
    });
    return mapping;
}

export const encodeOptimizationPriorityToHex = (
    topics: ICreationOptimizationPriority[],
    topicPositionMapping: TopicPositionMapping
): string => {
    let result = 0;
    for (const topic of topics) {
        const position = topicPositionMapping[topic.topic];
        result |= topic.priority << (position * 4);
    }
    return "0x" + result.toString(16);
}
