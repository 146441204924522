import { createSlice } from "@reduxjs/toolkit";
import type { IUploadedFile } from "types";

type SliceState = {
  list: IUploadedFile[];
  loading: {
    isLoading: boolean,
    type: "" | "fetch" | "upload" | "download" | "delete";
    content: string,
  };
  lastFetch: null | number;
};

const initialState: SliceState = {
  list: [],
  loading: {
    isLoading: false,
    type: "",
    content: "",
  },
  lastFetch: null,
};

const uploadedFilesSlice = createSlice({
  name: "uploadedFiles",
  initialState,
  reducers: {
    uploadedFilesRequested: (uploadedFiles) => {
      uploadedFiles.loading.isLoading = true;
      uploadedFiles.loading.type = "fetch";
    },
    uploadedFilesReceived: (uploadedFiles, action) => {
      uploadedFiles.list = action.payload;
      uploadedFiles.lastFetch = Date.now();
      uploadedFiles.loading.isLoading = false;
      uploadedFiles.loading.type = "";
    },
    uploadedFilesRequestFailed: (uploadedFiles) => {
      uploadedFiles.loading.isLoading = false;
      uploadedFiles.loading.type = "";
    },
    uploadedFilesAddRequested: (uploadedFiles, action) => {
      uploadedFiles.loading.isLoading = true;
      uploadedFiles.loading.type = "upload";
      uploadedFiles.loading.content = action.payload;
    },
    uploadedFileAddFulfilled: (uploadedFiles, action) => {
      uploadedFiles.list.unshift(action.payload);
      uploadedFiles.loading.isLoading = false;
      uploadedFiles.loading.content = "";
      uploadedFiles.loading.type = "";
    },
    uploadedFilesAddFailed: (uploadedFiles) => {
      uploadedFiles.loading.isLoading = false;
      uploadedFiles.loading.content = "";
      uploadedFiles.loading.type = "";
    },
    uploadedFilesDeleteRequested: (uploadedFiles, action) => {
      uploadedFiles.loading.isLoading = true;
      uploadedFiles.loading.type = "delete";
      uploadedFiles.loading.content = action.payload;
    },
    uploadedFileDeleteFulfilled: (uploadedFiles, action) => {
      const oldFile = action.payload;
      const index = uploadedFiles.list.findIndex(
        (uploadedFile) => uploadedFile.uuid === oldFile.uuid
      );
      uploadedFiles.list.splice(index, 1);
      uploadedFiles.loading.isLoading = false;
      uploadedFiles.loading.content = "";
      uploadedFiles.loading.type = "";
    },
    uploadedFilesDeleteFailed: (uploadedFiles) => {
      uploadedFiles.loading.isLoading = false;
      uploadedFiles.loading.content = "";
      uploadedFiles.loading.type = "";
    },
    uploadedFileDownloadRequested: (uploadedFiles) => {
      uploadedFiles.loading.isLoading = true;
      uploadedFiles.loading.content = "";
      uploadedFiles.loading.type = "download";
    },
    uploadedFileDownloadReceived: (uploadedFiles, action) => {
      uploadedFiles.loading.isLoading = false;
      uploadedFiles.loading.content = "";
      uploadedFiles.loading.type = "";
    },
    uploadedFileDownloadRequestFailed: (uploadedFiles) => {
      uploadedFiles.loading.isLoading = false;
      uploadedFiles.loading.content = "";
      uploadedFiles.loading.type = "";
    },
  },
});

export const {
  uploadedFilesRequested,
  uploadedFilesReceived,
  uploadedFilesRequestFailed,
  uploadedFilesAddRequested,
  uploadedFileAddFulfilled,
  uploadedFilesAddFailed,
  uploadedFilesDeleteRequested,
  uploadedFileDeleteFulfilled,
  uploadedFilesDeleteFailed,
  uploadedFileDownloadRequested,
  uploadedFileDownloadReceived,
  uploadedFileDownloadRequestFailed,
} = uploadedFilesSlice.actions;
export default uploadedFilesSlice.reducer;

export const getUploadedFiles = (state: any) =>
  state.entities.projects.selectedProject.uploadedFiles.list;

export const getLoading = (state: any) =>
  state.entities.projects.selectedProject.uploadedFiles.loading;

export const getDownloadedFileClientUrl = (state: any) =>
  state.entities.projects.selectedProject.uploadedFiles.downloadedFileClientUrl;