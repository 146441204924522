import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  TextField,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import moment from "moment";
import {
  LocalLibraryOutlined,
} from "@mui/icons-material";

import {
  filterList,
  getFilteredList,
  getPersonalLibraryProjectId,
  openPersonalLibrary,
} from "store/ui/projects/componentCatalogue/componentCatalogue.slice";
import {
  getCategoryChoices,
  getConstructionElements,
} from "store/entities/projects/componentCatalogue/componentCatalogue.slice";
import { generateCSV } from "./generateCSV/generateCSV";
import { generateJSON } from "./generateJSON/generateJSON";
import { generatePDF } from "./generatePDF/generatePDF";
import { generateIFC } from "./generateIFC/generateIFC";
import { getProjectPermissions, getSelectedProject } from "store/entities/projects/project.slice";
import { useAppDispatch, useAppSelector } from "store";
import { useNavigate, useParams } from "react-router-dom";
import { hasProjectPerm } from "services/authService";
import { IStakeholderPermission } from "types";
import { projectPermissions } from "common/utils/constants/auth.constants";

const options = [
  "Download as PDF",
  "Download as CSV",
  "Download as JSON",
  "Download as RHINO2",
  "Download as IFC",
];

interface ToolbarProps { }

export default function Toolbar(props: ToolbarProps) {
  const { } = props;
  const { t } = useTranslation("component_catalogue");
  const { projectId } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const project = useAppSelector(getSelectedProject);
  const elements = useAppSelector(getConstructionElements);
  const categoryChoices = useAppSelector(getCategoryChoices);
  const filteredElements = useAppSelector(getFilteredList);
  const personalLibraryProjectId = useAppSelector(getPersonalLibraryProjectId);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [filter, setFilter] = useState("");

  const userPermissions: IStakeholderPermission[] = useAppSelector(
    getProjectPermissions
  );

  const handlePersonalLibrary = () => {
    dispatch(openPersonalLibrary());
    return navigate("/projects/" + personalLibraryProjectId + "/component-catalogue/personal-library");
  };

  const handleDownloadPDF = async () => {
    await new Promise((resolve) => generatePDF(filteredElements, project, t));
  };

  const handleDownloadCSV = async () => {
    await new Promise((resolve) =>
      generateCSV(`${moment().format("YYYYMMDD")}_bauteilkatalog.csv`, elements)
    );
  };

  const handleDownloadJSON = async () => {
    await new Promise((resolve) =>
      generateJSON(
        `${moment().format("YYYYMMDD")}_bauteilkatalog.json`,
        elements
      )
    );
  };

  const handleDownloadIFC = async () => {
    /* await new Promise((resolve) => generateIFC(elements)) */
    /* generateIFC() */
  };

  const handleClick = () => {
    switch (selectedIndex) {
      case 0:
        handleDownloadPDF();
        break;
      case 1:
        handleDownloadCSV();
        break;
      case 2:
        handleDownloadJSON();
        break;
      case 4:
        handleDownloadIFC();
        break;
      default:
        handleDownloadPDF();
        break;
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleTextFilterChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFilter(event.target.value);

  useEffect(() => {
    let newElements: any[];

    if (filter === "") {
      newElements = elements;
    } else {
      const regExp = new RegExp(filter, "gi");
      const check: any = (obj: string) => {
        if (obj !== null && typeof obj === "object") {
          return Object.values(obj).some(check);
        }
        if (Array.isArray(obj)) {
          return obj.some(check);
        }
        return (
          (typeof obj === "string" || typeof obj === "number") &&
          regExp.test(obj)
        );
      };
      newElements = elements.filter(check);
    }
    dispatch(
      filterList({ list: newElements, categoryChoices: categoryChoices })
    );
  }, [filter, elements]);

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={2}
      sx={{ my: 4 }}
    >
      {hasProjectPerm(userPermissions, [
        projectPermissions.component_catalogue.maintainer,
      ]) && personalLibraryProjectId && (<Button
        variant="contained"
        startIcon={<LocalLibraryOutlined />}
        onClick={handlePersonalLibrary}
      >
        {t("list.header.personal_library")}
      </Button>)}
      <React.Fragment>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button onClick={handleClick}>{options[selectedIndex]}</Button>
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 1049 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
      <TextField
        sx={{ width: 200 }}
        size="small"
        id="outlined-search-field"
        label={t("list.header.search_components")}
        value={filter}
        onChange={handleTextFilterChange}
      />
    </Stack>
  );
}
