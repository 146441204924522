import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import i18next from 'i18next'

type SliceState = {
  locales: { language: string; code: string; direction: string }
  design: { colorTheme: string }
}

const initialState: SliceState = {
  locales: { language: '', code: '', direction: '' },
  design: { colorTheme: '' },
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    languageSelected: (settings, action) => {
      settings.locales = action.payload
      Cookies.set('i18next', action.payload.code)
      i18next.changeLanguage(action.payload.code)
      document.body.dir = action.payload.direction || 'ltr'
      document.documentElement.lang = action.payload.code || 'en-US'
    },
    designSelected: (settings, action) => {
      settings.design.colorTheme = action.payload
      Cookies.set('colorTheme', action.payload)
    },
  },
})

export const selectLanguage = (payload: {
  language: string
  code: string
  direction: string
}) => ({
  type: languageSelected.type,
  payload,
})

export const selectDesign = (payload: string) => ({
  type: designSelected.type,
  payload,
})

const { languageSelected, designSelected } = settingsSlice.actions

export default settingsSlice.reducer

export const getDesign = (state: any) => state.entities.settings.design
export const getLocales = (state: any) => state.entities.settings.locales
