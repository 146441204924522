import React, { useRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface ScrollProps {
  containerWidth: number;
  children: any;
}

export default function Scroll(props: ScrollProps) {
  const { children, containerWidth } = props;

  const [overflow, setOverflow] = useState(false);
  const [slideNumber, setSlideNumber] = useState(0);
  const [x, setX] = useState(0);
  const listRef = useRef<HTMLElement>();

  useEffect(() => {
    if (containerWidth / (children.length * (300 + 16) + x) >= 1) {
      setOverflow(false);
    } else {
      setOverflow(true);
    }
  }, [children, x]);

  useEffect(() => {
    let listElement = listRef.current;
    if (listElement) {
      listElement.style.transform = `translateX(${x}px)`;
    }
  }, [x]);

  const handleClick = (direction: string) => {
    let listElement = listRef.current;
    if (direction === "left" && slideNumber > 0 && listElement) {
      setX(x + 316);
      setSlideNumber(slideNumber - 1);
    }
    if (direction === "right" && slideNumber < 4 && listElement) {
      setX(x - 316);
      setSlideNumber(slideNumber + 1);
    }
  };

  return (
    <Box
      sx={{
        py: 1,
        position: "relative",
        overflow: "hidden",
        alignItems: "center",
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          overflow: "hidden",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
        }}
      >
        <Button
          sx={{
            display: slideNumber === 0 ? "none" : "inline-block",
            position: "absolute",
            left: 0,
            top: 0,
            width: "50px",
            height: "100%",
            zIndex: 99,
            borderRadius: 0,
            transition: "all 0.08s ease-in-out",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(22, 22, 22, 0.5)",
            },
          }}
          onClick={() => handleClick("left")}
          size="large"
        >
          <ArrowBackIosNewIcon />
        </Button>
        <Button
          sx={{
            display: overflow ? "inline-block" : "none",
            position: "absolute",
            right: 0,
            top: 0,
            width: "50px",
            height: "100%",
            zIndex: 1049,
            borderRadius: 0,
            transition: "all 0.08s ease-in-out",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(22, 22, 22, 0.5)",
            },
          }}
          size="large"
          onClick={() => handleClick("right")}
        >
          <ArrowForwardIosIcon />
        </Button>
      </Box>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          display: "flex",
          width: "max-content",
          ml: "64px",
          transform: "translateX(0px)",
          transition: "all 1s ease",
          scrollBehavior: "smooth",
        }}
        ref={listRef as any}
      >
        {children}
      </Stack>
    </Box>
  );
}
