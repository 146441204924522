import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store";
import { Grid } from "@mui/material";
import { getSelectedProject } from "store/entities/projects/project.slice";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";

const ConstructionSite: React.FC = () => {
  const { t } = useTranslation("dashboard", {
    keyPrefix: "project",
  });

  const project = useAppSelector(getSelectedProject);

  return (
    <Grid
      container
      columnSpacing={{ xs: 1, sm: 4, md: 8 }}
      rowSpacing={{ xs: 1, sm: 2, md: 2 }}
    >
      <Grid item xs={12} textAlign="center">
        <PlaceOutlinedIcon color="primary" />
        <Typography fontWeight="bold">{t("construction_site")}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" color="text.primary">
          {`${t("street")}: `}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {project.construction_site.street}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" color="text.primary">
          {`${t("city")}: `}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`${project.construction_site.postal_code} ${project.construction_site.city}`}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" color="text.primary">
          {`${t("country")}:`}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {project.construction_site.country}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ConstructionSite;
