export const IndexedDBConfig = {
    name: "holzbau.cloud.db",
    version: 1,
    objectStoresMeta: [
        {
            store: "files",
            storeConfig: { keyPath: "id", autoIncrement: true },
            storeSchema: [
                { name: "name", keypath: "name", options: { unique: false } },
                { name: "uuid", keypath: "uuid", options: { unique: true } },
                { name: "file", keypath: "file", options: { unique: false } },
                { name: "expiryDate", keypath: "expiryDate", options: { unique: false } },
            ],
        },
    ],
    expirationDeltaDays: 30,
};

export interface IIndexedDBFiles {
    id: number;
    name: string;
    uuid: string,
    file: File,
    expiryDate: number;
}