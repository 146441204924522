import * as React from 'react'

interface IProps {
  primaryColor: string
}

export const BuildYourHome = ({ primaryColor }: IProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      //   width='1157.5736'
      //   height='710'
      width='100%'
      height='100%'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1157.5736 710'
    >
      <title>build_your_ home</title>
      <path
        d='M938.08806,130.81271h-.00014c-52.11927,0-94.37029,46.88935-94.37029,104.73027v73.48753H866.319l13.08492-27.22772-3.27126,27.22772h145.27307l11.89539-24.75247-2.97381,24.75247h16.35618V251.32964C1046.68351,184.77005,998.06366,130.81271,938.08806,130.81271Z'
        transform='translate(-21.2132 -95)'
        fill='#2f2e41'
      />
      <path
        d='M892.53545,262.89374s29.455,68.72844-9.81835,82.47412l39.27339,129.60219,66.76476-3.92734,17.673-43.20073-3.92734-64.80109-5.891-43.20073s-41.23706,9.81835-37.30972-62.83743Z'
        transform='translate(-21.2132 -95)'
        fill='#ffb8b8'
      />
      <path
        d='M876.82609,343.40419s-64.80109-5.891-58.91008,41.23706,11.782,56.94642,11.782,56.94642l5.891,70.6921-35.346,21.60037H766.8606s-102.11082,23.564-51.05541,49.09173,68.72844-19.63669,68.72844-19.63669,94.25613,0,100.14714-15.70936S876.82609,343.40419,876.82609,343.40419Z'
        transform='translate(-21.2132 -95)'
        fill='#ffb8b8'
      />
      <path
        d='M1002.50094,323.7675s60.87376-21.60037,64.8011,25.5277,3.92733,51.05541,3.92733,51.05541l11.782,66.76476s23.564,58.91009,23.564,76.58311,17.673,72.65578,17.673,72.65578,23.564,76.58311-1.96367,74.61944-29.455-82.47412-29.455-82.47412l-53.01908-106.03816-15.70935-64.80109Z'
        transform='translate(-21.2132 -95)'
        fill='#ffb8b8'
      />
      <path
        d='M886.64444,335.54952H870.93508l-1.96367,82.47411s-25.5277,35.34606,5.891,66.76477l7.85468,51.05541,143.34788-5.891,9.81834-53.01908s19.6367-43.20073-7.85468-82.47412l-25.5277-80.51045H988.75525s31.83634,117.38447-35.346,119.78384C898.42646,435.69666,886.64444,335.54952,886.64444,335.54952Z'
        transform='translate(-21.2132 -95)'
        fill={primaryColor}
      />
      <path
        d='M956.86314,803.60306c.25045-24.97329.46963-39.96979.46963-39.96979s-1.48189,14.9965-3.997,39.96979H799.11289c40.75249-128.40434,78.12373-233.933,79.637-238.17a.65169.65169,0,0,1,.04173-.13568l4.7588-15.85225v-.01039l2.01414-6.72077v-.03134l2.129-7.04429,2.88027-9.61156,127.63209-3.92387,3.56909,8.02527,12.1475,27.31094a10.28342,10.28342,0,0,1,2.96378,1.39839c21.8425,14.30775,49.11172,119.12674,73.97023,244.76552Z'
        transform='translate(-21.2132 -95)'
        fill='#2f2e41'
      />
      <path
        d='M1031.956,506.38876l-155.12989,5.891s-7.08011,53.01908,0,53.01908c1.96367,0,162.98457,13.74569,161.0209-9.81835S1031.956,506.38876,1031.956,506.38876Z'
        transform='translate(-21.2132 -95)'
        fill='#2f2e41'
      />
      <circle cx='901.67217' cy='135.20961' r='55.17463' fill='#ffb8b8' />
      <path
        d='M964.91582,159.66243c-9.47523-12.20041-23.45045-19.92814-39.05123-19.92814h-1.85277c-35.72265,0-64.68143,32.32488-64.68143,72.19971v.00015h11.96972l1.93308-14.71392,2.83428,14.71392h70.99171L953.007,199.4859l-1.487,12.44825h13.9661q9.7756,48.54818-28.09188,97.09636h23.79092L973.0805,284.134l-2.97382,24.8965H1015.458l8.92157-57.26211C1024.37956,208.88439,999.46269,172.5196,964.91582,159.66243Z'
        transform='translate(-21.2132 -95)'
        fill='#2f2e41'
      />
      <circle cx='956.74187' cy='35.34605' r='35.34605' fill='#2f2e41' />
      <circle cx='647.90456' cy='317.274' r='48.18335' fill={primaryColor} />
      <path
        d='M323.746,374.59681a8.46527,8.46527,0,0,1-3.78865,6.95687l-.31681.198H176.90559l.57424-1.7491c.099-.31024,10.54752-30.63275,56.80354-24.77815,4.36289-1.49167,46.93585-15.39223,72.42021.22444C318.01017,362.37936,323.746,368.82141,323.746,374.59681Z'
        transform='translate(-21.2132 -95)'
        fill='#f2f2f2'
      />
      <path
        d='M551.67645,621.28332c14.36643.30818,25.79475,10.71922,25.79475,10.71922s-11.86426,9.9114-26.23069,9.60322-25.79475-10.71923-25.79475-10.71923S537.31,620.97514,551.67645,621.28332Z'
        transform='translate(-21.2132 -95)'
        fill={primaryColor}
      />
      <path
        d='M479.44938,572.24324c12.97945,6.16629,27.86273,1.98484,27.86273,1.98484s-6.16057-14.179-19.14-20.34529-27.86272-1.98484-27.86272-1.98484S466.46994,566.07694,479.44938,572.24324Z'
        transform='translate(-21.2132 -95)'
        fill={primaryColor}
      />
      <path
        d='M480.76878,566.11552c14.3101-1.30779,26.83571,7.75342,26.83571,7.75342s-10.67564,11.18155-24.98575,12.48933-26.83571-7.75342-26.83571-7.75342S466.45868,567.42331,480.76878,566.11552Z'
        transform='translate(-21.2132 -95)'
        fill='#3f3d56'
      />
      <path
        d='M533.22513,568.59307c-1.37162,14.30413,7.63362,26.87,7.63362,26.87s11.22907-10.62565,12.60068-24.92978-7.63361-26.87-7.63361-26.87S534.59675,554.289,533.22513,568.59307Z'
        transform='translate(-21.2132 -95)'
        fill='#3f3d56'
      />
      <path
        d='M600.61862,656.31983c-7.50572,12.25371-4.92339,27.496-4.92339,27.496s14.75151-4.62488,22.25723-16.8786,4.92339-27.496,4.92339-27.496S608.12434,644.06612,600.61862,656.31983Z'
        transform='translate(-21.2132 -95)'
        fill='#3f3d56'
      />
      <path
        d='M578.84357,789.54916l1.09384-.73933a34.26881,34.26881,0,0,1-6.04806-19.00436c.03062-9.93872,4.91232-19.13134,9.63351-28.02133.87631-1.65012,1.74971-3.29507,2.58991-4.93874a107.80857,107.80857,0,0,0,8.38529-21.05057c7.323-27.21368.11312-59.32065-19.286-85.88653-15.38925-21.07441-38.33-39.50284-70.13245-56.33853l-.6175,1.16669c31.62129,16.73964,54.41511,35.04141,69.68383,55.95049,19.16582,26.24584,26.29741,57.93385,19.07719,84.765a106.52771,106.52771,0,0,1-8.28538,20.79273c-.83763,1.63755-1.7078,3.27606-2.58088,4.92037-4.79629,9.0318-9.756,18.37107-9.78757,28.63658A35.60093,35.60093,0,0,0,578.84357,789.54916Z'
        transform='translate(-21.2132 -95)'
        fill='#3f3d56'
      />
      <circle cx='421.1788' cy='467.76347' r='12.54087' fill='#3f3d56' />
      <polygon
        points='737.032 709.366 46.624 709.366 58.274 694.707 229.206 479.657 237.074 469.756 307.699 380.901 391.828 275.056 500.096 411.27 737.032 709.366'
        fill='#3f3d56'
      />
      <path
        d='M526.56949,508.747a101.31472,101.31472,0,0,1-13.888,6.86393,105.92381,105.92381,0,0,1-76.297.75185c-29.14041-11.06168-53.35911-35.04371-84.212-39.45722-9.43966-1.35051-18.97679-.75881-28.52088-.188l88.73-111.633Z'
        transform='translate(-21.2132 -95)'
        fill='#f2f2f2'
      />
      <path
        d='M559.38458,365.38711c2.35028,7.27111.79043,15.18023-1.12525,22.57773s-4.19393,14.96467-3.2153,22.54326c2.1147,16.37652,18.15071,26.87981,33.03531,34.029s31.63092,14.56838,38.1906,29.722c3.62837,8.382,3.457,17.83741,4.86872,26.86124a76.7081,76.7081,0,0,0,28.37441,48.15471c-12.63826-.29955-23.95938-7.98428-32.906-16.91591s-16.36834-19.38192-25.99506-27.57592c-12.97458-11.04363-29.17979-17.366-44.41122-25.00178s-30.40784-17.4514-38.29193-32.55578c-8.05972-15.44086-7.168-34.32168-1.525-50.8,4.19088-12.238,11.89793-31.22862,21.82117-39.96493C546.01622,349.58386,556.42965,356.24534,559.38458,365.38711Z'
        transform='translate(-21.2132 -95)'
        fill='#f2f2f2'
      />
      <rect
        x='477.04909'
        y='612.96291'
        width='454.7716'
        height='96.3667'
        fill='#f2f2f2'
      />
      <polygon
        points='462.528 623.524 512.032 519.896 907.399 519.896 944.362 623.524 462.528 623.524'
        fill='#3f3d56'
      />
      <rect
        x='585.95665'
        y='526.4969'
        width='183.49275'
        height='60.72422'
        fill='#f2f2f2'
      />
      <polygon
        points='597.837 476.993 568.795 537.718 786.611 537.718 769.449 476.993 597.837 476.993'
        fill='#3f3d56'
      />
      <rect
        x='602.12778'
        y='632.10424'
        width='144.88007'
        height='77.22537'
        fill='#ccc'
      />
      <rect
        x='494.87032'
        y='634.74442'
        width='77.22537'
        height='74.58518'
        fill='#ccc'
      />
      <rect
        x='772.08959'
        y='639.36474'
        width='39.93277'
        height='69.96486'
        fill='#3f3d56'
      />
      <rect
        x='855.25536'
        y='643.32502'
        width='19.47135'
        height='18.15126'
        fill={primaryColor}
      />
      <rect
        x='881.32718'
        y='643.32502'
        width='19.47135'
        height='18.15126'
        fill={primaryColor}
      />
      <rect
        x='855.25536'
        y='668.07674'
        width='19.47135'
        height='18.81131'
        fill={primaryColor}
      />
      <rect
        x='881.32718'
        y='668.07674'
        width='19.47135'
        height='18.81131'
        fill={primaryColor}
      />
      <rect
        x='602.78782'
        y='543.11284'
        width='15.23845'
        height='14.20533'
        fill='#3f3d56'
      />
      <rect
        x='623.19185'
        y='543.11284'
        width='15.23845'
        height='14.20533'
        fill='#3f3d56'
      />
      <rect
        x='602.78782'
        y='562.48375'
        width='15.23845'
        height='14.72189'
        fill='#3f3d56'
      />
      <rect
        x='623.19185'
        y='562.48375'
        width='15.23845'
        height='14.72189'
        fill='#3f3d56'
      />
      <rect
        x='716.97576'
        y='543.11284'
        width='15.23845'
        height='14.20533'
        fill='#3f3d56'
      />
      <rect
        x='737.37978'
        y='543.11284'
        width='15.23845'
        height='14.20533'
        fill='#3f3d56'
      />
      <rect
        x='716.97576'
        y='562.48375'
        width='15.23845'
        height='14.72189'
        fill='#3f3d56'
      />
      <rect
        x='737.37978'
        y='562.48375'
        width='15.23845'
        height='14.72189'
        fill='#3f3d56'
      />
      <rect
        x='660.21181'
        y='543.11284'
        width='15.23845'
        height='14.20533'
        fill='#3f3d56'
      />
      <rect
        x='680.61584'
        y='543.11284'
        width='15.23845'
        height='14.20533'
        fill='#3f3d56'
      />
      <rect
        x='660.21181'
        y='562.48375'
        width='15.23845'
        height='14.72189'
        fill='#3f3d56'
      />
      <rect
        x='680.61584'
        y='562.48375'
        width='15.23845'
        height='14.72189'
        fill='#3f3d56'
      />
      <rect
        x='781.00021'
        y='646.45306'
        width='9.59458'
        height='8.9441'
        fill='#f2f2f2'
      />
      <rect
        x='793.84718'
        y='646.45306'
        width='9.59458'
        height='8.9441'
        fill='#f2f2f2'
      />
      <rect
        x='781.00021'
        y='658.64956'
        width='9.59458'
        height='9.26934'
        fill='#f2f2f2'
      />
      <rect
        x='793.84718'
        y='658.64956'
        width='9.59458'
        height='9.26934'
        fill='#f2f2f2'
      />
      <circle cx='806.74199' cy='679.29752' r='2.64018' fill='#f2f2f2' />
      <polygon
        points='647.575 461.163 625.793 461.163 625.793 466.397 630.15 466.397 630.15 481.624 643.219 481.624 643.219 466.397 647.575 466.397 647.575 461.163'
        fill='#f2f2f2'
      />
      <path
        d='M624.175,781.88059c-3.75241,13.87115-16.60525,22.4619-16.60525,22.4619s-6.769-13.89882-3.01658-27.77,16.60525-22.4619,16.60525-22.4619S627.92736,768.00945,624.175,781.88059Z'
        transform='translate(-21.2132 -95)'
        fill={primaryColor}
      />
      <path
        d='M590.03853,781.88059c3.75241,13.87115,16.60524,22.4619,16.60524,22.4619s6.769-13.89882,3.01658-27.77-16.60524-22.4619-16.60524-22.4619S586.28612,768.00945,590.03853,781.88059Z'
        transform='translate(-21.2132 -95)'
        fill={primaryColor}
      />
      <path
        d='M595.55243,778.89953c10.29665,10.02338,11.55431,25.43165,11.55431,25.43165s-15.43651-.84284-25.73317-10.86622-11.55432-25.43165-11.55432-25.43165S585.25577,768.87615,595.55243,778.89953Z'
        transform='translate(-21.2132 -95)'
        fill='#3f3d56'
      />
      <path
        d='M618.66106,778.89953c-10.29665,10.02338-11.55432,25.43165-11.55432,25.43165s15.43652-.84284,25.73318-10.86622,11.55431-25.43165,11.55431-25.43165S628.95772,768.87615,618.66106,778.89953Z'
        transform='translate(-21.2132 -95)'
        fill='#3f3d56'
      />
      <rect y='708.67991' width='1157.5736' height='1.32009' fill='#3f3d56' />
      <path
        d='M769.44473,428.22073a8.46527,8.46527,0,0,1-3.78866,6.95687l-.31681.198H622.60434l.57424-1.7491c.099-.31024,10.54752-30.63275,56.80355-24.77815,4.36289-1.49167,46.93585-15.39223,72.42021.22443C763.70893,416.00328,769.44473,422.44533,769.44473,428.22073Z'
        transform='translate(-21.2132 -95)'
        fill='#f2f2f2'
      />
      <path
        d='M330.07051,595.96634a8.46529,8.46529,0,0,1-3.78866,6.95687l-.31681.198H183.23012l.57424-1.74909c.099-.31025,10.54752-30.63276,56.80354-24.77815,4.3629-1.49167,46.93586-15.39224,72.42022.22443C324.33471,583.74888,330.07051,590.19094,330.07051,595.96634Z'
        transform='translate(-21.2132 -95)'
        fill='#f2f2f2'
      />
    </svg>
  )
}
