import React from 'react';
import { usePatternContext } from './patternProvider';
import { IConstructionMaterial } from 'types';

interface PatternProps {
    id: string;
    x: number;
    y: number;
    frameWidth: number;
    frameHeight: number;
    material: IConstructionMaterial["visual_representation_category"];
}

const WallPattern: React.FC<PatternProps> = ({
    id,
    x,
    y,
    frameWidth,
    frameHeight,
    material
}) => {
    const { getPatternSvg, strokeColor, strokeWidth } = usePatternContext();

    const { patternSvg: pattern, patternSvgWidth, patternSvgHeight } = getPatternSvg(material);

    const isRotated = frameWidth > frameHeight ? true : false

    // scale the stroke width to have every pattern the same stroke width after the 
    // pattern is applied to the parent and beeing scaled
    const scaleX = !isRotated ? frameWidth / patternSvgWidth : frameWidth / patternSvgHeight;
    const patternStrokeWidth = strokeWidth / scaleX;

    const stretechedPatternHeight = pattern.keepAspectRatio_on_isStretched ?
        !isRotated ? frameWidth * 2 : frameHeight :
        frameHeight;
    const stretechedPatternWidth = pattern.keepAspectRatio_on_isStretched ?
        !isRotated ? frameWidth : frameHeight * 2 :
        frameWidth;

    return (
        pattern.isStretched ? (
            <pattern
                id={id}
                patternUnits="userSpaceOnUse"
                x={x}
                y={y}
                width={stretechedPatternWidth}
                height={stretechedPatternHeight}
                style={{
                    stroke: strokeColor,
                    strokeWidth: patternStrokeWidth,
                    fill: strokeColor
                }}
            >
                <g transform={isRotated ? `translate(${stretechedPatternWidth}, 0) rotate(90)` : undefined}>
                    <pattern.patternSvgElement
                        width={isRotated ? stretechedPatternHeight : stretechedPatternWidth}
                        height={isRotated ? stretechedPatternWidth : stretechedPatternHeight}
                        preserveAspectRatio="none"
                    />
                </g>
            </pattern>
        ) :
            <pattern
                id={id}
                patternUnits="userSpaceOnUse"
                x={x}
                y={y}
                width={patternSvgWidth}
                height={patternSvgHeight}
                style={{
                    stroke: strokeColor,
                    strokeWidth: strokeWidth,
                    fill: strokeColor
                }}
            >
                <g transform={isRotated ? `translate(${patternSvgWidth}, 0) rotate(90)` : undefined}>
                    <pattern.patternSvgElement
                        width={isRotated ? patternSvgHeight : patternSvgWidth}
                        height={isRotated ? patternSvgWidth : patternSvgHeight}
                        preserveAspectRatio="xMinYMid"
                    />
                </g>
            </pattern>
    );
};

export default WallPattern;