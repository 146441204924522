import React from 'react'
import { useTranslation } from 'react-i18next'

interface NoPreviewAvailableProps {
  element: any
  fitToContainer: boolean
  minimalTheme: boolean
  strokeColor: string
  annotationColor: string
  svgRef?: any
}

export default function NoPreviewAvailable(props: NoPreviewAvailableProps) {
  const {
    element,
    fitToContainer,
    minimalTheme,
    strokeColor,
    annotationColor,
    svgRef,
  } = props
  const { t } = useTranslation('common', { keyPrefix: 'element_image' })

  const dimensions = { x: 0, y: 0, width: 250, height: 250 }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      id={`element_${element.id}`}
      x='0'
      y='0'
      width={fitToContainer ? '100%' : dimensions.width}
      height={fitToContainer ? '100%' : dimensions.height}
      viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
      ref={svgRef}
      style={
        minimalTheme
          ? {
              userSelect: 'none',
            }
          : {
              userSelect: 'none',
              cursor: 'move',
            }
      }
    >
      <text
        x={dimensions.width / 2}
        y={dimensions.height / 2}
        alignmentBaseline='middle'
        textAnchor='middle'
        fill={annotationColor}
      >
        {t('no_preview_available')}
      </text>
    </svg>
  )
}
