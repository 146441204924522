import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "store"
import { projectPermissions } from "common/utils/constants/auth.constants";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles'
import {
    closeAlertSnackbar,
    closeLoadingSnackbar,
    getAlertSnackbar,
    getLoading,
    getLoadingSnackbar,
    getToBeLoadedIfcModelFile,
    openSelectModelDialog,
    selectModel,
    setModelFetchingFailed,
    setModelFetchingFullfilled,
    setModelFetchingStarted,
    setModelLoadingFailed,
    setModelLoadingFullfilled,
    setModelLoadingStarted,
    clearToBeLoadedIfcModelFile,
    closeSelectModelDialog,
} from 'store/ui/projects/ifcViewer/ifcViewer.slice';
import * as THREE from 'three';
import * as OBC from 'openbim-components';
import * as WEBIFC from "web-ifc";
import { FragmentsGroup } from "bim-fragment";
import './styles/styles.css';
import { Postproduction } from 'openbim-components/navigation/PostproductionRenderer/src/postproduction';
import SelectModelDialog from './dialogs/selectModel/selectModelDialog';
import AlertSnackbar from 'common/components/snackbars/alertSnackbar';
import LoadingSnackbar from 'common/components/snackbars/loadingSnackbar';
import Tree from './tree/tree';
import { MaterialUIContainer } from './components/MaterialUIContainer';
import PropertiesPanel from './propertiesPanel/propertiesPanel';
import { PropertiesIndexMap, getPropertiesByExpressId, initializePropertiesIndexMap, properties } from './properties/properties';
import IfcConElementAssignment from './ifcConElementAssignment/ifcConElementAssignment';
import { getLocales } from 'store/entities/settings/settings.slice';
import { fetchConstructionElements } from 'store/entities/projects/componentCatalogue/componentCatalogue.actions';
import { fetchIfcConElementAssignments } from 'store/entities/projects/ifcViewer/ifcConElementAssignments/ifcConElementAssignments.actions';
import { FloatingWindow } from './components/FloatingWindow';
import VisibilityFilter from './visibilityFilter/visibilityFilter';
import { IStakeholderPermission, IUploadedFile, IUploadedFileTableRowData } from 'types';
import { useIndexedDB } from 'services/indexeddb';
import { IIndexedDBFiles } from 'services/indexeddb/indexeddbConfig';
import { downloadUploadedFileToIndexedDB } from 'store/entities/projects/uploadedFiles/uploadedFiles.actions';
import { fileToSha256Hex } from 'common/utils/helpers/getFileHash.helpers';
import { getUploadedFiles } from 'store/entities/projects/uploadedFiles/uploadedFiles.slice';
import { getProjectPermissions } from 'store/entities/projects/project.slice';
import { hasProjectPerm } from 'services/authService';

const IfcViewer: React.FC = () => {
    const viewerContainerRef = useRef<HTMLDivElement | null>(null);

    const [modelFile, setModelFile] = useState<File | null>(null);
    const [model, setModel] = useState<FragmentsGroup | null>(null);
    const [viewer, setViewer] = useState<OBC.Components | null>(null);

    const [spatialStructure, setSpatialStructure] = useState<any>(null);
    const [allModelProperties, setAllModelProperties] = useState<any>(null);
    const [propertiesIndexMap, setPropertiesIndexMap] = useState<PropertiesIndexMap>({});
    const [selectedModelProperties, setSelectedModelProperties] = useState<properties[]>([]);

    const [allModelExpressIds, setAllModelExpressIds] = useState<number[]>([]);
    const [selectedModelExpressIds, setSelectedModelExpressIds] = useState<number[]>([]);
    const [displayedModelExpressIds, setDisplayedModelExpressIds] = useState<number[]>([]);

    const userPermissions: IStakeholderPermission[] = useAppSelector(
        getProjectPermissions
    );

    const [modelTreeFloatingWindow, setModelTreeFloatingWindow] = useState<MaterialUIContainer | null>(null);
    const [propertiesPanelFloatingWindow, setPropertiesPanelFloatingWindow] = useState<MaterialUIContainer | null>(null);
    const [optimizationElementAssignmentFloatingWindow, setOptimizationElementAssignmentFloatingWindow] = useState<MaterialUIContainer | null>(null);
    const [visibilityFilter, setVisibilityFilter] = useState<MaterialUIContainer | null>(null);

    const dispatch = useAppDispatch()
    const location = useLocation()
    const theme = useTheme()
    const locales = useAppSelector(getLocales);
    const { projectId } = useParams();
    const { add, getLatestRecord, getAll, deleteRecord } = useIndexedDB("files");
    const uploadedFiles = useAppSelector(getUploadedFiles);
    const toBeLoadedIfcModelFile: IUploadedFileTableRowData | null = useAppSelector(getToBeLoadedIfcModelFile);

    const initializeViewerComponents = async (): Promise<void> => {
        // --------------- Css customization ------------------------------

        //OBC.FloatingWindow.Class.Base = "absolute flex flex-col z-1200 backdrop-blur-xl shadow-md overflow-auto top-under-app-bar resize left-5 min-h-[80px] min-w-[150px] w-fit h-fit text-white bg-ifcjs-100 rounded-md"

        // --------------- Components ------------------------------
        const [
            viewerInstance,
            sceneComponent,
            rendererComponent,
            cameraComponent,
            raycasterComponent
        ] = await initializeViewerScene();

        const fragments = new OBC.FragmentManager(viewerInstance);
        const classifier = new OBC.FragmentClassifier(viewerInstance);
        const materialManager = new OBC.MaterialManager(viewerInstance);

        const postProduction = await initializeViewerPostproduction(viewerInstance, rendererComponent);

        const mainToolbar = await initializeViewerMainToolbar(viewerInstance);

        const measurementToolbar = await initializeViewerMeasurementToolbar(viewerInstance);

        const optimizationToolbar = await initializeOptimizationToolbar(viewerInstance);

        const ifcLoaderComponent = await initializeViewerIfcLoader(viewerInstance, mainToolbar);

        const [fragmentBoundingBox, fragmentBoundingBoxButton] = await initializeViewerFragmentBoundingBox(viewerInstance, mainToolbar);

        const highlighterComponent = await initializeViewerHighlighter(viewerInstance);

        const propertiesProcessorComponent = await initializeViewerPropertyProcessor(viewerInstance, mainToolbar);

        await initializeViewerCustomPropertiesPanel(viewerInstance, mainToolbar)

        const propertiesHider = await initializeViewerPropertiesHider(viewerInstance, mainToolbar);

        const propertiesFinder = await initializeViewerPropertiesFinder(viewerInstance, mainToolbar);

        await initializeVisibilityFilter(viewerInstance, mainToolbar);

        const fragmentModelTree = await initializeViewerFragmentModelTree(viewerInstance, mainToolbar);

        await initializeViewerCustomFragmentModelTree(viewerInstance, mainToolbar);

        await initializeOptimizationElementAssignment(viewerInstance, optimizationToolbar);

        const fragmentPlans = await initializeViewerFragmentPlans(viewerInstance, mainToolbar);

        const fragmentExploder = await initializeViewerFragmentExploder(viewerInstance, mainToolbar);

        const clipper = await initializeViewerEdgesClipper(viewerInstance, mainToolbar);

        const simpleClipper = await initializeViewerSimpleClipper(viewerInstance, mainToolbar);

        const [LengthDimensions, AreaDimensions, AngleDimensions] = await initializeViewerMeasurements(viewerInstance, measurementToolbar);

        // --------------- Events ------------------------------
        ifcLoaderComponent.onIfcLoaded.add(async (model) => {
            classifier.byEntity(model);
            classifier.byStorey(model);
        })

        ifcLoaderComponent.onIfcLoaded.add(async (model) => {
            propertiesProcessorComponent.process(model);
        })

        ifcLoaderComponent.onIfcLoaded.add(async (model) => {
            fragmentBoundingBox.add(model);
            const bbox = fragmentBoundingBox.getMesh();
            fragmentBoundingBox.reset();

            fragmentBoundingBoxButton.onClick.add(() => {
                cameraComponent.controls.fitToSphere(bbox, true);
            })
        })

        ifcLoaderComponent.onIfcLoaded.add(async (model) => {
            const found = await classifier.find({ entities: ["IFCWALLSTANDARDCASE", "IFCWALL"] });
            const fragmentIdMap = found;
            const styles = clipper.styles.get();

            for (const fragID in fragmentIdMap) {
                const { mesh } = fragments.list[fragID];
                styles.filled.fragments[fragID] = new Set(fragmentIdMap[fragID]);
                styles.filled.meshes.add(mesh);
            }

            const meshes: any[] = [];
            for (const fragment of model.items) {
                const { mesh } = fragment;
                meshes.push(mesh);
                styles.projected.meshes.add(mesh);
            }

            const whiteColor = new THREE.Color("white");
            const whiteMaterial = new THREE.MeshBasicMaterial({ color: whiteColor });
            const existingMaterials = materialManager.get()
            if (!existingMaterials.includes("white")) {
                materialManager.addMaterial("white", whiteMaterial);
                materialManager.addMeshes("white", meshes);
            }

            await fragmentPlans.computeAllPlanViews(model);

            fragmentPlans.commands = {
                "Select": async (plan) => {
                    if (plan !== undefined) {
                        const found = await classifier.find({ storeys: [plan.name] });
                        highlighterComponent.highlightByID("default", found);
                    }
                },
                "Show": async (plan) => {
                    if (plan !== undefined) {
                        const found = await classifier.find({ storeys: [plan.name] });
                        propertiesHider.set(true, found);
                    }
                },
                "Hide": async (plan) => {
                    if (plan !== undefined) {
                        const found = await classifier.find({ storeys: [plan.name] });
                        propertiesHider.set(false, found);
                    }
                },
            }
            await fragmentPlans.updatePlansList();

            fragmentPlans.onNavigated.add(() => {
                postProduction.customEffects.glossEnabled = false;
                materialManager.setBackgroundColor(whiteColor);
                materialManager.set(true, ["white"]);
            });

            fragmentPlans.onExited.add(() => {
                postProduction.customEffects.glossEnabled = true;
                materialManager.resetBackgroundColor();
                materialManager.set(false, ["white"]);
            });

        })

        ifcLoaderComponent.onIfcLoaded.add(async (model) => {
            await fragmentModelTree.update(['storeys', 'entities']);
        })

        ifcLoaderComponent.onIfcLoaded.add(async (model) => {
            await highlighterComponent.update();
        })

        highlighterComponent.events.select.onHighlight.add(async (selection) => {
            await highlighterComponent.highlight("select_element", true);
            const selectedFragmentIDs = Object.keys(selection);
            let selectedExpressIDs: number[] = []
            for (const firstKey in selection) {
                if (selection.hasOwnProperty(firstKey)) {
                    const currentSet = selection[firstKey];
                    currentSet.forEach((fragmentId) => {
                        selectedExpressIDs.push(Number(fragmentId))
                    });
                }
            }
            for (const group of fragments.groups) {
                const fragmentFound = Object.values(group.keyFragments).find(id => id === selectedFragmentIDs[0])
                if (fragmentFound) {
                    await propertiesProcessorComponent.renderProperties(group, selectedExpressIDs[0]);
                }
            }
            setSelectedModelExpressIds(selectedExpressIDs)
        })

        highlighterComponent.events.select.onClear.add(async () => {
            await propertiesProcessorComponent.cleanPropertiesList();
            await highlighterComponent.clear("select_element");
            setSelectedModelExpressIds([]);
        });

        propertiesFinder.onFound.add(async result => {
            await highlighterComponent.highlightByID("select_element", result, true, true);
            const selectedFragmentIDs = Object.keys(result);
            let selectedExpressIDs: number[] = []
            for (const firstKey in result) {
                if (result.hasOwnProperty(firstKey)) {
                    const currentSet = result[firstKey];
                    currentSet.forEach((fragmentId) => {
                        selectedExpressIDs.push(Number(fragmentId))
                    });
                }
            }
            for (const group of fragments.groups) {
                const fragmentFound = Object.values(group.keyFragments).find(id => id === selectedFragmentIDs[0])
                if (fragmentFound) {
                    await propertiesProcessorComponent.renderProperties(group, selectedExpressIDs[0]);
                }
            }
            setSelectedModelExpressIds(selectedExpressIDs)
        })

        fragmentModelTree.onSelected.add(async (filter) => {
            await highlighterComponent.highlightByID("select_element", filter, true, true);
        });

        window.onkeydown = (event) => {
            if (event.code === 'Delete' || event.code === 'Backspace') {
                simpleClipper.deleteAll();

                LengthDimensions.deleteAll();
                //AreaDimensions.deleteAll();
                AreaDimensions.delete();
                //AngleDimensions.deleteAll();
                AngleDimensions.delete();
            }
        }
        // fragmentModelTree.onHovered.add((filter) => {
        //     highlighterComponent.highlightByID('hover', filter);
        // });

        // --------------- NavCube ------------------------------
        const navCube = new OBC.CubeMap(viewerInstance);
        navCube.offset = 1;
        navCube.setPosition("bottom-left");
        viewerInstance.tools.add("53311ea3-323a-476f-ae4a-d681778e8f67", navCube);

        // --------------- Final actions ------------------------------
        setViewer(viewerInstance);
    }

    // --------------- Components ------------------------------
    const initializeViewerScene = async (): Promise<any[]> => {
        const viewerInstance = new OBC.Components()

        const sceneComponent = new OBC.SimpleScene(viewerInstance)
        viewerInstance.scene = sceneComponent
        const scene = sceneComponent.get()
        const ambientLight = new THREE.AmbientLight(0xE6E7E4, 1)
        const directionalLight = new THREE.DirectionalLight(0xF9F9F9, 0.75)
        directionalLight.position.set(10, 50, 10)
        scene.add(ambientLight, directionalLight)
        scene.background = new THREE.Color("#202932")

        const viewerContainer = document.getElementById("viewer-container") as HTMLDivElement
        const rendererComponent = new OBC.PostproductionRenderer(viewerInstance, viewerContainer)
        viewerInstance.renderer = rendererComponent

        const cameraComponent = new OBC.OrthoPerspectiveCamera(viewerInstance)
        viewerInstance.camera = cameraComponent

        const raycasterComponent = new OBC.SimpleRaycaster(viewerInstance)
        viewerInstance.raycaster = raycasterComponent

        viewerInstance.init()

        return [viewerInstance, sceneComponent, rendererComponent, cameraComponent, raycasterComponent]
    }

    const initializeViewerPostproduction = async (viewerInstance: OBC.Components, rendererComponent: OBC.PostproductionRenderer): Promise<Postproduction> => {
        const postProduction = rendererComponent.postproduction;
        postProduction.enabled = true
        postProduction.customEffects.outlineEnabled = true;
        return postProduction
    }

    const initializeViewerIfcLoader = async (viewerInstance: OBC.Components, mainToolbar: OBC.Toolbar): Promise<OBC.FragmentIfcLoader> => {
        const ifcLoaderComponent = new OBC.FragmentIfcLoader(viewerInstance)
        ifcLoaderComponent.settings.wasm = {
            path: process.env.PUBLIC_URL + "/ifc/",
            absolute: true
        }
        ifcLoaderComponent.settings.webIfc.COORDINATE_TO_ORIGIN = true;
        ifcLoaderComponent.settings.webIfc.OPTIMIZE_PROFILES = true;
        ifcLoaderComponent.uiElement.get("toast").dispose()
        viewerInstance.tools.add("a659add7-1418-4771-a0d6-7d4d438e4624", ifcLoaderComponent);
        // mainToolbar.addChild(ifcLoaderComponent.uiElement.get("main"));

        const openIfcLoaderButton = new OBC.Button(viewerInstance);
        openIfcLoaderButton.materialIcon = "other_houses";
        openIfcLoaderButton.tooltip = "Load ifc file";
        openIfcLoaderButton.onClick.add(() => {
            dispatch(openSelectModelDialog());
        })
        mainToolbar.addChild(openIfcLoaderButton);

        return ifcLoaderComponent
    }

    const loadIfcFromFile = async () => {
        try {
            if (modelFile !== null && viewer !== null) {
                dispatch(setModelLoadingStarted(modelFile.name));
                // load ifc file to viewer
                const ifcLoaderComponent = await viewer.tools.get(OBC.FragmentIfcLoader)
                const fragments = await viewer.tools.get(OBC.FragmentManager)
                let classifier = await viewer.tools.get(OBC.FragmentClassifier)
                await classifier.dispose()
                classifier = await viewer.tools.get(OBC.FragmentClassifier)

                await fragments.dispose();

                const scene = viewer.scene.get();

                const data = await modelFile.arrayBuffer();
                const buffer = new Uint8Array(data);
                const model = await ifcLoaderComponent.load(buffer, modelFile.name);

                scene.add(model);
                await fragments.updateWindow();

                // setup spatial structure
                const ifcAPI = new WEBIFC.IfcAPI();
                ifcAPI.SetWasmPath(process.env.PUBLIC_URL + "/ifc/", true);
                await ifcAPI.Init();
                const modelID = ifcAPI.OpenModel(buffer, {
                    COORDINATE_TO_ORIGIN: true,
                    USE_FAST_BOOLS: true
                });

                const spatialStructure = await ifcAPI.properties.getSpatialStructure(modelID, true);
                const allIDs = getChildrenIdsByParentId([spatialStructure], [spatialStructure.expressID]);

                setAllModelExpressIds(allIDs);
                setSpatialStructure(spatialStructure);

                setDisplayedModelExpressIds(allIDs);
                setSelectedModelExpressIds([]);

                setModel(model);

                setAllModelProperties(model.properties);
                setPropertiesIndexMap(initializePropertiesIndexMap(model));

                console.log("Ifc file loaded");
                dispatch(setModelLoadingFullfilled());
            } else {
                console.log("No viewer or ifc model file provided");
                console.log("Loading ifc file failed");
                dispatch(setModelLoadingFailed());
            }
        }
        catch (error) {
            console.log("Loading ifc file failed");
            dispatch(setModelLoadingFailed());
        }
    }

    const getModelFile = () => {
        try {
            if (projectId !== undefined && toBeLoadedIfcModelFile !== null) {
                dispatch(setModelFetchingStarted(toBeLoadedIfcModelFile.name));
                getAll().then(async (dbItems: IIndexedDBFiles[]) => {
                    let fileFound = false;
                    for (const dbItem of dbItems) {
                        if (dbItem.uuid === toBeLoadedIfcModelFile.uuid) {
                            //console.log(`Model ${dbItem.name} with ${dbItem.uuid} found in IndexedDB`)
                            const uploadedFile: IUploadedFile = uploadedFiles.filter((file: IUploadedFile) => file.uuid === dbItem.uuid)[0]
                            const hash = await fileToSha256Hex(dbItem.file);
                            if (uploadedFile !== undefined && hash === uploadedFile.file_sha256_hex_hash) {
                                //console.log(`Model ${dbItem.name} with ${dbItem.uuid} checksum verified`);
                                dispatch(selectModel({ name: dbItem.name, uuid: dbItem.uuid }));
                                setModelFile(dbItem.file);
                                fileFound = true;
                                break;
                            } else {
                                //console.log(`Model ${dbItem.name} with ${dbItem.uuid} checksum failed`);
                                fileFound = false;
                                //console.log(`Delete Model ${dbItem.name} with ${dbItem.uuid} from IndexedDB`);
                                await deleteRecord(dbItem.id);
                                break;
                            }
                        }
                    }
                    if (fileFound === false) {
                        //console.log(`Model ${toBeLoadedIfcModelFile.name} with ${toBeLoadedIfcModelFile.uuid} not found in IndexedDB`)
                        dispatch(downloadUploadedFileToIndexedDB(
                            +projectId,
                            toBeLoadedIfcModelFile.file_id,
                            toBeLoadedIfcModelFile.name,
                            toBeLoadedIfcModelFile.uuid,
                            add,
                        )).then(() => {
                            getLatestRecord().then((dbItem: any) => {
                                if (dbItem.uuid === toBeLoadedIfcModelFile.uuid) {
                                    dispatch(selectModel({ name: dbItem.name, uuid: dbItem.uuid }))
                                    setModelFile(dbItem.file)
                                }
                            })
                        })
                    }
                });
                dispatch(closeSelectModelDialog());
                dispatch(setModelFetchingFullfilled());
            } else {
                dispatch(setModelFetchingFailed());
            }
        } catch (error) {
            dispatch(setModelFetchingFailed());
            dispatch(clearToBeLoadedIfcModelFile());
        }
    }

    const initializeViewerHighlighter = async (viewerInstance: OBC.Components): Promise<OBC.FragmentHighlighter> => {
        const highlighterComponent = new OBC.FragmentHighlighter(viewerInstance)
        await highlighterComponent.setup()

        highlighterComponent.outlineEnabled = true;

        const highlightMaterial = new THREE.MeshBasicMaterial({
            color: "#768669",
            depthTest: false,
            opacity: 1,
            transparent: true
        });

        await highlighterComponent.add('select_element', [highlightMaterial]);
        highlighterComponent.outlineMaterial.color.set(0xf0ff7a);

        // async function highlightOnClick(event: MouseEvent) {
        //     await highlighterComponent.highlight("select_element", true);
        // };
        // const viewerContainer = document.getElementById("viewer-container") as HTMLDivElement
        // viewerContainer.addEventListener('click', (event) => highlightOnClick(event));

        viewerInstance.tools.add("cb8a76f2-654a-4b50-80c6-66fd83cafd77", highlighterComponent);
        return highlighterComponent
    }

    const initializeViewerPropertyProcessor = async (viewerInstance: OBC.Components, mainToolbar: OBC.Toolbar): Promise<OBC.IfcPropertiesProcessor> => {
        const propertiesProcessorComponent = new OBC.IfcPropertiesProcessor(viewerInstance)
        viewerInstance.tools.add("23a889ab-83b3-44a4-8bee-ead83438370b", propertiesProcessorComponent);
        // mainToolbar.addChild(propertiesProcessorComponent.uiElement.get("main"));
        return propertiesProcessorComponent
    }

    const initializeViewerCustomPropertiesPanel = async (viewerInstance: OBC.Components, mainToolbar: OBC.Toolbar): Promise<void> => {
        await initializeFloatingWindowComponent(viewerInstance, mainToolbar, {
            component: (
                <PropertiesPanel
                    selectedModelProperties={selectedModelProperties}
                />
            ),
            title: "Selected element properties",
            buttonIcon: "category",
            buttonTooltip: "Selected element properties",
            setContainerState: setPropertiesPanelFloatingWindow,
            positionAndAlignment: ["top", "right"]
        });
    }

    const updateViewerCustomPropertiesPanel = async (): Promise<void> => {
        if (viewer !== null && propertiesPanelFloatingWindow !== null) {
            const panel = (
                <PropertiesPanel
                    selectedModelProperties={selectedModelProperties}
                />
            );
            propertiesPanelFloatingWindow.updateMaterialUIComponent(panel);
        }
    }

    const initializeViewerPropertiesHider = async (viewerInstance: OBC.Components, mainToolbar: OBC.Toolbar): Promise<OBC.FragmentHider> => {
        const propertiesHiderComponent = new OBC.FragmentHider(viewerInstance);
        await propertiesHiderComponent.loadCached();
        viewerInstance.tools.add("dd9ccf2d-8a21-4821-b7f6-2949add16a29", propertiesHiderComponent);
        //mainToolbar.addChild(propertiesHiderComponent.uiElement.get("main"));
        return propertiesHiderComponent
    }

    const initializeViewerPropertiesFinder = async (viewerInstance: OBC.Components, mainToolbar: OBC.Toolbar): Promise<OBC.IfcPropertiesFinder> => {
        const propertiesFinder = new OBC.IfcPropertiesFinder(viewerInstance);
        await propertiesFinder.init();
        viewerInstance.tools.add("propertiesFinder", propertiesFinder);
        //mainToolbar.addChild(propertiesFinder.uiElement.get("main"));
        return propertiesFinder
    }

    const initializeViewerFragmentBoundingBox = async (viewerInstance: OBC.Components, mainToolbar: OBC.Toolbar): Promise<any[]> => {
        const fragmentBoundingBox = new OBC.FragmentBoundingBox(viewerInstance);
        const fragmentBoxButton = new OBC.Button(viewerInstance);
        fragmentBoxButton.materialIcon = "zoom_in_map";
        fragmentBoxButton.tooltip = "Zoom to building";
        mainToolbar.addChild(fragmentBoxButton);
        return [fragmentBoundingBox, fragmentBoxButton]
    }

    const initializeViewerFragmentModelTree = async (viewerInstance: OBC.Components, mainToolbar: OBC.Toolbar): Promise<OBC.FragmentTree> => {
        const fragmentModelTree = new OBC.FragmentTree(viewerInstance);
        await fragmentModelTree.init();
        viewerInstance.tools.add("5af6ebe1-26fc-4053-936a-801b6c7cb37e", fragmentModelTree);
        //mainToolbar.addChild(fragmentModelTree.uiElement.get("main"));
        return fragmentModelTree
    }

    const initializeViewerCustomFragmentModelTree = async (viewerInstance: OBC.Components, mainToolbar: OBC.Toolbar): Promise<void> => {
        await initializeFloatingWindowComponent(viewerInstance, mainToolbar, {
            component: (
                <Tree
                    spatialStructure={spatialStructure}
                    selectedIds={selectedModelExpressIds}
                    selectItemsById={selectElementsByExpressId}
                    showHideItemsById={showHideElementsByExpressId}
                    displayedModelExpressIds={displayedModelExpressIds}
                />
            ),
            title: "IFC model tree structure",
            buttonIcon: "account_tree",
            buttonTooltip: "IFC model tree structure",
            onClose: floatingWindowOnClose,
            setContainerState: setModelTreeFloatingWindow,
            positionAndAlignment: ["top", "left"]
        });
    }

    const updateViewerCustomFragmentModelTree = async (): Promise<void> => {
        if (viewer !== null && modelTreeFloatingWindow !== null) {
            const tree = (
                <Tree
                    spatialStructure={spatialStructure}
                    selectedIds={selectedModelExpressIds}
                    selectItemsById={selectElementsByExpressId}
                    showHideItemsById={showHideElementsByExpressId}
                    displayedModelExpressIds={displayedModelExpressIds}
                />
            );
            modelTreeFloatingWindow.updateMaterialUIComponent(tree);
            modelTreeFloatingWindow.setOnClose(async () => {
                await floatingWindowOnClose();
            });
        }
    }

    const initializeViewerFragmentExploder = async (viewerInstance: OBC.Components, mainToolbar: OBC.Toolbar): Promise<OBC.FragmentExploder> => {
        const fragmentExploder = new OBC.FragmentExploder(viewerInstance);
        viewerInstance.tools.add("d260618b-ce88-4c7d-826c-6debb91de3e2", fragmentExploder);
        mainToolbar.addChild(fragmentExploder.uiElement.get("main"));
        return fragmentExploder
    }

    const initializeViewerEdgesClipper = async (viewerInstance: OBC.Components, mainToolbar: OBC.Toolbar): Promise<OBC.EdgesClipper> => {
        const clipper = new OBC.EdgesClipper(viewerInstance);

        const sectionMaterial = new THREE.LineBasicMaterial({ color: 'black' });
        const fillMaterial = new THREE.MeshBasicMaterial({ color: 'gray', side: 2 });
        const fillOutline = new THREE.MeshBasicMaterial({ color: 'black', side: 1, opacity: 0.5, transparent: true });
        clipper.styles.create("filled", new Set(), sectionMaterial, fillMaterial, fillOutline);
        clipper.styles.create("projected", new Set(), sectionMaterial);

        viewerInstance.tools.add("66290bc5-18c4-4cd1-9379-2e17a0617611", clipper);
        return clipper
    }

    const initializeViewerSimpleClipper = async (viewerInstance: OBC.Components, mainToolbar: OBC.Toolbar): Promise<OBC.SimpleClipper<OBC.SimplePlane>> => {
        const simpleClipper = new OBC.SimpleClipper(viewerInstance);

        const viewerContainer = document.getElementById("viewer-container") as HTMLDivElement
        viewerContainer.ondblclick = () => simpleClipper.create();

        //mainToolbar.addChild(simpleClipper.uiElement.get("main"));
        return simpleClipper
    }

    const initializeViewerFragmentPlans = async (viewerInstance: OBC.Components, mainToolbar: OBC.Toolbar): Promise<OBC.FragmentPlans> => {

        const fragmentPlans = new OBC.FragmentPlans(viewerInstance);

        viewerInstance.tools.add("a80874aa-1c93-43a4-80f2-df346da086b1", fragmentPlans);
        mainToolbar.addChild(fragmentPlans.uiElement.get("main"));
        return fragmentPlans
    }

    const initializeViewerMeasurements = async (viewerInstance: OBC.Components, measurementToolbar: OBC.Toolbar): Promise<any[]> => {
        const LengthDimensions = new OBC.LengthMeasurement(viewerInstance);
        viewerInstance.tools.add("2f9bcacf-18a9-4be6-a293-e898eae64ea1", LengthDimensions);
        LengthDimensions.snapDistance = 1;
        //viewerContainer.ondblclick = () => dimensions.create();

        const AreaDimensions = new OBC.AreaMeasurement(viewerInstance);
        viewerInstance.tools.add("c453a99e-f054-4781-9060-33df617db4a5", AreaDimensions);

        const AngleDimensions = new OBC.AngleMeasurement(viewerInstance);
        viewerInstance.tools.add("622fb2c9-528c-4b0a-8a0e-6a1375f0a3aa", AreaDimensions);

        // window.onkeydown = (event) => {
        //     if (event.code === 'Delete' || event.code === 'Backspace') {
        //         LengthDimensions.deleteAll();
        //         //AreaDimensions.deleteAll();
        //         AreaDimensions.delete();
        //         //AngleDimensions.deleteAll();
        //         AngleDimensions.delete();
        //     }
        // }
        measurementToolbar.addChild(
            LengthDimensions.uiElement.get("main"),
            AreaDimensions.uiElement.get("main"),
            AngleDimensions.uiElement.get("main"),
        );
        return [LengthDimensions, AreaDimensions, AngleDimensions]
    }

    const initializeViewerMainToolbar = async (viewerInstance: OBC.Components): Promise<OBC.Toolbar> => {
        const mainToolbar = new OBC.Toolbar(viewerInstance, { name: 'Main Toolbar', position: 'bottom' });
        viewerInstance.ui.addToolbar(mainToolbar)
        return mainToolbar
    }

    const initializeViewerMeasurementToolbar = async (viewerInstance: OBC.Components): Promise<OBC.Toolbar> => {
        const measurementToolbar = new OBC.Toolbar(viewerInstance, { name: 'Measurement Toolbar', position: 'bottom' });
        viewerInstance.ui.addToolbar(measurementToolbar)
        return measurementToolbar
    }

    const initializeOptimizationToolbar = async (viewerInstance: OBC.Components): Promise<OBC.Toolbar> => {
        const optimizationToolbar = new OBC.Toolbar(viewerInstance, { name: 'Optimization Toolbar', position: 'bottom' });
        viewerInstance.ui.addToolbar(optimizationToolbar)
        return optimizationToolbar
    }

    // --------------- Optimization Input ---------------
    const initializeOptimizationElementAssignment = async (viewerInstance: OBC.Components, optimizationToolbar: OBC.Toolbar): Promise<void> => {
        if (viewer !== null && projectId != null && hasProjectPerm(userPermissions, [projectPermissions.ifc_con_element_assignments.read_only,])) {
            await initializeFloatingWindowComponent(viewerInstance, optimizationToolbar, {
                component: (
                    <IfcConElementAssignment
                        theme={theme}
                        model={model}
                        selectedModelExpressIds={selectedModelExpressIds}
                        selectElementsByExpressId={selectElementsByExpressId}
                        getModelPropertiesByExpressId={getModelPropertiesByExpressId}
                        project_id={+projectId}
                    />
                ),
                title: "Element assignment",
                buttonIcon: "assignment",
                buttonTooltip: "Element assignment",
                setContainerState: setOptimizationElementAssignmentFloatingWindow
            });
        }
    }

    const updateOptimizationElementAssignment = async (): Promise<void> => {
        if (viewer !== null && projectId != null && optimizationElementAssignmentFloatingWindow !== null && hasProjectPerm(userPermissions, [projectPermissions.ifc_con_element_assignments.read_only,])) {
            const optimizationElementAssignment = (
                <IfcConElementAssignment
                    theme={theme}
                    model={model}
                    selectedModelExpressIds={selectedModelExpressIds}
                    selectElementsByExpressId={selectElementsByExpressId}
                    getModelPropertiesByExpressId={getModelPropertiesByExpressId}
                    project_id={+projectId}
                />
            );
            optimizationElementAssignmentFloatingWindow.updateMaterialUIComponent(optimizationElementAssignment);
        }
    }

    // --------------- Visibility Filter ---------------
    const initializeVisibilityFilter = async (viewerInstance: OBC.Components, mainToolbar: OBC.Toolbar): Promise<void> => {
        if (viewer !== null) {
            await initializeFloatingWindowComponent(viewerInstance, mainToolbar, {
                component: (
                    <VisibilityFilter
                        theme={theme}
                        model={model}
                        showHideItemsById={showHideElementsByExpressId}
                        allModelExpressIds={allModelExpressIds}
                        displayedModelExpressIds={displayedModelExpressIds}
                    />
                ),
                title: "Visibility Filter",
                buttonIcon: "filter_alt",
                buttonTooltip: "Visibility Filter",
                onClose: floatingWindowOnClose,
                setContainerState: setVisibilityFilter
            });
        }
    };

    const updateVisibilityFilter = async (): Promise<void> => {
        if (viewer !== null && model != null && visibilityFilter !== null) {
            const newVisibilityFilter = (
                <VisibilityFilter
                    theme={theme}
                    model={model}
                    showHideItemsById={showHideElementsByExpressId}
                    allModelExpressIds={allModelExpressIds}
                    displayedModelExpressIds={displayedModelExpressIds}
                />
            );
            visibilityFilter.updateMaterialUIComponent(newVisibilityFilter);
            visibilityFilter.setOnClose(async () => {
                await floatingWindowOnClose();
            });
        }
    }

    // --------------- Helpers ---------------
    const getChildrenIdsByParentId = (parent: any[], parentId: number[]): number[] => {
        const parentIdSet = new Set(parentId);
        const outputIds: number[] = [];
        function getIdsHelper(parent: any, hasParentMatched = false) {
            parent?.forEach((d: any) => {
                if (parentIdSet.has(d.expressID) || hasParentMatched) {
                    outputIds.push(d.expressID);
                    getIdsHelper(d.children, true);
                } else {
                    getIdsHelper(d.children);
                }
            });
        }
        getIdsHelper(parent);
        return outputIds;
    }

    const getParentIdsByChildrenIds = (allIdsStructure: any, childrenIds: number[]): number[] => {
        const result: number[] = [];

        const recursiveSearch = (currentObj: any): boolean => {
            let foundInChildren = false;

            // Check if current object's expressID is in childrenIds
            if (childrenIds.includes(currentObj.expressID)) {
                result.push(currentObj.expressID);
                foundInChildren = true;
            }

            // Recursively search children
            for (const child of currentObj.children) {
                if (recursiveSearch(child)) {
                    foundInChildren = true;
                }
            }

            // If found in children or current object, add current object's ID to result
            if (foundInChildren) {
                result.push(currentObj.expressID);
            }

            return foundInChildren;
        };

        recursiveSearch(allIdsStructure);

        // Remove duplicates and return the result
        return Array.from(new Set(result));
    };

    const getFragmentIdsFromExpressIds = async (viewerInstance: OBC.Components, expressIds: number[]): Promise<OBC.FragmentIdMap> => {
        const fragments = await viewerInstance.tools.get(OBC.FragmentManager)
        let selectedFragmentIDs: OBC.FragmentIdMap = {};
        for (const group of fragments.groups) {
            const fragmentMap = group.getFragmentMap(expressIds)
            selectedFragmentIDs = Object.assign(selectedFragmentIDs, fragmentMap)
        }

        const convertedMap: { [fragmentID: string]: Set<string> } = {};

        for (const fragmentID in selectedFragmentIDs) {
            if (selectedFragmentIDs.hasOwnProperty(fragmentID)) {
                const originalSet = selectedFragmentIDs[fragmentID];
                const stringSet = new Set<string>(Array.from(originalSet, String));

                convertedMap[fragmentID] = stringSet;
            }
        }

        return convertedMap
    }

    const selectElementsByExpressId = async (expressIds: number[]) => {
        if (viewer !== null && spatialStructure !== null) {
            const fragments = await viewer.tools.get(OBC.FragmentManager);
            const propertiesProcessorComponent = await viewer.tools.get(OBC.IfcPropertiesProcessor);
            const highlighterComponent = await viewer.tools.get(OBC.FragmentHighlighter);

            const childrenExpressIdsFromInputExpressIds = getChildrenIdsByParentId([spatialStructure], expressIds);

            const selectedFragmentIdMap = await getFragmentIdsFromExpressIds(viewer, childrenExpressIdsFromInputExpressIds);

            const selectedFragmentIds = Object.keys(selectedFragmentIdMap);

            await highlighterComponent.highlightByID("select_element", selectedFragmentIdMap, true, false);

            if (expressIds[0] !== spatialStructure.expressID) {
                for (const group of fragments.groups) {
                    const fragmentFound = Object.values(group.keyFragments).find(id => id === selectedFragmentIds[0]);
                    if (fragmentFound) {
                        await propertiesProcessorComponent.renderProperties(group, expressIds[0]);
                    }
                }
            }

            setSelectedModelExpressIds(expressIds);
        }
    }

    const showHideElementsByExpressId = async (show: boolean, expressIds: number[], addToOldShown: boolean = false) => {
        if (viewer !== null) {
            const fragmentHider = await viewer.tools.get(OBC.FragmentHider)

            const childrenExpressIdsFromInputExpressIds = getChildrenIdsByParentId([spatialStructure], expressIds);

            const selectedFragmentIds = await getFragmentIdsFromExpressIds(viewer, childrenExpressIdsFromInputExpressIds)

            await fragmentHider.set(show, selectedFragmentIds);

            if (show) {
                let newShownIdsSet: Set<number>;

                if (addToOldShown) {
                    newShownIdsSet = new Set(displayedModelExpressIds);
                } else {
                    const parentIds: number[] = getParentIdsByChildrenIds(spatialStructure, childrenExpressIdsFromInputExpressIds);
                    newShownIdsSet = new Set(parentIds);
                }

                childrenExpressIdsFromInputExpressIds.forEach((id: number) => newShownIdsSet.add(id));

                setDisplayedModelExpressIds(Array.from(newShownIdsSet));
            } else {
                const childrenIdsSet = new Set(childrenExpressIdsFromInputExpressIds);
                setDisplayedModelExpressIds((prev) => prev.filter(x => !childrenIdsSet.has(x)));
            }
        }
    }

    // const isElementVisibileByExpressId = async (expressId: number) => {
    //     const isVisible = displayedModelExpressIds.some((displayedId) => displayedId === expressId);
    //     return isVisible;
    // }

    // const getModelPropertiesByExpressId = (expressId: number) => {
    //     if (allModelProperties !== null) {
    //         let foundObject = null;
    //         for (const key in allModelProperties) {
    //             if (allModelProperties[key].expressID === expressId) {
    //                 foundObject = allModelProperties[key];
    //                 break; // Found the object, no need to continue the loop
    //             }
    //         }
    //         console.log(foundObject)
    //         return foundObject
    //     }
    // }

    const getModelPropertiesByExpressId = (expressId: number): properties[] | null => {
        let props = null;
        if (model !== null) {
            props = getPropertiesByExpressId(propertiesIndexMap, model, String(expressId));
        }
        return props;
    }

    type Position = 'top' | 'bottom';
    type Alignment = 'left' | 'middle' | 'right';
    type ComponentConfig = {
        component: JSX.Element;                    // The component to insert (e.g., VisibilityFilter, etc.)
        title: string;                             // Title of the floating window
        buttonIcon: string;                        // Icon for the toolbar button
        buttonTooltip: string;                     // Tooltip for the toolbar button
        onOpen?: () => void;                       // Optional callback when the window is opened
        onClose?: () => void;                      // Optional callback when the window is closed
        setContainerState?: (container: MaterialUIContainer) => void;  // Optional function to set the container state
        positionAndAlignment?: [Position, Alignment];
    };

    const initializeFloatingWindowComponent = async (
        viewerInstance: OBC.Components,
        mainToolbar: OBC.Toolbar,
        config: ComponentConfig
    ): Promise<void> => {
        if (viewerInstance !== null) {
            const materialUIContainer = new MaterialUIContainer(viewerInstance);
            const { component, title, buttonIcon, buttonTooltip, onOpen, onClose, setContainerState, positionAndAlignment } = config;

            materialUIContainer.insertMaterialUIComponent(component);

            materialUIContainer.setOnClose(async () => {
                if (onClose) {
                    await onClose();
                }
            });

            const window = new FloatingWindow(
                viewerInstance,
                `${title.toLowerCase().replace(/\s+/g, '')}`, // unique window ID
                `${materialUIContainer.domElement.style.width}px`,
            );

            window.addChild(materialUIContainer);
            window.title = title;
            if (positionAndAlignment !== undefined) {
                window.visible = true;
            } else {
                window.visible = false;
            }

            window.onHidden.add(() => {
                if (window.slots.content.children.length > 0)
                    window.slots.content.children[0].visible = false;
            });

            viewerInstance.ui.add(window);
            if (positionAndAlignment && positionAndAlignment.length === 2) {
                const [position, alignment] = positionAndAlignment;
                window.setPosition(position, alignment);
            }

            const openComponentButton = new OBC.Button(viewerInstance);
            openComponentButton.materialIcon = buttonIcon;
            openComponentButton.tooltip = buttonTooltip;
            openComponentButton.onClick.add(() => {
                const visible = window.visible;
                window.visible = !visible;
                if (window.visible && onOpen) {
                    onOpen();
                }
                if (window.slots.content.children.length > 0)
                    window.slots.content.children[0].visible = true;
            });
            mainToolbar.addChild(openComponentButton);

            if (setContainerState) {
                setContainerState(materialUIContainer);
            }
        }
    };

    const floatingWindowOnClose = async () => {
        if (model) {
            await showHideElementsByExpressId(true, allModelExpressIds);
        };
    };

    useEffect(() => {
        if (toBeLoadedIfcModelFile === null) {
            dispatch(openSelectModelDialog())
        } else {
            getModelFile();
        }
    }, [toBeLoadedIfcModelFile]);

    useEffect(() => {
        (async () => {
            await initializeViewerComponents();
            // if (modelFile === null) {
            //     dispatch(openSelectModelDialog())
            // }
        })();
        return () => {
            (async () => {
                const viewerContainer = viewerContainerRef.current;
                {
                    if (viewerContainer !== null)
                        while (viewerContainer.firstChild) {
                            viewerContainer.firstChild.remove();
                        }
                    setViewer(null);
                }
            })();
        }
    }, [location, modelFile]);

    useEffect(() => {
        const load = async () => {
            await loadIfcFromFile();
        }
        if (modelFile !== null && viewer !== null) {
            load();
        }
    }, [viewer]);

    useEffect(() => {
        const load = async () => {
            if (viewer !== null) {
                await updateViewerCustomFragmentModelTree();
            }
        }
        load();
    }, [spatialStructure, selectedModelExpressIds, displayedModelExpressIds]);

    useEffect(() => {
        const load = async () => {
            if (viewer !== null) {
                await updateViewerCustomPropertiesPanel();
                await updateOptimizationElementAssignment();
            }
            // const propertiesProcessorComponent = await viewer?.tools.get(OBC.IfcPropertiesProcessor)
            // if (model !== null && propertiesProcessorComponent !== undefined) {

            //     console.log(propertiesProcessorComponent.getProperties(model, String(selectedModelExpressIds[0])))
            // }
        }

        load();

        if (allModelProperties !== null) {
            if (selectedModelExpressIds.length !== 0 && model !== null) {
                const props = getModelPropertiesByExpressId(selectedModelExpressIds[0]);
                //const props = getPropertiesByExpressId(propertiesIndexMap, model, String(selectedModelExpressIds[0]))
                if (props !== null) {
                    setSelectedModelProperties(props);
                } else {
                    setSelectedModelProperties([])
                }
            } else {
                setSelectedModelProperties([])
            }
        }
    }, [spatialStructure, selectedModelExpressIds]);

    useEffect(() => {
        const load = async () => {
            if (viewer !== null) {
                await updateVisibilityFilter();
            }
        }
        load();
    }, [displayedModelExpressIds]);

    useEffect(() => {
        if (projectId !== undefined) {
            dispatch(fetchConstructionElements(+projectId))
            dispatch(fetchIfcConElementAssignments(+projectId))
        }
    }, [locales, projectId]);

    return (
        <Box height={`calc(100vh - ${theme.topNavigation.appbar.height}px)`}>
            <div style={{ position: "relative", zIndex: 1200, width: "100%", height: "100%" }} ref={viewerContainerRef} id="viewer-container" >
            </div>
            <SelectModelDialog
                setModelFile={setModelFile}
            />
            <AlertSnackbar
                localeUrl={"ifc_viewer"}
                localeUrlKeyPrefix={"snackbar"}
                getSnackbar={getAlertSnackbar}
                closeSnackbar={closeAlertSnackbar}
            />
            <LoadingSnackbar
                localeUrl={"ifc_viewer"}
                localeUrlKeyPrefix={"snackbar"}
                getSnackbar={getLoadingSnackbar}
                closeSnackbar={closeLoadingSnackbar}
                getLoading={getLoading}
            />
        </Box>
    )
}

export default IfcViewer