import Avatar from "@mui/material/Avatar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";

import { stringToColor } from "common/utils/helpers/stringToColor.helpers";
import {
  handleUpdateStakeholderDialogOpen,
  selectStakeholder,
} from "store/ui/projects/stakeholders/stakeholders.slice";
import { deleteStakeholder } from "store/entities/projects/stakeholders/stakeholders.actions";
import { useAppDispatch, useAppSelector } from "store";

import { getProjectPermissions } from "store/entities/projects/project.slice";
import { hasProjectPerm } from "services/authService";
import { projectPermissions } from "common/utils/constants/auth.constants";
import type { IStakeholder, IStakeholderPermission } from "types";
import { Box } from "@mui/material";

interface IStyledItemProps {
  stakeholder: IStakeholder;
}

const StyledItem: React.FC<IStyledItemProps> = ({ stakeholder }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <ListItemAvatar>
        <Avatar
          sx={{
            bgcolor: stringToColor(
              `${stakeholder.first_name} ${stakeholder.last_name}`
            ),
          }}
        >
          {`${stakeholder.first_name.slice(0, 1)}${stakeholder.last_name.slice(
            0,
            1
          )}`}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`${stakeholder.first_name} ${stakeholder.last_name}`}
        secondary={stakeholder.email}
      />
      {matches && (
        <ListItemText
          sx={{
            display: "flex",
            justifyContent: "right",
            color: "text.secondary",
          }}
        >
          {stakeholder.role}
        </ListItemText>
      )}
    </>
  );
};

interface IItemProps {
  projectOwnerId: number;
  stakeholder: IStakeholder;
}

const Item: React.FC<IItemProps> = ({ projectOwnerId, stakeholder }) => {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();

  const isProjectOwner = projectOwnerId === stakeholder.user;
  const userPermissions: IStakeholderPermission[] = useAppSelector(
    getProjectPermissions
  );

  return (
    <ListItem
      disablePadding
      secondaryAction={
        !isProjectOwner &&
        hasProjectPerm(userPermissions, [
          projectPermissions.stakeholder.maintainer,
        ]) && (
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => {
              projectId !== undefined &&
                dispatch(deleteStakeholder(+projectId, stakeholder.id));
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )
      }
    >
      {isProjectOwner ||
      !hasProjectPerm(userPermissions, [
        projectPermissions.stakeholder.maintainer,
      ]) ? (
        <Box
          sx={{
            p: "8px 48px 8px 16px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <StyledItem stakeholder={stakeholder} />
        </Box>
      ) : (
        <ListItemButton
          onClick={() => {
            dispatch(handleUpdateStakeholderDialogOpen());
            dispatch(selectStakeholder(stakeholder));
          }}
        >
          <StyledItem stakeholder={stakeholder} />
        </ListItemButton>
      )}
    </ListItem>
  );
};

export default Item;
