import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import {
  resendActivationEmail,
  signUpUser,
} from "store/entities/authentication/authentication.actions";
import { useAppDispatch } from "store";
import {
  getAuthenticationUrl,
  getSignUpConfirmationUrl,
  getSignInUrl,
  getSignUpUrl,
} from "assets/endpoints/app/authEndpoints";

export default function SignUp() {
  const { t } = useTranslation("authentication", { keyPrefix: "sign_up" });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState({
    current_password: false,
    new_password: false,
  });
  const [resendActivationTo, setResendActivationTo] = useState<string | null>(
    null
  );

  const handleClickShowPassword = (key: keyof typeof showPassword) => {
    setShowPassword({ ...showPassword, [key]: !showPassword[key] });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    re_password: "",
  });

  const validationSchema = yup.object({
    first_name: yup.string(),
    last_name: yup.string(),
    email: yup
      .string()
      .email()
      .required(t("required") as string),
    password: yup
      .string()
      .required(t("required") as string)
      .min(
        8,
        t("password_must_contain_at_least", {
          min_number: 8,
        }) as string
      )
      .matches(
        /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{6,20}$/,
        t("password_must_contain_number_and_letter") as string
      ),
    re_password: yup
      .string()
      .required(t("required") as string)
      .oneOf([yup.ref("password")], t("passwords_must_match") as string),
  });

  const signUpForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      dispatch(signUpUser(values));
      setResendActivationTo(signUpForm.values.email);
      signUpForm.resetForm();
    },
  });

  return (
    <Box>
      <Typography component="h1" variant="h5" textAlign={"center"}>
        {t("sign_up")}
      </Typography>
      {/* <Box
        component='form'
        id='sign-up-form'
        noValidate
        onSubmit={signUpForm.handleSubmit}
        sx={{ mt: 3 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id='first_name'
              name='first_name'
              label={t('first_name')}
              value={signUpForm.values.first_name}
              onBlur={signUpForm.handleBlur}
              error={
                signUpForm.touched.first_name &&
                Boolean(signUpForm.errors.first_name)
              }
              helperText={
                signUpForm.touched.first_name && signUpForm.errors.first_name
              }
              onChange={signUpForm.handleChange}
              fullWidth
              autoComplete='first_name'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='last_name'
              name='last_name'
              label={t('last_name')}
              value={signUpForm.values.last_name}
              onBlur={signUpForm.handleBlur}
              error={
                signUpForm.touched.last_name &&
                Boolean(signUpForm.errors.last_name)
              }
              helperText={
                signUpForm.touched.last_name && signUpForm.errors.last_name
              }
              onChange={signUpForm.handleChange}
              fullWidth
              autoComplete='last_name'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='email'
              name='email'
              label={t('email')}
              value={signUpForm.values.email}
              onBlur={signUpForm.handleBlur}
              error={
                signUpForm.touched.email && Boolean(signUpForm.errors.email)
              }
              helperText={signUpForm.touched.email && signUpForm.errors.email}
              onChange={signUpForm.handleChange}
              fullWidth
              autoComplete='username'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='password'
              name='password'
              label={t('password')}
              value={signUpForm.values.password}
              onBlur={signUpForm.handleBlur}
              error={
                signUpForm.touched.password &&
                Boolean(signUpForm.errors.password)
              }
              helperText={
                signUpForm.touched.password && signUpForm.errors.password
              }
              onChange={signUpForm.handleChange}
              fullWidth
              autoComplete='password'
              variant='outlined'
              type={showPassword.current_password ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle current password visibility'
                      onClick={() =>
                        handleClickShowPassword('current_password')
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword.current_password ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='re_password'
              name='re_password'
              label={t('re_password')}
              value={signUpForm.values.re_password}
              onBlur={signUpForm.handleBlur}
              error={
                signUpForm.touched.re_password &&
                Boolean(signUpForm.errors.re_password)
              }
              helperText={
                signUpForm.touched.re_password && signUpForm.errors.re_password
              }
              onChange={signUpForm.handleChange}
              fullWidth
              autoComplete='re_password'
              variant='outlined'
              type={showPassword.current_password ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle current password visibility'
                      onClick={() =>
                        handleClickShowPassword('current_password')
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword.current_password ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type='submit'
              fullWidth
              form='sign-up-form'
              variant='contained'
            >
              {t('sign_up')}
            </Button>
          </Grid>
          <Grid item xs={12} display='flex' justifyContent='space-between'>
            <Link
              component={RouterLink}
              to={getAuthenticationUrl() + getSignInUrl()}
              variant='body2'
            >
              {t('sign_in')}
            </Link>
            <Link
              variant='body2'
              onClick={() => {
                if (resendActivationTo !== null) {
                  dispatch(resendActivationEmail(resendActivationTo))
                }
              }}
            >
              {t('resend_activation')}
            </Link>
          </Grid>
        </Grid>
      </Box> */}
    </Box>
  );
}
