import React from "react";
import { useTranslation } from "react-i18next";
import { menu } from "assets/menu";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TreeView from "@mui/lab/TreeView";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Logout from "@mui/icons-material/LogoutOutlined";
import LoginIcon from "@mui/icons-material/LoginOutlined";
import Divider from "@mui/material/Divider";
import PersonAddIcon from "@mui/icons-material/PersonAddOutlined";
import Typography from "@mui/material/Typography";
import StyledTreeItem from "./item/treeItem";
import { useDispatch } from "react-redux";
import {
  changeNavigationTitle,
  closeSidebar,
} from "store/ui/navigation/navigation.slice";
import { useAppSelector } from "store";
import { getDesign } from "store/entities/settings/settings.slice";
import {
  getAuthenticationUrl,
  getSignInUrl,
  getSignOutUrl,
  getSignUpUrl,
} from "assets/endpoints/app/authEndpoints";
import { getSettingsUrl } from "assets/endpoints/app/settingsEndpoints";
import { getUser } from "store/entities/authentication/authentication.slice";
import { Toolbar } from "@mui/material";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("navigation", { keyPrefix: "sidebar" });

  const theme = useAppSelector(getDesign).colorTheme;
  const user = useAppSelector(getUser);

  const onSidebarClick = (event: any, featureName: string) => {
    dispatch(closeSidebar());
    dispatch(changeNavigationTitle(featureName));
  };

  const renderTree = menu.map((nodes) => {
    return (
      <StyledTreeItem
        key={nodes.id}
        nodeId={`${nodes.id}`}
        labelText={t(nodes.name)}
        link={nodes.url}
        labelIcon={nodes.icon}
        onClick={(event) => onSidebarClick(event, nodes.name)}
        bgColor="primary"
      ></StyledTreeItem>
    );
  });

  return (
    <>
      <Toolbar>
        <Stack
          sx={{
            py: 1.5,
            pr: 1.5,
            pl: 2.5,
            display: "flex",
            alignItems: "center",
          }}
          direction="row"
          spacing={2}
        >
          <Box
            component="img"
            src={
              theme === "dark"
                ? process.env.PUBLIC_URL + "/img/brand/logos/btc_logo_white.png"
                : process.env.PUBLIC_URL + "/img/brand/logos/btc_logo_blue.png"
            }
            alt="Logo"
            sx={{ height: "25px" }}
          />
          <Typography
            variant="h6"
            fontWeight="bold"
            display="flex"
            color="primary"
            noWrap
          >
            {process.env.REACT_APP_NAME}
          </Typography>
        </Stack>
      </Toolbar>
      <Divider />
      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        sx={{ height: 264, flexGrow: 1, overflowY: "auto" }}
      >
        {renderTree}
        {user.loggedIn ? (
          <StyledTreeItem
            nodeId="11"
            labelText={t("sign_out")}
            labelIcon={Logout}
            link={getAuthenticationUrl() + getSignOutUrl()}
            onClick={(event) => onSidebarClick(event, "sign_out")}
          />
        ) : (
          <>
            <StyledTreeItem
              nodeId="12"
              labelText={t("sign_in")}
              labelIcon={LoginIcon}
              link={getAuthenticationUrl() + getSignInUrl()}
              onClick={(event) => onSidebarClick(event, "sign_in")}
            />
            <StyledTreeItem
              nodeId="13"
              labelText={t("sign_up")}
              labelIcon={PersonAddIcon}
              link={getAuthenticationUrl() + getSignUpUrl()}
              onClick={(event) => onSidebarClick(event, "sign_up")}
            />
          </>
        )}
      </TreeView>
    </>
  );
};

export default Sidebar;
