import React from "react";
import ReactDOMServer from "react-dom/server";

import ElementImage from "common/components/elementImage/image";
import { svgToPng } from "common/utils/helpers/svgToPng.helpers";

const getImageDimensionsFromSvgViewBox = (svgString: string) => {
  let viewbox;
  const viewboxString = svgString.match(/viewBox="([^"]+)"/);
  if (viewboxString) {
    viewbox = viewboxString[1];
    const viewboxDimensions = viewbox.split(/(\s+)/);
    return {
      x: parseFloat(viewboxDimensions[0]),
      y: parseFloat(viewboxDimensions[2]),
      width: parseFloat(viewboxDimensions[4]),
      height: parseFloat(viewboxDimensions[6]),
    };
  }
};

export default async function image(
  doc: any,
  layout: any,
  startY: number,
  lineSpacing: number,
  element: any
) {
  const getImage: any = async () => {
    const svgString = ReactDOMServer.renderToStaticMarkup(
      <ElementImage element={element} minimalTheme={false} />
    );
    let img;

    const dimensions = getImageDimensionsFromSvgViewBox(svgString);
    if (dimensions !== undefined) {
      const widthRatio = layout.ContentWidth / dimensions.width;
      const heightRatio = layout.ContentWidth / 2 / dimensions.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = dimensions.width * ratio;
      const canvasHeight = dimensions.height * ratio;

      await svgToPng(svgString, 0).then(function (data) {
        img = data;
      });
      return {
        src: img as unknown as string,
        width: canvasWidth,
        height: canvasHeight,
      };
    }
  };

  let img = await getImage();

  doc.addImage(
    img.src,
    "PNG",
    layout.StartX,
    startY,
    img.width,
    img.height,
    `image${element.id}`,
    "NONE"
  );

  startY += lineSpacing / 2 + layout.ContentWidth / 2;

  return startY;
}
