import React, { useState } from "react";
import { useTheme } from '@mui/material/styles'
import Box from "@mui/material/Box";
import Navigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Slide from "@mui/material/Slide";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { menu } from "assets/menu";
import { useDispatch } from "react-redux";
import { changeNavigationTitle } from "store/ui/navigation/navigation.slice";

export default function BottomNavigation() {
  const { t } = useTranslation("navigation", { keyPrefix: "sidebar" });
  const theme = useTheme()
  const dispatch = useDispatch();
  const trigger = useScrollTrigger();
  const [value, setValue] = useState(1);

  return (
    <Box
      sx={{
        display: { sm: "none" },
      }}
    >
      <Slide appear={false} direction="up" in={!trigger}>
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
          }}
          elevation={3}
        >
          <Navigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            sx={{ height: theme.bottomNavigation.appbar.height }}
          >
            {menu.map((item) => (
              <BottomNavigationAction
                color="inherit"
                key={item.id}
                label={t(item.name)}
                icon={<item.icon />}
                value={item.id}
                component={RouterLink}
                to={`${item.url}`}
                onClick={() => dispatch(changeNavigationTitle(item.name))}
              />
            ))}
          </Navigation>
        </Paper>
      </Slide>
    </Box>
  );
}
