import React from 'react'
import { TFunction } from 'i18next'

export default function titlePage(
  project: any,
  doc: any,
  layout: any,
  fontSizes: any,
  lineSpacing: number,
  t: TFunction<'component_catalogue', 'pdf_export'>
) {
  let startY = layout.PageHeight / 4
  let startX = layout.StartX
  let tablecol1X = layout.ContentWidth / 4 + startX
  let endY = layout.EndY
  let endX = layout.EndX

  doc.setFontSize(28, 'bold')
  doc.text(t('pdf_export.catalog_of_components'), tablecol1X, startY)

  startY += lineSpacing * 2
  doc.setFontSize(fontSizes.TitleFontSize, 'bold')
  doc.text(`${t('pdf_export.status')}:`, startX, startY, { align: 'left' })
  doc.text(project.status, tablecol1X, startY)

  startY += lineSpacing
  doc.setFontSize(fontSizes.SubTitleFontSize, 'normal')
  doc.text(`${t('pdf_export.construction_project')}:`, startX, startY, {
    align: 'left',
  })
  doc.text(project.construction_site.street, tablecol1X, startY)
  startY += lineSpacing * 0.5
  doc.text(
    `${project.construction_site.postal_code} ${project.construction_site.city}`,
    tablecol1X,
    startY
  )
  startY += lineSpacing * 0.5
  doc.text(project.construction_site.country, tablecol1X, startY)
  startY += lineSpacing * 0.5

  startY = endY - 40
  doc.text(`${t('pdf_export.date')}:`, startX, startY, { align: 'left' })
  doc.text('Wed Jul 27', tablecol1X, startY)
  startY += lineSpacing * 0.5

  doc.text(`${t('pdf_export.project_no')}:`, startX, startY, { align: 'left' })
  doc.text(project.id.toString(), tablecol1X, startY)

  startY = endY
  doc.setFontSize(fontSizes.SmallFontSize, 'normal')
  doc.setTextColor(102, 102, 102)
  doc.text(t('pdf_export.generated_by_bauteil_cloud'), endX, startY, {
    align: 'right',
  })
  doc.setFontSize(fontSizes.NormalFontSize, 'normal')
  doc.setTextColor(0, 0, 0)
}
