import { createSlice } from "@reduxjs/toolkit";

type SliceState = {
  api: {
    submitting: boolean;
    loading: boolean;
    lastFetch: number | null;
  };
  loggedIn: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  persistToken: string | null;
  permissions: {
    groups: string[];
    projects: string[];
  };
  me: any;
};

const initialState: SliceState = {
  api: {
    submitting: false,
    loading: false,
    lastFetch: null,
  },
  loggedIn: false,
  accessToken: null,
  refreshToken: null,
  persistToken: null,
  permissions: {
    groups: [],
    projects: [],
  },
  me: {
    id: null,
    profile: { notifications: [] },
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userCreationPending: (user) => {
      user.api.submitting = true;
    },
    userCreationFulfilled: (user) => {
      user.api.submitting = false;
    },
    userCreationRejected: (user) => {
      user.api.submitting = false;
    },
    resendActivationEmailPending: (user) => {
      user.api.submitting = true;
    },
    resendActivationEmailFulfilled: (user) => {
      user.api.submitting = false;
    },
    resendActivationEmailRejected: (user) => {
      user.api.submitting = false;
    },
    authenticationPending: (user) => {
      user.api.submitting = true;
    },
    authenticationFulfilled: (user, action) => {
      user.api.submitting = false;
      user.accessToken = action.payload.access;
      user.refreshToken = action.payload.refresh;
      localStorage.setItem("loggedIn", "true");
      localStorage.setItem("refreshToken", action.payload.refresh);
      localStorage.setItem("accessToken", action.payload.access);
    },
    authenticationRejected: (user) => {
      user.api.submitting = false;
    },
    signInPending: (user) => {
      user.api.loading = true;
    },
    signInFulfilled: (user, action) => {
      user.loggedIn = true;
      user.me = action.payload;
      user.api.loading = false;
      user.api.lastFetch = Date.now();
    },
    signInRejected: (user) => {
      user.api.loading = false;
    },
    signedOut: (user) => {
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      return initialState;
    },
    userFetchFulfilled: (user, action) => {
      user.loggedIn = true;
      user.me = action.payload;
      user.api.loading = false;
      user.api.lastFetch = Date.now();
    },
    userUpdatePending: (user) => {
      user.api.submitting = true;
    },
    userUpdateFulfilled: (user, action) => {
      user.me = action.payload;
      user.api.submitting = false;
      user.api.lastFetch = Date.now();
    },
    userUpdateRejected: (user) => {
      user.api.submitting = false;
    },
    accountUpdatePending: (user) => {
      user.api.submitting = true;
    },
    accountUpdateFulfilled: (user, action) => {
      user.me.account = action.payload;
      user.api.submitting = false;
      user.api.lastFetch = Date.now();
    },
    accountUpdateRejected: (user) => {
      user.api.submitting = false;
    },
    profileUpdatePending: (user) => {
      user.api.submitting = true;
    },
    profileUpdateFulfilled: (user, action) => {
      user.me.profile = action.payload;
      user.api.submitting = false;
      user.api.lastFetch = Date.now();
    },
    passwordResetPending: (user) => {
      user.api.submitting = true;
    },
    passwordResetFulfilled: (user) => {
      user.api.submitting = false;
    },
    passwordResetRejected: (user) => {
      user.api.submitting = false;
    },
    profileUpdateRejected: (user) => {
      user.api.submitting = false;
    },
    passwordUpdatePending: (user) => {
      user.api.submitting = true;
    },
    passwordUpdateFulfilled: (user) => {
      user.api.submitting = false;
    },
    passwordUpdateRejected: (user) => {
      user.api.submitting = false;
    },
    emailUpdatePending: (user) => {
      user.api.submitting = true;
    },
    emailUpdateFulfilled: (user, action) => {
      user.api.submitting = false;
      user.me.email = action.payload;
    },
    emailUpdateRejected: (user) => {
      user.api.submitting = false;
    },
    usernameUpdatePending: (user) => {
      user.api.submitting = true;
    },
    usernameUpdateFulfilled: (user, action) => {
      user.api.submitting = false;
      user.me.username = action.payload;
    },
    usernameUpdateRejected: (user) => {
      user.api.submitting = false;
    },
    notificationsUpdatePending: (user) => {
      user.api.submitting = true;
    },
    notificationsUpdateFulfilled: (user, action) => {
      user.api.submitting = false;
      const notificationIndex = user.me.profile.notifications.findIndex(
        (notification: any) => notification.id === action.payload
      );
      user.me.profile.notifications[notificationIndex].marked_as_read = true;
      user.me.profile.notifications = user.me.profile.notifications.sort(
        function (a: any, b: any) {
          return (
            b["marked_as_read"] - a["marked_as_read"] ||
            a["notified_on"] - b["notified_on"]
          );
        }
      );
    },
    notificationsUpdateRejected: (user) => {
      user.api.submitting = false;
    },
    notificationsDeletePending: (user) => {
      user.api.submitting = true;
    },
    notificationsDeleteFulfilled: (user, action) => {
      user.api.submitting = false;
      user.me.profile.notifications = user.me.profile.notifications.filter(
        (notification: any) => notification.id !== action.payload
      );
    },
    notificationsDeleteRejected: (user) => {
      user.api.submitting = false;
    },
  },
});

export const {
  userCreationPending,
  userCreationFulfilled,
  userCreationRejected,
  resendActivationEmailPending,
  resendActivationEmailFulfilled,
  resendActivationEmailRejected,
  authenticationPending,
  authenticationFulfilled,
  authenticationRejected,
  signInPending,
  signInFulfilled,
  signInRejected,
  signedOut,
  userUpdatePending,
  userUpdateFulfilled,
  userUpdateRejected,
  accountUpdatePending,
  accountUpdateFulfilled,
  accountUpdateRejected,
  profileUpdatePending,
  profileUpdateFulfilled,
  profileUpdateRejected,
  passwordResetPending,
  passwordResetFulfilled,
  passwordResetRejected,
  passwordUpdatePending,
  passwordUpdateFulfilled,
  passwordUpdateRejected,
  emailUpdatePending,
  emailUpdateFulfilled,
  emailUpdateRejected,
  usernameUpdatePending,
  usernameUpdateFulfilled,
  usernameUpdateRejected,
  notificationsUpdatePending,
  notificationsUpdateFulfilled,
  notificationsUpdateRejected,
  notificationsDeletePending,
  notificationsDeleteFulfilled,
  notificationsDeleteRejected,
} = userSlice.actions;
export default userSlice.reducer;

export const getApi = (state: any) => state.auth.user.api;
export const getUser = (state: any) => state.auth.user;
export const getMyUserDetails = (state: any) => state.auth.user.me;
export const getMemberships = (state: any) => state.auth.user.me.memberships;
