import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { getSelectedProject } from "store/entities/projects/project.slice";
import { getLocales } from "store/entities/settings/settings.slice";
import { useAppDispatch, useAppSelector } from "store";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { fetchProjectStatusList, getProjectStatusList } from "store/entities/projects/projects.slice";
import { IProjectStatus } from "types";

const Project: React.FC = () => {
  const { t } = useTranslation("dashboard", {
    keyPrefix: "project",
  });
  const dispatch = useAppDispatch();

  const project = useAppSelector(getSelectedProject);
  const projectStatusOptions = useAppSelector(getProjectStatusList);

  const locales = useAppSelector(getLocales);
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  useEffect(() => {
    dispatch(fetchProjectStatusList());
  }, [locales]);

  return (
    <Grid
      container
      columnSpacing={{ xs: 1, sm: 4, md: 8 }}
      rowSpacing={{ xs: 1, sm: 2, md: 2 }}
    >
      <Grid item xs={12} textAlign="center">
        <InfoOutlinedIcon color="primary" />
        <Typography fontWeight="bold">{t("common")}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {`${t("name")}:`}
        </Typography>
        <Typography variant="body2" color="text.primary">
          {project.name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {`${t("status")}:`}
        </Typography>
        <Typography variant="body2" color="text.primary">
          {
            projectStatusOptions.find(
              (status: IProjectStatus) => status.value === project.status
            )?.label
          }
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {`${t("created_on")}:`}
        </Typography>
        <Typography variant="body2" color="text.primary">
          {`${new Date(project.create_time).toLocaleDateString(
            locales.code,
            dateOptions
          )}`}
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {`${t("updated_on")}:`}
        </Typography>
        <Typography variant="body2" color="text.primary">
          {`${new Date(project.last_change).toLocaleDateString(
            locales.code,
            dateOptions
          )}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Project;
