import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useAppSelector } from "store";
import { getConstructionMaterials } from "store/entities/projects/componentCatalogue/componentCatalogue.slice";
import { IConstructionMaterial, IIfcModelCreation, IResponseOptimized } from "types";

interface IModelResultTabProps {
    response_optimized: IResponseOptimized;
}

const ModelResultTab: React.FC<IModelResultTabProps> = ({ response_optimized }) => {
    const constructionMaterials: IConstructionMaterial[] = useAppSelector(getConstructionMaterials);

    const responseWallLayersMaterialSavings: { [key: number]: { "vNeu": number, "vSrc": number } } = {};

    response_optimized.walls.forEach((wall) => {
        wall.layers.forEach((layer) => {
            if (layer.construction_material) {
                const materialId = layer.construction_material;
                const vNeu = layer.v_neu;
                const vSrc = layer.v_src;

                if (responseWallLayersMaterialSavings[materialId]) {
                    responseWallLayersMaterialSavings[materialId].vNeu += vNeu;
                    responseWallLayersMaterialSavings[materialId].vSrc += vSrc;
                } else {
                    responseWallLayersMaterialSavings[materialId] = { vNeu, vSrc };
                }
            }
        });
    });

    const constructionMaterialSavings = constructionMaterials.map((material) => {
        return {
            ...material,
            savings: responseWallLayersMaterialSavings[material.id] || { vNeu: 0, vSrc: 0 }
        };
    });

    const getPercentageChange = (vSrc: number, vNeu: number) => {
        if (vSrc === 0) return 0;
        return ((vSrc - vNeu) / vSrc) * 100;
    };

    const totalSavings = constructionMaterialSavings.reduce(
        (acc, material) => {
            acc.vSrc += material.savings.vSrc;
            acc.vNeu += material.savings.vNeu;
            return acc;
        },
        { vSrc: 0, vNeu: 0 }
    );

    const overallPercentageChange = getPercentageChange(totalSavings.vSrc, totalSavings.vNeu);

    const gwpSavingsPerMaterial = constructionMaterialSavings.map(material => {
        const volumeSavings = material.savings.vSrc - material.savings.vNeu;
        const gwpTotal = material.meta_information?.environmental_properties?.gwp_total || 0;
        const gwpSavings = volumeSavings * gwpTotal;
        return {
            ...material,
            gwpSavings,
            gwpPercentageChange: getPercentageChange(material.savings.vSrc * gwpTotal, material.savings.vNeu * gwpTotal)
        };
    });

    const overallGwpSavings = gwpSavingsPerMaterial.reduce((acc, material) => acc + material.gwpSavings, 0);
    const overallGwpSrc = totalSavings.vSrc * (constructionMaterials.reduce((acc, material) => acc + (material.meta_information?.environmental_properties?.gwp_total || 0), 0) / constructionMaterials.length);
    const overallGwpNeu = totalSavings.vNeu * (constructionMaterials.reduce((acc, material) => acc + (material.meta_information?.environmental_properties?.gwp_total || 0), 0) / constructionMaterials.length);
    const overallGwpPercentageChange = getPercentageChange(overallGwpSrc, overallGwpNeu);

    return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ mr: 10 }}>
                <Box sx={{ mb: 2 }}>
                    <Typography variant="h6">Overall material savings</Typography>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                            <Typography variant="body1" color="GrayText">Total</Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                            <Typography variant="body1">{totalSavings.vSrc - totalSavings.vNeu} m3</Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography
                                variant="body1"
                                color={overallPercentageChange >= 0 ? "green" : "red"}
                            >
                                {overallPercentageChange >= 0 ? '↓' : '↑'} {Math.abs(overallPercentageChange).toFixed(2)}%
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ mb: 2 }}>
                    <Typography variant="h6">Detailed savings per material</Typography>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                            {constructionMaterialSavings.map((material) => (
                                <Typography key={`${material.id}-name`} variant="body1" color="GrayText">{material.name}</Typography>
                            ))}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                            {constructionMaterialSavings.map((material) => (
                                <Typography key={`${material.id}-savings`} variant="body1">{material.savings.vSrc - material.savings.vNeu} m3</Typography>
                            ))}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            {constructionMaterialSavings.map((material) => {
                                const percentageChange = getPercentageChange(material.savings.vSrc, material.savings.vNeu);
                                const isPositive = percentageChange >= 0;
                                return (
                                    <Typography
                                        key={`${material.id}-percentage`}
                                        variant="body1"
                                        color={isPositive ? "green" : "red"}
                                    >
                                        {isPositive ? '↓' : '↑'} {Math.abs(percentageChange).toFixed(2)}%
                                    </Typography>
                                );
                            })}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Box sx={{ mb: 2 }}>
                    <Typography variant="h6">Overall GWP savings</Typography>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                            <Typography variant="body1" color="GrayText">Total</Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                            <Typography variant="body1">{overallGwpSavings.toFixed(2)} kg CO2 Äq</Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography
                                variant="body1"
                                color={overallGwpPercentageChange >= 0 ? "green" : "red"}
                            >
                                {overallGwpPercentageChange >= 0 ? '↓' : '↑'} {Math.abs(overallGwpPercentageChange).toFixed(2)}%
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ mb: 2 }}>
                    <Typography variant="h6">Detailed GWP savings per material</Typography>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                            {gwpSavingsPerMaterial.map((material) => (
                                <Typography key={`${material.id}-gwp-name`} variant="body1" color="GrayText">{material.name}</Typography>
                            ))}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                            {gwpSavingsPerMaterial.map((material) => (
                                <Typography key={`${material.id}-gwp-savings`} variant="body1">{material.gwpSavings.toFixed(2)} kg CO2 Äq</Typography>
                            ))}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            {gwpSavingsPerMaterial.map((material) => {
                                const isPositive = material.gwpPercentageChange >= 0;
                                return (
                                    <Typography
                                        key={`${material.id}-gwp-percentage`}
                                        variant="body1"
                                        color={isPositive ? "green" : "red"}
                                    >
                                        {isPositive ? '↓' : '↑'} {Math.abs(material.gwpPercentageChange).toFixed(2)}%
                                    </Typography>
                                );
                            })}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default ModelResultTab;
