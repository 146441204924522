import { createSlice } from "@reduxjs/toolkit";
import type { IConstructionMaterial, IElement } from "types";

type SliceState = {
  elements: any[];
  options: {
    categories: { id: number; name: string }[];
    constructionMaterials: IConstructionMaterial[];
    constructionSections: any[];
  };
  loading: boolean;
  lastFetch: null | number;
};

const initialState: SliceState = {
  elements: [],
  options: {
    categories: [],
    constructionMaterials: [],
    constructionSections: [],
  },
  loading: false,
  lastFetch: null,
};

const componentCatalogueSlice = createSlice({
  name: "componentCatalogue",
  initialState,
  reducers: {
    elementsRequested: (componentCatalogue) => {
      componentCatalogue.loading = true;
    },
    elementsReceived: (componentCatalogue, action) => {
      componentCatalogue.elements = action.payload;
      componentCatalogue.lastFetch = Date.now();
      componentCatalogue.loading = false;
    },
    elementsRequestFailed: (componentCatalogue) => {
      componentCatalogue.loading = false;
    },
    categoriesRequested: () => { },
    categoriesReceived: (componentCatalogue, action) => {
      componentCatalogue.options.categories = action.payload;
    },
    categoriesRequestFailed: () => { },
    constructionMaterialsRequested: () => { },
    constructionMaterialsReceived: (componentCatalogue, action) => {
      componentCatalogue.options.constructionMaterials = action.payload;
    },
    constructionMaterialsRequestFailed: () => { },
    constructionSectionsRequested: () => { },
    constructionSectionsReceived: (componentCatalogue, action) => {
      componentCatalogue.options.constructionSections = action.payload;
    },
    constructionSectionsRequestFailed: () => { },
    elementCreated: (componentCatalogue, action) => {
      componentCatalogue.elements.push(action.payload);
    },
    elementUpdated: (componentCatalogue, action) => {
      const elementIndex = componentCatalogue.elements.findIndex(
        (element: any) => element.id === action.payload.id
      );
      Object.assign(componentCatalogue.elements[elementIndex], action.payload);
    },
    elementDeleted: (componentCatalogue, action) => {
      const elementIndex = componentCatalogue.elements.findIndex(
        (element: any) => element.id === action.payload
      );
      componentCatalogue.elements.splice(elementIndex, 1);
    },
    layerCreated: (componentCatalogue, action) => {
      const elementIndex = componentCatalogue.elements.findIndex(
        (element: IElement) => element.id === action.payload.id
      );
      componentCatalogue.elements[elementIndex] = action.payload;
    },
    layerUpdated: (componentCatalogue, action) => {
      const elementIndex = componentCatalogue.elements.findIndex(
        (element: IElement) => element.id === action.payload.id
      );
      componentCatalogue.elements[elementIndex] = action.payload;
    },
    layerDeleted: (componentCatalogue, action) => {
      const elementIndex = componentCatalogue.elements.findIndex(
        (element: IElement) => element.id === action.payload.id
      );
      componentCatalogue.elements[elementIndex] = action.payload;
    },
    layerFillingCreated: (componentCatalogue, action) => {
      const elementIndex = componentCatalogue.elements.findIndex(
        (element: IElement) => element.id === action.payload.id
      );
      componentCatalogue.elements[elementIndex] = action.payload;
    },
    layerFillingUpdated: (componentCatalogue, action) => {
      const elementIndex = componentCatalogue.elements.findIndex(
        (element: IElement) => element.id === action.payload.id
      );
      componentCatalogue.elements[elementIndex] = action.payload;
    },
    layerFillingDeleted: (componentCatalogue, action) => {
      const elementIndex = componentCatalogue.elements.findIndex(
        (element: IElement) => element.id === action.payload.id
      );
      componentCatalogue.elements[elementIndex] = action.payload;
    },
    layerReordered: (componentCatalogue, action) => {
      const element = action.payload.element;
      const elementIndex = componentCatalogue.elements.findIndex(
        (item: any) => item.id === element.id
      );
      const startIndex = action.payload.startIndex;
      const endIndex = action.payload.endIndex;

      const reorder_layer = (layersToReorder: any, startIndex: number, endIndex: number) => {
        const layersPositionUpdated = Array.from(layersToReorder);
        const [removedLayer] = layersPositionUpdated.splice(startIndex, 1);
        layersPositionUpdated.splice(endIndex, 0, removedLayer);
        const layersPostionKeyUpdated = layersPositionUpdated.map((layer, pos) => ({ ...(layer as object), position: pos + 1 }))
        return layersPostionKeyUpdated;
      };

      const newLayers: any[] = reorder_layer(element.layers, startIndex, endIndex);
      componentCatalogue.elements[elementIndex].layers = newLayers;
    },
  },
});

export const {
  elementsRequested,
  elementsReceived,
  elementsRequestFailed,
  categoriesRequested,
  categoriesReceived,
  categoriesRequestFailed,
  constructionMaterialsRequested,
  constructionMaterialsReceived,
  constructionMaterialsRequestFailed,
  constructionSectionsRequested,
  constructionSectionsReceived,
  constructionSectionsRequestFailed,
  elementCreated,
  elementUpdated,
  elementDeleted,
  layerCreated,
  layerUpdated,
  layerDeleted,
  layerReordered,
  layerFillingCreated,
  layerFillingUpdated,
  layerFillingDeleted
} = componentCatalogueSlice.actions;

export default componentCatalogueSlice.reducer;

export const getConstructionElements = (state: any) =>
  state.entities.projects.selectedProject.componentCatalogue.elements;

export const getConstructionLayers = (state: any, elementIndex: number) =>
  state.entities.projects.selectedProject.componentCatalogue.elements[
    elementIndex
  ].layers;

export const getConstructionMaterials = (state: any) =>
  state.entities.projects.selectedProject.componentCatalogue.options.constructionMaterials;

export const getCategoryChoices = (state: any) =>
  state.entities.projects.selectedProject.componentCatalogue.options.categories;

export const getConstructionSectionChoices = (state: any) =>
  state.entities.projects.selectedProject.componentCatalogue.options
    .constructionSections;
