import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { ICategoryChoice, IElement, ILayer, ILayerFilling } from "types";
import { RootState } from "store/rootReducer";

function groupByCategory(objectArray: IElement[]) {
  return objectArray.reduce(function (acc: any, obj: IElement) {
    let key = obj.category;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

type SliceState = {
  dialogs: {
    element: { update: boolean; create: boolean; delete: boolean, selection: boolean; };
    layers: { retrieve: boolean; create: boolean; update: boolean };
    layerFillings: { create: { open: boolean, type: "stud" | "layer" }; update: { open: boolean, type: "stud" | "layer" } };
    print: { open: boolean };
  };
  actions: { show: boolean };
  filteredList: any;
  selectedElement: IElement;
  selectedElements: IElement[]
  selectedLayer: ILayer;
  selectedLayerFilling: ILayerFilling;
  image: { ref: any };
  personalLibrary: {
    projectId: null | number;
    returnProjectId: null | number;
    open: boolean;
    actions: boolean;
    dialogs: {
      selection: boolean;
      create: boolean;
    };
  };
};

const initialState: SliceState = {
  dialogs: {
    element: { update: false, create: false, delete: false, selection: false },
    layers: { retrieve: false, create: false, update: false },
    layerFillings: { create: { open: false, type: "layer" }, update: { open: false, type: "layer" } },
    print: { open: false },
  },
  actions: { show: true },
  filteredList: {},
  selectedElement: {
    id: 0,
    identifier: "",
    name: "",
    description: "",
    category: "interior_wall",
    requirements: {
      fire_protection: "",
      direct_sound_reduction_index: 0,
      sound_reduction_index: 0,
      weighted_normalized_impact_sound_pressure_level: 0,
      normalized_impact_sound_pressure_level: 0,
    },
    performance: {
      total_width: 0,
      area_load: 0,
      thermal_transmittance: 0,
      fire_protection: "",
      direct_sound_reduction_index: 0,
      sound_reduction_index: 0,
      weighted_normalized_impact_sound_pressure_level: 0,
      normalized_impact_sound_pressure_level: 0,
    },
    layers: [],
  },
  selectedElements: [],
  selectedLayer: {
    id: 0,
    position: 0,
    name: "",
    construction_section: "wall_construction",
    layer_fillings: []
  },
  selectedLayerFilling: {
    id: 0,
    category: "layer",
    thickness: 0,
    construction_material: 0,
    additional_material_width: 0,
    additional_material_clearance: 0,
  },
  image: { ref: null },
  personalLibrary: {
    projectId: null,
    returnProjectId: null,
    open: false,
    actions: false,
    dialogs: {
      selection: false,
      create: false,
    },
  },
};

const componentCatalogueSlice = createSlice({
  name: "componentCatalogue",
  initialState,
  reducers: {
    listFiltered: (
      componentCatalogue,
      action: PayloadAction<{
        list: IElement[];
        categoryChoices: ICategoryChoice[];
      }>
    ) => {
      let list: IElement[] = action.payload.list;
      // const categories = action.payload.categoryChoices
      // const updatedList = list.map((element) => {
      //   const updatedCategory = categories.find(
      //     (category) => category.value === element.category
      //   )?.label
      //   const category: IElement['category'] =
      //     updatedCategory as IElement['category']
      //   return { ...element, category }
      // })
      const elementsByCategory = groupByCategory(list);
      componentCatalogue.filteredList = elementsByCategory;
    },
    elementSelected: (componentCatalogue, action) => {
      const elementIndex = componentCatalogue.selectedElements.findIndex(
        (element: IElement) => element.id === action.payload.id
      );
      if (elementIndex === -1) {
        componentCatalogue.selectedElements[
          componentCatalogue.selectedElements.length
        ] = action.payload;
      } else {
        componentCatalogue.selectedElements.splice(elementIndex, 1);
      };
      componentCatalogue.selectedElement = action.payload;

      const selectedLayer = componentCatalogue.selectedElement.layers.find((layer: ILayer) => layer.id === componentCatalogue.selectedLayer.id);
      componentCatalogue.selectedLayer = selectedLayer !== undefined ? selectedLayer : componentCatalogue.selectedLayer;
    },
    layerSelected: (componentCatalogue, action) => {
      componentCatalogue.selectedLayer = action.payload;
    },
    layerFillingSelected: (componentCatalogue, action) => {
      componentCatalogue.selectedLayerFilling = action.payload;
    },
    elementCreateDialogOpened: (componentCatalogue) => {
      componentCatalogue.dialogs.element.create = true;
    },
    elementCreateDialogClosed: (componentCatalogue) => {
      componentCatalogue.dialogs.element.create = false;
    },
    elementUpdateDialogOpened: (componentCatalogue) => {
      componentCatalogue.dialogs.element.update = true;
    },
    elementUpdateDialogClosed: (componentCatalogue) => {
      componentCatalogue.dialogs.element.update = false;
    },
    elementDeleteDialogOpened: (componentCatalogue) => {
      componentCatalogue.dialogs.element.delete = true;
    },
    elementDeleteDialogClosed: (componentCatalogue) => {
      componentCatalogue.dialogs.element.delete = false;
    },
    layersRetrieveDialogOpened: (componentCatalogue) => {
      componentCatalogue.dialogs.layers.retrieve = true;
    },
    layersRetrieveDialogClosed: (componentCatalogue) => {
      componentCatalogue.dialogs.layers.retrieve = false;
    },
    layerCreateDialogOpened: (componentCatalogue) => {
      componentCatalogue.dialogs.layers.create = true;
    },
    layerCreateDialogClosed: (componentCatalogue) => {
      componentCatalogue.dialogs.layers.create = false;
    },
    layerUpdateDialogOpened: (componentCatalogue) => {
      componentCatalogue.dialogs.layers.update = true;
    },
    layerUpdateDialogClosed: (componentCatalogue) => {
      componentCatalogue.dialogs.layers.update = false;
    },
    layerFillingCreateDialogOpened: (componentCatalogue, action) => {
      componentCatalogue.dialogs.layerFillings.create.open = true;
      componentCatalogue.dialogs.layerFillings.create.type = action.payload;
    },
    layerFillingCreateDialogClosed: (componentCatalogue) => {
      componentCatalogue.dialogs.layerFillings.create.open = false;
    },
    layerFillingUpdateDialogOpened: (componentCatalogue, action) => {
      componentCatalogue.dialogs.layerFillings.update.open = true;
      componentCatalogue.dialogs.layerFillings.update.type = action.payload;
    },
    layerFillingUpdateDialogClosed: (componentCatalogue) => {
      componentCatalogue.dialogs.layerFillings.update.open = false;
    },
    elementSelectionDialogClosed: (componentCatalogue) => {
      componentCatalogue.dialogs.element.selection = false;
      componentCatalogue.selectedElements = [];
      componentCatalogue.actions.show = true;
    },
    elementSelectionDialogOpened: (componentCatalogue) => {
      componentCatalogue.dialogs.element.selection = true;
      componentCatalogue.selectedElements = [];
      componentCatalogue.actions.show = false;
    },
    // Personal Library
    personalLibraryProjectIdSet: (componentCatalogue, action) => {
      componentCatalogue.personalLibrary.projectId = action.payload;
    },
    personalLibraryReturnProjectIdSet: (componentCatalogue, action) => {
      componentCatalogue.personalLibrary.returnProjectId = action.payload;
    },
    personalLibraryOpened: (componentCatalogue) => {
      componentCatalogue.personalLibrary.open = true;
      componentCatalogue.personalLibrary.dialogs.selection = false;
      componentCatalogue.selectedElements = [];
      componentCatalogue.selectedElement = initialState.selectedElement;
    },
    personalLibraryClosed: (componentCatalogue) => {
      componentCatalogue.personalLibrary.open = false;
      componentCatalogue.personalLibrary.dialogs.selection = false;
      componentCatalogue.personalLibrary.dialogs.create = false;
    },
    personalLibraryElementCreateDialogOpened: (componentCatalogue) => {
      componentCatalogue.personalLibrary.dialogs.create = true;
    },
    personalLibraryElementCreateDialogClosed: (componentCatalogue) => {
      componentCatalogue.personalLibrary.dialogs.create = false;
    },
    personalLibrarySelectionDialogClosed: (componentCatalogue) => {
      componentCatalogue.personalLibrary.dialogs.selection = false;
      componentCatalogue.selectedElements = [];
      componentCatalogue.personalLibrary.actions = true;
    },
    personalLibrarySelectionDialogOpened: (componentCatalogue) => {
      componentCatalogue.personalLibrary.dialogs.selection = true;
      componentCatalogue.selectedElements = [];
      componentCatalogue.personalLibrary.actions = false;
    },
    personalLibraryActionsVisible: (componentCatalogue) => {
      componentCatalogue.personalLibrary.actions = true;
    },
    personalLibraryActionsHidden: (componentCatalogue) => {
      componentCatalogue.personalLibrary.actions = false;
    }
  },
});

export const filterList = (payload: {
  list: IElement[];
  categoryChoices: ICategoryChoice[];
}) => ({
  type: listFiltered.type,
  payload,
});

export const selectElement = (payload: any) => ({
  type: elementSelected.type,
  payload,
});

export const selectLayer = (payload: any) => ({
  type: layerSelected.type,
  payload,
});

export const selectLayerFilling = (payload: any) => ({
  type: layerFillingSelected.type,
  payload,
});

export const openElementCreateDialog = () => ({
  type: elementCreateDialogOpened.type,
});

export const closeElementCreateDialog = () => ({
  type: elementCreateDialogClosed.type,
});

export const openElementUpdateDialog = () => ({
  type: elementUpdateDialogOpened.type,
});

export const closeElementUpdateDialog = () => ({
  type: elementUpdateDialogClosed.type,
});

export const openElementDeleteDialog = () => ({
  type: elementDeleteDialogOpened.type,
});

export const closeElementDeleteDialog = () => ({
  type: elementDeleteDialogClosed.type,
});

export const openLayersRetrieveDialog = () => ({
  type: layersRetrieveDialogOpened.type,
});

export const closeLayersRetrieveDialog = () => ({
  type: layersRetrieveDialogClosed.type,
});

export const openLayerCreateDialog = () => ({
  type: layerCreateDialogOpened.type,
});

export const closeLayerCreateDialog = () => ({
  type: layerCreateDialogClosed.type,
});

export const openLayerUpdateDialog = () => ({
  type: layerUpdateDialogOpened.type,
});

export const closeLayerUpdateDialog = () => ({
  type: layerUpdateDialogClosed.type,
});

export const openLayerFillingCreateDialog = (type: string) => ({
  type: layerFillingCreateDialogOpened.type,
  payload: type,
});

export const closeLayerFillingCreateDialog = () => ({
  type: layerFillingCreateDialogClosed.type,
});

export const openLayerFillingUpdateDialog = (type: string) => ({
  type: layerFillingUpdateDialogOpened.type,
  payload: type,
});

export const closeLayerFillingUpdateDialog = () => ({
  type: layerFillingUpdateDialogClosed.type,
});

export const openElementSelectionDialog = () => ({
  type: elementSelectionDialogOpened.type,
});

export const closeElementSelectionDialog = () => ({
  type: elementSelectionDialogClosed.type,
});

export const setPersonalLibraryProjectId = (
  project_id: number,
) => ({
  type: personalLibraryProjectIdSet.type,
  payload: project_id
});

export const setPersonalLibraryReturnProjectId = (
  project_id: number,
) => ({
  type: personalLibraryReturnProjectIdSet.type,
  payload: project_id
});

export const openPersonalLibrary = () => ({
  type: personalLibraryOpened.type,
});

export const closePersonalLibrary = () => ({
  type: personalLibraryClosed.type,
});

export const openPersonalLibraryCreateDialog = () => ({
  type: personalLibraryElementCreateDialogOpened.type,
});

export const closePersonalLibraryCreateDialog = () => ({
  type: personalLibraryElementCreateDialogClosed.type,
});

export const openPersonalLibrarySelectionDialog = () => ({
  type: personalLibrarySelectionDialogOpened.type,
});

export const closePersonalLibrarySelectionDialog = () => ({
  type: personalLibrarySelectionDialogClosed.type,
});

export const showPersonalLibraryActions = () => ({
  type: personalLibraryActionsVisible.type,
});

export const hidePersonalLibraryActions = () => ({
  type: personalLibraryActionsHidden.type,
});

export const {
  listFiltered,
  elementSelected,
  layerSelected,
  layerFillingSelected,
  elementCreateDialogOpened,
  elementCreateDialogClosed,
  elementUpdateDialogOpened,
  elementUpdateDialogClosed,
  elementDeleteDialogOpened,
  elementDeleteDialogClosed,
  elementSelectionDialogClosed,
  elementSelectionDialogOpened,
  layersRetrieveDialogOpened,
  layersRetrieveDialogClosed,
  layerCreateDialogOpened,
  layerCreateDialogClosed,
  layerUpdateDialogOpened,
  layerUpdateDialogClosed,
  layerFillingCreateDialogOpened,
  layerFillingCreateDialogClosed,
  layerFillingUpdateDialogOpened,
  layerFillingUpdateDialogClosed,
  personalLibraryProjectIdSet,
  personalLibraryReturnProjectIdSet,
  personalLibraryOpened,
  personalLibraryClosed,
  personalLibraryElementCreateDialogOpened,
  personalLibraryElementCreateDialogClosed,
  personalLibrarySelectionDialogOpened,
  personalLibrarySelectionDialogClosed,
  personalLibraryActionsVisible,
  personalLibraryActionsHidden,
} = componentCatalogueSlice.actions;

export default componentCatalogueSlice.reducer;

export const getFilteredList = (state: RootState) =>
  state.ui.projects.selectedProject.componentCatalogue.filteredList;

export const getSelectedElement = (state: RootState) =>
  state.ui.projects.selectedProject.componentCatalogue.selectedElement;

export const getSelectedElements = (state: RootState) =>
  state.ui.projects.selectedProject.componentCatalogue.selectedElements;

export const getSelectedLayer = (state: RootState) =>
  state.ui.projects.selectedProject.componentCatalogue.selectedLayer;

export const getSelectedLayerFilling = (state: RootState) =>
  state.ui.projects.selectedProject.componentCatalogue.selectedLayerFilling;

export const getElementDialogs = (state: RootState) =>
  state.ui.projects.selectedProject.componentCatalogue.dialogs.element;

export const getLayerDialogs = (state: RootState) =>
  state.ui.projects.selectedProject.componentCatalogue.dialogs.layers;

export const getLayerFillingDialogs = (state: RootState) =>
  state.ui.projects.selectedProject.componentCatalogue.dialogs.layerFillings;

export const getPrintDialog = (state: RootState) =>
  state.ui.projects.selectedProject.componentCatalogue.dialogs.print;

export const getActions = (state: RootState) =>
  state.ui.projects.selectedProject.componentCatalogue.actions;

export const getPersonalLibraryProjectId = (state: RootState) =>
  state.ui.projects.selectedProject.componentCatalogue.personalLibrary.projectId;

export const getPersonalLibraryReturnProjectId = (state: RootState) =>
  state.ui.projects.selectedProject.componentCatalogue.personalLibrary.returnProjectId;

export const getPersonalLibraryDialogs = (state: RootState) =>
  state.ui.projects.selectedProject.componentCatalogue.personalLibrary.dialogs;

export const getPersonalLibraryOpen = (state: RootState) =>
  state.ui.projects.selectedProject.componentCatalogue.personalLibrary.open;

export const getPersonalLibraryActions = (state: RootState) =>
  state.ui.projects.selectedProject.componentCatalogue.personalLibrary.actions;
