import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store";
import { getCategoryChoices, getConstructionElements } from "store/entities/projects/componentCatalogue/componentCatalogue.slice";
import { IElement } from "types";
import GeneralFilter from "./general/general";
import BuildingPhysicsFilter from "./buildingPhysics/buildingPhysics";
import ResetButton from "./reset/reset";
import { filterList } from "store/ui/projects/componentCatalogue/componentCatalogue.slice";

const Filter = () => {
    const { t } = useTranslation("component_catalogue", {
        keyPrefix: "library.filter",
    });

    const [nameFilter, setNameFilter] = useState("");
    const [fireProtectionFilter, setFireProtectionFilter] = useState("");
    const [soundProtectionFilter, setSoundProtectionFilter] = useState("");

    const dispatch = useAppDispatch();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));
    const elements: IElement[] = useAppSelector(getConstructionElements);
    const categoryChoices = useAppSelector(getCategoryChoices);

    const handleResetButtonClicked = () => {
        setNameFilter("");
        setFireProtectionFilter("");
        setSoundProtectionFilter("");
    };

    // helper Function for sound protection filter
    const extractSoundProtectionNumbersFromValue = (inputString: string): number[] => {
        const regex: RegExp = /\d+/g;
        const matches: RegExpMatchArray | null = inputString.match(regex);
        return matches ? matches.map(Number) : [];
    }

    useEffect(() => {
        let newElements: IElement[] = elements;

        // General Filters
        if (nameFilter !== "") {
            const check = (obj: IElement) => {
                if (obj.hasOwnProperty("name")) {
                    return obj.name.includes(nameFilter);
                }
            };
            newElements = newElements.filter((el) => check(el) === true);
        };

        // Building Physics
        if (fireProtectionFilter !== "") {
            const check = (obj: IElement) => {
                if (obj.hasOwnProperty('performance') && obj.performance.hasOwnProperty('fire_protection')) {
                    return obj.performance.fire_protection === fireProtectionFilter;
                }
            };
            newElements = newElements.filter((el) => check(el) === true);
        };

        if (soundProtectionFilter !== "") {
            const soundProtectionValues = extractSoundProtectionNumbersFromValue(soundProtectionFilter);
            const check = (obj: IElement) => {
                if (obj.hasOwnProperty('performance') && obj.performance.hasOwnProperty('direct_sound_reduction_index')) {
                    return obj.performance.direct_sound_reduction_index >= soundProtectionValues[0]
                        && obj.performance.direct_sound_reduction_index <= soundProtectionValues[1];
                }
            };
            newElements = newElements.filter((el) => check(el) === true);
        };

        // Actually filter elements
        dispatch(
            filterList({ list: newElements, categoryChoices: categoryChoices })
        );
    }, [nameFilter, soundProtectionFilter, fireProtectionFilter]);

    return (
        <>
            <Box
                sx={{ ml: { xs: 0, md: "64px" } }}
            >
                <Typography variant="h6" sx={{ mb: { xs: 2, md: 0 } }}>
                    {t("title")}
                </Typography>
                {matches ? (
                    <>
                        <Divider>
                            <Chip label={t("sections.header.general")} size="small" />
                        </Divider>
                        <GeneralFilter
                            nameFilter={nameFilter}
                            setNameFilter={setNameFilter}
                        />
                        <Divider>
                            <Chip label={t("sections.header.building_physics")} size="small" />
                        </Divider>
                        <BuildingPhysicsFilter
                            fireProtectionFilter={fireProtectionFilter}
                            setFireProtectionFilter={setFireProtectionFilter}
                            soundProtectionFilter={soundProtectionFilter}
                            setSoundProtectionFilter={setSoundProtectionFilter}
                        />
                        <Divider />
                        <ResetButton
                            handleResetButtonClicked={handleResetButtonClicked}
                            sxProps={{ mt: 1, }} />
                    </>
                ) : (
                    <>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>{t("sections.header.general")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <GeneralFilter
                                    nameFilter={nameFilter}
                                    setNameFilter={setNameFilter}
                                />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>{t("sections.header.building_physics")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <BuildingPhysicsFilter
                                    fireProtectionFilter={fireProtectionFilter}
                                    setFireProtectionFilter={setFireProtectionFilter}
                                    soundProtectionFilter={soundProtectionFilter}
                                    setSoundProtectionFilter={setSoundProtectionFilter}
                                />
                            </AccordionDetails>
                        </Accordion>
                        <ResetButton
                            handleResetButtonClicked={handleResetButtonClicked}
                            sxProps={{
                                position: "fixed",
                                bottom: { xs: 16, md: 72 },
                                left: { xs: 16, md: 72 },
                                float: "left",
                            }}
                        />
                    </>
                )}
            </ Box>
        </>
    );
}

export default Filter;