import React, { useEffect } from "react";

import { useAppDispatch } from "store";
import { useNavigate } from "react-router-dom";
import { signOutUser } from "store/entities/authentication/authentication.actions";
import { changeNavigationTitle } from "store/ui/navigation/navigation.slice";
import {
  getAuthenticationUrl,
  getSignInUrl,
} from "assets/endpoints/app/authEndpoints";

const SignOut = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(getAuthenticationUrl() + getSignInUrl(), { replace: true });
    dispatch(changeNavigationTitle("welcome"));
    dispatch(signOutUser());
  }, []);
  return null;
};

export default SignOut;
