import React, { useCallback, useMemo, useState, useEffect, useRef } from "react";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Paper from '@mui/material/Paper';
import TreeElement from "./treeElement/treeElement";

const AUTO_EXPAND_TYPES = ['IFCPROJECT', 'IFCSITE', 'IFCBUILDING'];

interface ITreeProps {
    spatialStructure: any;
    selectedIds: number[];
    selectItemsById: (ids: number[]) => Promise<void>;
    showHideItemsById: (show: boolean, expressIds: number[], addToOldShown?: boolean) => Promise<void>;
    displayedModelExpressIds: number[];
}

const Tree: React.FC<ITreeProps> = ({
    spatialStructure,
    selectItemsById,
    selectedIds,
    displayedModelExpressIds,
    showHideItemsById,
}) => {
    const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
    const [internalSelectedIds, setInternalSelectedIds] = useState<string[]>([]);
    const isFirstRender = useRef(true);
    const previousStructureId = useRef<string | null>(null);

    useEffect(() => {
        setInternalSelectedIds(selectedIds.map(String));
    }, [selectedIds]);

    const collectAutoExpandNodes = (element: any): string[] => {
        let nodes: string[] = [];

        if (AUTO_EXPAND_TYPES.includes(element.type)) {
            nodes.push(element.expressID.toString());
        }

        if (element.children && element.children.length > 0) {
            element.children.forEach((child: any) => {
                nodes = [...nodes, ...collectAutoExpandNodes(child)];
            });
        }

        return nodes;
    };

    const getAllExpressIds = useCallback((element: any): number[] => {
        let ids: number[] = [element.expressID];

        if (element.children && element.children.length > 0) {
            element.children.forEach((child: any) => {
                ids = [...ids, ...getAllExpressIds(child)];
            });
        }

        return ids;
    }, []);

    useEffect(() => {
        if (!spatialStructure || !spatialStructure.children[0]) return;

        const currentStructureId = spatialStructure.children[0].expressID.toString();

        if (currentStructureId !== previousStructureId.current) {
            isFirstRender.current = true;
            previousStructureId.current = currentStructureId;

            const allIds = getAllExpressIds(spatialStructure.children[0]);
            showHideItemsById(true, allIds);

            const autoExpandNodes = collectAutoExpandNodes(spatialStructure.children[0]);
            setExpandedNodes(autoExpandNodes);

            isFirstRender.current = false;
        }
    }, [spatialStructure, showHideItemsById, getAllExpressIds]);

    const handleSelect = useCallback(async (event: React.SyntheticEvent, nodeIds: string[]) => {
        setInternalSelectedIds(nodeIds);
        const idsNumberArray = nodeIds.map(Number);
        await selectItemsById(idsNumberArray);
    }, [selectItemsById]);

    const handleToggle = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpandedNodes(nodeIds);
    }, []);

    const renderTreeElement = useCallback((element: any): React.ReactNode => {
        return (
            <TreeElement
                key={element.expressID}
                nodeId={element.expressID.toString()}
                label={element.type}
                ContentProps={{
                    showHideItemsById,
                    displayedModelExpressIds
                }}
            >
                {element.children && element.children.length > 0 &&
                    element.children.map((child: any) => renderTreeElement(child))
                }
            </TreeElement>
        );
    }, [showHideItemsById, displayedModelExpressIds]);

    const memoizedTree = useMemo(() => {
        if (spatialStructure) {
            return renderTreeElement(spatialStructure.children[0]);
        }
        return null;
    }, [spatialStructure, renderTreeElement, displayedModelExpressIds, selectedIds]);

    return (
        <Paper sx={{ backgroundColor: "transparent", color: "white" }}>
            <TreeView
                aria-label="icon expansion"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                multiSelect={true}
                onNodeSelect={handleSelect}
                onNodeToggle={handleToggle}
                expanded={expandedNodes}
                selected={internalSelectedIds}
                sx={{
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    "&& .Mui-selected": {
                        backgroundColor: '#768669 !important',
                    },
                    "&& .Mui-selected:hover": {
                        backgroundColor: '#768669 !important',
                    },
                    "&& .Mui-focused": {
                        backgroundColor: '#768669 !important',
                    },
                }}
            >
                {memoizedTree}
            </TreeView>
        </Paper>
    );
};

export default React.memo(Tree, (prevProps, nextProps) => {
    return (
        prevProps.spatialStructure === nextProps.spatialStructure &&
        prevProps.selectedIds === nextProps.selectedIds &&
        prevProps.displayedModelExpressIds === nextProps.displayedModelExpressIds &&
        prevProps.showHideItemsById === nextProps.showHideItemsById &&
        prevProps.selectItemsById === nextProps.selectItemsById
    );
});