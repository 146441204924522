import { TFunction } from 'i18next'
import React from 'react'
import element from './element/element'

export default async function content(
  filteredList: any,
  doc: any,
  layout: any,
  fontSizes: any,
  lineSpacing: number,
  t: TFunction<'component_catalogue', 'pdf_export'>
) {
  let section = 0
  for await (const category of Object.keys(filteredList)) {
    section++

    for (let i = 0; i < filteredList[category].length; i++) {
      let subsection = i + 1

      const addElement = async () => {
        doc.addPage()
        const elementData = filteredList[category][i]
        let startY = layout.StartY * 2
        let startX = layout.StartX

        if (subsection === 1) {
          doc.setFontSize(fontSizes.TitleFontSize)
          doc.text(`${section} ${category}`, startX, startY)
          startY += lineSpacing
        }

        await element(
          elementData,
          doc,
          layout,
          fontSizes,
          lineSpacing,
          startY,
          t,
          section,
          subsection
        )
      }

      await addElement()
    }
  }
}
