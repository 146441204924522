import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem'
import { SvgIconProps } from '@mui/material/SvgIcon'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string
    '--tree-view-bg-color'?: string
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string
  color?: string
  link?: string
  labelIcon: React.ElementType<SvgIconProps>
  labelInfo?: string
  labelText: string
}

function StyledTreeItem(props: StyledTreeItemProps) {
  const {
    bgColor,
    color,
    link,
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    ...other
  } = props

  return (
    <TreeItem
      label={
        <>
          {link ? (
            <Link
              component={RouterLink}
              to={link}
              underline='none'
              color='inherit'
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 2,
                  px: 0,
                }}
              >
                <Box component={LabelIcon} color='inherit' sx={{ mr: 3 }} />
                <Typography variant='body1'>{labelText}</Typography>
                <Typography variant='caption'>{labelInfo}</Typography>
              </Box>
            </Link>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 2,
                px: 0,
              }}
            >
              <Box component={LabelIcon} color='inherit' sx={{ mr: 3 }} />
              <Typography variant='body1'>{labelText}</Typography>
              <Typography variant='caption'>{labelInfo}</Typography>
            </Box>
          )}
        </>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  )
}

export default StyledTreeItem
