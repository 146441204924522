import { Card as MuiCard } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Menu from './menu'

interface IProps {
  children: any
}

const Card: React.FC<IProps> = ({ children }) => {
  const { t } = useTranslation('dashboard', {
    keyPrefix: 'project',
  })

  return (
    <MuiCard variant='outlined'>
      <CardHeader
        action={<Menu />}
        title={
          <Typography gutterBottom variant='h6' fontWeight='bold'>
            {t('project_management')}
          </Typography>
        }
      />
      <CardContent>{children}</CardContent>
    </MuiCard>
  )
}

export default Card
