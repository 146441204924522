import { CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Shape } from "plotly.js";
import React, { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";
import { ILayerOptimized, IPlattenAusschnitt, IPlattenDimension } from "types";
import { ColorData, getPlatteColorFromLayerColorData, rawBoardWidthMillimeter } from "../layerVisualizer";

interface LayerPlotProps {
    layer: ILayerOptimized;
    layerX1: number;
    layerTop: number;
    colorData: ColorData;
    plattenType: 'neu' | 'src';
}

interface IPlatteCenter {
    x: number;
    y: number;
    platte: IPlattenAusschnitt | IPlattenDimension;
    platteWidth: number;
    platteHeight: number;
    platteArea: number;
}

const FallbackComponent = () => (
    <Box display="flex" justifyContent="center" alignItems="center" height={200}>
        <CircularProgress />
    </Box>
);

const LayerPlot: React.FC<LayerPlotProps> = ({
    layer,
    layerX1,
    layerTop,
    colorData,
    plattenType,
}) => {
    const plotRefs = useRef<(HTMLDivElement | null)[]>([]);
    const theme = useTheme();
    const [platteShapes, setPlatteShapes] = useState<Partial<Shape>[]>([]);
    const [binShapes, setBinShapes] = useState<Partial<Shape>[]>([]);
    const [platteCenters, setPlatteCenters] = useState<IPlatteCenter[]>([]);

    const handleResize = () => {
        if (plotRefs.current.length > 0) {
            plotRefs.current.forEach((plotRef) => {
                if (plotRef) {
                    const resetAxesButton = plotRef.querySelector('[data-title="Reset axes"]');
                    if (resetAxesButton) {
                        resetAxesButton.dispatchEvent(new MouseEvent('click', { bubbles: true }));
                    }
                }
            });
        }
    };

    useEffect(() => {
        const platten = plattenType === 'neu' ? layer.platten_neu : layer.platten_src;

        const newBinShapes = generateBinShapes(platten, layer.rohplatten_hoehe_neu);
        const newPlatteShapes = generatePlatteShapes(platten, colorData);
        const newPlatteCenters = generatePlatteCenters(platten);

        setBinShapes(newBinShapes);
        setPlatteShapes(newPlatteShapes);
        setPlatteCenters(newPlatteCenters);

        setTimeout(handleResize, 100);
    }, [layer, colorData, plattenType]);

    const generateBinShapes = (platten: any[], rohplattenHoehe: number): Partial<Shape>[] => {
        if (plattenType === 'neu') return [];

        const uniqueBins = new Set(platten.map(item => item.bin_number));
        return Array.from(uniqueBins).map((_bin_number, index) => ({
            type: 'rect',
            x0: index * rawBoardWidthMillimeter / 1000,
            y0: 0,
            x1: index * rawBoardWidthMillimeter / 1000 + rawBoardWidthMillimeter / 1000,
            y1: rohplattenHoehe / 1000,
            line: { color: theme.palette.common.black, width: 1 },
            fillcolor: theme.palette.common.white,
        }));
    };

    const generatePlatteShapes = (platten: any[], colorData: ColorData): Partial<Shape>[] => {
        return platten.map((platte) => {
            const colorObject = getPlatteColorFromLayerColorData(platte.id, colorData, theme);
            return {
                type: 'rect',
                x0: platte.x0,
                y0: platte.bottom,
                x1: platte.x1,
                y1: platte.top,
                line: {
                    color: colorObject.lineColor,
                    width: colorObject.lineWidth
                },
                fillcolor: colorObject.fillColor,
            };
        });
    };

    const generatePlatteCenters = (platten: any[]): IPlatteCenter[] => {
        return platten.map((platte) => {
            const platteWidth = platte.x1 - platte.x0;
            const platteHeight = platte.top - platte.bottom;
            const platteArea = platteWidth * platteHeight;

            return {
                x: ((platte.x0 + platte.x1) / 2),
                y: ((platte.bottom + platte.top) / 2),
                platte: platte,
                platteWidth: platteWidth * 1000,
                platteHeight: platteHeight * 1000,
                platteArea: platteArea
            };
        });
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [plotRefs]);

    const getHoverTemplate = (plattenType: 'neu' | 'src') => {
        const baseTemplate = `<b>Id:</b> %{customdata[0]}<br><b>Bin Number:</b> %{customdata[1]}<br><b>Width:</b> %{customdata[3]:.1f} mm<br><b>Height:</b> %{customdata[4]:.1f} mm<br><b>Area:</b> %{customdata[5]:.2f} m^2`;
        return plattenType === 'neu'
            ? `${baseTemplate}<br><b>Is Rotated:</b> %{customdata[2]}<br><extra></extra>`
            : `${baseTemplate}<br><extra></extra>`;
    };

    if (plattenType === "src" && layer.platten_src.length === 0) {
        return (
            <Typography>No Baseline Panels provided</Typography>
        );
    }
    else if (plattenType === "neu" && layer.platten_neu.length === 0) {
        return (
            <Typography>No Optimized Panels provided</Typography>
        );
    }

    return (
        <Grid container>
            {Array.from(new Array(1)).map((_element, index) => {
                return (
                    <Grid item xs={12} key={`${layer.id}-layer-${plattenType}-${index}`}>
                        <Box component={"div"} ref={(el: HTMLDivElement | null) => (plotRefs.current[layer.id] = el)}>
                            <Plot
                                style={{ width: "100%", height: "100%" }}
                                data={[{
                                    type: 'scatter',
                                    mode: 'markers',
                                    x: platteCenters.map(p => p.x),
                                    y: platteCenters.map(p => p.y),
                                    customdata: platteCenters.map(p => [
                                        p.platte.id,
                                        p.platte.bin_number + 1,
                                        'rotated' in p.platte ? p.platte.rotated.toString() : '',
                                        p.platteWidth,
                                        p.platteHeight,
                                        p.platteArea
                                    ]),
                                    hovertemplate: getHoverTemplate(plattenType),
                                    marker: { opacity: 0 },
                                    showlegend: false
                                }]}
                                layout={{
                                    modebar: { bgcolor: 'rgba(0,0,0,0)' },
                                    paper_bgcolor: 'rgba(0,0,0,0)',
                                    plot_bgcolor: 'rgba(0,0,0,0)',
                                    hovermode: "closest",
                                    hoverlabel: { bgcolor: "#FFF" },
                                    showlegend: false,
                                    dragmode: false,
                                    margin: { l: 10, r: 10, b: 10, t: 10, pad: 4 },
                                    xaxis: {
                                        range: [0, layerX1],
                                        showgrid: false,
                                        visible: false,
                                    },
                                    yaxis: {
                                        range: [0, layerTop],
                                        showgrid: false,
                                        visible: false,
                                        scaleanchor: "x",
                                        scaleratio: 1,
                                    },
                                    shapes: [...binShapes, ...platteShapes],
                                }}
                                useResizeHandler={true}
                                config={{
                                    displaylogo: false,
                                    scrollZoom: false,
                                    responsive: true,
                                    modeBarButtonsToRemove: ["zoom2d", "select2d", "lasso2d", "autoScale2d"],
                                }}
                            />
                        </Box>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default LayerPlot;