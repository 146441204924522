import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { Outlet } from "react-router-dom";
import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";

import { useAppDispatch, useAppSelector } from "store";
import {
  closeSnackbar,
  getSnackbar,
} from "store/ui/authentication/authentication.slice";
import { getDesign } from "store/entities/settings/settings.slice";
import BuildVersionInfo from "common/components/buildVersionInfo/buildVersionInfo";

interface Snackbar {
  open: boolean;
  message: string;
  severity: AlertColor;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
  }
);

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link component={RouterLink} to={"/"} color="inherit">
        {"holzbau.tech"}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Layout() {
  const { t } = useTranslation("authentication");

  const theme = useAppSelector(getDesign).colorTheme;
  const snackbar: Snackbar = useAppSelector(getSnackbar);
  const dispatch = useAppDispatch();

  const handleSnackbarClose = () => {
    dispatch(closeSnackbar());
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ my: 2 }}>
          <img
            width={"100px"}
            src={
              theme === "dark"
                ? process.env.PUBLIC_URL + "/img/brand/logos/btc_logo_white.png"
                : process.env.PUBLIC_URL + "/img/brand/logos/btc_logo_blue.png"
            }
            alt={t("logo") as string}
          />
        </Box>
        <Outlet />
      </Box>
      <Copyright sx={{ mt: 5 }} />
      <BuildVersionInfo />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbar.open}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
        TransitionComponent={Slide}
        sx={{ justifySelf: "center" }}
      >
        <Alert
          severity={snackbar.severity}
          onClose={handleSnackbarClose}
          sx={{ width: "100%" }}
        >
          {t(snackbar.message)}
        </Alert>
      </Snackbar>
    </Container>
  );
}
