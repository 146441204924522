import { Box, IconButton, Input, InputAdornment, Stack, Tooltip } from "@mui/material";
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridRowSelectionModel } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from "store";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { IIfcConElementAssignment } from "types";
import { useState } from "react";
import { LoadingSkeletonOverly, NoRowsOverlay } from "./overlays";
import { FragmentsGroup } from "bim-fragment";
import { getNameFromType } from "../helpers/helpers";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { red } from '@mui/material/colors';
import { getSelectedModel } from "store/ui/projects/ifcViewer/ifcViewer.slice";
import { getIfcConElementAssignments } from "store/entities/projects/ifcViewer/ifcConElementAssignments/ifcConElementAssignments.slice";
import { IIfcConElementAssignmentError } from "../ifcConElementAssignment";
import { updateIfcConElementAssignment } from "store/entities/projects/ifcViewer/ifcConElementAssignments/ifcConElementAssignments.actions";

interface ITableRow {
    id: any;
    name: any;
    ifcClass: String | null;
    constructionElement: string | null;
    error: String | null;
}


interface IProps {
    model: FragmentsGroup | null;
    ifcElements: any[];
    selectedModelExpressIds: number[];
    tableSelection: GridRowSelectionModel;
    setTableSelection: Dispatch<SetStateAction<GridRowSelectionModel>>;
    selectElementsByExpressId: (expressIds: number[]) => Promise<void>;
    ifcConElementAssignmentErrors: IIfcConElementAssignmentError[];
    setIfcConElementAssignmentErrors: Dispatch<SetStateAction<IIfcConElementAssignmentError[]>>;
    project_id: number;
}

const Table: React.FC<IProps> = ({
    model,
    ifcElements,
    selectedModelExpressIds,
    tableSelection,
    setTableSelection,
    selectElementsByExpressId,
    ifcConElementAssignmentErrors,
    setIfcConElementAssignmentErrors,
    project_id
}) => {
    const [tableRows, setTableRows] = useState<ITableRow[]>([]);

    const dispatch = useAppDispatch();

    const selectedModel = useAppSelector(getSelectedModel);
    const elementAssignmentList: IIfcConElementAssignment[] = useAppSelector(getIfcConElementAssignments);

    const [columnVisibilityModel] = useState<GridColumnVisibilityModel>({});
    const [localMinimalBracingForces, setLocalMinimalBracingForces] = useState<{ [key: number]: string }>({});

    const handleIfcConElementAssignmentMinimalBracingForceInputChange = useCallback((value: string, row: ITableRow) => {
        const new_minimal_bracing_force = Number(value);
        const existingAssignment: IIfcConElementAssignment | undefined = elementAssignmentList.find((assignment) => assignment.model_file.uuid === selectedModel.uuid && assignment.ifc_element_express_id === Number(row.id));

        setIfcConElementAssignmentErrors([]);
        let assignmentError = "";
        if (new_minimal_bracing_force !== -1 && (new_minimal_bracing_force <= 0 || new_minimal_bracing_force >= 99999999)) {
            assignmentError = "Please assign a valid number above 0 to 99999999 or -1 if not used";
        }
        else if (existingAssignment === undefined) {
            assignmentError = "Please assign to a construction element first before assigning a minimal bracing force";
        }

        // finally setting the assignment error
        if (assignmentError !== "") {
            const index = ifcConElementAssignmentErrors.findIndex(error => error.ifc_element_express_id === Number(row.id));
            if (index !== -1) {
                const updatedItems = [...ifcConElementAssignmentErrors];
                updatedItems[index] = { ...updatedItems[index], assignmentError: assignmentError };
                setIfcConElementAssignmentErrors(updatedItems);
            } else {
                const newError: IIfcConElementAssignmentError = { ifc_element_express_id: Number(row.id), assignmentError: assignmentError };
                setIfcConElementAssignmentErrors(prevItems => [...prevItems, newError]);
            }
            return;
        }

        if (existingAssignment !== undefined) {
            setLocalMinimalBracingForces(prev => ({
                ...prev,
                [row.id]: `${new_minimal_bracing_force}`
            }));
            dispatch(updateIfcConElementAssignment(
                project_id,
                existingAssignment.id,
                existingAssignment.model_file.uuid,
                existingAssignment.construction_element.id,
                existingAssignment.ifc_element_express_id,
                new_minimal_bracing_force
            ));
        }

    }, [dispatch, elementAssignmentList, ifcConElementAssignmentErrors, project_id, selectedModel.uuid, setIfcConElementAssignmentErrors]);

    const tableColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            type: 'string',
            width: 150,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'ifcClass',
            headerName: 'IFC-Class',
            type: 'string',
            width: 150,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'constructionElement',
            headerName: 'Assigned Construction-Element',
            type: 'string',
            minWidth: 200,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: "minimalBracingForce",
            headerName: "Minimal bracing force",
            width: 150,
            flex: 1,
            align: "left",
            headerAlign: 'left',
            sortable: false,
            renderCell: ({ row }: { row: ITableRow }) => {
                return (
                    <Stack sx={{ width: "100%" }} direction="row" alignItems="center">
                        <Input
                            id={`minimalBracingForce-input-${row.id}`}
                            name={`minimalBracingForce-input-${row.id}`}
                            fullWidth
                            inputProps={{
                                max: 99999999,
                                min: -1,
                                type: "number",
                            }}
                            placeholder="-1"
                            value={localMinimalBracingForces[row.id]}
                            onChange={(e) => setLocalMinimalBracingForces(prev => ({
                                ...prev,
                                [row.id]: e.target.value
                            }))}
                            onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                                handleIfcConElementAssignmentMinimalBracingForceInputChange(event.target.value, row)
                            }
                            endAdornment={<InputAdornment position="end">N</InputAdornment>}
                            sx={{
                                minWidth: 100,
                                '& input[type=number]': {
                                    MozAppearance: 'textfield',
                                },
                                '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                                    WebkitAppearance: 'none',
                                    margin: 0,
                                },
                            }}
                        />
                    </Stack>
                );
            }
        },
        {
            field: "show",
            headerName: "Show",
            width: 50,
            align: "left",
            headerAlign: 'left',
            sortable: false,
            renderCell: ({ row }) => (
                <Stack direction="row" alignItems="center">
                    <Tooltip title={"Show Element"}>
                        <IconButton onClick={() => selectElementsByExpressId([Number(row.id)])} aria-label="show" size="small">
                            <VisibilityIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            )
        },
        {
            field: 'Info',
            type: 'string',
            width: 50,
            align: 'left',
            headerAlign: 'left',
            sortable: false,
            renderCell: ({ row }) => (
                <Box>
                    {row.error !== null &&
                        <Tooltip title={row.error}>
                            <ErrorOutlineIcon fontSize="small" sx={{ color: red[500] }} />
                        </Tooltip>
                    }
                </Box>
            )
        },
    ];

    useEffect(() => {
        const newTableRows = ifcElements.map((ifcElement, index: number) => {
            const name = ifcElement.Name && ifcElement.Name.value ? ifcElement.Name.value : null;
            const ifcClass = ifcElement.type ? getNameFromType(ifcElement.type) : null;

            // search for assignment error with ifcElement
            const updatedError = ifcConElementAssignmentErrors.find(error => error.ifc_element_express_id === ifcElement.expressID);
            const error = updatedError ? updatedError.assignmentError : null;

            // search if ifcElement has an assignment
            const assignment = elementAssignmentList.find(assignment =>
                model !== null &&
                assignment.model_file.uuid === selectedModel.uuid &&
                assignment.ifc_element_express_id === ifcElement.expressID);

            const constructionElementName = assignment ? assignment.construction_element.name : null;
            const minimal_bracing_force = assignment ? assignment.minimal_bracing_force : -1;

            setLocalMinimalBracingForces(prev => ({
                ...prev,
                [ifcElement.expressID]: `${minimal_bracing_force}`
            }));

            return ({
                id: ifcElement.expressID,
                name: name,
                ifcClass: ifcClass,
                constructionElement: constructionElementName,
                error: error,
            })
        }
        );
        setTableRows(newTableRows);
    }, [ifcElements, elementAssignmentList, ifcConElementAssignmentErrors, model, selectedModel]);

    useEffect(() => {
        const filteredSelectedModelExpressIds = selectedModelExpressIds.filter(number =>
            ifcElements.some(ifcElement => ifcElement.expressID === number)
        );
        setTableSelection(tableSelection.concat(filteredSelectedModelExpressIds));
    }, [selectedModelExpressIds]);

    return (
        <DataGrid
            rows={tableRows}
            columns={tableColumns}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                },
            }}
            checkboxSelection
            rowSelectionModel={tableSelection}
            onRowSelectionModelChange={(newRowSelectionModel) => {
                setTableSelection(newRowSelectionModel);
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            disableColumnMenu
            columnVisibilityModel={columnVisibilityModel}
            slots={{
                loadingOverlay: LoadingSkeletonOverly,
                noRowsOverlay: NoRowsOverlay,
            }}
            autoHeight
            sx={{
                '--DataGrid-overlayHeight': '300px',
                minWidth: "800px",
            }}
        />
    );
};

export default Table;
