import * as React from 'react'

interface IProps {
  primaryColor: string
}

export const Approve = ({ primaryColor }: IProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      //   width='601.79779'
      //   height='539'
      width='100%'
      height='100%'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 601.79779 539'
    >
      <path
        d='M895.56824,624.1433c-5.47409,33.68243-30.13785,59.76261-56.39531,82.55466q-5.49927,4.77441-11.02476,9.36267c-.02563.01507-.05047.04368-.0764.0591-.17731.14649-.355.29329-.519.439-.76039.63027-1.521,1.26093-2.27531,1.88426l.4732.14777,1.47415.45273c-.506-.13891-1.00512-.27126-1.5111-.41016-.15077-.04281-.30765-.07867-.45816-.12185-17.40908-4.70256-35.17235-9.86321-49.4042-20.8804-14.76509-11.44227-24.93357-30.94918-19.84135-48.9224a32.912,32.912,0,0,1,2.77543-6.77551c.48014-.89932,1.00921-1.76838,1.56575-2.62584a35.27659,35.27659,0,0,1,61.43455,3.41775c-.24843-19.8038-18.33729-34.24479-35.30357-44.4654-16.97237-10.21366-36.18919-21.24848-41.52525-40.33112-2.97507-10.61658-.47243-21.552,5.36939-30.79086.18145-.28119.36263-.562.55127-.83694a51.483,51.483,0,0,1,30.73693-21.57158c23.50341-5.33225,48.1351,4.489,67.41325,18.96039C880.05462,546.97185,901.79047,585.84679,895.56824,624.1433Z'
        transform='translate(-299.1011 -180.5)'
        fill='#f2f2f2'
      />
      <path
        d='M845.71736,586.93461a87.5176,87.5176,0,0,1,13.168,21.71,75.99288,75.99288,0,0,1,5.2845,21.97079c1.42794,15.50125-1.51254,31.16962-7.377,45.51487A140.13225,140.13225,0,0,1,839.17293,706.698q-5.49927,4.77441-11.02476,9.36267c-.02563.01507-.05047.04368-.0764.0591-.17731.14649-.355.29329-.519.439-.76039.63027-1.521,1.26093-2.27531,1.88426l.4732.14777,1.47415.45273c-.506-.13891-1.00512-.27126-1.5111-.41016-.15077-.04281-.30765-.07867-.45816-.12185a77.46217,77.46217,0,0,0-32.56378-63.26543,78.12584,78.12584,0,0,0-33.90634-13.31288c.48014-.89932,1.00921-1.76838,1.56575-2.62584a81.00185,81.00185,0,0,1,13.87821,3.42892,79.36884,79.36884,0,0,1,39.75612,30.07206,81.14587,81.14587,0,0,1,14.08224,43.15734c.608-.70019,1.21519-1.41391,1.80252-2.11918,11.20485-13.27922,20.79015-28.12812,26.50305-44.62249a90.57992,90.57992,0,0,0,4.30651-43.68166c-2.54958-15.99914-10.742-30.1181-21.57127-41.96541-11.59885-12.67836-25.52566-23.45515-40.10058-32.48962a215.44368,215.44368,0,0,0-47.67063-22.06874,1.549,1.549,0,0,1-1.01105-1.88085,1.31756,1.31756,0,0,1,.55127-.83694,1.147,1.147,0,0,1,.994-.07677c2.06587.66609,4.11962,1.34613,6.16253,2.06754a217.88875,217.88875,0,0,1,48.23726,24.115C820.77482,562.043,834.69989,573.37019,845.71736,586.93461Z'
        transform='translate(-299.1011 -180.5)'
        fill='#fff'
      />
      <path
        d='M549.79366,510.01847a6.59735,6.59735,0,0,1-6.57308-6.166l-.979-14.94807a6.59729,6.59729,0,0,1,6.15184-7.0144l117.11145-7.66954a14.08739,14.08739,0,1,1,1.84107,28.11456l-117.11121,7.669Q550.01358,510.01822,549.79366,510.01847Z'
        transform='translate(-299.1011 -180.5)'
        fill={primaryColor}
      />
      <path
        d='M572.88629,511.94577a6.60564,6.60564,0,0,1-6.58968-6.35131l-.83455-22.32672a6.59666,6.59666,0,0,1,6.34635-6.83847l96.30393-3.59937a6.59749,6.59749,0,0,1,6.839,6.34636l.83455,22.32672a6.59666,6.59666,0,0,1-6.34636,6.83846l-96.30392,3.59937C573.05231,511.94428,572.96881,511.94577,572.88629,511.94577Z'
        transform='translate(-299.1011 -180.5)'
        fill='#2f2e41'
      />
      <path
        d='M685.739,717.75628H670.75872a6.6046,6.6046,0,0,1-6.59712-6.59712V586.30705a6.6046,6.6046,0,0,1,6.59712-6.59712H685.739a6.6046,6.6046,0,0,1,6.59712,6.59712V711.15916A6.6046,6.6046,0,0,1,685.739,717.75628Z'
        transform='translate(-299.1011 -180.5)'
        fill='#2f2e41'
      />
      <path
        d='M619.24758,651.76052l-12.81048-7.76489a6.60461,6.60461,0,0,1-2.222-9.06126l64.717-106.76968a6.6046,6.6046,0,0,1,9.06126-2.222l12.81047,7.76489a6.6046,6.6046,0,0,1,2.222,9.06126l-64.717,106.76968A6.6046,6.6046,0,0,1,619.24758,651.76052Z'
        transform='translate(-299.1011 -180.5)'
        fill='#2f2e41'
      />
      <circle cx='363.88604' cy='217.15117' r='51.76199' fill={primaryColor} />
      <path
        d='M634.96871,415.32452a12.27608,12.27608,0,0,1-4.48289-2.63145,8.255,8.255,0,0,1-2.413-6.53521,5.55329,5.55329,0,0,1,2.46889-4.274c1.851-1.18738,4.32676-1.1909,6.84743-.08005l-.09541-20.20715,2.02974-.00967.11229,23.75564-1.564-.98382c-1.81363-1.13891-4.4037-1.94057-6.23384-.76632a3.56624,3.56624,0,0,0-1.54428,2.75954,6.238,6.238,0,0,0,1.79419,4.87268c2.2414,2.14106,5.51156,2.81062,9.24072,3.409l-.32171,2.004A33.45573,33.45573,0,0,1,634.96871,415.32452Z'
        transform='translate(-299.1011 -180.5)'
        fill='#2f2e41'
      />
      <rect
        x='620.6406'
        y='376.83118'
        width='2.02963'
        height='10.93261'
        transform='translate(-137.84418 768.04837) rotate(-82.4768)'
        fill='#2f2e41'
      />
      <rect
        x='654.85167'
        y='381.34811'
        width='2.02963'
        height='10.93261'
        transform='translate(-112.59032 805.89049) rotate(-82.4768)'
        fill='#2f2e41'
      />
      <path
        d='M706.20856,619.80506H618.92363a8.63665,8.63665,0,0,1-8.627-8.627l18.27315-140.12728a8.61668,8.61668,0,0,1,8.62279-8.56158h30.976a46.71973,46.71973,0,0,1,46.667,46.667V611.17806A8.63665,8.63665,0,0,1,706.20856,619.80506Z'
        transform='translate(-299.1011 -180.5)'
        fill='#2f2e41'
      />
      <path
        d='M684.22942,612.33474a6.6077,6.6077,0,0,1-6.05687,3.85394l-14.97966-.13009a6.59656,6.59656,0,0,1-6.53938-6.6541l1.01757-117.35771a14.08681,14.08681,0,1,1,28.17257.24427l-1.017,117.35769A6.56028,6.56028,0,0,1,684.22942,612.33474Z'
        transform='translate(-299.1011 -180.5)'
        fill={primaryColor}
      />
      <path
        d='M690.84692,581.00532a6.60733,6.60733,0,0,1-6.05676,3.85371l-25.58609-.222a6.60463,6.60463,0,0,1-6.53939-6.6541l.83573-96.36753a6.59656,6.59656,0,0,1,6.654-6.53972l25.58564.22176a6.59656,6.59656,0,0,1,6.53938,6.6541l-.83527,96.36774A6.56847,6.56847,0,0,1,690.84692,581.00532Z'
        transform='translate(-299.1011 -180.5)'
        fill='#2f2e41'
      />
      <path
        d='M663.95516,366.95118c-6.9195-5.72612-16.16929-.31039-23.99441-1.079-7.48644-.73539-13.51208-7.32375-15.15411-14.41349-1.91571-8.27137,2.2368-16.67348,8.49192-21.97206,6.85091-5.80327,16.04888-7.60663,24.81428-6.72357,10.0463,1.0121,19.30194,5.5102,27.63473,11.00529a123.67127,123.67127,0,0,1,22.10837,18.02394c5.95844,6.33452,11.049,13.92335,12.79268,22.56655,1.58467,7.85488.65495,16.6102-3.87776,23.376a24.56693,24.56693,0,0,1-9.46352,8.10313c-3.95825,2.03789-8.1937,3.52147-12.02248,5.81332-5.78915,3.4653-11.34551,10.528-9.3746,17.73056a9.93908,9.93908,0,0,0,2.33959,4.20892c1.33732,1.429,3.66322-.53712,2.32238-1.9699-2.355-2.51647-2.329-5.93272-1.158-9.01283a16.51534,16.51534,0,0,1,7.18685-8.21112c4.00792-2.39361,8.45427-3.90856,12.56708-6.09761a27.42638,27.42638,0,0,0,9.89934-8.52616c4.83927-7.00191,6.15526-16.01972,4.87-24.31444-1.39085-8.97611-6.17-17.066-12.09647-23.80878-6.44965-7.33805-14.318-13.50375-22.34139-19.02467-8.6092-5.924-18.09069-10.89045-28.5098-12.55394-9.03171-1.442-18.76931-.26355-26.54717,4.79118-7.26012,4.71826-12.74448,12.70153-13.13759,21.5131a22.56757,22.56757,0,0,0,10.91387,20.03631,19.38647,19.38647,0,0,0,11.6294,2.46237c4.31923-.359,8.6227-1.76546,12.98178-1.4805a9.07438,9.07438,0,0,1,5.38686,2.05834c1.51182,1.25108,3.23758-1.26007,1.73817-2.50088Z'
        transform='translate(-299.1011 -180.5)'
        fill='#2f2e41'
      />
      <path
        d='M603.09,636.73807q.02787-.17169.06518-.34287a6.55216,6.55216,0,0,1,2.87284-4.15225l12.58836-8.11963a6.605,6.605,0,0,1,9.11987,1.96795l38.8328,60.20605a6.59811,6.59811,0,0,1-1.96795,9.11987l-12.58885,8.11955a6.605,6.605,0,0,1-9.11987-1.96795l-38.8328-60.206A6.54979,6.54979,0,0,1,603.09,636.73807Z'
        transform='translate(-299.1011 -180.5)'
        fill='#2f2e41'
      />
      <path
        id='a8ac6166-3ec4-44bd-a940-12ae5b8b67a0-532'
        data-name='Path 336'
        d='M555.94417,239.94972a12.74187,12.74187,0,0,0-11.65223-7.79607h-232.583a12.61461,12.61461,0,0,0-12.60784,12.60783V539.00062a12.61461,12.61461,0,0,0,12.60784,12.60783H544.30033a12.6246,12.6246,0,0,0,12.60789-12.60783V244.76148a12.38228,12.38228,0,0,0-.95566-4.81176ZM554.1,539.00062a9.81972,9.81972,0,0,1-9.80793,9.808H311.70894a9.80963,9.80963,0,0,1-9.808-9.8063V244.76317a9.82139,9.82139,0,0,1,9.808-9.808H544.30033a9.85668,9.85668,0,0,1,9.1038,6.16979,10.87172,10.87172,0,0,1,.3688,1.10654,9.72527,9.72527,0,0,1,.33533,2.53165Z'
        transform='translate(-299.1011 -180.5)'
        fill='#3f3d56'
      />
      <path
        id='a2e3fa0b-f178-421f-a74c-d97ebf543334-533'
        data-name='Path 337'
        d='M507.40726,301.62258H437.34993a5.6048,5.6048,0,1,1,0-11.2096h70.05733a5.6048,5.6048,0,1,1,0,11.2096Z'
        transform='translate(-299.1011 -180.5)'
        fill='#3f3d56'
      />
      <path
        id='b8b248e7-1a59-4f26-9a1f-f08ba006a4a5-534'
        data-name='Path 338'
        d='M507.40726,331.04652H437.34993a5.6048,5.6048,0,1,1,0-11.20959h70.05733a5.6048,5.6048,0,1,1,0,11.20959Z'
        transform='translate(-299.1011 -180.5)'
        fill='#3f3d56'
      />
      <path
        id='bda578db-f3c3-41a7-b056-20e03a5846b5-535'
        data-name='Path 339'
        d='M401.40408,347.80052H350.20649a6.31225,6.31225,0,0,1-6.30558-6.30558V281.07782a6.31225,6.31225,0,0,1,6.30558-6.30558h51.19759a6.31224,6.31224,0,0,1,6.30558,6.30558v60.41712A6.31224,6.31224,0,0,1,401.40408,347.80052Z'
        transform='translate(-299.1011 -180.5)'
        fill={primaryColor}
      />
      <path
        id='f14e980f-453d-4106-8f6f-6df09975b283-536'
        data-name='Path 340'
        d='M506.92109,391.296H348.59193a5.6048,5.6048,0,0,1,0-11.2096H506.92109a5.6048,5.6048,0,1,1,0,11.2096Z'
        transform='translate(-299.1011 -180.5)'
        fill='#ccc'
      />
      <path
        id='a41c7d3f-1ab8-4fa8-8fe0-c7e1addd1852-537'
        data-name='Path 341'
        d='M506.92109,420.71987H348.59193a5.6048,5.6048,0,0,1,0-11.2096H506.92109a5.6048,5.6048,0,1,1,0,11.2096Z'
        transform='translate(-299.1011 -180.5)'
        fill='#ccc'
      />
      <path
        id='edc5b707-0197-4423-9b0e-a0519931be3d-538'
        data-name='Path 342'
        d='M506.92109,450.14376H348.59193a5.6048,5.6048,0,0,1,0-11.20959H506.92109a5.6048,5.6048,0,1,1,0,11.20959Z'
        transform='translate(-299.1011 -180.5)'
        fill='#ccc'
      />
      <path
        id='fb042130-6ee2-42a1-966d-03aff1c9a355-539'
        data-name='Path 343'
        d='M506.92109,479.56771H348.59193a5.6048,5.6048,0,0,1,0-11.2096H506.92109a5.6048,5.6048,0,1,1,0,11.2096Z'
        transform='translate(-299.1011 -180.5)'
        fill='#ccc'
      />
      <path
        id='be06b282-4586-4a92-87f7-3b8195752fb5-540'
        data-name='Path 344'
        d='M506.92109,508.9916H348.59193a5.6048,5.6048,0,0,1,0-11.2096H506.92109a5.6048,5.6048,0,1,1,0,11.2096Z'
        transform='translate(-299.1011 -180.5)'
        fill='#ccc'
      />
      <circle
        id='b1bf23b1-fd72-4a0f-8358-88f260cf5936'
        data-name='Ellipse 44'
        cx='258.5817'
        cy='54.08971'
        r='54.08971'
        fill={primaryColor}
      />
      <path
        id='a3de7fb3-a6ab-4159-b608-3b82343f6a5d-541'
        data-name='Path 395'
        d='M552.32281,255.22333a5.61,5.61,0,0,1-3.375-1.12164l-.06038-.04528-12.7118-9.72413a5.649,5.649,0,1,1,6.874-8.96631l8.23369,6.314L570.74,216.2966a5.64666,5.64666,0,0,1,7.91687-1.04571l.00164.00123-.12075.16767.124-.16767a5.65346,5.65346,0,0,1,1.04449,7.91845l-22.88526,29.843a5.65007,5.65007,0,0,1-4.49321,2.203Z'
        transform='translate(-299.1011 -180.5)'
        fill='#fff'
      />
      <path
        d='M899.8989,719.5h-368a1,1,0,0,1,0-2h368a1,1,0,1,1,0,2Z'
        transform='translate(-299.1011 -180.5)'
        fill='#3f3d56'
      />
    </svg>
  )
}
